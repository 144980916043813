<template>
  <v-container fluid>
    <v-card class="align-center justify-center" width="75%">
      <v-card-title class="headline">
        No Images Were Processed
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <span id="project-text">
          It seems like the images within project <b>{{ currentProject.name }} </b>
        </span>
        <span id="line-2">has not been processed yet</span>
        <span id="line-3">Please navigate to the project and process the images.</span>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" :to="`/project/${currentProject.pid}`" id="go-to-project-btn">
          Go To {{ currentProject.name }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'InsightsNoProcessedImages',
  computed: {
    ...mapGetters(['currentProject']),
  },
};
</script>
