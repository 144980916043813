<template>
  <v-container fluid ref="top">
    <v-overlay :value="retrievingData">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
      <p>Retrieving {{ retrievingDataType }} Data...</p>
    </v-overlay>
    <SkeletonLoader type='insights' v-if='loadingProjects || loadingProject' />
    <div v-else>
      <InsightsContent :pid="pid" />
      <ScrollToTop
        v-if="!loadingProject && !loadingProjects"
        :target="target"
        :options="options">
      </ScrollToTop>
      <SnackBar :snackbar="imageNotification.success" :text="imageNotification.message" />
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SkeletonLoader from '@components/common/loaders/SkeletonLoader.vue';
import InsightsContent from '@components/analytics/insights/InsightsContent.vue';
import ScrollToTop from '@components/common/ScrollToTop.vue';
import SnackBar from '@components/common/SnackBar.vue';

export default {
  name: 'NfInsights',
  props: ['pid'],
  data: () => ({
    target: {},
    options: {
      offset: 0,
      easing: 'easeInOutCubic',
      duration: 300,
    },
  }),
  components: {
    SkeletonLoader,
    ScrollToTop,
    InsightsContent,
    SnackBar,
  },
  methods: {
    ...mapActions(['setProjectsLoading', 'finishProjectLoad', 'getProjects']),
    async fetchProject() {
      this.setProjectsLoading(true);
      const { cid, adminStatus } = this.currentUser;

      // If user is admin, you can automatically retrieve all projects by CID
      if (adminStatus) await this.getProjects(`company_id=${cid}`);
      else {
        await this.getProjects(`company_id=${cid}&user_id=${this.currentUser.uid}`);
      }
    },
  },
  computed: {
    ...mapGetters(['currentUser', 'allProjects', 'currentProject', 'loadingProject', 'loadingProjects', 'currentProject', 'imageNotification', 'retrievingImageData', 'retrievingFolderData']),
    retrievingData() {
      return this.retrievingImageData || this.retrievingFolderData;
    },
    retrievingDataType() {
      if (this.retrievingFolderData) return 'Folder';
      if (this.retrievingImageData) return 'Image';
      return '';
    },
  },
  watch: {
    pid(newPid) { this.fetchProject(newPid); },
  },
  mounted() { this.target = this.$refs.top; },
  async created() { await this.fetchProject(this.pid); },
};
</script>
