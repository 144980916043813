<template>
  <v-card flat>
    <v-card-title class="headline">Please verify your account</v-card-title>
    <v-card-subtitle class="subtitle">Verification codes will expire in 10 minutes</v-card-subtitle>
    <v-card-text>
      <span class="mb-2" id="instruction">{{ message }}</span>
      <v-text-field
        outlined
        class="mt-2"
        id="forgot-password-2fa"
        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
        label="Verification Code"
        v-model="code"
        :type="show ? 'text' : 'password'"
        :error-messages="authenticationErrors.code"
        @click:append="show = !show"
      ></v-text-field>
      <v-btn color="primary" text small @click="resendCode">Resend Code</v-btn>
      <v-card-actions>
        <v-btn color="primary" text @click="navigateBack">Back</v-btn>
        <v-btn color="primary" id="code-verify" text @click="verifyCode">Verify</v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import services from '@services';
import cypressAccess from '@utils/cypress-access';

export default {
  name: 'VerifyUser',
  props: ['uid', 'type'],
  data: () => ({
    code: '',
    show: false,
    contact: '',
    sid: '',
  }),
  methods: {
    ...mapActions(['getUserDocumentById', 'createVerification', 'getVerification', 'updateVerification', 'verifyPasswordResetCode', 'clearAuthenticationErrors', 'setAuthenticationError']),
    async cancelVerification() {
      const payload = {
        sid: this.sid,
        verification: 'password-reset',
      };

      try {
        const res = await this.updateVerification(payload);
        return res;
      } catch (err) {
        return err;
      }
    },
    async sendVerification() {
      const channel = (this.type === 'phone') ? 'sms' : 'email';

      try {
        const res = await this.createVerification({
          uid: this.uid,
          channel,
          verification: 'password-reset',
        });
        return res;
      } catch (err) {
        return err;
      }
    },
    clearVerificationState() {
      this.sid = '';
      this.clearAuthenticationErrors();
    },
    navigateBack() {
      const path = `/forgot-password/verification-select/${this.uid}`;
      this.$router.push(path);
    },
    async resendCode() {
      // Cancel the current verification
      try {
        const res = await this.cancelVerification();
        if (res.status === 200) {
          await this.sendVerification();
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('err: ', err);
      }
    },
    async verifyCode() {
      if (this.code === '') {
        this.setAuthenticationError([
          {
            key: 'code',
            message: 'Please provide the verification code that was sent to your device',
          },
        ]);
        return;
      }
      const payload = {
        uid: this.currentUser.uid,
        verification: 'password-reset',
        body: {
          code: this.code,
          sid: this.sid,
          cypress_bypass: cypressAccess(),
        },
      };
      try {
        const res = await services.auth.twofactor_verify(payload);
        if (res.status === 200) {
          this.sid = '';
          this.$router.push(`/forgot-password/reset/${this.uid}`);
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('err: ', err);
      }
    },
  },
  computed: {
    ...mapGetters(['currentUser', 'authenticationErrors']),
    message() {
      if (this.type === 'email') return `We've sent a verification code to the email: ${this.contact}`;
      return `We've sent a verification code to the phone number: ${this.contact}`;
    },
  },
  async created() {
    let user;
    // If this.currentUser is null, fetch for it, otherwise set user to this.currentUser
    if (!this.currentUser) {
      const response = await this.getUserDocumentById(this.uid);
      user = response.data;
    } else user = cloneDeep(this.currentUser);

    if (this.type === 'email') this.contact = user.email;

    if (this.type === 'phone') {
      const lastFourDigits = user.phone.substring(user.phone.length - 4, user.phone.length);
      this.contact = `(***)-***-${lastFourDigits}`;
    }
    const res = await this.sendVerification();

    if (res.status === 200) {
      this.sid = res.data.sid;
    }
  },
  destroyed() {
    if (this.$route.name === 'VerificationMethods') {
      this.cancelVerification()
        .then(() => {
          this.clearVerificationState();
        });
    }
  },
};
</script>
