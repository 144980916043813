<template>
  <div :id="`donut-chart-${chartId}`" ref="donut"></div>
</template>

<script>
import Plotly from '@components/charts/custom-plotly';

export default {
  name: 'DonutChart',
  props: ['title', 'labels', 'values', 'orientation', 'showLegend', 'chartId'],
  data: () => ({
    donutChart: null,
    // Order: Green (None), Blue (Low), Yellow (Medium), Red (High)
    severityColors: ['rgb(44, 160, 44)', 'rgb(31, 119, 180)', 'rgb(255,255,0)', 'rgb(214, 39, 40)'],
  }),
  computed: {
    labelsDisplayed() {
      return this.labels;
    },
  },
  methods: {
    async plot(element, pieLabels, pieValues, configs) {
      // If the chartId is 'severity', then fix the color schemes according to the severity levels
      const colors = (this.chartId === 'severity') ? this.severityColors : [];

      const trace = {
        labels: pieLabels,
        values: pieValues,
        type: 'pie',
        textinfo: 'label+percent+name',
        textposition: 'inside',
        hole: 0.4,
      };

      // Set trace.marker to an object with property 'colors' assigned to it
      if (colors.length > 0) trace.marker = { colors };

      const data = [trace];
      const layout = {
        title: {
          text: this.title,
          font: { family: 'Courier New, monospace', size: 24 },
          showlegend: this.showLegend,
          legend: { orientation: this.orientation, valign: 'bottom' },
        },
      };

      await Plotly.newPlot(element, data, layout, configs);
      this.donutChart = await Plotly.relayout(element, { showlegend: this.showLegend });
    },
    async react(element, pieLabels, pieValues, configs) {
      const trace = {
        labels: pieLabels,
        values: pieValues,
        type: 'pie',
        textinfo: 'label+percent+name',
        textposition: 'inside',
        hole: 0.4,
      };

      const data = [trace];

      const layout = {
        title: {
          text: this.title,
          font: { family: 'Courier New, monospace', size: 24 },
          showlegend: this.showLegend,
          legend: { orientation: this.orientation, valign: 'bottom' },
        },
      };

      await Plotly.react(element, data, layout, configs);
      this.donutChart = await Plotly.relayout(element, { showlegend: this.showLegend });
    },
  },
  async mounted() {
    const donut = document.getElementById(`donut-chart-${this.chartId}`);

    const config = { displaylogo: false, responsive: true, displayModeBar: false };

    await this.plot(donut, this.labels, this.values, config);
  },
};
</script>
