<template>
<v-container>
  <v-card>
    <SkeletonLoader v-show="!loaded" type="image" />
    <img
      :src="
        (currentImage.compressedUrl)
          ? currentImage.compressedUrl
          : currentImage.originalImageUrl
      "
      ref="marker"
      width="100%"
      @load="handleLoading"
      v-show="loaded"
    />
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            icon
            :loading="generating"
            v-on="on"
            v-bind="attrs"
            :href="companyHas('poleByFolders') ? currentImage.processedImageUrl:
              currentImage.processedImageUrl"
            target="_blank"
            :small="isMobile"
          >
            <v-icon>mdi-fullscreen</v-icon>
          </v-btn>
        </template>
        <span>Full Sized Image</span>
      </v-tooltip>
    </v-card-actions>
  </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import SkeletonLoader from '@components/common/loaders/insights/SkeletonLoader.vue';
import { ReadOnlyAnnotator } from '@components/annotations/annotorious';
import { generateImageViewer } from '@components/annotations/fabric-renderer';

export default {
  name: 'AnnotoriousAnalyticsRenderer',
  props: ['images', 'currentTopImage'],
  components: {
    SkeletonLoader,
  },
  data: () => ({
    anno: null,
    generating: false,
    loaded: false,
  }),
  computed: {
    ...mapGetters([
      'currentImage',
      'currentAnnotations',
      'insightsSeverities',
      'insightsFaults',
      'small',
      'extraSmall',
      'flatProjectLabels',
      'companyHas',
    ]),
    isMobile() {
      return this.small || this.extraSmall;
    },
  },
  methods: {
    initAnnotations() {
      const { marker } = this.$refs;
      this.anno = ReadOnlyAnnotator(marker, this.flatProjectLabels);
      this.anno.setAnnotations(this.currentAnnotations);
    },
    async exportAnnotation() {
      this.generating = true;
      const [head, body, script] = await generateImageViewer(this.currentImage);
      const newWin = window.open('', Date.now().toString());
      newWin.document.head.innerHTML = head;
      newWin.document.body.innerHTML = body;
      newWin.document.head.appendChild(script);
      this.generating = false;
    },
    async refreshAnnotator() {
      this.anno.destroy();
      this.initAnnotations();
      await new Promise((r) => setTimeout(r, 1));
      this.anno.destroy();
      this.initAnnotations();
    },
    handleLoading() {
      this.loaded = true;
    },
  },
  mounted() {
    this.initAnnotations();
  },
  watch: {
    async currentAnnotations() {
      await this.refreshAnnotator();
    },
    async images() {
      await this.refreshAnnotator();
    },
    async insightsSeverities() {
      await this.refreshAnnotator();
    },
    async insightsFaults() {
      await this.refreshAnnotator();
    },
  },
};
</script>

<style scoped>
.center-image {
  text-align: center;
}
</style>>
