<template>
  <v-container fluid>
    <v-card>
      <v-tabs
        v-model="tab"
        class="elevation-2"
        dark
        centered
        vertical
      >
      <v-tabs-slider></v-tabs-slider>
      <v-tab
        v-for="tab in tabs"
        :key="tab.title"
        :to="tab.route"
        link
      >
        <v-icon>{{tab.icon}}</v-icon>
        {{tab.title}}
      </v-tab>

      <v-tab-item
        v-for="tab in tabs"
        :key="tab.title"
        :value="tab.route"
      >
        <v-card
          flat
          tile
        >
          <router-view></router-view>
        </v-card>
      </v-tab-item>
    </v-tabs>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'Settings',
  props: ['uid'],
  data: () => ({
    tab: null,
    tabs: [
      {
        tabId: 'profile',
        title: ' \xa0  \xa0  \xa0 User Profile',
        icon: 'account_box',
      },
      {
        tabId: 'reset',
        title: 'Reset Password',
        icon: 'lock',
      },
    ],
  }),
  mounted() {
    const { uid } = this;
    this.tabs.forEach((tab, index) => {
      const { tabId } = tab;
      this.tabs[index].route = `/settings/user/${uid}/${tabId}`;
    });
  },
};
</script>
