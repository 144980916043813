<template>
  <v-container fluid>
    <v-card elevation="4" :flat="small || extraSmall || medium" id="fault-filters">
      <v-card-title v-if="!(small || extraSmall || medium)" class="headline"> Filter </v-card-title>
      <v-container>
        Filter By:
        <br />
        <v-radio-group v-model="filterBy" :mandatory="false" row>
          <v-radio
            label="Severity"
            v-if="!companyHas('poleByFolders')"
            value="severity"
            id="filter-severity"
            @click="filterBySeverity"
          >
          </v-radio>

          <v-radio
            label="Priority"
            v-else
            value="priority"
            id="filter-priority"
            @click="filterByPriority"
          >
          </v-radio>
          <v-radio label="Fault Type" value="fault" id="filter-fault" @click="filterByFault">
          </v-radio>
        </v-radio-group>
        <br />
        <span v-show="filterBy == 'severity' && openSeverity && !closeFilters"
          >Severity Filter</span
        >
        <span v-show="filterBy == 'priority' && openPriority && !closeFilters"
          >Priority Filter</span
        >
        <span v-show="filterBy == 'fault' && openFault && !closeFilters">Filter by Fault</span>
      </v-container>
      <v-card-actions v-show="filterBy == 'severity' && openSeverity && !closeFilters">
        <v-checkbox
          v-model="severityTypeSelected.all"
          id="severity-all"
          label="All"
          class="mx-2"
          @click="severityClick('all')"
        ></v-checkbox>
        <v-checkbox
          v-model="severityTypeSelected.custom"
          id="severity-custom"
          label="Custom"
          class="mx-2"
          @click="severityClick('custom')"
        ></v-checkbox>
      </v-card-actions>
      <v-card-actions v-show="filterBy == 'priority' && openPriority && !closeFilters">
        <v-checkbox
          v-model="priorityTypeSelected.all"
          id="priority-all"
          label="All"
          class="mx-2"
          @click="priorityClick('all')"
        ></v-checkbox>
        <v-checkbox
          v-model="priorityTypeSelected.custom"
          id="priority-custom"
          label="Custom"
          class="mx-2"
          @click="priorityClick('custom')"
        ></v-checkbox>
      </v-card-actions>
      <v-card-actions v-show="filterBy == 'fault' && openFault && !closeFilters">
        <v-checkbox
          v-model="faultTypeSelected.all"
          id="fault-all"
          label="All"
          class="mx-2"
          @click="faultClick('all')"
        ></v-checkbox>
        <v-checkbox
          v-model="faultTypeSelected.custom"
          id="fault-custom"
          label="Custom"
          class="mx-2"
          @click="faultClick('custom')"
        ></v-checkbox>
      </v-card-actions>
      <SeverityFilter
        :all="severityTypeSelected.all"
        :custom="severityTypeSelected.custom"
        v-show="filterBy == 'severity' && openSeverity && !closeFilters"
      />
      <PriorityFilter
        :all="priorityTypeSelected.all"
        :custom="priorityTypeSelected.custom"
        v-show="filterBy == 'priority' && openPriority && !closeFilters"
      />
      <FilterFaultsInsights
        :all="faultTypeSelected.all"
        :custom="faultTypeSelected.custom"
        v-show="filterBy == 'fault' && openFault && !closeFilters"
      />
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import SeverityFilter from '@components/analytics/insights/filters/SeverityFilter.vue';
import PriorityFilter from '@components/analytics/insights/filters/PriorityFilter.vue';
import FilterFaultsInsights from '@components/analytics/insights/filters/FilterFaultsInsights.vue';
import { all } from '@components/analytics/insights/filters/faults';
import severities from '@utils/severity-level';

export default {
  name: 'FaultSummaryFilters',
  components: { SeverityFilter, FilterFaultsInsights, PriorityFilter },
  props: ['type'],
  data: () => ({
    severity: false,
    priority: false,
    fault: false,
    severityTypeSelected: {
      all: true,
      custom: false,
    },
    priorityTypeSelected: {
      all: true,
      custom: false,
    },
    faultTypeSelected: {
      all: true,
      custom: false,
      structure: false,
      cross: false,
      equipment: false,
    },
    currentFaultTypeFilter: 'all',
    faultFiltersSelected: [],
    severityFiltersSelected: [],
    priorityFiltersSelected: [],
    filterBy: null,
    timeout: null,
    openSeverity: false,
    openFault: false,
    openPriority: false,
    closeFilters: true,
  }),
  methods: {
    ...mapActions([
      'setFilterStatus',
      'setInsightFilters',
      'setInsightsFolders',
      'setSeverityFilter',
      'setInsightsFaults',
    ]),
    // Clicks on severity radio button
    severityClick(type) {
      const filterStatus = cloneDeep(this.currentFilterStatus);
      // Set custom to false
      if (type === 'all') this.severityTypeSelected.custom = !this.severityTypeSelected.custom;

      // Set all to false
      if (type === 'custom') this.severityTypeSelected.all = !this.severityTypeSelected.all;

      // Sets the filter status for severity
      filterStatus.severity.all = this.severityTypeSelected.all;
      filterStatus.severity.custom = this.severityTypeSelected.custom;
      this.setFilterStatus(filterStatus);
    },
    // Clicks on priority radio button
    priorityClick(type) {
      const filterStatus = cloneDeep(this.currentFilterStatus);
      // Set custom to false
      if (type === 'all') this.priorityTypeSelected.custom = !this.priorityTypeSelected.custom;

      // Set all to false
      if (type === 'custom') this.priorityTypeSelected.all = !this.priorityTypeSelected.all;

      // Sets the filter status for severity
      filterStatus.priority.all = this.priorityTypeSelected.all;
      filterStatus.priority.custom = this.priorityTypeSelected.custom;
      this.setFilterStatus(filterStatus);
    },
    // Clicks on fault type radio button
    faultClick(type) {
      const filterStatus = cloneDeep(this.currentFilterStatus);
      // Set custom to false
      if (type === 'all') this.faultTypeSelected.custom = !this.faultTypeSelected.custom;

      // Set all to false
      if (type === 'custom') this.faultTypeSelected.all = !this.faultTypeSelected.all;

      // Sets the filter status for severity
      filterStatus.fault.all = this.faultTypeSelected.all;
      filterStatus.fault.custom = this.faultTypeSelected.custom;
      this.setFilterStatus(filterStatus);
    },
    // Radio button click for filter by fault
    filterByFault() {
      if (this.filterBy === 'fault' && this.openFault && !this.closeFilters) {
        this.closeFilters = true;
      } else {
        this.closeFilters = false;
      }
      this.filterBy = 'fault';
      this.openFault = true;
      this.openSeverity = false;
      this.openPriority = false;
      const filterState = cloneDeep(this.currentFilterStatus);

      filterState.severity.opened = false;
      filterState.priority.opened = false;
      filterState.fault.opened = true;
      const fault = {
        opened: true,
        all: this.faultTypeSelected.all,
        custom: this.faultTypeSelected.custom,
      };
      filterState.fault = fault;

      this.setFilterStatus(filterState);
      this.setInsightFilters([]);
    },
    // Selects 'all' or 'custom'
    filterBySeverity() {
      if (this.filterBy === 'severity' && this.openSeverity && !this.closeFilters) {
        this.closeFilters = true;
      } else {
        this.closeFilters = false;
      }
      this.openSeverity = true;
      this.openPriority = false;
      this.openFault = false;
      const filterState = cloneDeep(this.currentFilterStatus);

      filterState.fault.opened = false;
      filterState.fault.value = [];
      filterState.severity.opened = true;
      const severity = {
        opened: true,
        all: this.severityTypeSelected.all,
        custom: this.severityTypeSelected.custom,
      };
      filterState.severity = severity;

      this.setFilterStatus(filterState);
    },
    filterByPriority() {
      if (this.filterBy === 'priority' && this.openPriority && !this.closeFilters) {
        this.closeFilters = true;
      } else {
        this.closeFilters = false;
      }
      this.filterBy = 'priority';
      this.openPriority = true;
      this.openSeverity = false;
      this.openFault = false;
      const filterState = cloneDeep(this.currentFilterStatus);

      filterState.fault.opened = false;
      filterState.priority.opened = true;
      const priority = {
        opened: true,
        all: this.priorityTypeSelected.all,
        custom: this.priorityTypeSelected.custom,
      };
      filterState.priority = priority;

      this.setFilterStatus(filterState);
    },
    closeDialog() {
      this.$emit('closeDialog', 'filter');
    },
  },
  computed: {
    ...mapGetters([
      'extraSmall',
      'small',
      'medium',
      'insightsFaults',
      'insightsSeverities',
      'currentFilterStatus',
      'companyHas',
      'allFolders',
    ]),
    isNFInsightsPage() {
      return this.$route.name === 'Insights' && this.companyHas('poleByFolders');
    },
  },
  watch: {
    currentFilterStatus: {
      deep: true,
      // Constantly keeps both the scrollable and un-scrollable filter boxes
      // in the same state
      handler(newStatus) {
        const { fault, severity, priority } = newStatus;
        if (fault.opened) {
          this.filterBy = 'fault';
          this.faultTypeSelected.all = fault.all;
          this.faultTypeSelected.custom = fault.custom;
        }

        if (severity.opened) {
          this.filterBy = 'severity';
          this.severityTypeSelected.all = severity.all;
          this.severityTypeSelected.custom = severity.custom;
        }

        if (priority.opened) {
          this.filterBy = 'priority';
          this.priorityTypeSelected.all = priority.all;
          this.priorityTypeSelected.custom = priority.custom;
        }
      },
    },
    insightsSeverities(newSeverities) {
      this.severityFiltersSelected = newSeverities;

      if (this.filterBy !== 'fault') {
        this.setInsightFilters(newSeverities);
      }
    },
    filterBy(newValue, oldValue) {
      if (oldValue !== newValue) {
        this.closeFilters = false;
      }
    },
    closeFilters(newValue) {
      if (newValue) {
        this.setInsightsFolders(this.allFolders);
        this.setFilterStatus({
          severity: {
            opened: false,
            all: true,
            custom: false,
            values: severities,
          },
          priority: {
            opened: false,
            all: true,
            custom: false,
            values: ['None', 'TD-1', 'TD-2', 'TD-4', 'TD-5', 'Emergency'],
          },
          fault: {
            opened: false,
            all: true,
            custom: false,
            values: all,
          },
        });
      }
    },
  },
};
</script>
