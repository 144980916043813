<template>
    <v-tooltip v-model="showDownloadJSON" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          id="download-json-btn"
          icon
          x-large
          color="primary"
          v-bind="attrs"
          v-on="on"
          @click="downloadJSON"
        >
          <v-icon>fab fa-js</v-icon>
        </v-btn>
      </template>
      Export to JSON
    </v-tooltip>
</template>

<script>
import { mapGetters } from 'vuex';
import { saveAs } from 'file-saver';
import helpers, { find } from '@methods/helpers';

export default {
  name: 'DownloadJsonData',
  data: () => ({
    showDownloadJSON: false,
  }),
  methods: {
    downloadJSON() {
      const jsonData = this.allImages.map((image) => {
        const currentImage = image;
        const { location } = currentImage;
        currentImage.location = [location.latitude, location.longitude];
        const associatedProject = find(this.allProjects,
          (project) => project.pid === currentImage.project_id);
        const { length } = currentImage.process_tracking;
        const severity = length > 1 ? currentImage.process_tracking[length - 1].severity : 'n/a';
        const labels = length > 1 ? currentImage.process_tracking[length - 1].labels : 'n/a';
        return {
          companyId: currentImage.cid,
          date: helpers.convertToDate(currentImage.date).format('YYYY-MM-DD'),
          filename: currentImage.filename,
          isDeleted: false,
          isProcessed: length > 0,
          labels,
          location: currentImage.location,
          originalImageUrl: currentImage.originalImageUrl,
          processedImageUrl: currentImage.processedImageUrl,
          projectId: currentImage.project_id,
          severity,
          projectName: associatedProject === undefined ? 'N/A' : associatedProject.name,
        };
      });

      const fileToSave = new Blob([...JSON.stringify(jsonData)], {
        type: 'application/json',
        name: 'insights.json',
      });
      saveAs(fileToSave);
    },
    testDownloadJSON() {
      const data = {
        key: 'value',
      };
      const fileName = 'myData.json';

      const fileToSave = new Blob([JSON.stringify(data)], {
        type: 'application/json',
        name: fileName,
      });

      saveAs(fileToSave);
    },
  },
  computed: {
    ...mapGetters(['allProcessedImages', 'allImages', 'allProjects']),
  },
};
</script>
