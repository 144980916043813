<template>
<v-container fluid>
  <v-card>
    <BarChart
      title=""
      :labels="labels"
      :values="values"
      ref="chart"
    />
  </v-card>
</v-container>
</template>

<script>
import BarChart from '@components/charts/BarChart.vue';

export default {
  name: 'ReportChart',
  props: ['labels', 'values', 'dateRange'],
  components: {
    BarChart,
  },
  methods: {
    average(values) {
      const sum = values.reduce((a, b) => a + b, 0);
      return new Intl.NumberFormat().format(sum / values.length);
    },
  },
  watch: {
    labels() {
      this.$refs.chart.react(this.labels, this.values);
    },
    values() {
      this.$refs.chart.react(this.labels, this.values);
    },
  },
};
</script>
