<template>
  <v-container fluid>
    <div id="second-reviewer-form">
      <v-row>
        <v-col :cols="(showReviewedField) ? '8' : '12'">
          <v-select
            outlined
            dense
            :items="reviewers"
            clearable
            item-text="name"
            return-object
            label="Select a second reviewer"
            v-model="reviewer"
            @click:clear="setSecondReviewerCleared"
            @change="reviewerChange"
          ></v-select>
        </v-col>
        <v-col cols="4" v-if="showReviewedField">
          <v-select
            outlined
            dense
            :items="['Yes', 'No']"
            label="Reviewed?"
            v-model="reviewer.reviewed"
            @change="changeReviewStatus"
          ></v-select>
        </v-col>
      </v-row>
      <v-textarea
        outlined
        dense
        @input="getReason"
        label="Reason for a Second Reviewer"
        v-model="reviewer.reason"
        counter
      ></v-textarea>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import userHelpers from '@methods/get-users.js';

export default {
  name: 'SecondReviewerForm',
  props: ['secondReviewer'],
  data: () => ({
    reviewers: [],
    reviewer: {
      assigner_uid: '',
      email: '',
      reviewed: 'No',
      reason: '',
      name: '',
      uid: '',
    },
    updated: {
      assigner_uid: '',
      email: '',
      name: '',
      uid: '',
      reviewed: false,
      reason: '',
    },
    reviewerFields: ['assigner_uid', 'email', 'uid', 'name'],
  }),
  methods: {
    ...mapActions(['setModifiedFolder', 'clearSecondReviewerForm', 'setSecondReviewer']),
    initReviewer() {
      const secondReviewer = cloneDeep(this.secondReviewer);

      if (Object.keys(secondReviewer).length > 0) {
        this.reviewerFields.forEach((field) => {
          this.reviewer[field] = secondReviewer[field];
          this.updated[field] = secondReviewer[field];
        });
        this.reviewer.reviewed = secondReviewer.reviewed ? 'Yes' : 'No';
        this.reviewer.reason = secondReviewer.reason;

        this.updated.reviewed = secondReviewer.reviewed;
        this.updated.reason = secondReviewer.reason;
      }
    },
    setSecondReviewerCleared() {
      this.clearSecondReviewerForm(true);
    },
    // Detects changes in the reviewer name
    reviewerChange() {
      if (this.reviewer) {
        // Only modify these changes: assigner_uid, email, uid, name
        const reviewerFields = ['assigner_uid', 'email', 'uid', 'name'];
        reviewerFields.forEach((field) => {
          this.updated[field] = this.reviewer[field];
        });
      } else {
        this.reviewer = {
          assigner_uid: '',
          email: '',
          name: '',
          uid: '',
          reviewed: 'No',
          reason: '',
        };
        this.updated = cloneDeep(this.reviewer);
        this.updated.reviewed = (this.updated.reviewed === 'Yes');
      }
      this.setSecondReviewer(this.updated);
    },
    getReason() {
      // Detects change in reason string. Update modifiedFolder in Vuex
      this.updated.reason = this.reviewer.reason;
      this.setModifiedFolder(this.updated);
    },
    changeReviewStatus() {
      // Detects 'reviewed' value. Update modifiedFolder in Vuex
      this.updated.reviewed = this.reviewer.reviewed === 'Yes';
      this.setModifiedFolder(this.updated);
    },
  },
  computed: {
    ...mapGetters(['currentCompany', 'currentUser']),
    showReviewedField() {
      if (Object.keys(this.secondReviewer).length > 0) {
        return this.currentUser.uid === this.secondReviewer.uid;
      }
      return false;
    },
  },
  async created() {
    this.reviewers = await userHelpers
      .getSecondReviewer(this.currentCompany.employees, this.currentUser.uid);
  },
  mounted() {
    this.initReviewer();
    this.setSecondReviewer(this.updated);
  },
};
</script>
