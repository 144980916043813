<template>
  <v-card>
    <v-app-bar color="white" fixed>
      <v-toolbar-items>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              id="rectangle-btn"
              icon
              v-on="on"
              @click="setMode('rect')"
            ><v-icon>crop_3_2</v-icon></v-btn>
          </template>
          Draw Rectangle
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              id="polygon-btn"
              icon
              v-on="on"
              @click="setMode('polygon')"
            ><v-icon>fas fa-draw-polygon</v-icon></v-btn>
          </template>
          Draw Polygon
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              id="visible-btn"
              icon
              v-on="on"
              @click="setVisible"
            ><v-icon>{{
              (visibility)
                ? 'visibility_off' : 'visibility'
              }}</v-icon></v-btn>
          </template>
          {{ (visibility) ? 'Hide Annotations' : 'Show Annotations' }}
        </v-tooltip>
        <v-tooltip bottom v-if="aiProcessed">
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              id="human-in-loop-thumbs-up-btn"
              color="primary"
              icon
              @click="updateFeedback(1)"
            >
              <v-icon>
                {{
                  aiFeedback === 1
                    ? 'mdi-thumb-up'
                    : 'mdi-thumb-up-outline'
                }}
              </v-icon>
            </v-btn>
          </template>
          {{
            aiFeedback === 1 ?
              'Reset AI Feedback'
              :
              'The AI detected labels are accurate'}}
        </v-tooltip>
        <v-tooltip bottom v-if="aiProcessed">
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              id="human-in-loop-thumbs-down-btn"
              color="primary"
              icon
              @click="updateFeedback(-1)"
            ><v-icon>
              {{aiFeedback === -1 ? 'mdi-thumb-down' : ' mdi-thumb-down-outline'}}

            </v-icon>
            </v-btn>
          </template>
          {{
            aiFeedback === -1 ?
              'Reset AI Feedback'
              :
              'The AI detected labels are not accurate'}}
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              id="save-btn"
              icon
              v-on="on"
              @click="save"
            ><v-icon>save</v-icon></v-btn>
          </template>
          Save Annotations
        </v-tooltip>
        <v-dialog
          v-model="confirmationDialog"
          id="ai-unsaved-changes"
          v-if="changeDetected"
          width="50%"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              id="ai-unsave-confirm-btn"
              icon
              v-on="on"
              v-bind="attrs"
            ><v-icon>mdi-close</v-icon></v-btn>
          </template>
          <UnsavedAnnotationsConfirm
            :unsavedAmount="amountOfChanges"
            v-on:saveAnnotations="closeSave"
            v-on:continueAnnotations="closeConfirmation"
            v-on:discardAnnotations="discardAnnotations"
          />
        </v-dialog>
        <v-btn
          v-else
          id="human-in-loop-close-btn"
          color="primary"
          icon
          @click="closeSave"
        ><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              :color="shouldZoom ? 'primary' : 'grey'"
              id="search-minus-btn"
              icon
              @click="selectZoom"
            ><v-icon>fas fa-arrows-alt</v-icon></v-btn>
          </template>
          Select Zoom
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              color="primary"
              id="search-minus-btn"
              icon
              @click="resetSize"
            ><v-icon>fas fa-search</v-icon></v-btn>
          </template>
          Reset
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              color="primary"
              id="search-minus-btn"
              icon
              @click="zoomOut"
            ><v-icon>fas fa-search-minus</v-icon></v-btn>
          </template>
          Zoom Out
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              id="search-plus-btn"
              icon
              v-on="on"
              @click="zoomIn"
            ><v-icon>fas fa-search-plus</v-icon></v-btn>
          </template>
          Zoom In
        </v-tooltip>
          <RequestLabelModal />
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              id="pause-btn"
              icon
              v-on="on"
              @click="pauseTimer"
              v-if="companyHas('timeLogs')"
            ><v-icon>pause_circle_filled</v-icon></v-btn>
          </template>
          Pause
        </v-tooltip>
      </v-toolbar-items>
      <v-icon color="primary" v-if="isAnnotified">check_circle</v-icon>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-spacer></v-spacer>
        <v-container class="px-0" fluid v-if="companyHas('labelVerified')">
          <v-spacer></v-spacer>
          <v-switch
            v-model="verifiedLabelStatus"
            label="Add image to retraining batch"
            @change="switchHandler"
            :loading="updatingVerificationStatus"
            :error="verifyLabelError"
          ></v-switch>
          <v-spacer></v-spacer>
        </v-container>
        <SeverityComboBox />
      </v-toolbar-items>
      <v-toolbar-title> Edit Annotations</v-toolbar-title>
    </v-app-bar>
    <ImageEditor
      ref="editor"
      :visibility="visibility"
      :size="imageSize"
      :tab="(editMode || $route.meta.editMode) ? 'ai' : 'original'"
      @navigated="handleNavigation"
      @zoomSelection="handleSelectZoom"
    ></ImageEditor>
    <v-overlay :value="savingAnnotations">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
      <p>Saving changes</p>
    </v-overlay>
    <v-overlay :value="timerPaused">
      <v-icon size="99" @click="unPausetimer">play_circle_filled</v-icon>
    </v-overlay>
  </v-card>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { cloneDeep, uniq, find } from 'lodash';
import { convertW3CToRegions } from '@components/annotations/annotorious';
import ImageEditor from '@components/annotations/ImageEditor.vue';
import RequestLabelModal from '@components/images/labels/RequestLabelModal.vue';
import SeverityComboBox from '@components/annotations/SeverityComboBox.vue';
import UnsavedAnnotationsConfirm from '@components/annotations/UnsavedAnnotationsConfirm.vue';
import { filterVegetationEncroachment, getHighestSeverity } from '@utils/images';
import { deepCompare, getAmountChangedBy, severityColorPicker } from '@methods/helpers';
import services from '@services';
import moment from 'moment';

export default {
  name: 'HumanInTheLoop',
  props: ['pid', 'editMode'],
  components: {
    ImageEditor,
    SeverityComboBox,
    UnsavedAnnotationsConfirm,
    RequestLabelModal,
  },
  data: () => ({
    confirmationDialog: false,
    visibility: true,
    imageSize: 1024,
    savingAnnotations: false,
    timerPaused: false,
    shouldZoom: false,
    scrollTop: 0,
    scrollLeft: 0,
    selectedImages: [],
    verifiedLabelStatus: null,
    nextImage: null,
    verifyLabelError: false,
    updatingVerificationStatus: false,
  }),
  watch: {
    shouldZoom() {
      this.$refs.editor.anno.readOnly = this.shouldZoom;
      this.$refs.editor.anno.disableEditor = this.shouldZoom;
      this.$refs.editor.cancelSelected();
    },
    currentImage(newImage) {
      if (this.companyHas('labelVerified')) {
        this.verifiedLabelStatus = newImage.process_tracking.slice(-1)[0].labels_verified;
      }
    },
    changeDetected(newChangeDetection) {
      if (newChangeDetection && !this.verifiedLabelStatus) {
        this.verifyLabelError = true;
      }
    },
  },
  methods: {
    ...mapActions([
      'getImage',
      'updateImageAnnotation',
      'setCurrentImage',
      'setNotification',
      'setCurrentAnnotations',
      'startTimer',
      'stopTimer',
      'resetTimer',
      'updateAiFeedBack',
      'updateImage',
      'setRegions',
    ]),
    switchHandler(verified) {
      if (this.verifyLabelError) {
        this.verifyLabelError = false;
      }
      const update = { ...this.currentImage };
      if (verified === update.process_tracking.slice(-1)[0].labels_verified) return;
      update.process_tracking.slice(-1)[0].labels_verified = verified;
      const payload = {
        cid: this.currentCompany.cid,
        pid: this.currentProject.pid,
        iid: this.currentImage.id,
        image: { update },
      };
      this.updatingVerificationStatus = true;

      this.updateImage(payload)
        .then((res) => {
          const { data } = res;
          const verifiedStatus = (data.process_tracking.slice(-1)[0].labels_verified)
            ? 'verified' : 'no longer verified';
          this.updatingVerificationStatus = false;
          this.setNotification({
            success: true,
            message: `The labels for ${data.filename} are ${verifiedStatus}`,
            color: '',
          });
        })
        .catch((err) => {
          this.updatingVerificationStatus = false;
          this.setNotification({
            success: false,
            message: err.response?.data.message,
            color: 'error',
          });
        });
    },
    async updateFeedback(feedback) {
      const aiFeedback = this.currentImage.ai_feedback === feedback ? 0 : feedback;
      const query = {
        filename: this.currentImage.filename,
        companyId: this.currentImage.companyId,
        project_id: this.currentImage.project_id,
        date: this.currentImage.date,
        imageId: this.currentImage.id,
      };
      try {
        await this.updateAiFeedBack({ query, aiFeedback });
      } catch (err) {
        this.setNotification({ success: false, message: err.message });
      }
    },
    reroute() {
      if (this.$route.name === 'Insights') this.$emit('closeHIL');
      else if (
        this.editMode
        || this.$route.meta.editMode
      ) this.$router.push({ path: `/project/${this.pid}/images/ai/${this.currentImage.filename}/processed` });
      else this.$router.push({ path: `/project/${this.pid}/images/original/${this.currentImage.filename}` });
    },
    closeConfirmation() {
      this.confirmationDialog = false;
    },
    discardAnnotations() {
      this.setRegions([...this.currentAnnotations]);
      this.reroute();
    },
    async closeSave() {
      await this.save();
      this.reroute();
    },
    removeAnnotation() {
      this.$refs.editor.cancelSelected();
    },
    toggleZoom() {
      this.shouldZoom = !this.shouldZoom;
    },
    setMode(toolname) {
      this.$refs.editor.setMode(toolname);
    },
    setVisible() {
      this.visibility = !this.visibility;
    },
    zoomIn() {
      this.shouldZoom = false;
      this.setSize(100);
    },
    zoomOut() {
      this.shouldZoom = false;
      // TODO: Externalize hardcoded value
      if (this.imageSize > 1024) {
        this.setSize(-100);
      }
    },
    scrollTo(clientX, clientY) {
      Vue.nextTick(() => {
        this.scrollable.scrollLeft += (clientX - this.image.x / 2);
        this.scrollable.scrollTop += (clientY - this.image.y / 2);
      });
    },
    setSize(size) {
      this.imageSize += size;
      this.scrollTo(0, 0);
    },
    resetSize() {
      this.shouldZoom = false;
      this.$refs.editor.cancelSelected();
      this.imageSize = 1024;
    },
    selectZoom() {
      this.toggleZoom();
    },
    handleSelectZoom(event) {
      if (!this.shouldZoom) return;
      this.imageSize *= 2;
      this.scrollTo(event.clientX, event.clientY);
    },
    pauseTimer() {
      this.stopTimer();
      this.timerPaused = true;
    },
    unPausetimer() {
      this.startTimer();
      this.timerPaused = false;
    },
    // Saves to process tracking??
    async mainSave() {
      let response = { data: {}, success: false };
      const image = cloneDeep(this.currentImage);
      const processTracking = image.process_tracking;

      if (processTracking.length === 0) {
        processTracking.push({ confidences: [], regions: [] });
      }
      const { length } = processTracking;
      const { confidences = [] } = processTracking[length - 1];
      if (this.changeDetected) {
        // List of W3C objects
        const modifications = cloneDeep(this.modifiedAnnotations)
          .map((anno) => convertW3CToRegions(anno, this.flatProjectLabels))
          .map((anno) => ({
            confidence: anno.confidence,
            regions: anno.regions,
            labels: anno.label,
            from_model: (anno.from_model) ? anno.from_model : false,
          }));

        const getAbbreviation = (faultType) => {
          const targetLabel = this.projectLabels.find((label) => label.faultType === faultType);
          return targetLabel ? targetLabel.abbreviation : faultType;
        };

        const regions = modifications.map((mod) => mod.regions);
        const labels = modifications.map((mod) => {
          if (typeof (mod.labels) === 'string') {
            const labelArray = mod.labels.split(':');
            return {
              severity: labelArray[0],
              label: labelArray[1],
              from_model: mod.from_model,
              abbreviation: getAbbreviation(labelArray[1]),
              confidence: mod.confidence,
            };
          }
          return mod.labels;
        });

        // const confidences = labels.map((label, index) => {
        //   if (!label.from_model) {
        //     return 1.0;
        //   }
        //   // eslint-disable-next-line max-len
        // eslint-disable-next-line max-len
        //   return processTracking.confidences && processTracking.confidences[index] ? processTracking.confidences[index] : 1.0;
        // });
        processTracking.slice(-1)[0].regions = { ...regions };
        processTracking.slice(-1)[0].labels = labels;
        processTracking.slice(-1)[0].confidences = confidences;
        processTracking.slice(-1)[0].severity = getHighestSeverity(labels);

        let timeLogs = image.time_logs;
        if (timeLogs !== null && typeof timeLogs === 'object') {
          timeLogs.push({
            userName: `${this.currentUser.firstName} ${this.currentUser.lastName}`,
            userId: this.currentUser.uid,
            timeSpent: this.timeSpent,
            date: Date.now(),
          });
        } else {
          timeLogs = [{
            userName: `${this.currentUser.firstName} ${this.currentUser.lastName}`,
            userId: this.currentUser.uid,
            timeSpent: this.timeSpent,
            date: moment(),
          }];
        }

        const payload = {
          company_id: this.currentCompany.cid,
          project_id: this.currentProject.pid,
          id: this.currentImage.id,
          update: {
            process_tracking: processTracking,
            time_logs: timeLogs,
            bucket: this.currentImage.bucket,
            storage_platform: this.currentImage.storage_platform,
          },
        };

        payload.project = this.currentProject.name;

        response = await services.images.updateImageAnnotation(
          payload.company_id,
          payload.project_id,
          payload.id,
          payload.update,
        );

        if (this.openVerifiedLabelDialog) {
          this.openVerifiedLabelDialog = false;
        }
      }
      return response;
    },
    async save() {
      if (this.changeDetected) {
        this.stopTimer();
        this.savingAnnotations = true;
        const response = await this.mainSave();

        if (response.status === 200) {
          this.$nextTick(() => {
            // temporary solution from compression timing issue
            const image = response.data;
            image.compressedProcessUrl = image.processedImageUrl;
            this.setCurrentImage(image);
          });
          const { filename } = response.data;
          const mod = cloneDeep(this.modifiedAnnotations);

          // TODO: find a solution around this.
          // TODO: We only filter vegetation encroachment if it's newfoundland power.

          const regExp = /NewfoundLand Power/g;
          const isNf = this.currentCompany.companyName.search(regExp);

          let annos;

          if (isNf) {
            const projectType = this.currentProject.project_type;
            const isDistribution = projectType && projectType === 'Distribution';
            annos = (isDistribution) ? filterVegetationEncroachment(mod) : mod;
          } else annos = mod;

          this.setCurrentAnnotations(annos);
          this.setNotification({ success: true, message: `The annotations for the image with filename ${filename} has been modified` });
          this.savingAnnotations = false;
          this.resetTimer();
          this.startTimer();

          if (this.verifyLabelError) this.verifyLabelError = false;
        }
      }
    },
    uniqueClassTypes: (classes) => uniq(classes),
    getChipColor(classType) {
      // Handles the classTypes with <severity>:<label> format
      let key;
      if (classType.includes(':')) {
        [key] = classType.split(':');
      } else {
        const { severity } = find(
          this.flatProjectLabels,
          (abbreviation) => abbreviation.faultType === classType,
        );

        key = severity;
      }

      return severityColorPicker(key);
    },
    getLabelText: (classType, labels) => {
      // Removes the : from labels shown as <severity>:<label> format
      const classLabel = (classType.includes(':')) ? classType.split(':')[1] : classType;
      return `${classLabel} (${labels.filter((label) => label === classType).length})`;
    },
    async handleNavigation(nextImage) {
      this.stopTimer();
      await this.save();
      this.resetTimer();
      this.startTimer();
      nextImage.source = 'hitl'; // eslint-disable-line no-param-reassign
      this.setCurrentImage(nextImage);
    },
  },
  computed: {
    ...mapGetters([
      'currentImage',
      'currentCompany',
      'currentProject',
      'currentAnnotations',
      'modifiedAnnotations',
      'timeSpent',
      'currentUser',
      'companyHas',
      'flatProjectLabels',
      'currentSeverityFilters',
      'projectLabels',
    ]),
    aiFeedback() {
      const feedback = this.currentImage.ai_feedback;
      return feedback == null ? 0 : feedback;
    },
    aiProcessed() {
      return !!this.currentImage.processedImageUrl;
    },
    imageDetail() {
      const image = { ...this.currentImage };

      const details = {
      };

      details.severity = 'None';
      // if process tracking exists
      if (image.process_tracking.length) {
        const processing = image.process_tracking.slice(-1)[0];
        details.caption = processing.caption;
        details.classes = processing.labels;
        details.severity = processing.severity;
        details.priority = processing.priority || 'None';
        details.reviewed = processing.reviewed;
      }

      return details;
    },
    scrollable() {
      return document.getElementById('scrollable');
    },
    image() {
      return document.getElementById('image-editor-img');
    },
    changeDetected() {
      const detected = !deepCompare(this.currentAnnotations, this.modifiedAnnotations);
      return detected;
    },
    amountOfChanges() {
      return getAmountChangedBy(this.currentAnnotations, this.modifiedAnnotations, 'id');
    },
    isAnnotified() {
      const annotified = !!(this.currentImage.annotated);

      return annotified;
    },
    annoLabels() {
      const uniqLabels = this.uniqueClassTypes(this.imageDetail.classes);
      const comboLabels = uniqLabels.map((l) => ({
        value: l,
        text: this.getLabelText(l, this.imageDetail.classes),
        color: this.getChipColor(l),
      }));
      return comboLabels;
    },
  },
  mounted() {
    this.startTimer();
    this.verifiedLabelStatus = this.currentImage.process_tracking.slice(-1)[0].labels_verified;
  },
  destroyed() {
    this.resetTimer();
  },
};
</script>
