<template>
  <v-snackbar v-model="displayed" :id="id">
    {{ text }}
    <template v-slot:action="{ attrs }">
      <v-btn
        id="close-snackbar-btn"
        color="primary"
        text
        v-bind="attrs"
        @click="displayed=false"
      >Close</v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'SnackBar',
  props: {
    snackbar: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: 'generic-snackbar',
    },
  },
  data: () => ({
    displayed: false,
  }),
  watch: {
    snackbar(newSnackbar) {
      this.displayed = newSnackbar;
    },
  },
};

</script>
