<template>
  <v-card :loading="loadingInvoice">
    <v-card-title class="headline">{{ title }}</v-card-title>
    <v-divider></v-divider>
    <v-card-text id="description">
      {{ content }}
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn id="close-btn" color="primary" @click="close">Close</v-btn>
      <v-btn id="pause-btn" color="primary" @click="confirm">Confirm</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'PauseSubscriptionConfirm',
  props: ['id', 'invoiceId', 'title', 'content'],
  methods: {
    ...mapActions(['updateSubscriptionById', 'getInvoice', 'payInvoice', 'setSubscriptionNotification']),
    close() {
      this.$emit('closePauseSub');
    },
    async confirm() {
      const { title } = this;
      const data = {
        pause_collection: (title.includes('Pausing') || title.includes('Pause')) ? {
          behavior: 'void',
        } : '',
      };

      const payload = {
        id: this.id,
        data,
      };

      this.updateSubscriptionById(payload)
        .then(() => {
          this.close();
          this.setSubscriptionNotification({ success: true, message: `${this.currentSubscription.plan.nickname} has been paused.` });
        });
    },
  },
  computed: {
    ...mapGetters(['currentInvoice', 'loadingInvoice', 'currentSubscription']),
  },
  created() {
    // this.getInvoice(this.invoiceId);
  },
};
</script>
