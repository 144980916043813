<template>
  <v-container fluid>
    <NoImageProcessingPlan v-if="!hasService"/>
    <ImageProcessingSettings v-else />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import NoImageProcessingPlan from '@components/billing/subscriptions/NoImageProcessingPlan.vue';
import ImageProcessingSettings from '@components/billing/subscriptions/ImageProcessingSettings.vue';

export default {
  name: 'SubscriptionPlans',
  components: {
    NoImageProcessingPlan,
    ImageProcessingSettings,
  },
  methods: {
    ...mapActions(['getCustomer']),
  },
  computed: {
    ...mapGetters(['currentCompany', 'currentCustomer']),
    hasService() {
      const { services } = this.currentCompany;
      return Object.keys(services).includes('gold')
        || Object.keys(services).includes('silver')
        || Object.keys(services).includes('bronze');
    },
  },
  created() {
    const { customerId } = this.currentCompany;
    this.getCustomer(customerId);
  },
};
</script>
