import services from '@services';

const ROOT_FOLDER = {
  name: '__all__',
  path: '__all__',
  nice_date: '',
  date: 'Z',
};

const nfReviewedFilter = (folders, filter) => {
  const reviewedFolders = folders.filter((folder) => {
    if (!folder.second_reviewer) return false;

    return folder.second_reviewer.reviewed;
  });

  const unreviewedFolders = folders.filter((folder) => {
    if (!folder.second_reviewer) return true;

    return !folder.second_reviewer.reviewed;
  });

  switch (filter) {
    case 'reviewed':
      return reviewedFolders;
    case 'not_reviewed':
      return unreviewedFolders;
    default:
      return folders;
  }
};

const generalReviewedFilter = (folders, filter) => {
  const reviewedFolders = folders.filter((folder) => folder.complete_inspection);
  const unreviewedFolders = folders.filter((folder) => !folder.complete_inspection);

  switch (filter) {
    case 'reviewed':
      return reviewedFolders;
    case 'unreviewed':
      return unreviewedFolders;
    default:
      return folders;
  }
};

const state = {
  loadingFolder: false,
  loading: false,
  folders: [],
  pageNo: 1,
  insightsFolders: [],
  folder: {},
  selectedFolderPath: '',
  showingFolders: true,
  editing: false,
  filter: {},
  cleared: false,
  modified: {
    field_inspection: false,
    clustering_uncertainty: false,
    overall_severity: '',

    // fields that are within a reviewer object. NOT FOLDER
    second_reviewer: {
      reason: '',
      uid: '',
      assigner_uid: '',
      name: '',
      email: '',
      reviewed: false,
    },
  },
  epriMapKey: Math.floor(Math.random() * (10 - 1 + 1)) + 1,
};

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const getters = {
  currentPage: (state) => state.pageNo,
  selectedFolderPath: (state) => state.selectedFolderPath,
  loadingFolders: (state) => state.loading,
  loadingFolder: (state) => state.loadingFolder,
  epriMapKey: (state) => state.epriMapKey,
  allFolders: (state) => {
    // If it's Newfoundland Power, then use the method nfReviewedFilter to filter through their
    // defintion of what a reviewed folder is.
    const isNfInstance = state.filter.company === 'Newfoundland Power' || state.filter.company === 'Newfoundland Power (staging)';
    if (isNfInstance) {
      return nfReviewedFilter(state.folders, state.filter.filterKey);
    }

    // Otherwise for the general instances, we'll just look for a property 'reviewed'
    // within a folder object
    return generalReviewedFilter(state.folders, state.filter.filterKey);
  },
  insightsFolders: (state) => {
    const reviewed = state.insightsFolders.filter((folder) => {
      if (!folder.second_reviewer) return false;

      return folder.second_reviewer.reviewed;
    });

    const unreviewed = state.insightsFolders.filter((folder) => {
      if (!folder.second_reviewer) return true;

      return !folder.second_reviewer.reviewed;
    });

    switch (state.filter) {
      case 'reviewed':
        return reviewed;
      case 'not_reviewed':
        return unreviewed;
      default:
        return state.insightsFolders;
    }
  },
  currentFolder: (state) => state.folder,
  rootFolder: () => ROOT_FOLDER,
  showingFolders: (state) => state.showingFolders,
  editingFolder: (state) => state.editing,
  modifiedFolder: (state) => state.modified,
  secondReviewerCleared: (state) => state.cleared,
};

const actions = {
  setCurrentPage({ commit }, pageNo) {
    commit('set_page_number', pageNo);
  },
  // Fetches only folders pertaining to the project being viewed
  async getFoldersByProject({ commit }, payload) {
    commit('set_loading_folders', true);

    return new Promise((resolve, reject) => {
      services.folders.getByProject(payload.cid, payload.pid)
        .then((res) => {
          const folders = res.data.sort((a, b) => {
            const textA = a.path.toUpperCase();
            const textB = b.path.toUpperCase();

            const s = (textA > textB) ? 1 : 0;
            return (textA < textB) ? -1 : s;
          });

          commit('set_folders', folders);
          if (folders[0] !== undefined) commit('set_folder', folders[0]);
          else commit('set_folder', ROOT_FOLDER);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },

  // Adds another folder to the list of folders
  async addFolderProject({ commit, state }, folder) {
    const folderExists = state.folders.find((f) => f.path === folder.path);
    if (folderExists) return;
    commit('set_folders', [...state.folders, folder]);
  },

  // Creates new folders
  async createFolders({ commit }, payload) {
    return new Promise((resolve, reject) => {
      services.folders.saveFolders(payload)
        .then((res) => {
          if (res.status === 201) {
            commit('set_folders', payload);
            resolve(res);
          }
        })
        .catch((err) => reject(err));
    });
  },

  createFolder({ commit }, payload) {
    return new Promise((resolve, reject) => {
      services.folders.create(payload.company_id, payload.project_id, payload.data)
        .then((res) => {
          commit('create_folder', res.data);
          resolve(res);
        })
        .catch((err) => {
          commit('folder_errors', err.response.data);
          reject(err.response.data);
        });
    });
  },
  setSelectedFolderPath({ commit }, payload) {
    commit('set_selected_folder_path', payload);
  },
  getFoldersByCompany({ commit }, cid) {
    return new Promise((resolve, reject) => {
      services.folders.getByCompany(cid)
        .then((res) => {
          resolve(res);
          commit('set_folders', res.data);
          commit('set_loading_folders', false);
        })
        .catch((err) => reject(err));
    });
  },

  // sets the current folder
  setCurrentFolder({ commit, getters: storeGetters }, folder) {
    commit('set_folder', folder);

    const hasAssetMetadata = storeGetters.companyHas('asset_metadata') && folder.asset_metadata;
    if (hasAssetMetadata && folder.asset_metadata !== undefined) {
      commit('set_asset_metadata', {
        metadata: folder.asset_metadata,
        config: JSON.parse(storeGetters.companyConfig('asset_metadata')),
      });
    }
  },
  // tell the simple file manager to show folders
  setShowFolders({ commit }, status) {
    commit('set_show_folders', status);
  },

  setLoadingFolders({ commit }, loading) {
    commit('set_loading_folders', loading);
  },

  updateFolder({ commit }, payload) {
    return new Promise((resolve, reject) => {
      services.folders.update(payload)
        .then((res) => {
          commit('update_folder', res.data);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },

  setFolderFilter(store, filter) {
    const company = store.getters.currentCompany;
    const filterData = { filterKey: filter, company: company.companyName };
    store.commit('filter_folders', filterData);
  },

  setModifiedFolder({ commit }, field) {
    commit('set_modified_folder', field);
  },
  clearSecondReviewerForm({ commit }, cleared) {
    commit('clear_second_reviewer', cleared);
  },
  editFolderFields({ commit }, edit) {
    commit('set_edit_folder', edit);
  },
  setFolders({ commit }, payload) {
    commit('set_folders', payload);
  },
  setInsightsFolders({ commit }, payload) {
    commit('set_insights_folders', payload);
    commit('set_epri_map_key', Math.floor(Math.random() * (10 - 1 + 1)) + 1);
  },
};

const mutations = {
  set_loading_folders: (state, status) => { state.loading = status; },
  set_loading_folder: (state, status) => { state.loadingFolder = status; },
  set_current_folder: (state, image) => { state.folder = image; },
  set_folders: (state, folders) => {
    state.folders = folders;
    state.loading = false;
  },
  set_insights_folders: (state, folders) => { state.insightsFolders = folders; },
  set_epri_map_key: (state, randomNumber) => { state.epriMapKey = randomNumber; },
  set_folder: (state, folder) => {
    state.folder = folder;
  },
  set_edit_folder: (state, edit) => { state.editing = edit; },
  set_modified_folder: (state, field) => {
    if (field.type === 'reason' || field.type === 'reviewed') {
      state.modified.second_reviewer[field.type] = field.value;
    } else state.modified[field.type] = field.value;
  },
  update_folder: (state, newFolder) => {
    const index = state.folders.findIndex((folder) => folder.path === newFolder.path);
    if (index !== -1) state.folders.splice(index, 1, newFolder);
  },
  create_folder: (state, folder) => {
    state.folders.unshift(folder);
  },
  set_show_folders: (state, status) => { state.showingFolders = status; },
  filter_folders: (state, filter) => { state.filter = filter; },
  clear_second_reviewer: (state, cleared) => { state.cleared = cleared; },
  set_selected_folder_path: (state, path) => { state.selectedFolderPath = path; },
  set_page_number: (state, pageNo) => { state.pageNo = pageNo; },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
