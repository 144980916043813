import axios from 'axios';
import firebase from '@database';
import { baseProcessUrl } from '@utils/endpoint';
import { eraseCookie } from '@authentication/cookie';
import platformBackendClient from '@backend-clients/platform-backend';

const mlBackendClient = axios.create({
  baseURL: baseProcessUrl,
  maxContentLength: Infinity,
  maxBodyLength: Infinity,
});

const unauthorizedRedirect = () => {
  // Reload the browser because it clears the
  // component states while checking for authentication tokens
  // and app.vue will handle the redirect
  window.location.reload();
};

mlBackendClient.interceptors.request.use(async (config) => {
  const c = { ...config };

  if (firebase.auth().currentUser) {
    const res = await platformBackendClient.get(`/api/auth/token/jwt/${firebase.auth().currentUser.uid}`);
    const { token } = res.data;

    c.headers.common.Authorization = token;
  }

  return c;
});

mlBackendClient.interceptors.response.use((response) => response, (err) => {
  console.error('error: ', err, err.response.status);
  // If status code is 401, then it is an unauthorized token. Move user back
  // to login page
  if (err.response.status === 401) {
    const user = firebase.auth().currentUser;
    eraseCookie('2fa');

    if (user) {
      firebase.auth().signOut()
        .then(() => unauthorizedRedirect());
    } else {
      unauthorizedRedirect();
    }
  }
  return Promise.reject(err);
});

export const setMLAuthToken = (token) => {
  if (token) {
    mlBackendClient.defaults.headers.common.Authorization = token; // Apply to every request
  } else delete mlBackendClient.defaults.headers.common.Authorization; // Deletes auth header
};

export default mlBackendClient;
