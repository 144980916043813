<template>
  <v-container fluid id="severities">
    <Chip
      :text="s"
      :color="severityChipColor(s).color"
      size="x-small"
      v-if="!editingImage"
      :text-color="severityChipColor(s).text"
    />
    <v-select
      :items="severities"
      label="Select a severity level"
      v-model="s"
      dense
      outlined
      v-if="editingImage"
    >
    </v-select>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Chip from '@components/common/Chip.vue';
import { severityColorPicker } from '@methods/helpers';
import severities from '@utils/severity-level';

export default {
  name: 'ImageSeverity',
  components: {
    Chip,
  },
  data: () => ({
    edit: false,
    severities: [],
    s: '',
  }),
  methods: {
    ...mapActions(['updateImage', 'setNotification', 'setModifiedImage']),
    severityChipColor: (severity) => severityColorPicker(severity),
  },
  computed: {
    ...mapGetters(['currentImage', 'editingImage']),
  },
  watch: {
    s(newSeverity) {
      this.setModifiedImage({ type: 'severity', value: newSeverity });
    },
    currentImage(newImage) {
      const newProcessedImage = newImage.process_tracking[newImage.process_tracking.length - 1];
      this.s = newProcessedImage.severity;
    },
  },
  mounted() {
    const index = this.currentImage.process_tracking.length - 1;
    this.s = this.currentImage.process_tracking[index].severity;
    this.severities = [...severities];
  },
};
</script>
