<template>
  <v-card>
    <v-card-text id="description">
      Please review the following changes in services that will occur once
      the request to change your plan has been approved. Please confirm below
      that you'd like to follow through with this request.
      <v-row>
        <v-col cols="6" class="text-center">
          <span class="text-h5">
            Current:
            <span class="text-h6" id="current-plan-name">{{ oldPlan.plan.nickname }}</span>
          </span>
          <br />
          <span class="subtitle" id="current-price">
            Price: ${{ (currentPrice.tiers[0].unit_amount/100).toFixed(2) }}/image
          </span>
          <List :dense="true" :items="currentServices" font="font-weight-light" />
        </v-col>
        <v-col cols="6" class="text-center">
          <span class="text-h5">
            New:
            <span class="text-h6" id="new-plan-name">
              {{ newPlan.nickname }}
            </span>
          </span>
          <br />
          <span class="subtitle" id="new-price">
            Price: ${{ (currentNewPrice.tiers[0].unit_amount/100).toFixed(2) }}/image
          </span>
          <List :dense="true" :items="newServices" font="font-weight-light" />
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        text
        id="close-btn"
        color="primary"
        @click="close"
      >Close</v-btn>
      <v-btn
        text
        color="primary"
        id="request-btn"
        @click="requestPlanChange"
      >Request Plan Change</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import List from '@components/common/List.vue';
import { find } from '@methods/helpers';

export default {
  name: 'PlanRequestDialog',
  components: {
    List,
  },
  props: ['oldPlan', 'newPlan', 'currentServices', 'newServices'],
  methods: {
    ...mapActions(['changePlan']),
    close() {
      this.$emit('close');
    },
    requestPlanChange() {
      const newPriceId = this.newPlan.id;

      const { customerId } = this.currentCompany;
      const payload = {
        customerId,
        data: {
          company: this.currentCompany.companyName,
          email: this.currentUser.email,
          newPlanId: newPriceId,
        },
      };
      this.changePlan(payload)
        .then(() => this.close());
    },
  },
  computed: {
    ...mapGetters(['allPrices', 'currentSubscription', 'currentCompany', 'currentUser', 'currentPrice', 'currentNewPrice', 'loadingPrice']),
    currentPriceId() {
      const { id } = this.currentSubscription.plan;
      return find(this.allPrices, (price) => price.id === id).id;
    },
  },
};
</script>
