<template>
  <v-card class="pa-2">
    <v-card-title class="text-caption-xs text-caption-sm text-caption-md text-caption-lg">
      Select Image by Structure
    </v-card-title>
    <v-card-text>
      <v-select
        outlined
        v-model="selectedProject"
        label="Project"
        :items="projectList"
        :loading="loadingProjects"
        item-text="name"
        single-line
        item-value="pid"
        @change="projectSelected"></v-select>
      <v-select
        outlined
        label="Structure"
        :items="folderToUse"
        item-text="path"
        return-object
        v-model="val"></v-select>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { cloneDeep } from 'lodash';
// import { uniqueArray } from '@methods/helpers';
import { setFolder } from '@utils/map/session-storage';

export default {
  name: 'StructureSelect',
  props: ['images', 'type', 'pImages'],
  data: () => ({
    fileSelected: {},
    val: {},
    projectList: [],
    selectedProject: '',
    imageList: [],
  }),
  computed: {
    ...mapGetters(['small', 'extraSmall', 'rootFolder', 'currentFolder', 'currentImage', 'allImages', 'allFolders', 'companyHas', 'processedImagesR', 'allProjects', 'loadingProjects', 'insightsFolders', 'insightsFaults', 'filteredImagesByFault', 'imagesByFilter', 'insightsPriorities', 'currentFilterStatus']),
    folderToUse() {
      return this.$route.name === 'Insights' && this.companyHas('poleByFolders') ? this.insightsFolders : this.allFolders;
    },
    imagesToUse() {
      return this.$route.name === 'Insights' && this.companyHas('poleByFolders') ? this.imageList : this.images;
    },
    foldersList() {
      const names = [];
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.folderToUse.length; i++) {
        names.push(this.folderToUse[i].path);
      }

      // Otherwise, return the list of folders for the original images
      return names;
    },
  },
  methods: {
    ...mapActions(['setShowFolders', 'setCurrentFolder', 'setInsightsFolders', 'setShowFolders', 'setFilterStatus', 'setInsightsPriorities', 'setInsightsIndex', 'setCurrentImage', 'setCurrentFolder', 'setCurrentProject']),
    closeDialog() {
      this.$emit('closeDialog', 'structure');
    },
    onFilenameChange(f) {
      // Change event handler for the select field, which sets the new image as the current image
      const [image] = this.images
        .filter((img) => img.filename + img.date === f.filename + f.date);

      this.setCurrentImage(image);
    },
    setProject() {
      const [project] = this.projectList.filter((p) => p.pid === this.selectedProject);
      this.setCurrentProject(project);
    },
    projectSelected() {
      if (this.selectedProject) {
        this.$router
          .push({ path: `/analytics/insights/${this.selectedProject}` })
          .then(() => { this.setProject(); });
      }
    },
  },
  watch: {
    val() {
      if (this.val) {
        this.setCurrentFolder(this.val);
        setFolder(this.val);
        this.setShowFolders(false);
      }
    },
    insightsPriorities(newValue) {
      if (newValue.length === 0) {
        const imageNameArray = [];
        this.images.forEach((image) => {
          imageNameArray.push(image.filename);
        });
        this.imageList = imageNameArray;
      }
    },
    insightsFaults(newValue) {
      if (newValue.length === 0) {
        const imageNameArray = [];
        this.images.forEach((image) => {
          imageNameArray.push(image.filename);
        });
        this.imageList = imageNameArray;
      }
    },
    currentImage(newCurrentImage) {
      this.fileSelected = newCurrentImage;
    },
    allProjects(projects) {
      this.projectList = projects;
    },
    pImages: {
      deep: true,
      handler(newValue) {
        const imageNameArray = [];
        newValue.all.forEach((image) => {
          imageNameArray.push(image.filename);
        });
        this.imageList = imageNameArray;
      },
    },
  },
  mounted() {
    // Mounts fileSelected as currentImage from Vuex store
    this.fileSelected = cloneDeep(this.currentImage);
    const originalFilename = this.fileSelected.filename;

    // If the original filename includes a '+', then take everything after the '+' sign
    // Otherwise 'niceFilename' takes the value of 'originalFilename'
    if (originalFilename.includes('+')) [, this.fileSelected.niceFilename] = originalFilename.split('+');
    else this.fileSelected.niceFilename = originalFilename;

    // If the filename at this point includes a '!' sign,
    // then take truncate it to the filename after the !
    if (this.fileSelected.niceFilename.includes('!')) [, this.fileSelected.niceFilename] = this.fileSelected.niceFilename.split('!');
    this.projectList = this.allProjects;
    const { pid } = this.$router.history.current.params;
    this.selectedProject = pid;

    this.setProject();

    [this.val] = this.folderToUse;

    const imageNameArray = [];
    this.pImages.withLocation.forEach((image) => {
      imageNameArray.push(image.filename);
    });
    this.imageList = imageNameArray;
  },
};
</script>
