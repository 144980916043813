import platformBackendClient from '@backend-clients/platform-backend';

export default {
  /*
    |-----------------------------------------------|
    |   @method       all                           |
    |   @parameter    query <String>                |
    |   @description  Fetches a list of users by    |
    |                 either company or teams       |
    |-----------------------------------------------|
  */
  all: (query) => platformBackendClient.get(`/api/users/?${query}`),

  /*
    |-----------------------------------------------|
    |   @method       get                           |
    |   @parameter    id <String>                   |
    |   @description  Fetches a user by user id     |
    |-----------------------------------------------|
  */
  get: (id) => platformBackendClient.get(`/api/users/${id}`),

  /*
    |-----------------------------------------------|
    |   @method       firebase_get                  |
    |   @parameter    id <String>                   |
    |   @description  Fetches a user by firebase    |
    |                 user id (used for postgres)   |
    |-----------------------------------------------|
  */
  firebase_get: (id) => platformBackendClient.get(`/api/users/firebase/${id}`),

  /*
    |-----------------------------------------------|
    |   @method       email_get                     |
    |   @parameter    payload <Object>              |
    |   @description  Fetches user data by email    |
    |-----------------------------------------------|
  */
  email_get: (payload) => platformBackendClient.post('/api/users/email', payload),

  /*
    |-----------------------------------------------|
    |   @method       create                        |
    |   @parameter    newUser <Object>              |
    |   @description  Creates a new user            |
    |-----------------------------------------------|
  */
  create: (companyId, cid, newUser) => platformBackendClient.post(`/api/users/create/?company_id=${companyId}&cid=${cid}`, newUser),

  /*
    |-----------------------------------------------|
    |   @method       create                        |
    |   @parameter    payload <Object>              |
    |   @description  Creates a new user for sso    |
    |                 authentication                |
    |-----------------------------------------------|
  */
  sso_create: (payload) => platformBackendClient.post('/api/users/sso', payload),

  /*
    |-----------------------------------------------|
    |   @method       create_auth                   |
    |   @parameter    newUser <Object>              |
    |   @description  Creates a new auth user       |
    |-----------------------------------------------|
  */
  create_auth: (newUser) => platformBackendClient.post('/api/users/auth', newUser),

  /*
    |-----------------------------------------------|
    |   @method       delete_auth                   |
    |   @parameter    id <String>                   |
    |   @description  Deletes a Firebase Auth User  |
    |-----------------------------------------------|
  */
  delete_auth: (id) => platformBackendClient.delete(`/api/users/auth/${id}`),

  /*
    |-----------------------------------------------|
    |   @method       update                        |
    |   @parameter    uid <String>                  |
    |   @description  Updates a user data from db   |
    |-----------------------------------------------|
  */
  update: (id, uid, updates) => platformBackendClient.put(`/api/users/${uid}${id ? `?user_id=${id}` : ''}`, updates),

  /*
    |-----------------------------------------------|
    |   @method       delete                        |
    |   @parameter    cid <String>, uid <String>    |
    |   @description  Deletes user                  |
    |-----------------------------------------------|
  */
  delete: (cid, companyId, id, uid) => platformBackendClient.delete(`/api/users/${uid}/?company_id=${companyId}&user_id=${id}&cid=${cid}`),

  enableUser: (uid, enabled = true) => platformBackendClient.put(`/api/users/${uid}/enable`, { enabled }),
};
