<template>
  <v-data-table
    :headers="headers"
    :items="items"
    sort-by="calories"
    class="elevation-1"
    id="teams-table"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title id="teams-table-title">
          {{ isDrift ? 'Companies' :'Teams' }}
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <!-- NEW TEAM -->
        <v-dialog v-model="dialog" max-width="500px" id="new-team-dialog">
          <template v-slot:activator="{ on: dialog, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  color="primary"
                  icon
                  id="create-team-btn"
                  class="mb-2"
                  v-bind="attrs"
                  v-on="{...tooltip, ...dialog}"
                >
                  <v-icon>group_add</v-icon>
                </v-btn>
              </template>
              {{isDrift? 'Add Company':'Add Team'}}
            </v-tooltip>
          </template>
          <v-card>
            <v-card-title id="new-team-form-title">
              <span class="headline" id="form-title-text">{{ formTitle }}</span>
            </v-card-title>
            <v-divider></v-divider>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      outlined
                      v-model="editedItem.name"
                      :label="isDrift?'Company Name':'Team Name'"
                      id="new-team-name"
                      :error-messages="errors.name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      label="Members"
                      :items="users"
                      item-text="name"
                      return-object
                      chips
                      v-model="editedItem.members"
                      outlined
                      deletable-chips
                      clearable
                      clear-icon="clear"
                      multiple
                      :loading="loadingUsers"
                      id="new-team-members"
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      label="Projects"
                      :items="projects"
                      item-text="name"
                      clearable
                      return-object
                      chips
                      v-model="editedItem.projects"
                      outlined
                      deletable-chips
                      multiple
                      :loading="loadingProjects"
                      clear-icon="clear"
                      id="new-team-projects"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close" id="cancel-new-team-btn">
                Cancel
              </v-btn>
              <v-btn color="blue darken-1" text @click="save" id="create-new-team-btn">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- DELETE TEAM -->
        <v-dialog v-model="dialogDelete" max-width="500px" id="edit-team-dialog">
          <v-card>
            <v-card-title class="headline" :id="`confirm-delete-team-${editedItem.tid}-message`">
              Are you sure you want to delete the team: {{ editedItem.name }}?
            </v-card-title>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="closeDelete"
                :id="`close-confirm-delete-team-${editedItem.tid}-btn`"
              >Cancel</v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="deleteItemConfirm"
                :id="`confirm-delete-team-${editedItem.tid}-btn`"
              >OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <!-- MEMBERS COLUMN -->
    <template v-slot:[`item.members`]="{ item }">
      <div class="ma-2 d-inline" v-for="(member, i) in item.members" :key="i">
        <v-chip color="primary" class="ma-2" x-small :id="`user-${member.uid}`">
        {{ member.name }}
      </v-chip>
      </div>
    </template>

    <!-- PROJECTS COLUMN -->
    <template v-slot:[`item.projects`]="{ item }">
      <div class="ma-2 d-inline" v-for="(project, i) in item.projects" :key="i">
        <v-chip
          color="green"
          text-color="white"
          class="ma-2"
          x-small
          :id="`project-${project.pid}`"
        >
          {{ project.name }}
        </v-chip>
      </div>
    </template>

    <!-- ACTIONS COLUMN -->
    <template v-slot:[`item.actions`]="{item}">
      <v-icon small class="mr-2" @click="editItem(item)" :id="`update-team-${item.tid}-btn`">
        mdi-pencil
      </v-icon>
      <v-icon small @click="deleteItem(item)" :id="`delete-team-${item.tid}-btn`">
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import teamHeaders from '@components/company/team_headers';
import services from '@services';

export default {
  name: 'TeamsTable',
  props: ['teams'],
  data: () => ({
    dialog: false,
    dialogDelete: false,
    loadingUsers: false,
    loadingProjects: false,
    headers: [],
    items: [],
    users: [],
    projects: [],
    editedIndex: -1,
    editedItem: {
      name: '',
      members: [],
      projects: [],
    },
    defaultItem: {
      name: '',
      members: [],
      projects: [],
    },
    errors: {
      name: '',
    },
  }),
  computed: {
    ...mapGetters(['currentCompany', 'isDrift']),
    formTitle() {
      const name = this.isDrift ? 'Company' : ' Team';
      return this.editedIndex === -1 ? `New ${name}` : `Edit ${name}`;
    },
  },
  watch: {
    dialog(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.close();
    },
    dialogDelete(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.closeDelete();
    },
    teams(newTeamsList) {
      this.items = newTeamsList;
    },
  },
  methods: {
    ...mapActions(['createTeam', 'updateTeam', 'deleteTeam', 'setNotification']),
    initialize() {
      this.loadingUsers = true;
      services.users.all(`company_id=${this.currentCompany.cid}`)
        .then((res) => {
          this.users = [...res.data];
          this.loadingUsers = false;
        });

      services.projects.all(`company_id=${this.currentCompany.cid}`)
        .then((res) => {
          this.projects = [...res.data];
          this.loadingProjects = false;
        });
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = { ...item };
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      const payload = {
        cid: this.editedItem.cid,
        tid: this.editedItem.firestore_tid,
        id: this.editedItem.tid,
      };

      this.deleteTeam(payload)
        .then(() => {
          this.closeDelete();
        });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.editedIndex > -1) {
        const updatedTeam = { ...this.editedItem };
        updatedTeam.members = updatedTeam
          .members
          .map((user) => ({
            uid: user.firestore_uid,
            id: user.uid,
          }));
        updatedTeam.projects = updatedTeam
          .projects
          .map((project) => ({
            id: project.pid,
            pid: project.firestore_pid,
          }));

        const payload = {
          id: updatedTeam.tid,
          tid: updatedTeam.firestore_tid,
          cid: this.currentCompany.cid,
          data: updatedTeam,
        };
        this.updateTeam(payload)
          .then((res) => {
            this.setNotification({
              message: `Successfully updated ${res.data.name}`,
              color: '',
              success: true,
            });
            this.close();
          });
      } else {
        // Adding
        const payload = {
          cid: this.currentCompany.firestore_cid,
          id: this.currentCompany.cid,
          data: {
            name: this.editedItem.name,
            projects: this.editedItem.projects.map((project) => ({
              pid: project.firestore_pid,
              id: project.pid,
            })),
            members: this.editedItem.members.map((user) => ({
              uid: user.firestore_uid,
              id: user.uid,
            })),
          },
        };

        this.createTeam(payload)
          .then((res) => {
            this.setNotification({
              message: `Successfully created ${res.data.name}`,
              color: '',
              success: true,
            });
            this.close();
          })
          .catch((err) => {
            if (err.response.data.name) this.errors.name = err.response.data.name;
          });
      }
    },
  },
  mounted() {
    this.items = [...this.teams];
    if (this.isDrift) {
      this.headers = teamHeaders.map((col) => (col.value === 'name' ? { ...col, text: 'Company' } : col));
    } else {
      this.headers = [...teamHeaders];
    }
  },
  created() {
    this.initialize();
  },
};
</script>
