<template>
  <v-container fluid>
    <Loading v-if="loadBillingSummary" />
    <v-row v-else>
      <v-col cols="12">
        <h2 id="billing-overview-title">Billing Overview</h2>
      </v-col>
      <CurrentMonthOverview class="ma-4" :cost="monthlyBillingSummary.total_due.slice(-1)[0]"/>
      <CostTrend class="ma-4"
      :labels="monthlyBillingSummary.months"
      :values="monthlyBillingSummary.total_due"
      :dateRange="dateRange"/>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CurrentMonthOverview from '@components/billing/overview/CurrentMonthOverview.vue';
import CostTrend from '@components/billing/overview/CostTrend.vue';
import { getStartOfMonth, getEndOfMonth } from '@methods/helpers';
import Loading from '@components/common/Loading.vue';

export default {
  name: 'BillingOverview',
  components: {
    CurrentMonthOverview,
    CostTrend,
    Loading,
  },
  computed: {
    ...mapGetters(['loadBillingSummary', 'currentCompany', 'monthlyBillingSummary']),
    dateRange() {
      return `${getStartOfMonth()} - ${getEndOfMonth()}`;
    },
  },
  methods: {
    ...mapActions(['fetchBillingSummary']),
  },
  created() {
    const today = Math.ceil(Date.now() / 1000);
    this.fetchBillingSummary({
      customerId: this.currentCompany.customerId,
      start: 0,
      end: today,
    });
  },
};
</script>
