import firebase from '@database';
import services from '@services';
import store from '@store';
import key from '@database/provider-keys';
import { encrypt } from '@utils/crypto';
import { createCookie } from '@authentication/cookie';
import { setAuthToken } from '@backend-clients/platform-backend';
import { setMLAuthToken } from '@backend-clients/ml-backend';

export default {
  access: {
    sso: {
      localhost: true,
      'demo.buzzpowerai.com': true,
      'stage.buzzpowerai.com': true,
      'internal.buzzpowerai.com': true,
      'dev.buzzpowerai.com': true,
      'nypa.buzzpowerai.com': true,
      'mig-prod.buzzpowerai.com': true,
      'mig.buzzpowerai.com': true,
      'dow.buzzpowerai.com': false,
      'nationalgrid.buzzpowerai.com': false,
      'duke.buzzpowerai.com': false,
      'are-corp.buzzpowerai.com': false,
      'nfpower.buzzpowerai.com': false,
      'test.buzzpowerai.com': true,
      'uav-recon.buzzpowerai.com': false,
      'skt.buzzpowerai.com': false,
      'de.buzzpowerai.com': true,
      'dev-de.buzzpowerai.com': true,
      'app.buzzpowerai.com': true,
    },
    azure: {
      localhost: true,
      'demo.buzzpowerai.com': true,
      'stage.buzzpowerai.com': true,
      'internal.buzzpowerai.com': true,
      'dev.buzzpowerai.com': true,
      'nypa.buzzpowerai.com': false,
      'dow.buzzpowerai.com': false,
      'nationalgrid.buzzpowerai.com': false,
      'duke.buzzpowerai.com': false,
      'are-corp.buzzpowerai.com': false,
      'nfpower.buzzpowerai.com': false,
      'test.buzzpowerai.com': true,
      'uav-recon.buzzpowerai.com': false,
      'skt.buzzpowerai.com': false,
    },
  },
  /*
    |-------------------------------------------------|
    |   @method       microsoft                       |
    |   @params       <Void>                          |
    |   @return       Promise <UserCredential>        |
    |   @description  Uses the Firebase OAuthProvider |
    |                 with microsoft to sign a user   |
    |                 into the platform. If the user  |
    |                 exists within Firebase Auth     |
    |                 it will retrieve the credentials|
    |                 from Firebase Auth to sign in   |
    |                 with email and password.        |
    |-------------------------------------------------|
  */
  microsoft: () => {
    const provider = new firebase.auth.OAuthProvider('microsoft.com');
    provider.addScope('email');

    provider.setCustomParameters({
      prompt: 'consent',
      tenant: process.env.VUE_APP_AZURE_TENANT_ID,
    });

    return new Promise((resolve, reject) => {
      firebase.auth().signInWithPopup(provider)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /*
    |-------------------------------------------------|
    |   @method       saml                       |
    |   @params       <Void>                          |
    |   @return       Promise <UserCredential>        |
    |   @description  Uses the Firebase OAuthProvider |
    |                 with microsoft to sign a user   |
    |                 into the platform. If the user  |
    |                 exists within Firebase Auth     |
    |                 it will retrieve the credentials|
    |                 from Firebase Auth to sign in   |
    |                 with email and password.        |
    |-------------------------------------------------|
  */
  saml: () => {
    const provider = new firebase.auth.SAMLAuthProvider(key());

    return new Promise((resolve, reject) => {
      firebase.auth().signInWithPopup(provider)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /*
    |-------------------------------------------------|
    |   @method       setJwtAuthentication            |
    |   @params       user <Object>                   |
    |   @return       Promise <User>                  |
    |   @description  Retrieves user document in      |
    |                 Firestore and sets the jwt token|
    |                 within the localStorage         |
    |-------------------------------------------------|
  */
  setJwtAuthentication: (uid) => new Promise((resolve, reject) => {
    services.users.firebase_get(uid)
      .then((res) => {
        store.dispatch('setFirstFactorAuth', res.data);
        services.auth.getJwtToken(res.data.firestore_uid)
          .then((jwtRes) => {
            firebase.auth().currentUser.getIdToken()
              .then((idToken) => {
                setAuthToken(`Bearer ${idToken}`);
                const { token } = jwtRes.data;
                setMLAuthToken(token);
                createCookie('2fa', encrypt('2fa=true'));
                // localStorage.setItem('jwtToken', token);

                store.dispatch('setSecondFactorAuth');
                resolve(res);
              })
              .catch((err) => reject(err));
          });
      })
      .catch((err) => reject(err));
  }),

  /*
    |-------------------------------------------------|
    |   @method       authenticateExistingUser        |
    |   @params       query <String>                  |
    |   @return       Promise <User>                  |
    |   @description  Creates a custom token to allow |
    |                 users who has an account within |
    |                 Firebase auth to have access    |
    |-------------------------------------------------|
  */
  authenticateExistingUser: (query) => new Promise((resolve, reject) => {
    services.auth.getFirebaseUser(query)
      .then((res) => {
        const userInfo = res.data;

        services.users.get(userInfo.uid)
          .then((response) => {
            const user = response.data;

            services.auth.getCustomToken(user.uid)
              .then((tokenResponse) => {
                firebase.auth().signInWithCustomToken(tokenResponse.data)
                  .then(() => resolve(user))
                  .catch((err) => reject(err));
              })
              .catch((err) => reject(err));
          })
          .catch((err) => reject(err));
      })
      .catch((err) => reject(err));
  }),
};
