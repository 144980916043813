<template>
  <v-card>
    <v-card-title class="headline">
      <v-icon color="warning" class="mr-2">warning</v-icon>
      Cancel Processing Dialog
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <span class="mb-2">
        Please confirm that you'd like to proceed with
        cancelling the current image processing session.
      </span>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        olor="primary"
        text
        @click="close"
      >Close</v-btn>
      <v-btn
        color="primary"
        text
        @click="confirm"
      >Confirm</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'CancelProcessingWarning',
  methods: {
    close() {
      this.$emit('closeCancel');
    },
    confirm() {
      this.$emit('confirmCancel');
    },
  },
};
</script>
