<template>
  <v-card flat>
    <v-card-title class="headline">
      No Default Address
    </v-card-title>
    <v-card-text>
      Your default address has not been set yet
    </v-card-text>
    <v-card-actions>
      <v-dialog v-model="dialog">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            color="primary"
            v-on="on"
            v-bind="attrs"
          >Edit Address</v-btn>
        </template>
        <AddressForm v-on:closeAddress="closeDialog" />
      </v-dialog>
    </v-card-actions>
  </v-card>
</template>

<script>
import AddressForm from '@components/checkout/AddressForm.vue';

export default {
  name: 'NoAddress',
  components: {
    AddressForm,
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>
