<template>
  <div class="reset-password">
    <v-container fluid>
      <Loading v-if="loadingUser" />
      <v-card v-else>
        <v-card-title class="headline">Password Reset</v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-row>
              <span v-if="resettingPassword" :class="textColor">{{status.message}}</span>
              <p class="reset-description">
                If you would like to reset your password,
                click the button below for an email with instructions.
              </p>
              <p class="reset-description"> Please choose a password that is secure.</p>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" block @click="onResetPw">Send Reset Password Email</v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Loading from '@components/common/Loading.vue';

export default {
  name: 'ResetPassword',
  props: ['uid'],
  data: () => ({
    resettingPassword: false,
    status: {},
  }),
  components: {
    Loading,
  },
  methods: {
    ...mapActions(['getUserProfile', 'passwordReset']),
    async onResetPw() {
      const { email } = this.currentUser;

      const response = await this.passwordReset(email);
      this.resettingPassword = true;
      if (response.code === 'auth/user-found') {
        this.status.code = response.code;
        this.status.message = response.message;
      }
    },
  },
  computed: {
    ...mapGetters(['currentUser', 'loadingUser']),
    resetButtonClicked() {
      return Object.keys(this.status).length > 0;
    },
    textColor() {
      return (this.status.code === 'auth/user-found') ? 'green-text' : 'red--text';
    },
  },
};
</script>
