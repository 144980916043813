<template>
  <v-container fluid>
    <router-view></router-view>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Admin',
  methods: {
    ...mapActions(['getCompanies']),
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  created() {
    // Check if the user is a buzz admin
    const noAdminAccess = !(Object.keys(this.currentUser).includes('buzzAdmin') && this.currentUser.buzzAdmin !== null);

    if (noAdminAccess) this.$router.push('/admin/no-access');
    else {
      this.getCompanies()
        .then(() => {
          if (this.$route.path !== '/admin/instances') this.$router.push('/admin/instances');
        });
    }
  },
};
</script>
