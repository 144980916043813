<template>
  <v-container fluid>
    <div id="image-reviewed-status" v-if="!editingImage">
      <span class="text-caption">{{ review }}</span>
    </div>
    <div id="image-reviewed-editor" v-else>
      <v-select
        :items="['Yes', 'No']"
        v-model="review"
        dense
        outlined
        @change="getImageReviewedStatus"
      >
      </v-select>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ImageReviewed',
  props: ['reviewed'],
  data: () => ({
    edit: false,
    review: '',
  }),
  methods: {
    ...mapActions(['updateImage', 'setNotification', 'setModifiedImage']),
    getImageReviewedStatus() {
      const reviewed = this.review === 'Yes';
      this.setModifiedImage({ type: 'reviewed', value: reviewed });
    },
  },
  computed: {
    ...mapGetters(['currentImage', 'editingImage']),
  },
  watch: {
    reviewed(newReviewed) {
      this.review = (newReviewed) ? 'Yes' : 'No';
    },
  },
  mounted() {
    this.review = (this.currentImage.reviewed) ? 'Yes' : 'No';
  },
};
</script>
