<template>
  <v-container fluid>
    <div id="first-reviewer" v-if="!editingProject">
      <span class="text-caption">{{ firstReviewerName }}</span>
    </div>
    <div id="editing-first-reivewer" v-else>
      <FirstReviewerForm :firstReviewer="project.first_reviewer" />
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import FirstReviewerForm from '@components/images/image_details/reviewers/FirstReviewerForm.vue';

export default {
  name: 'FirstReviewer',
  props: ['project'],
  components: {
    FirstReviewerForm,
  },
  computed: {
    ...mapGetters(['editingProject']),
    firstReviewerName() {
      return this.project.first_reviewer ? this.project.first_reviewer.name : 'First reviewer has not been assigned yet';
    },
  },
};
</script>
