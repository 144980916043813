var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"project"},[_c('LoadingOverlay',{attrs:{"type":"Maximo Report","loading":_vm.exportingMaximoData}}),(_vm.loadingProject)?_c('Loading'):_c('v-container',{attrs:{"fluid":""}},[_c('h1',[_vm._v("Project Description: ")]),_c('v-row',[_c('v-col',{attrs:{"md":"9"}},[_c('v-card',[(_vm.companyHas('mission_metadata'))?_c('div',[_c('DataTable',{attrs:{"loading":_vm.loadingProject,"entries":_vm.projectEntries,"expand":true,"headers":[
                { text: 'Field', value: 'name', align: 'start' },
                { text: 'Value', value: 'value', align: 'start' },
              ],"toolbar":_vm.projectsToolbar}}),_c('v-divider'),_c('DataTable',{attrs:{"loading":_vm.loadingMission,"entries":_vm.missionEntries,"expand":true,"headers":[
                { text: 'Field', value: 'name', align: 'start' },
                { text: 'Value', value: 'value', align: 'start' },
              ],"toolbar":_vm.missionsToolbar}})],1):_c('ProjectDataTable',{attrs:{"project":_vm.currentProject}})],1)],1),_c('v-col',{attrs:{"md":"3"}},[_c('div',{staticClass:"my-2"},[_c('v-btn',{attrs:{"id":"back-to-projects","color":"primary","to":"/projects/view/grid","small":""}},[_vm._v("Back To Projects")])],1),_c('div',{staticClass:"my-2"},[(_vm.hasPermission('PROJECT_WRITE'))?_c('v-btn',{attrs:{"color":"primary","to":`/project/${_vm.currentProject.pid}/edit`,"small":"","id":"edit-project"}},[_vm._v(" Edit Project ")]):_vm._e()],1),_c('div',{staticClass:"my"},[_c('RetrainModelButton')],1),_c('div',{staticClass:"my-2"},[_c('v-btn',{attrs:{"color":"primary","small":"","id":"image-ai-processing-btn"},on:{"click":_vm.goToImageRoute}},[_vm._v(" Images & AI Processing ")])],1),(_vm.companyHas('mission_metadata') && _vm.companyHas('asset_metadata'))?_c('div',{staticClass:"my-2"},[_c('V-btn',{attrs:{"color":"primary","disabled":!(this.currentMission),"small":""},on:{"click":_vm.aiProcessingForMediaHub}},[_vm._v(" AI processing for Media Hub ")])],1):_vm._e(),(_vm.companyHas('mission_metadata') && _vm.companyHas('asset_metadata'))?_c('div',{staticClass:"my-2"},[_c('V-btn',{attrs:{"color":"primary","small":""},on:{"click":_vm.exportToMediaHub}},[_vm._v(" Export to Media Hub ")])],1):_vm._e()])],1)],1),_c('v-snackbar',{attrs:{"timeout":3000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" ")]),_c('loading-overlay',{attrs:{"type":_vm.loadingOverlayType,"override":true,"loading":_vm.shouldShowOverlay}}),_c('v-overlay',{attrs:{"value":_vm.loadingImages}},[_c('div',{staticStyle:{"text-align":"center"}},[_c('div',[_c('v-progress-circular',{attrs:{"size":70,"width":7,"color":"white","indeterminate":""}})],1),_c('div',[_vm._v(" Loading images... ")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }