<template>
  <v-select
    v-model="selectedProject"
    :loading="loadingProjects"
    label="Select a Project"
    :items="projectList"
    item-text="name"
    single-line
    item-value="pid"
    @change="projectSelected"
  ></v-select>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ProjectRouteSelector',
  data: () => ({
    selectedProject: '',
    projectList: [],
  }),
  computed: {
    ...mapGetters(['loadingProjects', 'allProjects']),
  },
  watch: {
    allProjects(projects) {
      this.projectList = projects;
    },
  },
  methods: {
    ...mapActions(['setCurrentProject']),
    setProject() {
      const [project] = this.projectList.filter((p) => p.pid === this.selectedProject);
      this.setCurrentProject(project);
    },
    projectSelected() {
      if (this.selectedProject) {
        this.$router
          .push({ path: `/analytics/insights/${this.selectedProject}` })
          .then(() => { this.setProject(); });
      }
    },
  },
  mounted() {
    this.projectList = this.allProjects;
    const { pid } = this.$router.history.current.params;
    // eslint-disable-next-line radix
    this.selectedProject = parseInt(pid);
    this.setProject();
  },
};
</script>
