import trim from 'lodash/trim';
import toLower from 'lodash/toLower';
import chunk from 'lodash/chunk';
import time from '@utils/time';
import services from '@services';

const chunkProjects = (projects) => chunk(projects, 6);

const filterProjects = (user, projectList) => {
  const isBuzzUser = user.buzzAdmin !== null;
  const showPrototyping = isBuzzUser && user.buzzAdmin === 'developer';
  const projectWithoutPrototyping = projectList.filter((project) => !project.prototyping);
  if (user.permissions.includes('FULL_ACCESS')) {
    return (showPrototyping) ? projectList : projectWithoutPrototyping;
  }
  const userTids = user.teams.map(({ tid }) => tid);
  return projectWithoutPrototyping.filter(
    ({ teams }) => teams && teams.find(({ tid }) => userTids.includes(tid)),
  );
};

const state = {
  projects: [],
  project: {},
  filtered: [],
  projectTeams: [],
  notification: {
    success: false,
    message: '',
  },
  search: '',
  projectsPerPage: [],
  page: 1,
  loadingProjects: false,
  loadingProject: false,
  loadingProcessed: false,
  editing: false,
  exporting: false,
};

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const getters = {
  allProjects: (state) => state.projects,
  currentProject: (state) => state.project,
  filteredProjects: (state) => state.filtered,
  projectsOnPage: (state) => {
    if (state.search === '') return chunk(state.projects, 6);
    return state.projectsPerPage;
  },
  currentProjectsPage: (state) => state.page,
  currentProjectSearch: (state) => state.search,
  teamsOfProject: (state) => state.projectTeams,
  projectNotification: (state) => state.notification,
  loadingProjects: (state) => state.loadingProjects,
  loadingProject: (state) => state.loadingProject,
  loadingTeamsInProject: (state) => state.loadingTeams,
  loadingProcessedInProject: (state) => state.loadingProcessed,
  editingProject: (state) => state.editing,
  exportingMaximoData: (state) => state.exporting,
};

const actions = {
  clearProjects({ commit }) {
    commit('clear_projects');
  },
  setExportMaximoState({ commit }, exporting) {
    commit('set_exporting_maximo', exporting);
  },
  setProjectsLoading({ commit }, loading) {
    commit('set_loading_projects', loading);
  },
  finishProjectLoad({ commit }) {
    commit('finish_project_load');
  },
  setProjectSearch({ commit }, search) { commit('set_project_filter', search); },
  setProjectsPage({ commit }, page) { commit('set_projects_page', page); },
  getProjects(store, query) {
    store.commit('set_loading_projects', true);
    return new Promise((resolve, reject) => {
      services.projects.all(query)
        .then((res) => {
          const sortedProjectList = time.sortByDescendingDates(res.data);
          const projects = filterProjects(store.getters.currentUser, sortedProjectList);
          store.commit('fetch_projects', projects);
          resolve(res);
        })
        .catch((err) => {
          store.commit('set_loading_projects', false);
          reject(err);
        });
    });
  },
  createProject({ commit }, payload) {
    return new Promise((resolve, reject) => {
      services.projects.create(payload.company_id, payload.cid, payload.data)
        .then((res) => {
          commit('create_project', res.data);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getProject({ commit }, payload) {
    commit('set_loading_project', true);
    return new Promise((resolve, reject) => {
      services.projects.get(payload.cid, payload.pid)
        .then((res) => {
          commit('fetch_project', res.data);
          resolve(res);
        })
        .catch((err) => {
          commit('set_loading_project', false);
          reject(err);
        });
    });
  },
  setCurrentProject({ commit }, project) {
    commit('set_current_project', project);
  },
  updateProject({ commit }, payload) {
    return new Promise((resolve, reject) => {
      services.projects.update(payload.company_id, payload.pid, payload.id, payload.updates)
        .then((res) => {
          commit('update_project', res.data);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },
  deleteProject({ commit }, payload) {
    return new Promise((resolve, reject) => {
      services.projects.delete(payload.cid, payload.pid, payload.firestore_pid)
        .then((res) => {
          commit('remove_project', res.data.pid);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },
  editProjectFields({ commit }, edit) {
    commit('set_edit_project', edit);
  },
};

const mutations = {
  clear_projects: (state) => { state.projects = []; },
  set_loading_projects: (state, loading) => {
    state.loadingProjects = loading;
  },
  set_loading_project: (state, loading) => {
    state.loadingProject = loading;
  },
  finish_project_load: (state) => { state.loadingProjects = false; },
  set_exporting_maximo: (state, exporting) => {
    state.exporting = exporting;
  },
  set_project_filter: (state, search) => {
    const reformatted = toLower(trim(search));
    state.search = reformatted;
    state.projectsPerPage = chunkProjects(state.projects
      .filter((project) => toLower(trim(project.name)).search(reformatted) > -1));
  },
  set_projects_page: (state, page) => { state.page = page; },
  fetch_projects: (state, projects) => {
    // Sorts each project in descending order
    state.projects = projects.sort((a, b) => new Date(b.date) - new Date(a.date));
    state.loadingProjects = false;
  },
  fetch_project: (state, project) => {
    state.project = project;
    state.loadingProject = false;
  },
  create_project: (state, newProject) => {
    state.projects.unshift(newProject);
  },
  update_project: (state, newProject) => {
    const index = state.projects.findIndex((project) => project.pid === newProject.pid);
    if (index !== -1) {
      state.projects.splice(index, 1, newProject);
    }
    state.project = newProject;
  },
  remove_project: (state, pid) => {
    state.projects = state.projects.filter((project) => project.pid !== pid);
  },
  set_edit_project: (state, edit) => { state.editing = edit; },
  set_current_project: (state, project) => { state.project = project; },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
