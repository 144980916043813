<template>
  <v-expansion-panels v-model="panel">
    <v-expansion-panel inset @click="expansionAction" id="panel">
      <v-expansion-panel-header class="text-xs-h6 text-sm-h6" id="header">
        {{ header }}
      </v-expansion-panel-header>
      <v-divider></v-divider>
      <v-expansion-panel-content>
        <slot></slot>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ExpansionPanel',
  props: {
    header: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    show: false,
    panel: [],
  }),
  methods: {
    async expansionAction() {
      this.show = !this.show;
    },
  },
  computed: {
    ...mapGetters(['currentPanelStates']),
  },
};
</script>
