<template>
  <v-container>
    <v-row>
      <v-col md="6">
        <span>{{ totalTime }}</span>
      </v-col>
      <v-col md="6">
        <AnnotationTimeLogs :timeLogs="timeLogs"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AnnotationTimeLogs from '@components/images/image_details/AnnotationTimeLogs.vue';

export default {
  name: 'ImageTimeLogs',
  props: ['totalTime', 'timeLogs'],
  components: {
    AnnotationTimeLogs,
  },
  methods: {
    show() {

    },
  },
};
</script>
