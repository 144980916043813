import services from '@services';

const state = {};

const getters = {};

const actions = {
  getProcessedImages({ commit }, payload) {
    return new Promise((resolve, reject) => {
      services.processed_images.all(payload.cid, payload.pid)
        .then((res) => {
          commit('fetch_images', res.data);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteProcessedImages(store, payload) {
    return new Promise((resolve, reject) => {
      services.processed_images.delete(
        payload.company_id,
        payload.project_id,
        payload.cid,
        payload.pid,
        payload.images,
      )
        .then((res) => {
          const { allImages } = store.getters;
          const images = allImages.filter((image) => !res.data.includes(image.id));
          store.commit('fetch_images', images);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {};

export default {
  state,
  actions,
  getters,
  mutations,
};
