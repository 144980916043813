<template>
  <div class="image-upload">
    <form>
      <v-stepper v-model="e6">
        <v-stepper-step :complete="e6 > 1" step="1">
          Choose Metadata File (must be .csv/.xlsx format)
          <small>It must have the following columns: <b>{{ requiredHeaders.join(', ')}}</b></small>
        </v-stepper-step>
        <v-stepper-content step="1">
          <v-row>
          <v-col>
            <v-file-input
            show-size outlined
            label="Choose Metadata File (must be .csv/.xlsx format)"
            @change="handleCSVChange"
            :error="csvHasError"
            :error-messages="csvErrorMessage"
            :loading="uploadingImages"
            :disabled="uploadingImages"
            v-if="!useExistingMeta"
            >
            </v-file-input>
            <p v-else>{{ this.existingMetaUrl }}</p>
          </v-col>
          <v-col>
            <MetaDataFromURL
              :isShown="metaPopUp"
              @setMetaUrl="useMetaFromUrl"
              @cancel="metaPopUp = false"
            />
            <v-btn class="float-right mb-5" color="primary" block
            :disabled="uploadingImages"
            @click="setMetaPopUp">{{ metaButtonText }}</v-btn>
          </v-col>
        </v-row>
        <v-btn color="primary" @click="validateMetaFile" :loading="processingMeta">Continue</v-btn>
        </v-stepper-content>
        <v-stepper-step step="2">
          Click in the input and select all the image files you want to upload
          <small>only images are allowed.</small>
        </v-stepper-step>
        <v-stepper-content step="2">
          <v-file-input
            :loading="uploadingImages"
            multiple show-size outlined
            v-model="imageFiles"
            @change="handleChange"
            :error="hasError"
            :error-messages="errorMessage"
            :disabled="uploadingImages"
            label="Upload Images"
          >
          </v-file-input>
          <v-btn color="primary" @click="handleUpload" :loading="uploadingImages">Upload</v-btn>
        </v-stepper-content>
      </v-stepper>
    </form>
    <v-snackbar :color='snackcolor' v-model="snackbar" timeout="-1">
      {{ snackmessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import * as mime from 'mime-types';
import lodash from 'lodash';
import XLSX from 'xlsx';
import { mapActions, mapGetters } from 'vuex';
import ExifReader from 'exifreader';
import helpers, { generateCSVBlob } from '@methods/helpers';
import MetaDataFromURL from '@components/images/upload/MetaDataFromURL.vue';
import platformBackendClient from '@backend-clients/platform-backend';

export default {
  name: 'EPRIInputFolderUploader',
  props: ['cid', 'pid'],
  components: {
    MetaDataFromURL,
  },
  data() {
    return {
      e6: 1,
      loaderHasError: true,
      loaderErrorMessage: '',
      zipFile: null,
      imageFiles: [],
      totalFiles: 0,
      successUploads: [],
      failedUploads: [],
      snackbar: false,
      snackcolor: 'info',
      snackmessage: '',
      csvErrorMessage: [],
      csvFile: null,
      csvHasError: true,
      requiredHeaders: [
        'POLENUMBER',
        'LONG',
        'LAT',
        'FEEDER',
      ],
      poleAssignError: 1e-3,
      folders: [],
      metaPopUp: false,
      useExistingMeta: false,
      existingMetaUrl: '',
      filePolicies: {},
      metaDataObjects: {},
      imagesMetaData: {},
      hasError: false,
      errorMessage: [],
      processingMeta: false,
    };
  },
  methods: {
    ...mapActions([
      'setNewImageAmount',
      'setNotification',
      'setUploading',
      'getProject',
      'saveImages',
      'addFolderProject',
      'initProgress',
      'addProgressCompleted',
      'endProgress',
      'setTotal',
      'getImagesByProject',
      'createFolders',
    ]),
    // eslint-disable-next-line no-unused-vars
    prepareFileData(file) {
      const params = this.filePolicies[file.name].fields;
      const formData = new FormData();
      formData.append('Content-Type', mime.lookup(file.name));
      Object.keys(params).forEach((p) => {
        formData.append(p, params[p]);
      });
      formData.append('file', file);
      return formData;
    },
    async parallelUpload(files, batchSize) {
      const fData = files.map((f) => this.prepareFileData(f));
      const chunkedFdata = lodash.chunk(fData, batchSize);
      // eslint-disable-next-line no-restricted-syntax
      for (const chunk of chunkedFdata) {
        try {
          // eslint-disable-next-line no-await-in-loop
          await Promise.all(
            chunk.map((formData) => this.uploadFile(formData)),
          );
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
        }
      }
    },
    resetUploadData() {
      this.successUploads = [];
      this.failedUploads = [];
      this.totalFiles = 0;
      this.imagesMetaData = {};
      this.filePolicies = {};
      this.folders = [];
    },
    uploadFile(formData) {
      return new Promise((resolve, reject) => {
        const fname = formData.get('key');
        this.totalFiles -= 1;
        const rem = this.totalFiles;
        const fileName = formData.get('file').name;
        fetch(this.uploadUrl, {
          method: 'post',
          body: formData,
        })
          .then((resp) => {
            this.successUploads.push(fileName);
            this.setSnackMessage(`Uploaded ${fname}, ${rem} left.`, 'success');
            this.addProgressCompleted(1);
            resolve(resp);
          }).catch((err) => {
            this.failedUploads.push({
              file: fileName,
              reason: err.message,
            });
            this.setSnackMessage(`Failed to upload ${fname}, ${rem} left.`, 'error');
            reject(err);
          });
      });
    },
    useMetaFromUrl(url) {
      this.useExistingMeta = true;
      this.existingMetaUrl = url;
      this.metaPopUp = false;
    },
    setMetaPopUp() {
      if (this.useExistingMeta) {
        this.useExistingMeta = false;
      } else {
        this.metaPopUp = true;
      }
    },
    async validateMetaFile() {
      this.processingMeta = true;
      if (!this.csvHasError || this.useExistingMeta) {
        try {
          await this.processMetaData();
          this.e6 = 2;
        } catch (err) {
          this.csvHasError = true;
          this.csvErrorMessage = err.message;
        }
      }
      this.processingMeta = false;
    },
    handleCSVChange(file) {
      this.csvHasError = false;
      this.csvErrorMessage = '';
      const acceptedTypes = ['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
      if (file === null) {
        this.csvHasError = true;
        this.csvErrorMessage = 'Empty selection.';
      } else if (!acceptedTypes.includes(file.type)) {
        this.csvHasError = true;
        this.csvErrorMessage = 'Must be .csv/.xlsx file format.';
      } else {
        this.csvFile = file;
      }
    },
    async extractFilesMeta() {
      // eslint-disable-next-line no-restricted-syntax
      for (const file of this.imageFiles) {
        try {
          if (!helpers.isImage(mime.lookup(file.name))) {
            this.failedUploads.push({
              file: file.name,
              reason: `File is not an image. mime type is ${mime.lookup(file.name)}`,
            });
            // eslint-disable-next-line no-continue
            continue;
          }
          // eslint-disable-next-line no-await-in-loop
          const exifT = await ExifReader.load(file);
          const imageMeta = {
            lat: exifT.GPSLatitude,
            latRef: exifT.GPSLatitudeRef,
            long: exifT.GPSLongitude,
            longRef: exifT.GPSLongitudeRef,
          };
          const location = this.getImageLocation(imageMeta);
          let gimbalPitchDegree;
          if (exifT.GimbalPitchDegree === undefined) {
            gimbalPitchDegree = null;
          } else {
            gimbalPitchDegree = parseFloat(exifT.GimbalPitchDegree.value);
          }
          this.imagesMetaData[file.name] = {
            location,
            gimbalPitchDegree,
            name: file.name,
          };
          this.addProgressCompleted(1);
        } catch (err) {
          this.setSnackMessage(err.message, 'error');
          this.failedUploads.push({
            file: file.name,
            reason: err.message,
          });
        }
      }
      this.endProgress();
    },
    async handleUpload() {
      // reset varaibles
      this.resetUploadData();
      // make sure that there are images
      if (this.imageFiles.length === 0) {
        this.hasError = true;
        this.errorMessage = 'Empty selection.';
      }
      try {
        this.setUploading(true);
        this.totalFiles = this.imageFiles.length;
        // extract metadata
        this.initProgress({
          title: 'Extracting Image Metadata (Progress)',
          total: this.totalFiles,
        });
        await this.extractFilesMeta();
        // cluster files
        this.initProgress({
          title: 'Clustering Images (Progress)',
          total: this.totalFiles,
        });
        this.clusterFiles();
        // prepare signed policy payloads
        const files = [];
        this.imageFiles.forEach((f) => {
          if (helpers.isImage(mime.lookup(f.name))) {
            files.push({
              contentType: mime.lookup(f.name),
              fileName: `${this.folderPath}/${f.name}`,
              file: f,
            });
          }
        });
        const chunkedFiles = lodash.chunk(files, 50);
        this.setSnackMessage(`${this.totalFiles} image(s) in total to be uploaded.`);
        this.initProgress({
          title: 'Uploading Images (Progress)',
          total: this.totalFiles,
        });
        // eslint-disable-next-line no-restricted-syntax
        for (const chunkFs of chunkedFiles) {
          try {
            // eslint-disable-next-line no-await-in-loop
            const policies = await platformBackendClient.post('/api/upload/signed_url', {
              bucketName: this.currentCompany.bucket,
              uploadPath: this.uploadPath,
              project: this.currentProject.pid,
              company: this.currentCompany.cid,
              files: chunkFs.map((f) => ({ contentType: f.contentType, fileName: f.fileName })),
            });
            this.filePolicies = { ...this.filePolicies, ...policies.data };
            // eslint-disable-next-line no-await-in-loop
            await this.parallelUpload(chunkFs.map((f) => f.file), 5);
            // eslint-disable-next-line no-await-in-loop
            await this.onQueueComplete();
            this.successUploads = [];
          } catch (err) {
            this.setSnackMessage(err.message, 'error');
          }
        }

        await this.createFolders({ folders: this.folders });
        this.setSnackMessage('Images Upload.', 'success');
        this.downloadFailedUploads();
        await this.getImagesByProject({
          pid: this.currentProject.pid,
          cid: this.currentCompany.cid,
        });
      } catch (err) {
        this.setSnackMessage(err.message, 'error');
      }
      // call get images endpoint
      this.setUploading(false);
      this.endProgress();
    },
    handleChange(files) {
      this.hasError = false;
      this.errorMessage = '';
      if (files.length === 0) {
        this.hasError = true;
        this.errorMessage = 'Empty selection.';
      }
    },
    async getSignedPolicies(fileObjects, path, location) {
      const files = fileObjects.map((file) => ({
        contentType: mime.lookup(file.name),
        fileName: `${this.folderPath}/${file.name}`,
      }));
      const policies = await platformBackendClient.post('/api/upload/signed_url', {
        bucketName: this.currentCompany.bucket,
        uploadPath: path,
        project: this.currentProject.pid,
        company: this.currentCompany.cid,
        files,
        location,
      });
      return policies.data;
    },
    clusterFiles() {
      Object.values(this.imagesMetaData).forEach((image) => {
        // find related image from metadata based on long and lat
        const metaObject = this.findMetaData(this.metaDataObjects.data, image);
        this.metaToFolder(metaObject, image);
        this.addProgressCompleted(1);
      });
      this.endProgress();
    },
    findMetaData(metas, image) {
      // find related image from metadata based on long and lat
      return metas.find((m) => {
        const metaLocation = [
          parseFloat(m[this.metaDataObjects.headerIndexes.LAT]),
          parseFloat(m[this.metaDataObjects.headerIndexes.LONG]),
        ];
        if (typeof image.location.longitude !== 'number'
        || typeof image.location.latitude !== 'number'
        || typeof metaLocation[0] !== 'number'
        || typeof metaLocation[1] !== 'number'
        ) return false;
        return helpers.isWithinEuclidError(
          [image.location.latitude, image.location.longitude],
          metaLocation,
          this.poleAssignError,
        );
      });
    },
    metaToFolder(metaObject, image) {
      let path = metaObject === undefined ? `Pole ${image.location.latitude.toFixed(3)} ${image.location.longitude.toFixed(3)}` : `Pole ${metaObject[this.metaDataObjects.headerIndexes.POLENUMBER]}`;
      let lineId = metaObject === undefined ? '' : metaObject[this.metaDataObjects.headerIndexes.FEEDER];
      let structureId = metaObject === undefined ? '' : metaObject[this.metaDataObjects.headerIndexes.POLENUMBER];
      path = path === null || path === undefined ? `Pole ${image.location.latitude.toFixed(3)} ${image.location.longitude.toFixed(3)}` : path;
      lineId = lineId === null || lineId === undefined ? '' : lineId;
      structureId = structureId === null || structureId === undefined ? '' : structureId;
      if (this.imagesMetaData[image.name] !== undefined
      || this.imagesMetaData[image.name] !== null) {
        this.imagesMetaData[image.name].folder = path;
        this.imagesMetaData[image.name].structureId = structureId;
        this.imagesMetaData[image.name].lineId = lineId;
        const fIndex = this.folders.findIndex((fold) => fold.path === path);
        if (fIndex === -1) {
          this.folders.push({
            path,
            location: [
              image.location.latitude || metaObject[this.metaDataObjects.headerIndexes.LAT],
              image.location.longitude || metaObject[this.metaDataObjects.headerIndexes.LONG],
            ],
            project: this.currentProject.pid,
            company: this.currentCompany.cid,
          });
        }
      }
    },
    processMetaData() {
      return new Promise((resolve, reject) => {
        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: 'binary' });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws, { header: 1 }).filter((r) => r.length > 0);
          const headers = data[0].map((h) => h.toUpperCase());
          // make sure all required headers are pressent.
          const headerIndexes = {};
          this.requiredHeaders.forEach((h) => {
            const ind = headers.indexOf(h);
            if (ind === -1) {
              reject(Error(`'${h}' column header is missing from spreadsheet. 
              The following column headers shoulde be present; ${this.requiredHeaders.join(', ')}`));
            }
            headerIndexes[h] = ind;
          });
          this.metaDataObjects = {
            data: data.slice(1),
            headerIndexes,
          };
          resolve({
            data: data.slice(1),
            headerIndexes,
          });
        };
        if (this.useExistingMeta) {
          fetch(this.existingMetaUrl)
            .then((res) => res.blob())
            .then((blob) => reader.readAsBinaryString(blob));
        } else {
          reader.readAsBinaryString(this.csvFile);
        }
      });
    },
    getImageLocation(metaData) {
      const {
        lat, latRef, long, longRef,
      } = metaData;

      const convertedCoordinatePoints = {};
      if (
        lat !== undefined
        || latRef !== undefined
        || long !== undefined
        || longRef !== undefined
      ) {
        const latDir = ['S', 'W'].includes(latRef.value[0]) ? -1 : 1;
        convertedCoordinatePoints.lat = lat.description * latDir;
        const longDir = ['S', 'W'].includes(longRef.value[0]) ? -1 : 1;
        convertedCoordinatePoints.long = long.description * longDir;
      }
      return (Object.keys(convertedCoordinatePoints).length > 0)
        ? helpers.getGeoCoordinates(convertedCoordinatePoints) : {};
    },
    async onQueueComplete() {
      const uploadedFiles = this.successUploads.map((fname) => this.imagesMetaData[fname]);
      if (uploadedFiles.length === 0) {
        return;
      }
      const imageDocs = uploadedFiles.map(async (currentFile) => ({
        filename: currentFile.name,
        location: this.imagesMetaData[currentFile.name].location,
        createdAt: helpers.getStringDate(),
        date: helpers.getStringDateTime(),
        caption: '',
        notes: [],
        pid: this.currentProject.pid,
        cid: this.currentCompany.cid,
        uid: this.currentUser.uid,
        bucket: this.currentCompany.bucket,
        url: `${this.uploadUrl}${this.folderPath}/${currentFile.name}`,
        gimbalPitchDegree: currentFile.gimbalPitchDegree,
        folder: currentFile.folder,
        lineId: currentFile.lineId,
        structureId: currentFile.structureId,
      }));

      Promise.all(imageDocs)
        .then(async (docs) => {
          const imageDocList = docs.map((doc) => {
            const currentDoc = doc;
            currentDoc.processedImage = null;
            return currentDoc;
          });

          const imageObjects = imageDocList.map((img) => ({
            filename: img.filename,
            companyId: img.cid,
            project_id: img.pid,
            originalImageUrl: img.url,
            userId: img.uid,
            location: [img.location.latitude, img.location.longitude],
            date: img.date,
            isDeleted: false,
            caption: '',
            notes: [],
            bucket: img.bucket,
            process_tracking: [],
            gimbalPitchDegree: img.gimbalPitchDegree,
            folder: img.folder,
            lineId: img.lineId,
            structureId: img.structureId,
            annotated: false,
            time_logs: [],
          }));

          let response = await this.saveImages({ imageObjects });

          if (response.status !== 201) {
            response = await this.saveImages({ imageObjects });
          }

          if (response.status !== 201) {
            response = await this.saveImages({ imageObjects });
          }

          if (response.status !== 201) {
            response = await this.saveImages({ imageObjects });
          }

          if (response.status === 201) {
            const { data } = response;
            this.setNotification({
              success: true,
              message: `${data.length} images have been
          uploaded to the ${this.currentProject.name} project`,
            });
          }
          this.setUploading(false);
          this.endProgress();
        });
    },
    setSnackMessage(message, status = 'info') {
      this.snackcolor = status;
      this.snackmessage = message;
      this.snackbar = true;
    },
    downloadFailedUploads() {
      if (this.failedUploads.length === 0) return;
      const csvData = this.failedUploads.reduce((a, v) => `${a}${v.file},${v.reason}\n`, 'File,Reason\n');
      const blob = generateCSVBlob(csvData);
      const exportedFilename = 'failed uploads.csv';
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, exportedFilename);
      } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', exportedFilename);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.csvDownloaded = true;
        }
      }
    },
  },
  computed: {
    ...mapGetters(['currentUser', 'currentProject', 'allImages', 'uploadingImages', 'currentCompany']),
    folderPath() {
      return `original/${this.currentProject.pid}`;
    },
    uploadUrl() {
      return `https://storage.googleapis.com/${this.currentCompany.bucket}/`;
    },
    metaButtonText() {
      return this.useExistingMeta ? 'Upload from computer' : 'Use Existing MetaData';
    },
  },
  created() {
    const { pid } = this;
    if (Object.keys(this.currentProject).length === 0) {
      this.getProject({ pid, cid: this.currentCompany.cid });
    }
  },
};
</script>

<style scoped>
.display-none{
  display: none;
}
</style>
