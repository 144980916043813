<template>
  <div class="text-center">
    <v-dialog
      v-model="showDialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{this.title}}
        </v-card-title>

        <v-card-text>
          {{this.description}}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="showDialog = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'DialogMessage',
  props: ['title', 'description', 'show', 'onOK'],
  computed: {
    showDialog: {
      get() { return this.show; },
      set(value) { this.$emit('updateShow', value); },
    },
  },
  methods: {
    closeDialog() {
      this.$emit('updateShow', false);
    },
  },
};
</script>
