<template>
  <v-tooltip bottom v-if="isIcon" id="tooltip">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :color="color"
        :href="url"
        icon
        :small="isSmall"
        :x-small="isXSmall"
        :large="isLarge"
        v-on="on"
        v-bind="attrs"
        target="_blank"
      ><v-icon>fullscreen</v-icon></v-btn>
    </template>
    Full Screen View
  </v-tooltip>
  <v-btn
    v-else
    :color="color"
    :href="url"
    target="_blank"
    :outlined="isOutlined"
    :text="isText"
    v-on="on"
    v-bind="attrs"
  >
    Full Screen
    <v-icon>fullscreen</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'FullScreenBtn',
  props: {
    url: {
      type: String,
      default: '',
    },
    buttonType: {
      type: String,
      default: 'text',
    },
    color: {
      type: String,
      default: 'primary',
    },
    size: {
      type: String,
      default: '',
    },
  },
  computed: {
    isIcon() {
      return this.buttonType === 'icon';
    },
    isText() {
      return this.buttonType === 'text';
    },
    isOutlined() {
      return this.buttonType === 'outlined';
    },
    isSmall() {
      return this.size === 'small';
    },
    isXSmall() {
      return this.size === 'x-small';
    },
    isLarge() {
      return this.size === 'large';
    },
  },
};
</script>
