<template>
  <v-container>
    <v-card>
      <v-card-title class="headline">
        Payment Methods
        <v-spacer></v-spacer>
        <v-dialog v-model="paymentDialog" class="add-payment-dialog">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              id="add-payment-btn"
              icon
              small
              color="primary"
              v-bind="attrs"
              v-on="on"
            ><v-icon>add</v-icon></v-btn>
          </template>
          <AddPayment v-on:closeDialog="close" />
        </v-dialog>
      </v-card-title>
      <v-divider></v-divider>
      <v-list two-line>
        <div
          v-for="(payment, index) in payments"
          :key="index"
        >
          <v-list-item id="payment-item">
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox
                  v-model="checked"
                  class="card-select"
                  :input-value="active"
                  :value="payment"
                  color="primary"
                  @click="checkedBox(index)"
                ></v-checkbox>
              </v-list-item-action>
              <v-list-item-content class="payment-content">
                <PaymentContent
                  :brand="upperFirst(payment.card.brand)"
                  :icon="getCreditCardIcon(payment.card.brand)"
                  :name="payment.billing_details.name"
                  :expirationMonth="payment.card.exp_month"
                  :expirationYear="payment.card.exp_year"
                  :last4="payment.card.last4"
                  :payment="payment"
                  :id="payment.id"
                  :isChecked="(checkedIndex === index) ? true : false"
                />
              </v-list-item-content>
            </template>
          </v-list-item>
          <v-divider v-if="index < payments.length-1"></v-divider>
        </div>
      </v-list>
    </v-card>
  </v-container>
</template>

<script>
import AddPayment from '@components/payments/AddPayment.vue';
import PaymentContent from '@components/payments/billing-portal/PaymentContent.vue';
import { toUpperFirst, getCreditCardIcon } from '@methods/helpers';

export default {
  name: 'PaymentMethodList',
  props: ['payments'],
  components: {
    AddPayment,
    PaymentContent,
  },
  data: () => ({
    paymentDialog: false,
    paymentCheck: false,
    removeDialog: false,
    editDialog: [false, false],
    checked: [],
    checkedStatus: [],
    checkedIndex: null,
  }),
  methods: {
    close() {
      this.paymentDialog = false;
    },
    closeDelete() {
      this.removeDialog = false;
    },
    closeEdit(indexToClose) {
      this.testEditDialog[indexToClose] = false;
    },
    upperFirst: (brand) => toUpperFirst(brand),
    getCreditCardIcon: (brand) => getCreditCardIcon(brand),
    checkedBox(index) {
      if (this.checked.length > 1) this.checked.shift();
      if (this.checked.length !== 0) this.checkedIndex = index;
      else this.checkedIndex = null;
    },
  },
  mounted() {
    for (let i = 0; i < this.payments.length; i += 1) {
      this.checkedStatus.push(false);
    }
  },
};
</script>
