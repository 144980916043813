<template>
  <v-card tile flat>
    <v-toolbar tile height="75" dense>
      <ProjectRouteSelector />
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <!-- <DownloadCSV :images="images" /> -->
      <ExportXlsx :images="images" />
      <DownloadJsonData />
      <v-dialog v-model="reports" width="50%" v-if="isMobile">
        <template v-slot:activator="{ on: dialog, attrs }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn icon color="primary" v-on="{...dialog, ...tooltip}" v-bind="attrs">
                <v-icon>details</v-icon>
              </v-btn>
            </template>
            Reports Detail
          </v-tooltip>
        </template>
        <ReportsDialog :pid="pid" v-on:closeReports="closeReportsDialog"/>
      </v-dialog>
      <v-dialog v-model="abbreviations" v-if="isMobile">
        <template v-slot:activator="{ on: dialog, attrs }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn icon color="primary" v-on="{...tooltip, ...dialog}" v-bind="attrs">
                <v-icon>photo_size_select_small</v-icon>
              </v-btn>
            </template>
            View Labels
          </v-tooltip>
        </template>
        <AbbreviationsDialog v-on:closeAbbrebiations="closeAbbrDialog" />
      </v-dialog>
      <v-dialog v-model="filters" v-if="isMobile">
        <template v-slot:activator="{ on: dialog, attrs }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn icon color="primary" v-on="{...dialog, ...tooltip}" v-bind="attrs">
                <v-icon>filter_alt</v-icon>
              </v-btn>
            </template>
            Filter Images
          </v-tooltip>
        </template>
        <DashboardFiltersDialog
          v-on:closeFilter="closeFilterDialog"
          :images="images"
          :index="index"
        />
      </v-dialog>
    </v-toolbar>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
// import DownloadCSV from '@components/analytics/insights/DownloadCSV.vue';
import ExportXlsx from '@components/dashboard/exports/ExportXlsx.vue';
import DownloadJsonData from '@components/analytics/insights/DownloadJsonData.vue';
import ProjectRouteSelector from '@components/common/ProjectRouteSelector.vue';
import ReportsDialog from '@components/analytics/insights/mobile_dialogs/ReportsDialog.vue';
import AbbreviationsDialog from '@components/analytics/insights/mobile_dialogs/AbbreviationsDialog.vue';
import DashboardFiltersDialog from '@components/analytics/insights/mobile_dialogs/DashboardFiltersDialog.vue';

export default {
  name: 'InsightsToolbar',
  props: ['pid', 'images', 'index'],
  data: () => ({
    reports: false,
    abbreviations: false,
    filters: false,
  }),
  components: {
    // DownloadCSV,
    ExportXlsx,
    DownloadJsonData,
    ProjectRouteSelector,
    ReportsDialog,
    AbbreviationsDialog,
    DashboardFiltersDialog,
  },
  methods: {
    closeReportsDialog() { this.reports = false; },
    closeAbbrDialog() { this.abbreviations = false; },
    closeFilterDialog() { this.filters = false; },
  },
  computed: {
    ...mapGetters(['small', 'extraSmall', 'isLaptop']),
    isMobile() {
      return (this.small || this.extraSmall) || !this.isLaptop;
    },
  },
};
</script>
