<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        sm="6"
      >
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="startDate"
              label="From"
              prepend-icon="mdi-calendar"
              outlined
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="startDate"
            no-title
            @input="menu1 = false"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col
        cols="12"
        sm="6"
      >
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="endDate"
              label="To"
              prepend-icon="mdi-calendar"
              outlined
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="endDate"
            no-title
            @input="menu2 = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  data: () => ({
    startDate: new Date((new Date()).setDate(1)).toISOString().substr(0, 10),
    endDate: new Date().toISOString().substr(0, 10),
    menu1: false,
    menu2: false,
  }),
  computed: {
    ...mapGetters(['billingSummary']),
  },
  methods: {
    changeRange() {
      const timeRange = {
        start: Math.ceil(Date.parse(this.startDate) / 1000),
        end: Math.ceil(Date.parse(this.endDate) / 1000),
      };
      if (timeRange.start >= timeRange.end) {
        return;
      }
      this.$emit('reportRange', timeRange);
    },
  },
  watch: {
    startDate() {
      this.changeRange();
    },
    endDate() {
      this.changeRange();
    },
  },
  async created() {
    this.startDate = new Date(this.billingSummary.startDate).toISOString().substr(0, 10);
    this.endDate = new Date(this.billingSummary.endDate).toISOString().substr(0, 10);
  },
};
</script>
