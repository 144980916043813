<template>
  <v-container fluid>
    <v-card class="align-center justify-center" width="75%">
      <v-card-title class="headline">
        No Images Uploaded
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <span id="text-with-project">
          The project <b>{{ currentProject.name }}</b> does not have any images uploaded to it.
        </span>
        <span id="navigate-message">
          Please navigate to the project's image page to upload your images.
        </span>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" :to="`/project/${currentProject.pid}`" id="nav-project-btn">
          Go To {{ currentProject.name }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'InsightsNoImages',
  computed: {
    ...mapGetters(['currentProject']),
  },
};
</script>
