import platformBackendClient from '@backend-clients/platform-backend';

const state = {
  invoices: {},
  invoice: {},
  loading: false,
  loadingInvoices: false,
  billingSummary: {},
  loadBillingSummary: false,
  paying: false,
};

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const getters = {
  currentInvoice: (state) => state.invoice,
  loadingInvoice: (state) => state.loading,
  payingInvoice: (state) => state.paying,
  loadingInvoices: (state) => state.loadingInvoices,
  allInvoices: (state) => state.invoices,
  billingSummary: (state) => state.billingSummary,
  loadBillingSummary: (state) => state.loadBillingSummary,
  monthlyBillingSummary: (state) => {
    const monthly = {
      months: [],
      total_due: [],
      total_paid: [],
      total_remaining: [],
      days: [],
    };
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    Object.keys(state.billingSummary).sort().forEach((year) => {
      if (!Number.isNaN(Number(year))) {
        months.forEach((month) => {
          if (state.billingSummary[year].months[month] !== undefined) {
            monthly.months.push(`${year}-${month}`);
            monthly.total_due.push(state.billingSummary[year].months[month].total_due);
            monthly.total_paid.push(state.billingSummary[year].months[month].total_paid);
            monthly.total_remaining.push(state.billingSummary[year].months[month].total_remaining);
            monthly.days.push(state.billingSummary[year].months[month].days);
          }
        });
      }
    });
    return monthly;
  },
  dailyBillingSummary: (state) => {
    const daily = {};
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    Object.keys(state.billingSummary).sort().forEach((year) => {
      if (!Number.isNaN(Number(year))) {
        months.forEach((month) => {
          if (state.billingSummary[year].months[month] !== undefined) {
            daily[`${year}-${month}`] = {
              days: [],
              total_due: [],
              total_paid: [],
              total_remaining: [],
            };
            Object.keys(state.billingSummary[year].months[month].days).forEach((day) => {
              daily[`${year}-${month}`].days.push(day);
              daily[`${year}-${month}`].total_due.push(
                state.billingSummary[year].months[month].days[day].total_due,
              );
              daily[`${year}-${month}`].total_paid.push(
                state.billingSummary[year].months[month].days[day].total_paid,
              );
              daily[`${year}-${month}`].total_remaining.push(
                state.billingSummary[year].months[month].days[day].total_remaining,
              );
            });
          }
        });
      }
    });
    return daily;
  },
};

const actions = {
  async getInvoice({ commit }, id) {
    commit('loading_invoice');
    const res = await platformBackendClient.get(`/api/invoices/${id}`);

    commit('fetch_invoice', res.data);
    return res.data;
  },
  async payInvoice({ commit }, id) {
    commit('paying_invoice');
    const res = await platformBackendClient.post(`/api/invoices/${id}/pay`);

    commit('invoice_paid');
    return res.data;
  },
  async fetchInvoices({ commit }, params) {
    commit('loading_invoices', true);
    try {
      const invoices = await platformBackendClient.get(
        `/api/customers/${params.company.customerId}/invoices/monthly`,
      );
      commit('fetch_invoices', invoices.data);
    } catch (err) {
      commit('loading_invoices', false);
    }
  },

  async fetchBillingSummary({ commit }, params) {
    if (params.load === undefined) {
      commit('loading_billing_summary', true);
    }
    try {
      const res = await platformBackendClient.get(
        `/api/billing/${params.customerId}?start=${params.start}&end=${params.end}`,
      );
      commit('set_billing_summary', res.data);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
    commit('loading_billing_summary', false);
  },
};

const mutations = {
  loading_invoices: (state, status) => {
    state.loadingInvoices = status;
  },
  loading_invoice: (state) => {
    state.loading = true;
  },
  paying_invoice: (state) => {
    state.paying = true;
  },
  invoice_paid: (state) => {
    state.paying = false;
  },
  fetch_invoice: (state, invoice) => {
    state.invoice = invoice;
    state.loading = false;
  },
  fetch_invoices: (state, invoiceList) => {
    state.invoices = invoiceList;
    state.loadingInvoices = false;
  },
  loading_billing_summary: (state, status) => {
    state.loadBillingSummary = status;
  },
  set_billing_summary: (state, summaryObject) => {
    state.billingSummary = summaryObject;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
