<template>
  <v-container fluid>
    <Loading v-if="loadingPaymentMethods" />
    <v-container v-else fluid id="order-summary">
      <span id="order-summary" class="font-weight-black float-left">Order Summary</span>
      <span class="float-right font-weight-light" id="product">{{ product }}</span>
      <v-row class="my-8">
        <v-col cols="12">
          <span class="float-left" id="cost-unit">{{
            (pricePerUnit) ? 'Price per Unit' : 'Subtotal'
          }}</span>
          <span class="float-right font-weight-light" id="price">{{ cost }}</span>
        </v-col>
        <v-col cols="12" v-if="pricePerUnit" id="price-per-unit">
          <span class="float-left">Subtotal:</span>
          <span class="float-right font-weight-light">{{ subTotal }}</span>
        </v-col>
        <v-col cols="12">
          <span class="float-left" id="tax-label">Tax:</span>
          <span class="float-right font-weight-light" id="tax-amount">$0.00</span>
        </v-col>
        <v-col cols="12">
          <span class="float-left" id="total-label">Total:</span>
          <span class="float-right font-weight-light" id="total-amount">
            {{ subTotal + tax }}
          </span>
        </v-col>
        <v-col
          cols="12"
          v-if="product !== 'Dashboard'"
          id="plan-info"
        >
          <span
            v-if="product.includes('Image Processing')"
            class="font-weight-light"
          >
            You will not be charged until the end of month, as your usage determines your invoice
          </span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row>
        <v-col
          xs="12"
          sm="12"
          md="6"
          lg="6"
          xl="6"
        >
          <CheckoutCardData
            :payments="allPaymentMethods"
            v-on:selectedPayment="getSelectedPayment"
          />
        </v-col>
        <v-expand-x-transition>
          <v-col
            v-show="paymentMethodSelected"
            class="shrink"
            xs="12"
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <CheckoutAddress
              :id="selectedPaymentMethod.id"
              :address="allPaymentMethods[0].billing_details.address"
              :payment="selectedPaymentMethod"
            />
          </v-col>
        </v-expand-x-transition>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CheckoutCardData from '@components/checkout/CheckoutCardData.vue';
import CheckoutAddress from '@components/checkout/CheckoutAddress.vue';
import Loading from '@components/common/Loading.vue';
import { camelCase, toLowerFirst } from '@methods/helpers';

export default {
  name: 'CheckoutForm',
  props: ['product', 'cost', 'pricePerUnit'],
  data: () => ({
    prices: {
      bronzePlan: '$0.95/image',
      silverPlan: '$0.90/image',
      goldPlan: '$0.80/image',
      dashboard: '$150',
    },
    tax: 0,
    selectedPaymentMethod: {},
  }),
  components: {
    CheckoutCardData,
    CheckoutAddress,
    Loading,
  },
  methods: {
    ...mapActions(['getPaymentMethodsByCustomerId']),
    getSelectedPayment(payment) {
      this.selectedPaymentMethod = payment;
      this.$emit('selectedPaymentId', payment.id);
    },
    getPrice(plan) {
      const key = camelCase(plan);
      return this.prices[key];
    },
  },
  computed: {
    ...mapGetters(['currentCompany', 'allPaymentMethods', 'loadingPaymentMethods']),
    subTotal() {
      const { name } = this.product;
      if (name === 'Dashboard') return this.prices[toLowerFirst(name)];
      return '$0.00';
    },
    paymentMethodSelected() {
      return Object.keys(this.selectedPaymentMethod).length > 0;
    },
  },
  created() {
    const { customerId } = this.currentCompany;
    this.getPaymentMethodsByCustomerId(customerId);
  },
};
</script>
