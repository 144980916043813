import mailer from '@utils/mailer.js';

const state = {
  sending: false,
};

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const getters = {
  sendingEmail: (state) => state.sending,
};

const actions = {
  sendEmailNotification({ commit }, payload) {
    commit('sending_email', true);
    return new Promise((resolve, reject) => {
      mailer.notifyReviewer(payload.data, payload.type)
        .then((res) => {
          commit('sending_email', false);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },
};

const mutations = {
  sending_email: (state, sending) => {
    state.sending = sending;
  },
};

export default {
  state,
  actions,
  getters,
  mutations,
};
