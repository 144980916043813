import platformBackendClient from '@backend-clients/platform-backend';

const mailer = {
  notifyReviewer: (payload, type) => new Promise((resolve, reject) => {
    platformBackendClient.post(`/api/email/${type}`, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  }),
};

export default mailer;
