<template>
  <v-card>
    <v-card-title>
      <v-container>
        <v-row>
          <v-col cols="12">
            <span class="headline">
              Image Transfer
            </span>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-text>
      <v-container fluid>
        <v-row>
          <v-col sm="12" md="6" lg="7">
            <v-list-item-group
              v-model="imagesToMove"
              multiple
            >
              <v-list-item v-for="(img, index) in folderImages" :key="index">
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox
                      :input-value="active"
                      color="primary"
                    ></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{ img.filename }}</v-list-item-title>
                    <v-list-item-subtitle>{{ img.folder }}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-col>
          <v-col sm="12" md="6" lg="5">
            <span class="headline">
              Transfer To
            </span>
            <v-radio-group v-model="poleChoiceMode">
              <v-row>
                <v-col cols="12">
                  <v-radio
                  :label="`${poleBefore.path} (${poleBefore.dist})`"
                  value="pole-before"></v-radio>
                </v-col>
                <v-col cols="12">
                  <v-radio
                  :label="`${poleAfter.path} (${poleAfter.dist})`"
                  value="pole-after"></v-radio>
                </v-col>
                <v-col cols="12">
                  <v-radio
                  label="Enter Pole Number"
                  value="pole-choice"
                  v-show="poleChoiceMode !== 'pole-choice'">
                  </v-radio>
                  <v-combobox
                    v-show="poleChoiceMode === 'pole-choice'"
                    v-model="poleChoice"
                    :filter="filter"
                    :items="sortedFolders"
                    label="Enter Pole Number"
                    clearable
                    item-text="text"
                    item-value="path"
                  >
                  </v-combobox>
                </v-col>
              </v-row>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="closeDialog" :loading="transfering">Cancel</v-btn>
      <v-btn color="primary" @click="transfer" :loading="transfering">Transfer</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import helpers from '@methods/helpers';

export default {
  name: 'PoleChangeManager',
  props: ['show'],
  data: () => ({
    poleChoiceMode: 'pole-before',
    poleChoice: '',
    poleBefore: '',
    poleAfter: '',
    imagesToMove: [],
    folderImages: [],
    sortedFolders: [],
    transfering: false,
  }),
  methods: {
    ...mapActions(['updateImage', 'setCurrentFolder', 'updateFolder']),
    closeDialog() {
      this.$emit('cancel');
    },
    async transfer() {
      this.transfering = true;
      const imagesSelected = this.imagesToMove.map((im) => this.folderImages[im]);
      if (imagesSelected.length === 0) {
        this.transfering = false;
        return;
      }
      let poleChoice;
      switch (this.poleChoiceMode) {
        case 'pole-before':
          poleChoice = this.poleBefore;
          break;
        case 'pole-after':
          poleChoice = this.poleAfter;
          break;
        case 'pole-choice':
          poleChoice = this.poleChoice;
          break;
        default:
          poleChoice = this.currentFolder;
          break;
      }
      const tPayloads = imagesSelected.map((image) => {
        const payload = {
          project_id: image.project_id,
          companyId: image.companyId,
          user_id: image.user_id || image.userId,
          filename: image.filename,
          date: image.date,
          update: { folder: poleChoice.path },
        };
        return payload;
      });
      await Promise.all(tPayloads.map((t) => this.updateImage(t)));

      // update folders
      const currentFolderPayload = {
        pid: this.currentFolder.project,
        cid: this.currentFolder.company,
        path: this.currentFolder.path,
        updates: {
          image_count: this.currentFolder.image_count - 1,
          processed_image_count: this.currentFolder.processed_image_count - 1,
        },
      };

      const response = await this.updateFolder(currentFolderPayload);
      if (response.status === 200) {
        const nextFolderPayload = {
          pid: poleChoice.project,
          cid: poleChoice.company,
          path: poleChoice.path,
          updates: {
            image_count: poleChoice.image_count + 1,
            processed_image_count: poleChoice.processed_image_count + 1,
          },
        };

        await this.updateFolder(nextFolderPayload);
      }
      const cf = this.currentFolder;
      this.setCurrentFolder(this.rootFolder);
      this.setCurrentFolder(cf);
      this.transfering = false;
      this.$emit('cancel');
    },
    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : '');

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return text.toString()
        .toLowerCase()
        .indexOf(query.toString().toLowerCase()) > -1;
    },
    initPoleManager() {
      if (!this.show) return;
      this.folderImages = this.selectedImageList.filter(
        (img) => img.folder === this.currentFolder.path,
      );
      this.imagesToMove = this.folderImages.map((e, i) => i);
      this.sortedFolders = this.allFolders.map((fold) => {
        const dist = helpers.euclidError(fold.location, this.currentFolder.location).toFixed(4);
        const f = {
          ...fold,
          dist,
          text: `${fold.path} (${dist})`,
        };
        return f;
      });
      this.sortedFolders.sort((a, b) => a.dist - b.dist);
      if (this.sortedFolders.length === 0) return;
      if (this.sortedFolders.length === 1) {
        [this.poleBefore] = this.sortedFolders;
        [this.poleAfter] = this.sortedFolders;
      } else if (this.sortedFolders.length === 2) {
        [this.poleBefore] = this.sortedFolders;
        // eslint-disable-next-line prefer-destructuring
        this.poleAfter = this.sortedFolders[1];
      } else {
        // eslint-disable-next-line prefer-destructuring
        this.poleBefore = this.sortedFolders[1];
        // eslint-disable-next-line prefer-destructuring
        this.poleAfter = this.sortedFolders[2];
      }
    },
  },
  computed: {
    ...mapGetters(['allFolders', 'currentFolder', 'rootFolder', 'selectedImageList']),
  },
  mounted() {
    this.initPoleManager();
  },
  watch: {
    show() {
      this.initPoleManager();
    },
  },
};
</script>
