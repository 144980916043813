<template>
  <div id="severity-level-filters" class="mx-auto d-block">
    <v-chip-group v-model="selected">
      <v-chip
        v-for="(severity, i) in severities"
        :key="severity"
        small
        :color="colors[i].color"
        :text-color="colors[i].textColor"
        :id="`filter-${severity}`"
      >
        {{ severity }}
      </v-chip>
    </v-chip-group>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import tdScalingSeverityColorPicker from '@components/images/severity';
import { severityColorPicker } from '@methods/helpers';
import severities from '@utils/severity-level';

export default {
  name: 'SeverityLevelFilters',
  data: () => ({
    defaultSeverities: [],
    tdScale: ['None', 'TD-1', 'TD-2', 'TD-4', 'TD-5'],
    colors: ['', '', '', '', '', ''],
    selected: '',
    active: '',
  }),
  methods: {
    ...mapActions(['setSeverityFilterClicked', 'setSeverityFilter', 'setInsightFilters']),
    severityColor(s) {
      return severityColorPicker(s, this.companyHas('td_scaling'));
    },
  },
  computed: {
    ...mapGetters(['companyHas', 'currentSeverityFilters']),
    severities() {
      return (this.companyHas('td_scale')) ? this.tdScale : this.defaultSeverities;
    },
  },
  watch: {
    currentSeverityFilters() {
      const index = this.defaultSeverities.findIndex(
        (severity) => severity === this.currentSeverityFilters[0],
      );

      if (index !== this.selected) {
        this.selected = index;
      }
    },
    selected(newSelected) {
      const index = newSelected;
      const removeIndex = this.colors.findIndex((color) => color !== '');

      if (removeIndex !== -1) this.colors[removeIndex] = '';
      let level;
      if (this.companyHas('td_scale')) {
        level = this.tdScale[index];
      } else {
        level = this.defaultSeverities[index];
      }
      this.colors[index] = (this.companyHas('td_scale')) ? tdScalingSeverityColorPicker(level) : severityColorPicker(level);

      const filter = (this.companyHas('td_scale')) ? this.tdScale[index] : this.defaultSeverities[index];
      this.setSeverityFilter([filter]);
      this.setInsightFilters(typeof filter !== 'undefined' ? [filter] : []);
      this.$eventBus.$emit('map:filterSeverity', filter);
      this.setSeverityFilterClicked(true);
    },
  },
  mounted() {
    this.defaultSeverities = [...severities];
  },
};
</script>

<style scoped>
#filter-Critical.v-chip--active{
  color: white;
}
</style>
