<template>
  <v-container>
    <v-menu
      v-model="menu"
      :close-on-click="true"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="date"
          :label="label"
          prepend-icon="event"
          readonly
          :error-messages="error"
          dense
          outlined
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        @input="menu = false"
        v-on:change="getDate"
      ></v-date-picker>
    </v-menu>
  </v-container>
</template>

<script>
export default {
  name: 'DatePicker',
  props: ['label', 'error', 'iDate'],
  data: () => ({
    menu: false,
    date: '',
  }),
  watch: {
    iDate(newDate) {
      this.date = newDate;
    },
  },
  methods: {
    getDate() {
      this.$emit('dateInput', this.date);
    },
  },
};
</script>
