import firebase from '@database';
import platformBackendClient from '@backend-clients/platform-backend';

export default {
  get: (uid) => platformBackendClient.get(`/api/auth/firebase/${uid}`),

  update: (uid, data) => platformBackendClient.put(`/api/auth/firebase/${uid}`, data),

  update_jwt: (uid) => platformBackendClient.put(`/api/auth/signed-token/update/${uid}`),

  twofactor_verify: (payload) => platformBackendClient.post(`/api/auth/${payload.verification}/verify/${payload.uid}`, payload.body),

  reset_password: (payload) => platformBackendClient.post('/api/users/reset-password', payload),

  getFirebaseToken: () => firebase.auth().currentUser.getIdToken()
    .then((token) => `Bearer ${token}`),
  /*
    |-------------------------------------------|
    |   @method       getJwtToken               |
    |   @params       uid <String>              |
    |   @return       Promise <JwtToken>        |
    |   @descrtipion  Generates a Jwt Web token |
    |                 based on the user id that |
    |                 is provided by Firebase   |
    |                 Authentication            |
    |-------------------------------------------|
  */
  getJwtToken: (uid) => new Promise((resolve, reject) => {
    platformBackendClient.get(`/api/auth/token/jwt/${uid}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  }),

  getFirebaseUser: (query) => new Promise((resolve, reject) => {
    platformBackendClient.get(`/api/user/?${query}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  }),

  getCustomToken: (uid) => new Promise((resolve, reject) => {
    platformBackendClient.get(`/api/auth/firebase/custom-token/user/${uid}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  }),

  customTokenAuthenticate: (token) => new Promise((resolve, reject) => {
    firebase.auth().signInWithCustomToken(token)
      .then(() => resolve())
      .catch((err) => reject(err));
  }),

  registrationEmail: (recipient, name, url) => platformBackendClient.post('/api/email/registration', { recipient, name, url }),

  deleteFirebaseUser: (uid) => new Promise((resolve, reject) => {
    platformBackendClient.delete(`/api/auth/firebase/${uid}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  }),
};
