<template>
  <div class="pt-5">
    <v-textarea
    outlined
    :disabled="!hasPermission('IMAGE_DETAIL_WRITE')"
    :label="labelText"
    v-model="note"
    hide-details
  ></v-textarea>
  <v-btn
    v-if="hasPermission('IMAGE_DETAIL_WRITE')"
    color="primary"
    @click="updateNote(false)"
    :loading="savingNote"
    class="my-2"
    >
      Save Note
      <span>
        <v-icon light>save</v-icon>
      </span>
  </v-btn>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {

  name: 'FolderNote',
  data: () => ({
    note: '',
    imageFolder: null,
    savingNote: false,
    labelText: '',
  }),
  methods: {
    ...mapActions(['updateFolder', 'setNotification']),
    async updateNote() {
      if (!this.imageFolder) return;
      if (this.note === this.imageFolder.note) return;
      this.savingNote = true;
      const payload = {
        pid: this.currentProject.pid,
        cid: this.currentCompany.cid,
        sid: this.imageFolder.id,
        updates: {
          note: this.note,
        },
      };

      const response = await this.updateFolder(payload);

      if (response.status === 200) {
        const notification = {
          success: true,
          message: 'notes successfully updated.',
        };
        this.setNotification(notification);
      }
      this.savingNote = false;
    },
    initNote() {
      const imageFolder = this.allFolders.find((f) => f.path === this.currentImage.folder);
      this.imageFolder = imageFolder;
      this.note = imageFolder ? imageFolder.note : '';
      this.labelText = `Note - ${imageFolder ? imageFolder.path : ''}`;
    },
  },
  computed: {
    ...mapGetters(['hasPermission', 'currentImage', 'allFolders', 'currentCompany', 'currentProject']),
  },
  mounted() {
    this.initNote();
  },
  watch: {
    currentImage() {
      if (this.$route.name !== 'ProcessedImages') return;
      this.initNote();
    },
  },
};
</script>
