<template>
  <v-card>
    <v-card-title id="normal-payment" class="headline">
      Add Payment
      <v-spacer></v-spacer>
      <v-btn
        id="close-add-payment"
        icon
        color="primary"
        @click="closeForm"
      >
        <v-icon>close</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <StripeAddPayment />
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import StripeAddPayment from '@components/stripe_elements/StripeAddPayment.vue';

export default {
  name: 'AddPayment',
  data: () => ({
    formData: {
      fullName: '',
      cardNumber: '',
      exp_month: '',
      exp_year: '',
      cvc: '',
      address: {
        street: '',
        city: '',
        state: '',
        unit: '',
        zip: '',
      },
    },
  }),
  components: { StripeAddPayment },
  methods: {
    getFormData(payment) { this.formData = payment; },
    closeForm() { this.$emit('closeDialog'); },
  },
  computed: {
    ...mapGetters(['currentCompany', 'currentUser']),
    isCustomer() {
      return this.currentCompany.customerId.length > 0;
    },
  },
};
</script>
