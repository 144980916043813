<template>
  <v-container fluid>
    <v-card flat>
      <v-card-title class="headline">No Plan</v-card-title>
      <v-card-text>
        <p class="font-weight-light message">
          It seems like you haven't activated our image processing services yet.
          Have a look below for more details on each plans and services that we offer with PowerAI
        </p>
      </v-card-text>
      <v-container fluid v-if="loadingPrices">
        <v-row>
          <v-col xs="12" sm="12" md="6" lg="4" xl="4">
            <v-skeleton-loader
              v-bind="attrs"
              type="card"
            ></v-skeleton-loader>
          </v-col>
          <v-col xs="12" sm="12" md="6" lg="4" xl="4">
            <v-skeleton-loader
              v-bind="attrs"
              type="card"
            ></v-skeleton-loader>
          </v-col>
          <v-col xs="12" sm="12" md="6" lg="4" xl="4">
            <v-skeleton-loader
              v-bind="attrs"
              type="card"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid v-else>
        <v-data-iterator
          :items="prices"
          hide-default-footer
        >
          <template v-slot:default="props">
            <v-row>
              <v-col
                v-for="plan in props.items"
                :key="plan.nickname"
                cols="12"
                sm="6"
                md="4"
                lg="3"
              >
                <v-card class="plan-card">
                  <v-card-title class="subheading font-weight-bold">
                    {{ plan.nickname }} Plan
                    <span v-if="plan.nickname === 'Silver'"> (recommended)</span>
                  </v-card-title>
                  <v-card-subtitle class="subtitle">
                    Price: ${{ (plan.tiers[0].unit_amount/100).toFixed(2) }}/image
                  </v-card-subtitle>
                  <v-divider></v-divider>
                  <v-list dense>
                    <v-list-item
                      class="plan-services"
                      v-for="(plan, index) in plans[lowerFirst(plan.nickname)]"
                      :key="index"
                    >
                      <v-list-item-content>
                        {{ plan }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="activate-plan-btn"
                      color="primary"
                      text
                      :to="`/image-plan/${plan.nickname}/${plan.product}`"
                    >Activate</v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import plans from '@components/billing/subscriptions/plans';
import { toLowerFirst } from '@methods/helpers';

export default {
  name: 'NoImageProcessingPlan',
  data: () => ({
    attrs: {
      class: 'mb-6',
      elevation: 2,
    },
    plans: {
      bronze: [],
      silver: [],
      gold: [],
    },
    prices: [],
  }),
  methods: {
    ...mapActions(['getProducts', 'getServices', 'getPrices', 'getProduct']),
    lowerFirst(str) {
      return toLowerFirst(str);
    },
  },
  computed: {
    ...mapGetters(['currentCompany', 'loadingProduct', 'loadingPrices', 'allPrices', 'pricingErrors', 'currentProduct']),
    priceData() {
      return this.allPrices.filter((price) => price.nickname !== 'Dashboard');
    },
  },
  async created() {
    const { bronze, silver, gold } = plans;
    this.plans.bronze = bronze;
    this.plans.silver = silver;
    this.plans.gold = gold;

    this.getProducts();
    const prices = await this.getPrices();

    this.prices = prices.filter((price) => price.nickname !== 'Dashboard');
  },
};
</script>
