import firebase from '@database';
import platformBackendClient from '@backend-clients/platform-backend';

const productCollection = firebase.database.collection('products');

const state = {
  products: [],
  product: {},
  loading: false,
  loadServices: false,
  load: false,
};

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const getters = {
  allProducts: (state) => state.products,
  currentProduct: (state) => state.product,
  loadingProducts: (state) => state.loading,
  loadingProduct: (state) => state.load,
};

const actions = {
  getProduct({ commit }, productId) {
    commit('loading_product');
    return new Promise((resolve, reject) => {
      platformBackendClient.get(`/api/products/${productId}`)
        .then((res) => {
          commit('fetch_product', res.data);
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  },
  // Getting products from stripe store
  async getProducts({ commit }) {
    commit('loading_product');
    return new Promise((resolve, reject) => {
      platformBackendClient.get('/api/products')
        .then((res) => {
          commit('fetch_products', res.data);
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  },
  getProductByName({ commit }, productName) {
    commit('loading_product');
    return new Promise((resolve, reject) => {
      productCollection
        .where('name', '==', productName)
        .get()
        .then((querySnapShot) => {
          const { docs } = querySnapShot;
          const data = docs.map((doc) => doc.data());
          const [product] = data;
          const res = {
            productId: product.productId,
            name: product.name,
          };
          resolve(res);
          commit('fetch_product', res);
        })
        .catch((err) => reject(err));
    });
  },
  getAllProducts({ commit }) {
    commit('loading_products');

    return new Promise((resolve, reject) => {
      productCollection.get()
        .then((querySnapshot) => {
          const { docs } = querySnapshot;
          const data = docs.map((doc) => doc.data());
          commit('fetch_products', data);
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getProductByProductId({ commit }, productId) {
    commit('loading_product');
    return new Promise((resolve, reject) => {
      productCollection.where('productId', '==', productId).get()
        .then((querySnapShot) => {
          const { docs } = querySnapShot;
          const data = docs.map((doc) => doc.data());
          commit('fetch_product', data[0]);
          resolve(data[0]);
        })
        .catch((err) => reject(err));
    });
  },
};

const mutations = {
  loading_services: (state) => {
    state.loadServices = true;
  },
  loading_products: (state) => {
    state.loading = true;
  },
  loading_product: (state) => {
    state.load = true;
  },
  fetch_products: (state, products) => {
    state.products = products;
    state.loading = false;
  },
  fetch_product: (state, product) => {
    state.product = product;
    state.load = false;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
