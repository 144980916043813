<template>
  <v-container fluid>
    <v-sheet class="mx-auto" elevation="8">
      <v-slide-group
        v-model="model"
        class="pa-4"
        active-class="success"
        show-arrows
      >
        <v-slide-item v-for="(images, classType) in alphabeticallySorted" :key="classType">
          <FaultSummaryCard :type="classType" :images="images" />
        </v-slide-item>
      </v-slide-group>
    </v-sheet>
  </v-container>
</template>

<script>
import FaultSummaryCard from '@components/analytics/insights/fault_summary/FaultSummaryCard.vue';
import { flattenArray, uniqueArray } from '@methods/helpers';

export default {
  name: 'FaultSummaryCards',
  props: ['images'],
  components: {
    FaultSummaryCard,
  },
  data: () => ({
    model: false,
  }),
  computed: {
    alphabeticallySorted() {
      const res = {};

      // Flatten the list of labels of each processed image
      const classLists = flattenArray(this.images
        .filter((img) => img.processedImageUrl)
        .map((img) => img.process_tracking.slice(-1)[0].labels));
      classLists.forEach((classItem, index) => {
        // If it includes a colon,
        // split it up and take the 1st index of the split up array to set as classLists[index]
        if (classItem.includes(':')) {
          const label = classItem.split(':')[1];
          classLists[index] = label;
        }
      });

      // Create a unique array out of classLists
      const allClassLists = uniqueArray(classLists).sort();
      allClassLists.forEach((classType) => { res[classType] = []; });

      this.images
        .filter((image) => image.processedImageUrl)
        .forEach((image) => {
          const { labels } = image.process_tracking.slice(-1)[0];
          labels.forEach((classType) => {
            // The label within process_tracking will be shown as (severity):(label)
            // Declare an undefined 'key' and set the key to be either classType
            // or the first index of the string split
            let key;
            if (classType.includes(':')) [, key] = classType.split(':');
            else key = classType;
            res[key].push(image);
          });
        });

      return res;
    },
  },
};
</script>
