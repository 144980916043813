<template>
  <v-card>
    <v-card-title class="headline">Pausing the {{ subscriptionName }}</v-card-title>
    <v-card-text id="pausing-description">
      Pausing the {{ subscriptionName }} will pause the invoice charges immediately.
      You will still have access to all your data.
    </v-card-text>
     <v-card-actions>
      <v-dialog v-model="pauseSub" width="450">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            id="pause-sub-btn"
            v-on="on"
            v-bind="attrs"
            text
            color="error"
          >{{ `Pause ${currentSubscription.plan.nickname} Plan` }}</v-btn>
        </template>
        <PauseSubscriptionConfirm
          :title="`Pause ${currentSubscription.plan.nickname} Plan`"
          :name="currentSubscription.plan.nickname"
          :id="currentSubscription.id"
          :invoiceId="currentSubscription.latest_invoice.id"
          :content="content"
          v-on:closePauseSub="closePauseDialog"
        />
      </v-dialog>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import PauseSubscriptionConfirm from '@components/dashboard-settings/access/confirms/PauseSubscriptionConfirm.vue';

export default {
  name: 'PausePlanSection',
  components: {
    PauseSubscriptionConfirm,
  },
  props: ['subscriptionName', 'buttonText', 'content'],
  data: () => ({
    pauseSub: false,
  }),
  computed: {
    ...mapGetters(['currentSubscription']),
  },
  methods: {
    closePauseDialog() {
      this.pauseSub = false;
    },
  },
};
</script>
