<template>
  <div id="image-navigation-btns" class="mx-auto d-block">
    <v-btn fab small left color='primary' @click="setPrevImage" id="prev-btn">
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>
    <v-btn fab small left color='primary' @click="setNextImage" id="next-btn">
      <v-icon>mdi-chevron-right</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ImageNavigationButtons',
  props: ['images'],
  methods: {
    ...mapActions(['setCurrentImage']),
    findImageIndex() {
      return this.images.findIndex((image) => image.id === this.currentImage.id);
    },
    setNextImage() {
      const currentIndex = this.findImageIndex();
      let nextImage;

      if (currentIndex === this.images.length - 1) {
        [nextImage] = this.images;
      } else {
        nextImage = this.images[currentIndex + 1];
      }
      this.setCurrentImage(nextImage);
    },
    setPrevImage() {
      const currentIndex = this.findImageIndex();
      let prevImage;

      if (currentIndex === 0) {
        [prevImage] = this.images.slice(-1);
      } else {
        prevImage = this.images[currentIndex - 1];
      }

      this.setCurrentImage(prevImage);
    },
  },
  computed: {
    ...mapGetters(['currentImage']),
  },
};
</script>
