const CryptoJS = require('crypto-js');

// For documentation: https://cryptojs.gitbook.io/docs/
/*
  |-------------------------------------------------------|
  | @method       encrypt                                 |
  | @params       text <String>                           |
  | @description  Takes in a string text to encrypt into  |
  |               a secure hash                           |
  |-------------------------------------------------------|
*/
export const encrypt = (text) => CryptoJS.AES
  .encrypt(text, process.env.VUE_APP_ENCRYPTION_PASSWORD)
  .toString();

/*
  |-------------------------------------------------------|
  | @method       decrypt                                 |
  | @params       cipherText <String>                     |
  | @description  Takes in an encrypted text to decode    |
  |               into readable text                      |
  |-------------------------------------------------------|
*/
export const decrypt = (cipherText) => {
  if (!cipherText) return '';

  const bytes = CryptoJS.AES.decrypt(cipherText, process.env.VUE_APP_ENCRYPTION_PASSWORD);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};
