<template>
  <v-container fluid v-if="loadingProduct || loadingPrices">
    {{ loadingPrices }}
    <Loading />
  </v-container>
  <v-container fluid v-else>
    <v-row>
      <v-col cols="4">
        <v-card :loading="loadingProduct">
          <v-card-title class="headline">{{ currentProduct.name }}</v-card-title>
          <v-card-subtitle class="subtitle">
            <span>
              {{ currentPrice.nickname }} Plan
            </span>
            <br />
            <span>
              ${{ (currentPrice.tiers[0].unit_amount)/100 }}/image
            </span>
          </v-card-subtitle>
          <v-divider></v-divider>
          <v-card-text>
            <p class="font-weight-black">Services</p>
            <v-list dense>
              <v-list-item
                v-for="(service, index) in services"
                :key="index"
              >
                <v-list-item-content>{{ service }}</v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
        <v-alert
          class="mt-4"
          v-if="noPaymentSelected"
          dense
          outlined
          type="error"
        >
          Please select a payment method
        </v-alert>
      </v-col>
      <v-col cols="8">
        <v-card>
          <CheckoutForm
            :product="`${currentProduct.name} - ${currentPrice.nickname} Plan`"
            :cost="`$${(currentPrice.tiers[0].unit_amount/100).toFixed(2)}/image`"
            v-on:selectedPaymentId="getPaymentMethod"
            :name="currentProduct.name"
            subtotal="$0.00"
            tax="$0.00"
          />
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              id="activate-btn"
              text
              color="primary"
              @click="activatePlan"
              :loading="processingPayment"
            >
              {{ `Activate ${currentProduct.name} Plan` }}
              <template v-slot:loader>
                <v-progress-circular
                  color="primary"
                  indeterminate
                  class="mr-2"
                ></v-progress-circular>
                <span>Processing your payment...</span>
              </template>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CheckoutForm from '@components/checkout/CheckoutForm.vue';
import Loading from '@components/common/Loading.vue';
import plans from '@components/billing/subscriptions/plans';
import { toLowerFirst } from '@methods/helpers';

export default {
  name: 'PurchaseImagePlan',
  props: ['productId', 'planName'],
  data: () => ({
    selectedPaymentId: '',
    services: [],
    noPaymentSelected: false,
  }),
  components: {
    CheckoutForm,
    Loading,
  },
  methods: {
    ...mapActions(['getProduct', 'getPrice', 'getPriceByProductId', 'createSubscription', 'setLoadingPrices', 'updateCompany', 'getCustomer']),
    getPaymentMethod(id) {
      if (this.noPaymentSelected) this.noPaymentSelected = false;
      this.selectedPaymentId = id;
    },
    async activatePlan() {
      if (this.selectedPaymentId === '') {
        this.noPaymentSelected = true;
        return;
      }

      const data = {
        paymentMethodId: this.selectedPaymentId,
        priceId: this.currentPrice.id,
        customerId: this.currentCompany.customerId,
      };
      const result = await this.createSubscription(data);
      if (result.subscription.status === 'active') {
        const { id } = result.subscription;
        const plan = toLowerFirst(result.subscription.plan.nickname);
        const { currentCompany } = this;
        currentCompany.services[plan] = {
          id,
          amount: 0,
          payment: {
            id: result.subscription.default_payment_method,
          },
        };
        this.updateCompany(currentCompany)
          .then((response) => {
            const { customerId } = response;
            this.getCustomer(customerId)
              .then(() => {
                this.$router.push({ path: '/billing/subscription-plans/access' });
              });
          });
      }
    },
  },
  computed: {
    ...mapGetters(['currentProduct', 'loadingProduct', 'currentPrice', 'loadingPrices', 'currentCompany', 'processingPayment']),
  },
  created() {
    const { productId, planName } = this;
    this.setLoadingPrices();
    this.getProduct(productId);
    const payload = {
      planName,
    };
    this.getPriceByProductId(payload)
      .then((price) => {
        const { nickname } = price;
        const tierName = toLowerFirst(nickname);
        this.services = plans[tierName];
      });
  },
};
</script>
