<template>
  <v-container fluid id="image-caption">
    <div id="caption-display" v-if="!editingImage">
      <span v-if="noCaption">
        No caption has been assigned to this image yet
      </span>
      <span v-else>{{ caption }}</span>
      <!-- <v-btn small icon @click="toggle('open')">
        <v-icon>mdi-pencil</v-icon>
      </v-btn> -->
    </div>
    <div id="caption-editor" v-else>
      <v-text-field
        v-model="caption"
        outlined
        dense
        :error-messages="error"
        label="Enter an image caption"
        @input="imageCaptionHandler"
      >
      </v-text-field>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ImageCaption',
  props: ['type'],
  data: () => ({
    edit: false,
    caption: '',
    error: '',
  }),
  methods: {
    ...mapActions(['updateImage', 'setNotification', 'setModifiedImage']),
    getCaption() {
      let cap = '';
      if (this.$route.name === 'ProcessedImages') {
        const processTracking = this.currentImage.process_tracking;
        const processedImage = processTracking[processTracking.length - 1];

        cap = (!Object.keys(processedImage).includes('caption') || processedImage.caption === '') ? '' : processedImage.caption;
      } else cap = (!Object.keys(this.currentImage).includes('caption') || this.currentImage.caption === '') ? '' : this.currentImage.caption;

      return cap;
    },
    imageCaptionHandler() {
      this.setModifiedImage({ type: 'caption', value: this.caption });
    },
  },
  computed: {
    ...mapGetters(['currentImage', 'editingImage']),
    noCaption() {
      return this.caption === '';
    },
  },
  watch: {
    editingImage(isOpen) {
      const noCaption = this.caption === 'No caption have been set for this image yet';
      if (isOpen && noCaption) {
        this.caption = '';
      } else {
        this.caption = this.getCaption();
      }
    },
  },
  mounted() {
    this.caption = this.getCaption();
  },
};
</script>
