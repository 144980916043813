<template>
  <v-container>
    <InsightsTemplate v-if="type==='insights'"/>
    <DashboardAccessLoader v-else-if="type==='dashboard-access'" />
  </v-container>
</template>

<script>
import InsightsTemplate from '@components/common/loaders/InsightsTemplate.vue';
import DashboardAccessLoader from '@components/common/loaders/dashboard-access/DashboardAccessLoader.vue';

export default {
  name: 'SkeletonLoader',
  props: ['type'],
  components: {
    InsightsTemplate,
    DashboardAccessLoader,
  },
  data: () => ({
    attrs: {
      class: 'mb-6',
      boilerplate: true,
      elevation: 2,
    },
  }),
};
</script>
