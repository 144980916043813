<template>
  <v-card>
    <v-card-title class="headline">Add Location</v-card-title>
    <v-divider></v-divider>
    <v-btn
      text
      x-small
      link
      :to="`/project/${pid}/images/ai/add_locations`"
      class="ma-4"
    >
      <v-icon>keyboard_backspace</v-icon>
      Back to List
    </v-btn>
    <v-container fluid>
      <v-row>
        <v-col xs="12" sm="12" md="6" lg="6" xl="6">
          <v-img
            :src="currentProcessedImage.url"
            :lazy-src="currentProcessedImage.url"
            aspect-ratio="1"
          >
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col>
        <v-col xs="12" sm="12" md="6" lg="6" xl="6">
          <span class="font-weight-light">
            Manually include the latitude and longitude coordinates of this image
          </span>
          <v-text-field  class="mt-4" outlined label="Latitude" v-model="lat"></v-text-field>
          <v-text-field outlined label="Longitude" v-model="lng"></v-text-field>
          <v-btn
            x-small
            color="primary"
            class="mb-4"
            @click="geocodeCoordinates"
          >Confirm Location</v-btn>
          <div id="fetch-location" v-if="fetchAddress">
            <v-progress-circular indeterminate></v-progress-circular>
            <span class="font-weight-medium">Getting the address of your coordinates...</span>
          </div>
          <p class="font-weight-medium" v-if="!fetchAddress && address.length > 0">
            If
            <span class="text-decoration-underline">{{address}}</span>
            is the correct location for this image, save the coordinate points.
          </p>
        </v-col>
      </v-row>
    </v-container>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="closeDialog">Close</v-btn>
      <v-btn color="primary" @click="saveLocation" :disabled="!foundAddress">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import helpers from '@methods/helpers';
import gmapsInit from '../../gmaps_api';

export default {
  name: 'EditImageForm',
  props: ['id', 'pid'],
  data: () => ({
    lat: '',
    lng: '',
    google: null,
    maps: null,
    geocoder: null,
    LatLng: null,
    address: '',
    fetchAddress: false,
    updateMessage: '',
  }),
  methods: {
    ...mapActions(['getCurrentProcessedImage', 'updateProcessedImageById']),
    closeDialog() {
      this.$emit('closeDialog');
    },
    geocodeCoordinates() {
      this.fetchAddress = true;
      const { maps, lat, lng } = this;
      const coordinates = new maps.LatLng(parseFloat(lat), parseFloat(lng));
      const geocoder = new maps.Geocoder();
      geocoder.geocode({ location: coordinates }, (results, status) => {
        if (status !== 'OK' || !results[0]) throw new Error(status);
        this.address = results[0].formatted_address;
        this.fetchAddress = false;
      });
    },
    saveLocation() {
      const { lat, lng } = this;

      const coordinates = {
        lat: parseFloat(lat),
        long: parseFloat(lng),
      };
      const updatedImage = this.currentProcessedImage;
      updatedImage.location = helpers.getGeoCoordinates(coordinates);
      this.updateProcessedImageById(updatedImage)
        .then((res) => {
          const { code } = res;

          if (code === 'SUCCESS') this.updateMessage = 'Image location has been updated';
        });
    },
  },
  computed: {
    ...mapGetters(['currentProcessedImage']),
    foundAddress() {
      return this.address.length > 0;
    },
  },
  created() {
    this.getCurrentProcessedImage(this.id);
  },
  async mounted() {
    try {
      this.google = await gmapsInit();
      this.maps = this.google.maps;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  },
};
</script>
