<template>
  <v-container fluid>
    <EmployeesTable
      title="Employees"
      :employees="employeeData" :cid="cid" />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import EmployeesTable from '@components/company/EmployeesTable.vue';

export default {
  name: 'Employees',
  props: ['cid'],
  components: {
    EmployeesTable,
  },
  computed: {
    ...mapGetters(['allUsers', 'isAdmin', 'currentUser']),
    employeeData() {
      const currentUserTeamIds = this.currentUser.teams.map(({ tid }) => tid);
      const existingEmployees = this.allUsers.filter((e) => e != null).filter((user) => {
        if (this.currentUser.buzzAdmin === 'developer') {
          return true;
        } if (this.isAdmin) {
          return !(user.buzzAdmin);
        }
        return !(user.buzzAdmin) && user.teams.some(({ tid }) => currentUserTeamIds.includes(tid));
      });
      const formatStatus = (status) => status.split('_')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
      return existingEmployees.map((employee) => ({
        firstName: employee.firstName,
        lastName: employee.lastName,
        phone: employee.phone,
        email: employee.email,
        role: employee.role,
        teams: employee.teams,
        uid: employee.uid,
        firestore_uid: employee.firestore_uid,
        status: formatStatus(employee.status),
        permissions: employee.permissions,
      }));
    },
  },
};
</script>
