<template>
  <v-container fluid>
    <v-row>
      <v-col cols="4" v-for="i in 3" :key="i">
        <CardSkeletonLoader width="300"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CardSkeletonLoader from '@components/common/loaders/CardSkeletonLoader.vue';

export default {
  name: 'SwitchPlansLoaders',
  components: {
    CardSkeletonLoader,
  },
};
</script>
