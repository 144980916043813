<template>
  <v-container fluid id="filter-faults">
    <v-select
      :items="labelList"
      label="Filter by Fault Type"
      outlined
      chips
      small-chips
      clearable
      item-color="primary"
      multiple
      v-model="value"
      @input="onFaultChange"
    >
    </v-select>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import {
  all,
} from './faults';

export default {
  name: 'FilterFaultsInsights',
  props: ['all', 'custom'],
  data: () => ({
    value: [],
    labelList: [],
  }),
  methods: {
    ...mapActions(['setInsightsFaults', 'setFilterStatus', 'setInsightsFolders', 'setShowFolders']),
    async onFaultChange() {
      if (this.value === null || this.value === undefined) this.value = [];
      const filterStatus = cloneDeep(this.currentFilterStatus);
      filterStatus.fault.value = this.value;

      this.setInsightsFaults(this.value);
      this.setFilterStatus(filterStatus);

      if (this.insightsFaults.length > 0) {
        // eslint-disable-next-line arrow-body-style
        let result = [];

        this.insightsFaults.forEach((fault) => {
          // eslint-disable-next-line arrow-body-style
          const returnedFolders = this.companyFolders.filter((folder) => {
            return folder.faults.includes(fault);
          });
          result = [...new Set([...result, ...returnedFolders])];
        });

        this.setInsightsFolders(result);
      } else {
        this.setInsightsFolders(this.allFolders);
      }
      this.setShowFolders(true);
    },
  },
  computed: {
    ...mapGetters(['allImages', 'companyFolders', 'allFolders', 'currentFilterStatus', 'insightsFaults', 'flatProjectLabels']),
    allOrCustom() {
      const filter = (this.all) ? 'all' : 'custom';
      return filter;
    },
  },
  watch: {
    allOrCustom(newStatus) {
      if (newStatus === 'all') {
        this.value = all;
        this.setInsightsFolders(this.allFolders);
      } else {
        this.value = [];
      }

      const filter = cloneDeep(this.currentFilterStatus);
      filter.fault.value = this.value;
      this.setFilterStatus(filter);
      this.setInsightsFaults(this.value);
    },
    // Keeps this.value v-model consistent when the UI switches between
    // scrollable and un-scrollable
    // eslint-disable-next-line func-names
    'currentFilterStatus.fault': function (newFault) {
      this.value = newFault.value;
    },
    flatProjectLabels(newValue) {
      const labelList = [];
      // eslint-disable-next-line no-unused-expressions
      newValue.forEach((label) => {
        labelList.push(label.faultType);
      });
      const uniq = [...new Set(labelList)];
      this.labelList = uniq;
      this.value = uniq;
    },
  },
  mounted() { this.setInsightsFaults(this.value); },
};
</script>
