export default () => {
  let providerKey;

  switch (window.location.hostname) {
    case 'nypa.buzzpowerai.com':
      providerKey = process.env.VUE_APP_NYPA_SAML;
      break;
    case 'de.buzzpowerai.com':
      providerKey = process.env.VUE_APP_DE_SAML;
      break;
    case 'dev-de.buzzpowerai.com':
      providerKey = process.env.VUE_APP_DE_SAML;
      break;
    default:
      providerKey = process.env.VUE_APP_DEV_SAML;
      break;
  }

  return providerKey;
};
