<template>
  <v-card>
    <ReportsDetailMobile :pid="pid" />
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="close">Close</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ReportsDetailMobile from '@components/analytics/insights/image_section/ReportsDetailMobile.vue';

export default {
  name: 'ReportsDialog',
  props: ['pid'],
  components: { ReportsDetailMobile },
  methods: {
    close() {
      this.$emit('closeReports');
    },
  },
};
</script>
