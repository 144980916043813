<template>
  <v-container fluid>
    <div id="histogram" ref="chart"></div>
  </v-container>
</template>

<script>
import Plotly from '@components/charts/custom-plotly';
import dateFormat from '@utils/time-formats';

export default {
  name: 'HistogramV2',
  props: {
    images: {
      type: Array,
      default: Array,
    },
    chartId: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    histogram: null,
    imagesByDate: {},
  }),
  methods: {
    sortImagesByDate(images) {
      const imageDateCount = {};

      images.forEach((image) => {
        const createdAt = dateFormat(image.date, 'YEAR_MONTH_DATE');
        if (!(createdAt in imageDateCount)) imageDateCount[createdAt] = [];
        imageDateCount[createdAt].push(image);
      });
      return imageDateCount;
    },
    async initializeChart() {
      const { chart } = this.$refs;

      const dates = this.images.map((image) => dateFormat(image.date, 'YEAR_MONTH_DATE'));

      const trace = { x: dates, type: 'histogram' };

      this.imagesByDate = this.sortImagesByDate(this.images);
      const data = [trace];

      const layout = {
        title: {
          text: this.title,
          font: {
            family: 'Courier New, monospace',
            size: 24,
          },
        },
        hovertext: 'test',
        histnorm: 'probability',
        showLegend: true,
        legend: { orientation: 'h', valign: 'bottom' },
        marker: {
          thickness: 50,
        },
      };

      const config = {
        displayLogo: false,
        responsive: true,
        displayModeBar: false,
      };

      try {
        await Plotly.newPlot(chart, data, layout, config);
        this.histogram = await Plotly.relayout(chart, { showLegend: true });
        return this.histogram;
      } catch (err) {
        return err;
      }
    },
  },
  async mounted() {
    await this.initializeChart();
  },
};
</script>
