<template>
  <div class="carousel-display">
    <v-hover v-slot:default="{ hover }">
      <v-card
        :class="{ 'on-hover': hover }"
        id="image-shown"
      >
      <FilterableAnnotoriousRenderer v-if="imageType === 'ai'"/>
        <v-img
          :src="source"
          fit
          id="display-image"
          :lazy-src="source"
          height="auto"
          v-else
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
            </v-row>
          </template>
          <v-overlay :value="hover" absolute id="overlay">
            <v-card-text>
              <span v-if="!openTextEdit" class="headline">
                {{ caption }}
              </span>
              <v-text-field
                v-else
                label="Image Caption"
                v-model="caption"
                id="caption-field"
              ></v-text-field>
            </v-card-text>
            <v-divider></v-divider>
            <v-spacer></v-spacer>
            <v-card-actions v-if="!openTextEdit">
              <v-btn
                v-if="hasPermission('IMAGE_DETAIL_WRITE')"
                small
                text
                outlined
                @click="onOpenCaptionEditor"
                class="text-center"
                id="edit-caption-btn"
              >
                Edit Caption
                <v-icon>edit</v-icon>
              </v-btn>
            </v-card-actions>
            <v-card-actions v-else>
              <v-btn small text outlined @click="onCloseCaptionEditor">
                Close
              </v-btn>
              <v-btn small text outlined @click="onSaveCaptionEditor">
                Save
              </v-btn>
            </v-card-actions>
          </v-overlay>
        </v-img>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FilterableAnnotoriousRenderer from '@components/annotations/FilterableAnnotoriousRenderer.vue';

export default {
  name: 'ImageDisplay',
  props: ['image', 'imageType'],
  components: {
    FilterableAnnotoriousRenderer,
  },
  data: () => ({
    openTextEdit: false,
    caption: '',
    isFullScreen: false,
    annotatedImage: null,
  }),
  methods: {
    ...mapActions(['setNotification', 'updateImage', 'setCurrentImage']),
    // getCaption(image) {
    //   if (this.imageType === 'original' && image.caption) return image.caption;
    //   if (this.imageType === 'ai' && image.caption) return image.caption;
    //   return '';
    // },
    onOpenCaptionEditor() {
      this.openTextEdit = true;
    },
    onCloseCaptionEditor() {
      this.openTextEdit = false;
    },
    async onSaveCaptionEditor() {
      const imageDataToUpdate = {
        cid: this.currentCompany.cid,
        pid: this.currentProject.pid,
        user_id: this.image.user_id,
        iid: this.currentImage.id,
        update: {},
      };

      if (this.imageType === 'original') imageDataToUpdate.update.caption = this.caption;
      else {
        imageDataToUpdate.update.process_tracking = this.image.process_tracking;
        const { length } = imageDataToUpdate.update.process_tracking;

        imageDataToUpdate.update.process_tracking = this.image.process_tracking;
        imageDataToUpdate.update.process_tracking[length - 1].caption = this.caption;
      }
      const { update, ...imageUpdate } = imageDataToUpdate;
      const response = await this.updateImage({
        ...imageUpdate,
        image: { update },
      });
      if (response.status === 200) {
        const { data } = response;
        this.setCurrentImage(data);
        const { filename } = data;
        const imageType = (this.imageType === 'original') ? 'image' : 'processed image';
        this.setNotification({
          success: true,
          message: `Caption for the ${imageType} with filename ${filename} has been updated`,
        });
        this.onCloseCaptionEditor();
      }
    },
  },
  computed: {
    ...mapGetters(['currentImage', 'hasPermission', 'currentCompany', 'currentProject']),
    source() {
      return this.$route.name.includes('Original') ? this.currentImage.compressedUrl : this.currentImage.compressedProcessUrl;
    },
    cap() {
      const image = { ...this.currentImage };
      if (this.imageType === 'original') return image.caption;
      const { length } = image.process_tracking;
      return image.process_tracking[length - 1].caption;
    },
  },
  watch: {
    currentImage: {
      deep: true,
      handler(newImage) {
        this.caption = (this.imageType === 'original')
          ? newImage.caption
          : newImage.process_tracking[newImage.process_tracking.length - 1].caption;
      },
    },
  },
  mounted() {
    const image = { ...this.currentImage };
    const { length } = image.process_tracking;

    this.caption = (this.imageType === 'original') ? image.caption : image.process_tracking[length - 1].caption;
  },
};
</script>
