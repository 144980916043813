<template>
  <v-container fluid>
    <v-card width="800" class="mx-auto d-block" :loading="loadingProduct">
      <v-card-title class="headline">Analytics Dashboard</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <p>
          The Analytics Dashboard enables users to gain
          <span class="font-weight-black">data visualization insights</span>
          on processed images, gain <span class="font-weight-black">asset and fault mapping</span>
          , and <span class="font-weight-black">generate reports</span>.
        </p>
        <p>
          Get started today for <span class="font-weight-black">$150/month (per user)</span>
        </p>
        <p class="text-decoration-underline">
          * 3 users minimum
        </p>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          id="purchase-dashboard-btn"
          color="primary"
          :to="`/checkout/${currentProduct.productId}`"
        >Get Started</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'PurchaseDashboard',
  methods: {
    ...mapActions(['getProductByName']),
  },
  computed: {
    ...mapGetters(['currentProduct', 'loadingProduct']),
  },
  created() {
    this.getProductByName('Dashboard');
  },
};
</script>
