// import API_KEY from './stripe_api_key';

export default (Stripe, API_KEY) => {
  const stripe = Stripe(API_KEY);
  const elements = stripe.elements();
  const paymentRequest = stripe.paymentRequest({
    country: 'US',
    currency: 'usd',
    total: {
      label: 'Demo Total',
      amount: 1099,
    },
    requestPayerName: true,
    requestPayerEmail: true,
  });
  const { createToken } = stripe;
  const { confirmCardSetup } = stripe;
  const { retrieveSetupIntent } = stripe;

  return {
    elements,
    paymentRequest,
    createToken,
    confirmCardSetup,
    retrieveSetupIntent,
    confirmCardPayment: stripe.confirmCardPayment,
  };
};
