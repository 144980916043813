<template>
  <v-container fluid>
    <TableSkeletonLoader v-if="loadingCompanies" />
    <CompanyTable v-else />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import CompanyTable from '@components/admin/CompanyTable.vue';
import TableSkeletonLoader from '@components/common/loaders/TableSkeletonLoader.vue';

export default {
  name: 'Instances',
  components: {
    TableSkeletonLoader,
    CompanyTable,
  },
  computed: {
    ...mapGetters(['loadingCompanies']),
  },
};
</script>
