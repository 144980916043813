// import axios from 'axios';
import { saveAs } from 'file-saver';
import {
  utils as xlsxUtils,
  writeFile as xlsxWriteFile,
} from 'xlsx';
import moment from 'moment';
// import { baseURL } from '@utils/endpoint';
import helpers from '@methods/helpers';
import xlsxFormatter from '@components/analytics/insights/xlsx-format';
import timeFormats from '@utils/time-formats';
import abbreviations from '@components/images/abbreviations';
import { uniq, compact } from 'lodash';
import platformBackendClient from '@backend-clients/platform-backend';

const { FULL_MONTH_DATE_YEAR } = timeFormats;

export default {
  hasLocation: (item) => {
    if (!item.location) return false;

    return Object.keys(item).includes('location')
      || item.location.length === 2
      || (item.location[0] !== null && item.location[1] !== null);
  },
  /*
    |-----------------------------------------------------------------------|
    |   @function     getClassList                                          |
    |   @params       images <Array>                                        |
    |   @return       <Object>                                              |
    |   @description  Goes through and sorts all the found labels in all    |
    |                 images to an object (or dictionary). Keys would be    |
    |                 strings of the label names, being valued as an array  |
    |                 of images that contains that specific label.          |
    |                 Edge Case: returns empty object if parameter is empty |
    |-----------------------------------------------------------------------|
  */
  getClassList: (images) => {
    if (images.length < 1) return {};
    const labelDictionary = {};

    const processedImages = images.filter((image) => image.processedImageUrl);

    processedImages.forEach((image) => {
      const { labels } = image.process_tracking.slice(-1)[0];

      labels.forEach((label) => {
        let currentLabel;
        if (typeof label === 'string') {
          currentLabel = (label.includes(':')) ? label.split(':')[1] : label;
        } else {
          currentLabel = label.label;
        }

        if (!(currentLabel in labelDictionary)) {
          labelDictionary[currentLabel] = [];
        }
        labelDictionary[currentLabel].push({
          filename: image.filename,
          project_id: image.project_id,
          location: image.location,
          process_tracking: image.process_tracking,
          processedImageUrl: image.processedImageUrl,
          date: image.date,
          folder: image.folder,
        });
      });
    });
    return labelDictionary;
  },

  /*
    |-----------------------------------------------------------------------|
    |   @function     getNoLocationImages                                   |
    |   @params       images <Array>                                        |
    |   @return       <Array>                                               |
    |   @description  Finds and returns all images with no location within  |
    |                 the image exif data                                   |
    |                  Edge Case: returns empty array if parameter is empty |
    |-----------------------------------------------------------------------|
  */
  getNoLocationImages: (images) => {
    if (images.length < 1) return [];

    return images.filter((image) => {
      if (!image.location) return true;

      return !Object.keys(image).includes('location')
        || image.location.length < 2
        || (image.location[0] === null && image.location[1] === null);
    });
  },

  /*
    |-----------------------------------------------------------------------|
    |   @function     getLocatedImages                                      |
    |   @params       images <Array>                                        |
    |   @return       <Array>                                               |
    |   @description  Finds and returns all images containing location in   |
    |                 the image exif data                                   |
    |                 Edge Case: returns empty array if parameter is empty  |
    |-----------------------------------------------------------------------|
  */
  getLocatedImages: (images) => {
    if (images.length < 1) return [];

    return images.filter((image) => (Object.keys(image).includes('location')
        || image.location.length === 2)
        && (image.location[0] !== null && image.location[1] !== null));
  },

  /*
    |-----------------------------------------------------------------------|
    |   @function     getQueryString                                        |
    |   @params       filters <Object>                                      |
    |   @return       <String>                                              |
    |   @description  If no filters are passed in, it returns               |
    |                 an empty string. Otherwise it goes through            |
    |                 each filter types and generates a query by pushing    |
    |                 the encoded URI component of the stringified version  |
    |                 of the filter arrays passed in and joins it into a    |
    |                 string with the '&' character                         |
    |-----------------------------------------------------------------------|
  */
  getQueryString: (filters) => {
    const hasFilters = Object
      .values(filters)
      .filter((f) => f.length > 0)
      .length > 0;
    if (!hasFilters) return '';

    const filterTypes = Object.keys(filters);
    const queries = [];

    filterTypes.forEach((filter) => {
      if (filters[filter].length > 0) {
        const currentQuery = `${filter}=${encodeURIComponent(JSON.stringify(filters[filter]))}`;
        queries.push(currentQuery);
      }
    });

    return queries.join('&');
  },

  /*
    |-----------------------------------------------------------------------|
    |   @function     formatProcessedImages                                 |
    |   @params       images <Array>                                        |
    |   @return       <Array>                                               |
    |   @description  Finds all images that have been processed and maps it |
    |                 to contain consistent data formats throughout each    |
    |                 client instances                                      |
    |                 Edge Case: returns empty array if parameter is empty  |
    |-----------------------------------------------------------------------|
  */
  formatProcessedImages: (images) => {
    if (images.length === 0) return [];

    return images
      .filter((image) => image.processedImageUrl)
      .map((image) => {
        const currentImage = image;

        const formattedImage = {
          id: currentImage.id,
          bucket: currentImage.bucket,
          createdAt: currentImage.createdAt,
          folder: (currentImage.folder) ? currentImage.folder : '',
          gimbalPitchDegree: (currentImage.gimbalPitchDegree)
            ? currentImage.gimbalPitchDegree
            : null,
          lineId: (currentImage.lineId) ? currentImage.lineId : '',
          time_logs: currentImage.time_logs,
          structureId: (currentImage.structureId) ? currentImage.structureId : '',
          caption: currentImage.caption,
          companyId: currentImage.companyId,
          date: currentImage.date,
          filename: currentImage.filename,
          isDeleted: currentImage.isDeleted,
          compressedUrl: currentImage.compressedUrl,
          compressedProcesUrl: currentImage.compressedProcessUrl,
          location: currentImage.location,
          originalImageUrl: currentImage.originalImageUrl,
          process_tracking: [currentImage.process_tracking.slice(-1)[0]],
          project_id: currentImage.project_id,
          timestamp: currentImage.timestamp,
        };

        if (currentImage.processedImageUrl) {
          formattedImage.processedImageUrl = currentImage.processedImageUrl;
        }

        return formattedImage;
      });
  },

  /*
    |-----------------------------------------------------|
    |   @method       formatFolder                        |
    |   @params       folder <Object>, images [<Object]   |
    |   @return       Promise [<folders>]                 |
    |   @description  Formats the `allFolders` list that  |
    |                 is passed in to have consistent     |
    |                 data throughout all platforms       |
    |-----------------------------------------------------|
  */
  formatFolder: (folder, images, project) => {
    const currentFolder = folder;
    if (folder.path === '__all__') {
      currentFolder.project = project.pid;
      currentFolder.company = project.cid;
      currentFolder.image_count = images.length;
      currentFolder.processed_image_count = images
        .filter((image) => image.processedImageUrl).length;

      const projectDate = project.date;
      currentFolder.date = timeFormats(projectDate, 'DAY_DATE');
      currentFolder.reviewed = false;

      return {
        project: project.pid,
        company: project.cid,
        image_count: '',
        path: folder.path,
        processed_image_count: currentFolder.processed_image_count,
        date: timeFormats(projectDate, 'DAY_DATE'),
        reviewed: false,
        overall_severity: '',
        note: '',
        work_order: false,
        location: [null, null],
      };
    }

    return {
      company: folder.company,
      complete_inspection: (folder.complete_inspection) ? folder.complete_inspection : false,
      date: (folder.path === '__all__')
        ? ''
        : timeFormats(new Date(folder.date), 'DAY_DATE'),
      path: folder.path,
      overall_severity: (folder.overall_severity) ? folder.overall_severity : '',
      note: (folder.note) ? folder.note : '',
      project: folder.project,
      work_order: (folder.work_order) ? folder.work_order : false,
      id: folder.id,
      location: (folder.location) ? folder.location : [null, null],
      reviewed: folder.reviewed,
      image_count: (folder.path === '__all__') ? images.length : folder.image_count,
      processed_image_count: (folder.path === '__all__')
        ? images.filter((image) => image.processedImageUrl).length
        : helpers.getProcessedImagesByFolder(images, folder).length,
    };
  },

  /*
    |=============================================|
    |   E X P O R T  X L S X  F U N C T I O N S   |
    |=============================================|
  */

  fetchProjectByPid: (pid) => new Promise((resolve, reject) => {
    platformBackendClient.get(`/api/projects/${pid}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  }),

  /*
    |-------------------------------------------------|
    |   @method       fetchImagesByProject            |
    |   @params       cid <String>, pid <String>      |
    |   @return       Promise [<images>]              |
    |   @description  Takes in the company id and the |
    |                 project id to fetch a list of   |
    |                 images within a project         |
    |-------------------------------------------------|
  */
  fetchImagesByProject: (cid, pid) => new Promise((resolve, reject) => {
    platformBackendClient.get(`/api/image/company/${cid}/project/${pid}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  }),

  /*
    |-------------------------------------------------|
    |   @method       fitToColumn                     |
    |   @params       aoa [<Array>]                   |
    |   @return       <Number>                        |
    |   @description  Goes through the first array in |
    |                 the aoa (array of array) and    |
    |                 determines the index with the   |
    |                 greatest length to set as the   |
    |                 size of the excel cell          |
    |-------------------------------------------------|
  */
  fitToColumn: (aoa) => aoa[0]
    .map((a, i) => ({
      wch: Math.max(...aoa.map((a2) => {
        const current = (a2[i]) ? a2[i] : '';
        if (typeof current === 'number') return current.toString().length;
        return current.length;
      })),
    })),

  /*
    |-------------------------------------------------|
    |   @method       generateXlsxSheet               |
    |   @params       data <Object>                   |
    |   @return       <void>                          |
    |   @description  Goes through the folder data to |
    |                 extract information on the      |
    |                 images within it to generate an |
    |                 excel sheet                     |
    |-------------------------------------------------|
  */
  generateXlsxSheet(data) {
    const rows = [];

    data.folders.forEach((folder) => {
      const folderImages = helpers.getImagesByFolder(data.images, folder);
      const xlsxRow = xlsxFormatter(
        folder,
        folderImages,
        data.project,
        data.attachmentPrefix,
        data.attachmentSubprefix,
        data.client,
      );
      rows.push(xlsxRow);
    });

    const aoa = [Object.keys(rows[0] || [])]
      .concat(rows.map((row) => Object.values(row)));
    const sheet = xlsxUtils.aoa_to_sheet(aoa);
    sheet['!cols'] = this.fitToColumn(aoa);
    const book = xlsxUtils.book_new();
    xlsxUtils.book_append_sheet(book, sheet, 'Work Request');

    const xlsxFilename = `${data.project.name}-${moment().format(FULL_MONTH_DATE_YEAR)}.xlsx`;
    const xlsx = xlsxWriteFile(book, xlsxFilename);
    return xlsx;
  },

  /*
    |-------------------------------------------------------------|
    |   @method       formatImageCSVData                          |
    |   @parameters   images [<Objects>], projectName <String>    |
    |   @return       [<Object>]                                  |
    |-------------------------------------------------------------|
  */
  formatImageJsonData: (images, folders, projectName) => {
    const DEFECTIVE_LABELS = ['High', 'Medium', 'Low'];

    const getTitle = (image) => {
      if (!image.processedImageUrl) return '';

      const { labels } = image.process_tracking.slice(-1)[0];
      const uniqueLabels = compact(uniq(labels));
      const labelList = uniqueLabels.map((label) => {
        if (typeof label === 'object') {
          return label.label;
        }
        if (label.includes(':')) {
          return label.split(':')[1];
        }
        return label;
      });
      // eslint-disable-next-line arrow-body-style
      const filtered = abbreviations.filter((label) => {
        return DEFECTIVE_LABELS.includes(label.severity) && labelList.includes(label.faultType);
      });

      return filtered.map((label) => label.faultType).join('; ');
    };

    const hasLocation = (item) => {
      if (!item.location) return false;

      return (
        Object.keys(item).includes('location')
        || item.location.length === 2
        || (item.location[0] !== null && item.location[1] !== null)
      );
    };

    if (images.length < 1) return [];

    const getSeverity = (image) => {
      const tracking = image.process_tracking.slice(-1)[0];
      if (Object.keys(image).includes('processedImageUrl') && tracking) {
        const { severity } = image.process_tracking.slice(-1)[0];
        return severity ? severity.replace(/,/, '') : 'N/A';
      }
      return 'N/A';
    };
    const getDescription = (image) => {
      const tracking = image.process_tracking.slice(-1)[0];
      if (image.processedImageUrl !== null && tracking && tracking.labels) {
        const labels = tracking.labels.map((label) => {
          if (typeof label === 'string') {
            return label;
          }
          return `${label.severity}:${label.label}`;
        });
        return labels.join(',')
          .replace(/,/g, ':');
      }
      return 'n/a';
    };

    const jsonData = images.map((image) => {
      const imageFolder = folders.find((folder) => image.folder === folder.path);
      const isProcessed = image.processedImageUrl !== null;
      return {
        project: projectName,
        site: imageFolder && imageFolder.path ? imageFolder.path : '',
        structure: '',
        filename: image.filename,
        fault: getTitle(image),
        description: getDescription(image),
        image_caption: image.caption,
        priority:
          imageFolder && imageFolder.overall_severity ? imageFolder.overall_severity : 'N/A',
        severity: getSeverity(image),
        work_order: imageFolder && imageFolder.work_order ? 'Yes' : 'No',
        work_order_number: '',
        date_inspected: moment().format('YYYY-MM-DD'),
        date_processed: moment().format('YYYY-MM-DD'),
        attachment: isProcessed ? image.processedImageUrl : 'n/a',
        images: image.originalImageUrl,
        latitude: hasLocation ? image.location[0] : 'n/a',
        longitude: hasLocation ? image.location[1] : 'n/a',
        structure_notes: imageFolder && imageFolder.note ? imageFolder.note : '',
      };
    });

    return jsonData;
  },

  /*
    |-------------------------------------------------------------|
    |   @method       saveJson                                    |
    |   @parameters   images [<Objects>], projectName <String>    |
    |   @return       <Void>                                      |
    |   @description  Formats data to be saved as a Json file.    |
    |                 Generates the filename.                     |
    |-------------------------------------------------------------|
  */
  saveJson(images, folders, projectName) {
    const jsonData = this.formatImageJsonData(images, folders, projectName);
    const filename = `${projectName}-${moment().format(FULL_MONTH_DATE_YEAR)}.json`;
    const fileToSave = new Blob([...JSON.stringify(jsonData)], {
      type: 'application/json',
      name: filename,
    });

    saveAs(fileToSave, filename);
    return fileToSave;
  },

  /*
    |-------------------------------------------------------------|
    |   @method       getCsvData                                  |
    |   @parameters   cid <String>, pid <String>                  |
    |   @return       <Response>                                  |
    |   @description  Asynchornous function that makes a call     |
    |                 to the endpoint /api/dashboard/company/:cid |
    |                 /project/:pid/csv to generate csvData. It   |
    |                 will be passed into a Blob with a generated |
    |                 filename.                                   |
    |-------------------------------------------------------------|
  */
  getCsvData: (cid, pid) => new Promise((resolve, reject) => {
    platformBackendClient.get(`/api/dashboard/company/${cid}/project/${pid}/csv`)
      .then((res) => {
        const csvData = res.data.csv;
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        resolve({
          blob,
          filename: res.data.filename,
        });
      })
      .catch((err) => reject(err));
  }),
};
