<template>
  <div class="image-upload">
    <vue-dropzone
      ref="directDropzone"
      id="drop"
      :options="options"
      @vdropzone-upload-progress="onUploadProgress"
      @vdropzone-total-upload-progress="totalUploadProgress"
      @vdropzone-sending="beforeSend"
      @vdropzone-file-added="addedFile"
      @vdropzone-files-added="handleAddedFiles"
      @vdropzone-queue-complete="onQueueComplete"
      style="max-height: 500px; overflow: scroll;"
    ></vue-dropzone>
    <v-progress-linear
      indeterminate
      color="primary"
      v-if="uploadingImages"
    ></v-progress-linear>
    <p class="text-center">
      {{ (uploadingImages) ?
        'Give us a moment to finish up the upload'
        : `${totalProgress.toFixed(2)} %`}}
    </p>
    <p class="text-center" v-if="uploadingImages">
      Please wait for the images to finish uploading before submitting
      the ticket
    </p>
    <DialogMessage
      :show="this.showMessage"
      @updateShow="this.updateShow"
      :title="'Max Size Exceed'"
      :description="'The total file size of the folder ' +
       'exceed the size limit of 2GB. ' +
       'For large file upload, please contact us for assistance '" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import vue2Dropzone from 'vue2-dropzone';
import DialogMessage from '@components/common/DialogMessage.vue';
import services from '../../../services';

export default {
  name: 'SupportImageUploader',
  props: ['reset'],
  components: {
    vueDropzone: vue2Dropzone,
    DialogMessage,
  },
  data() {
    return {
      showMessage: false,
      uploadingImages: false,
      totalProgress: 0,
      indeterminate: false,
      options: {
        paramName: 'file',
        acceptedFiles: 'image/*',
        url: 'https://storage.googleapis.com/',
        thumbnailWidth: 200,
        addRemoveLinks: true,
        dictDefaultMessage: "<div class='v-list-item__icon'><i aria-hidden='true' class='v-icon notranslate material-icons theme--light light-blue--text text--lighten-3' style='font-size:200px;'>cloud_upload</i></div>",
        createImageThumbnails: false,
        autoProcessQueue: false,
        parallelUploads: 5,
        timeout: 3600 * 1000,
        maxFilesize: 20,
        // Sendgrid recommends that the file attachments do not exceed 10MB
        // but the limit is 30MB. To keep it within range
        // while still going over only 10MB, the maxFileSize
        // will be set to 20MB
      },
      filePolicies: {},
    };
  },
  methods: {
    ...mapActions(['setUploading', 'setNotification']),
    updateShow(value) {
      this.showMessage = value;
    },
    preUploadProcess(file, formData) {
      const params = this.filePolicies[file.name].fields;
      formData.append('Content-Type', file.type);
      Object.keys(params).forEach((p) => {
        formData.append(p, params[p]);
      });
      return formData;
    },
    // eslint-disable-next-line no-unused-vars
    onUploadProgress(file, progress, bytesSent) {
      this.uploadProgress = Number.parseFloat(progress).toFixed(2);
    },
    async beforeSend(file, xhr, formData) {
      this.preUploadProcess(file, formData);
    },
    // eslint-disable-next-line no-unused-vars
    totalUploadProgress(totaluploadprogress, totalBytes, totalBytesSent) {
      if (totaluploadprogress) this.totalProgress = totaluploadprogress;
    },
    /* eslint no-param-reassign: "error" */
    addedFile(file) {
      this.$emit('upload', { uploading: true });
      file.previewElement.querySelector('[data-dz-name]').textContent = file.name;
    },
    async handleAddedFiles() {
      this.$refs.directDropzone.setOption('url', this.uploadUrl);
      this.$refs.directDropzone.setOption('clickable', false);
      this.setUploading(true);
      await new Promise((r) => setTimeout(r, 1000));
      const dfiles = this.$refs.directDropzone.getAcceptedFiles();
      await this.handleUploadFiles(dfiles);
      this.$refs.directDropzone.processQueue();
      const elementsToModify = ['dz-image', 'dz-details', 'dz-progress', 'dz-success-mark'];
      elementsToModify.forEach((className) => this.noZIndex(className));
    },
    async onQueueComplete() {
      this.$refs.directDropzone.getAcceptedFiles().filter((file) => file.status === 'success');
      this.$refs.directDropzone.setOption('clickable', false);
      const urls = Object.keys(this.filePolicies).map((filename) => {
        const policy = this.filePolicies[filename];
        const url = `${policy.url}${policy.fields.key}`;
        return url;
      });

      this.$emit('upload', {
        uploading: false,
        images: urls.map((url) => ({
          filename: url.split('/').slice(-1)[0],
          url,
          contentType: 'image/*',
        })),
      });
      this.setUploading(false);
    },
    noZIndex(className) {
      const elements = document.getElementsByClassName(className);
      Object.keys(elements).forEach((element) => elements[element].setAttribute('style', 'z-index: 0'));
    },
    async handleUploadFiles(uploadFiles) {
      const data = uploadFiles.map((file) => ({ contentType: file.type, filename: `support/${file.name}` }));
      const payload = { data };
      try {
        const res = await services.storage.signed_url(this.currentCompany.bucket, payload);
        this.filePolicies = { ...this.filePolicies, ...res.data };
        return res;
      } catch (err) {
        this.setNotification({
          message: 'There was an issue uploading the iamges',
          success: true,
          color: 'error',
        });
        return err;
      }
    },
  },
  computed: {
    ...mapGetters(['currentCompany', 'currentProject']),
    uploadUrl() {
      return `https://storage.googleapis.com/${this.currentCompany.bucket}/`;
    },
  },
  watch: {
    // Reset value from the parent component will
    // remove the displayed files. Normally occurs
    // when the user is finished writing up a support
    // ticket
    reset(newReset) {
      if (newReset) {
        this.$refs.directDropzone.removeAllFiles();
      }
    },
  },
};
</script>
