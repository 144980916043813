const abbreviations = [
  {
    faultType: 'Conductor Good',
    abbreviation: 'COG',
    severity: 'None',
  },
  {
    faultType: 'Conductor Damaged',
    abbreviation: 'COD',
    severity: 'High',
  },
  {
    faultType: 'Connectors Corroded',
    abbreviation: 'COC',
    severity: 'Medium',
  },
  {
    faultType: 'Cotter Pin Missing_Loose',
    abbreviation: 'CPL',
    severity: 'Medium',
  },
  {
    faultType: 'Dampers Damaged',
    abbreviation: 'DAD',
    severity: 'Medium',
  },
  {
    faultType: 'Glass Insulators Broken',
    abbreviation: 'GLB',
    severity: 'High',
  },
  {
    faultType: 'Glass Insulators Contaminated',
    abbreviation: 'GLC',
    severity: 'High',
  },
  {
    faultType: 'Glass Insulators Good',
    abbreviation: 'GLG',
    severity: 'None',
  },
  {
    faultType: 'Ground Bonds Broken',
    abbreviation: 'GBB',
    severity: 'High',
  },
  {
    faultType: 'Marker Balls',
    abbreviation: 'MAB',
    severity: 'None',
  },
  {
    faultType: 'Misaligned Amor Grips',
    abbreviation: 'MIA',
    severity: 'Low',
  },
  {
    faultType: 'Misaligned Hardware',
    abbreviation: 'MIH',
    severity: 'Medium',
  },
  {
    faultType: 'Misaligned Insulators',
    abbreviation: 'MII',
    severity: 'Medium',
  },
  {
    faultType: 'Nests',
    abbreviation: 'NST',
    severity: 'Low',
  },
  {
    faultType: 'No Nest',
    abbreviation: 'NON',
    severity: 'None',
  },
  {
    faultType: 'Polymer Insulators Contaminated',
    abbreviation: 'PYC',
    severity: 'High',
  },
  {
    faultType: 'Polymer Insulators Flashed',
    abbreviation: 'PYF',
    severity: 'High',
  },
  {
    faultType: 'Porcelain Insulators Broken',
    abbreviation: 'PRB',
    severity: 'High',
  },
  {
    faultType: 'Porcelain Insulators Contaminated',
    abbreviation: 'PRC',
    severity: 'High',
  },
  {
    faultType: 'Porcelain Insulators Flashed',
    abbreviation: 'PRF',
    severity: 'High',
  },
  {
    faultType: 'Porcelain Insulators Good',
    abbreviation: 'PRG',
    severity: 'None',
  },
  {
    faultType: 'Properly Aligned Insulators',
    abbreviation: 'PAI',
    severity: 'None',
  },
  {
    faultType: 'Wood Pole Cap Problems',
    abbreviation: 'WCP',
    severity: 'Low',
  },
  {
    faultType: 'Wood Pole Cavities',
    abbreviation: 'WPC',
    severity: 'Low',
  },
  {
    faultType: 'Rust',
    abbreviation: 'RST',
    severity: 'Medium',
  },
  {
    faultType: 'Polymer Insulator Good',
    abbreviation: 'PYG',
    severity: 'None',
  },
  {
    faultType: 'Cotter Pin Good',
    abbreviation: 'CPG',
    severity: 'None',
  },
  {
    faultType: 'Vegetation Encroachment',
    abbreviation: 'VEE',
    severity: 'Medium',
  },
  {
    faultType: 'Pole',
    abbreviation: 'POL',
    severity: 'None',
  },
  {
    faultType: 'Cross Arm Good',
    abbreviation: 'CAG',
    severity: 'None',
  },
  {
    faultType: 'Cross Arm Damaged',
    abbreviation: 'CAD',
    severity: 'High',
  },
  {
    faultType: 'Joint Rusting',
    abbreviation: 'JOR',
    severity: 'Medium',
  },
  {
    faultType: 'Joint Rusting Polymer',
    abbreviation: 'JRP',
    severity: 'Medium',
  },
  {
    faultType: 'Polymer Insulators Broken',
    abbreviation: 'PYB',
    severity: 'High',
  },
  {
    faultType: 'EPAC Insulator Good',
    abbreviation: 'EIG',
    severity: 'None',
  },
  {
    faultType: 'EPAC Insulator Damaged',
    abbreviation: 'EID',
    severity: 'High',
  },
  {
    faultType: '8080 Insulator Good',
    abbreviation: '8IG',
    severity: 'None',
  },
  {
    faultType: '8080 Insulator Damaged',
    abbreviation: '8ID',
    severity: 'High',
  },
  {
    faultType: 'Double Pole',
    abbreviation: 'DBP',
    severity: 'None',
  },
  {
    faultType: 'Structure Damage - Cracks',
    abbreviation: 'CRK',
    severity: 'Medium',
  },
];

abbreviations.sort((a, b) => (a.faultType > b.faultType ? 1 : -1));
export default abbreviations;
