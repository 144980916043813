<template>
  <v-container id="image-priority" fluid>
    <div id="image-priority-display" v-if="!edit">
      <Chip :text="priority" size="x-small" />
      <v-btn icon @click="toggle('open')">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </div>
    <div id="image-priority-editor" v-else>
      <v-select
        label="Select Image Priority"
        :items="['None', 'TD-5', 'TD-4', 'TD-2', 'TD-1', 'Emergency']"
        outlined
        dense
        v-model="modifiedPriority"
      ></v-select>
    </div>
  </v-container>
</template>

<script>
import Chip from '@components/common/Chip.vue';

export default {
  name: 'ImagePriority',
  props: ['priority'],
  components: {
    Chip,
  },
  data: () => ({
    edit: false,
    modifiedPriority: '',
  }),
  methods: {
    toggle(status) {
      if (status === 'open') {
        this.edit = true;
      }

      if (status === 'close') {
        this.edit = false;
      }
    },
  },
};
</script>
