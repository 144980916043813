<template>
  <v-container fluid id="pole-field-inspection">
    <div id="field-inspection-display" v-if="!editingFolder">
      <span class="text-caption">{{ inspect }}</span>
    </div>
    <div id="field-inspection-editor" v-else>
      <v-select
        :items="['Yes', 'No']"
        dense
        outlined
        v-model="inspect"
        label="Request for field inspection?"
        @change="changeFieldInspect"
      >
      </v-select>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'PoleFieldInspection',
  props: ['folder'],
  data: () => ({
    edit: false,
    inspect: '',
  }),
  methods: {
    ...mapActions(['updateFolder', 'setNotification', 'setFieldInspection']),
    changeFieldInspect() {
      this.setFieldInspection(this.inspect);
    },
  },
  computed: {
    ...mapGetters(['currentProject', 'editingFolder']),
  },
  watch: {
    folder(newFolder) {
      this.inspect = newFolder.field_inspection ? 'Yes' : 'No';
    },
  },
  mounted() {
    this.inspect = this.folder.field_inspection ? 'Yes' : 'No';
  },
};
</script>
