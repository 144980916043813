<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <v-container>
          <v-row>
            <v-col>
              <v-btn icon text large @click="goBack"><v-icon>mdi-arrow-left-thick</v-icon></v-btn>
            </v-col>
            <v-col>
              <v-text-field
                id="images-list-search"
                outlined
                v-model="search"
                append-icon="mdi-magnify"
                label="Search Images"
                single-line
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="showSeverityFilters">
            <SeverityLevelFilters />
          </v-row>
        </v-container>
      </v-card-title>
      <v-data-table
        id="images-list-table"
        :headers="headers"
        :items="imagesList"
        class="elevation-1"
        :search="search"
        :sort-by="['date']"
        :sort-desc="[true]"
        v-scroll:#images-list-table="onScroll"
        :footer-props="footer"
        :loading="loadingFolders"
        loading-text="Loading folders..."

      >
      <template v-slot:item="{ item }">
        <tr
          @click="selectImage(item)"
          :class="(isSelected(item)) ? 'selected-image' : 'not-selected-image'"
        >
          <td>
            <v-btn icon text><v-icon dark>mdi-image</v-icon></v-btn>
          </td>
          <td>{{getCaption(item)}}</td>
          <td>{{item.niceName}}</td>
          <td>{{item.folder}}</td>
          <td>{{item.nice_date}}</td>
        </tr>
      </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import SeverityLevelFilters from '@components/folders/SeverityLevelFilters.vue';
import dateFormat from '@utils/time-formats';

export default {
  name: 'ImageList',
  props: {
    images: {
      type: Array,
      default: () => [],
    },
    showSeverityFilters: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SeverityLevelFilters,
  },
  data: () => ({
    search: '',
    headers: [
      {
        text: '',
        align: 'start',
        sortable: false,
        value: '',
      },
      { text: 'Caption', value: 'caption' },
      {
        text: 'File Name',
        align: 'start',
        sortable: false,
        value: 'niceName',
      },
      {
        text: 'Folder Name',
        value: 'folder',
      },
      {
        text: 'Date',
        value: 'nice_date',
      },
    ],
    timeout: null,
    perPage: 10,
    footer: {
      showFirstLastPage: true,
      firstIcon: 'mdi-page-first',
      lastIcon: 'mdi-page-last',
      prevIcon: 'mdi-chevron-left',
      nextIcon: 'mdi-chevron-right',
      'items-per-page-options': [10, 50, 100, 200, 500],
    },
  }),
  methods: {
    ...mapActions(['setCurrentImage', 'setLazyLoadPointer', 'setShowFolders', 'setSeverityFilter', 'setInsightFilters']),
    onScroll(e) {
      // debounce if scrolling fast
      // eslint-disable-next-line no-unused-expressions
      this.timeout && clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        const { scrollTop } = e.target;
        const rows = Math.ceil(scrollTop / this.rowHeight);

        this.start = rows + this.perPage > this.images.length
          ? this.images.length - this.perPage : rows;

        this.$nextTick(() => {
          e.target.scrollTop = scrollTop;
        });
      }, 20);
    },
    filterSeverity(selectedSeverity) {
      Vue.nextTick(() => {
        const filteredImages = this.images.filter((image) => {
          const { length } = image.process_tracking;
          const { severity } = image.process_tracking[length - 1];
          return severity === selectedSeverity;
        });
        if (filteredImages.length > 0) {
          this.selectImage(filteredImages[0]);
        }
      });
    },
    selectImage(image) {
      const pointer = this.images.findIndex(
        (img) => img.filename + img.date === image.filename + image.date,
      );
      this.setCurrentImage(image);
      this.$eventBus.$emit('folder:imageChanged', image);
      this.setLazyLoadPointer(pointer);
    },
    goBack() {
      const current = { ...this.currentImage };
      if (this.companyHas('poleByFolders')) {
        this.setShowFolders(true);
      } else {
        this.setSeverityFilter([]);
        this.setInsightFilters([]);
        this.$eventBus.$emit('map:filterSeverity');
        this.$emit('goBack');
      }
      this.$nextTick(() => {
        this.setCurrentImage({ ...current });
      });
    },
    isSelected(image) {
      return this.currentImage.filename === image.filename;
    },
    getCaption(image) {
      const { name } = this.$route;
      const isAiProcessed = (name === 'ProcessedImages');
      return (isAiProcessed) ? image.process_tracking.slice(-1)[0].caption : image.caption;
    },
  },
  computed: {
    ...mapGetters(['currentFolder', 'rootFolder', 'companyHas', 'currentCompany', 'loadingFolders', 'currentImage']),
    imagesList() {
      return this.images.map((img) => {
        let niceName = (img.filename.includes('+')) ? img.filename.split('+')[1] : img.filename;
        [niceName] = niceName.split('!').slice(-1);
        return {
          ...img,
          nice_date: dateFormat(img.date, 'FULL_DATE'),
          niceName,
        };
      });
    },
    enabledFolders() {
      return this.companyHas('poleByFolders');
    },
  },
  created() {
    this.$eventBus.$on('map:filterSeverity', this.filterSeverity);
  },
  mounted() {
    if (this.images.length === 0) {
      return;
    }
    this.selectImage(this.images[0]);
  },
};
</script>

<style scoped>
#images-list-table{
  max-height: 650px;
  overflow: auto;
}
.selected-image {
  cursor: pointer;
  background-color: #E3F2FD;
}
.not-selected-image {
  cursor: pointer;
}
</style>
