<template>
  <v-card>
    <v-card-title>
      <v-text-field
        id="abbreviation-search"
        outlined
        v-model="search"
        append-icon="mdi-magnify"
        label="Search Abbreviation"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      id="abbreviation-table"
      :headers="headers"
      :items="labels"
      :height="h"
      hide-default-footer
      class="elevation-1"
      :items-per-page="labels.length"
      :search="search"
      v-scroll:#abbreviation-table="onScroll"
    >
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'LabelAbbreviations',
  props: ['h'],
  data: () => ({
    search: '',
    headers: [
      {
        text: 'Fault Type',
        align: 'start',
        sortable: false,
        value: 'faultType',
      },
      { text: 'Abbreviation', value: 'abbreviation' },
    ],
    labels: [],
    timeout: null,
    perPage: 10,
  }),
  computed: {
    ...mapGetters(['flatProjectLabels', 'projectLabels']),
  },
  methods: {
    onScroll(e) {
      // debounce if scrolling fast
      // eslint-disable-next-line no-unused-expressions
      this.timeout && clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        const { scrollTop } = e.target;
        const rows = Math.ceil(scrollTop / this.rowHeight);

        this.start = rows + this.perPage > this.labels.length
          ? this.labels.length - this.perPage : rows;

        this.$nextTick(() => {
          e.target.scrollTop = scrollTop;
        });
      }, 20);
    },
  },
  mounted() {
    this.labels = this.flatProjectLabels;
  },
};
</script>

<style scoped>
#abbreviation-table{
  max-height: 250px;
  overflow: auto;
}
</style>
