<template>
  <v-card>
    <v-card-title class="headline">Edit Billing Address</v-card-title>
    <v-card-text>
      <v-container fluid>
        <v-row>
          <v-col xs="12" sm="12" md="12" lg="12" xl="12">
            <v-text-field
              outlined
              label="Street Address"
              v-model="currentAddress.line1"
            ></v-text-field>
          </v-col>
          <v-col xs="12" sm="4" md="4" lg="4" xl="4">
            <v-text-field
              outlined
              label="City"
              v-model="currentAddress.city"
            ></v-text-field>
          </v-col>
          <v-col xs="12" sm="4" md="4" lg="4" xl="4">
            <v-select
              outlined
              label="State"
              :items="stateChoices"
              v-model="currentAddress.state"
            ></v-select>
          </v-col>
          <!-- <v-col xs="12" sm="4" md="6" lg="6" xl="6">
            <v-text-field
              outlined
              label="State"
              v-model="currentAddress.state"
            ></v-text-field>
          </v-col> -->
          <v-col xs="12" sm="4" md="4" lg="4" xl="4">
            <v-text-field
              outlined
              label="Zip Code"
              v-model="currentAddress.postal_code"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="saveAddress">Save</v-btn>
      <v-btn color="primary" @click="closeDialog">Close</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import states from '@components/billing/states';

export default {
  name: 'AddressForm',
  props: ['address', 'payment'],
  data: () => ({
    stateChoices: [],
    currentAddress: {
      line1: '',
      city: '',
      state: '',
      postal_code: '',
      country: '',
    },
  }),
  methods: {
    ...mapActions(['getPaymentMethodById', 'updatePaymentMethod']),
    closeDialog() {
      this.$emit('closeAddress');
    },
    saveAddress() {
      const data = {
        billing_details: {
          address: this.currentAddress,
        },
      };
      const payload = {
        id: this.currentPaymentMethod.id,
        data,
      };
      this.updatePaymentMethod(payload);
    },
  },
  computed: {
    ...mapGetters(['currentPaymentMethod', 'loadingPaymentMethod']),
  },
  async created() {
    const paymentMethod = await this.getPaymentMethodById(this.payment.id);
    const { address } = paymentMethod.billing_details;

    this.currentAddress.line1 = address.line1;
    this.currentAddress.city = address.city;
    this.currentAddress.state = address.state;
    this.currentAddress.postal_code = address.postal_code;
    this.currentAddress.country = address.country;
  },
  mounted() {
    this.stateChoices = states;
    // this.$nextTick(() => {
    //   const { address } = this.currentPaymentMethod.billing_details;

    //   this.currentAddress.line1 = address.line1;
    //   this.currentAddress.city = address.city;
    //   this.currentAddress.state = address.state;
    //   this.currentAddress.postalCode = address.postal_code;
    //   this.currentAddress.country = address.country;
    // });
  },
};
</script>
