<template>
  <!-- <v-card> -->
    <v-select
      outlined
      v-model="detectionTime"
      label="Filter by Timeframe"
      id="filter-timeframe"
      :items="timeFrame"
      clearable
      @change="getTimeframe"
    >
    </v-select>
  <!-- </v-card> -->
</template>

<script>
export default {
  name: 'DectionFaultFilter',
  data: () => ({
    detectionTime: '',
    timeFrame: [
      'Day',
      'Week',
      'Month',
    ],
  }),
  methods: {
    getTimeframe() {
      this.$emit('filterDate', this.detectionTime);
    },
  },
};
</script>
