<template>
  <v-list-group no-action :prepend-icon="icon" :id="id">
    <template v-slot:activator>
      <v-list-item-content>
        <v-list-item-title>{{ title }}</v-list-item-title>
      </v-list-item-content>
    </template>
    <v-list-item
      v-for="child in children"
      :key="child.title"
      link
      :to="child.path"
      :id="child.id"
    >
      <v-list-item-title>{{ child.title }}</v-list-item-title>
    </v-list-item>
  </v-list-group>
</template>

<script>
export default {
  name: 'ListGroupItem',
  props: {
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    children: {
      type: Array,
      default: Array,
    },
    id: {
      type: String,
      default: 'generic-list-group-id',
    },
  },
};
</script>
