<template>
<v-container fluid>
  <v-card>
    <v-card-text>
      <p class="font-weight-bold" id="report-overview-date">
        {{dateRange}}</p>
      <p class="font-weight-bold" id="report-overview-cost">
        ${{new Intl.NumberFormat().format(cost)}}</p>
    </v-card-text>
  </v-card>
</v-container>
</template>

<script>
export default {
  name: 'ReportOverview',
  props: ['cost', 'dateRange'],
};
</script>
