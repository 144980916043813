<template>
    <v-text-field
      label="Search"
      v-model="search"
      prepend-inner-icon="search"
      hide-details
      class="ma-0 searchbar"
      clearable
      @input="filter"
    ></v-text-field>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'SearchBar',
  props: ['searchFor'],
  data: () => ({ search: '' }),
  methods: {
    ...mapActions(['setProjectSearch']),
    filter() {
      switch (this.searchFor) {
        case 'projects':
          this.setProjectSearch(this.search);
          break;
        default:
          break;
      }
    },
  },
};
</script>
