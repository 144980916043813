import Plotly from 'plotly.js/lib/core';
import Pie from 'plotly.js/lib/pie';
import Bar from 'plotly.js/lib/bar';
import Histogram from 'plotly.js/lib/histogram';
// import ScatterMapBox from 'plotly.js/lib/scattermapbox';

Plotly.register([
  Pie,
  Bar,
  // ScatterMapBox,
  Histogram,
]);

export default Plotly;
