<template>
  <v-dialog v-model="dialog">
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            color="primary"
            id="request-label-btn"
            icon
            @click="openDialog"
            v-bind="attrs"
            v-on="{...dialog, ...tooltip}"
          >
            <v-icon>fa-solid fa-tags</v-icon>
        </v-btn>
        </template>
        Request Label
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title>Request Label</v-card-title>
      <RequestLabelForm
        :key="currentImage.id"
        v-on:request="requestLabel"
        @closeDialog="close"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import RequestLabelForm from '@components/images/labels/RequestLabelForm.vue';

export default {
  name: 'RequestLabelModal',
  components: {
    RequestLabelForm,
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    ...mapActions(['requestNewLabel', 'setNotification']),
    openDialog() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    async requestLabel(labelData) {
      // Create service to request for a new label
      try {
        const res = await this.requestNewLabel(labelData);

        if (res.status === 202) {
          this.setNotification({
            success: true,
            message: res.data.message,
            color: '',
          });

          this.close();
        }
      } catch (err) {
        this.setNotification({
          success: true,
          message: 'Failed to request a label',
          color: 'error',
        });
        // eslint-disable-next-line no-console
        console.error('Error: ', err);
      }
    },
  },
  computed: {
    ...mapGetters(['currentImage']),
  },
};
</script>
