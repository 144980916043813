<template>
  <v-expansion-panels>
    <v-expansion-panel @change="updatePanelState">
      <v-expansion-panel-header class="text-xs-h6 text-sm-h6">
        Abbreviations Reference
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <LabelAbbreviations h="175"/>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import LabelAbbreviations from '@components/images/LabelAbbreviations.vue';

export default {
  name: 'CollapseAbbreviations',
  components: { LabelAbbreviations },
  data: () => ({ show: false }),
  methods: {
    ...mapActions(['setPanelStates']),
    updatePanelState() {
      this.show = !this.show;
      const panels = cloneDeep(this.currentPanelStates);
      panels.abbreviations = this.show;
      this.setPanelStates(panels);
    },
  },
  computed: {
    ...mapGetters(['currentPanelStates']),
  },
};
</script>
