<template>
  <v-card max-height="600">
    <v-card-title class="headline">Images With No Coordinates</v-card-title>
    <v-divider></v-divider>
    <v-container>
      <v-row>
        <v-col
          v-for="(image, i) in images"
          :key="i"
          class="d-flex child-flex"
          cols="4"
        >
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card
                width="200"
                flat
                :to="`/project/${image.pid}/images/ai/add_locations/${image.id}`"
              >
                <NoLocationImage :image="image" />
                <v-fade-transition>
                  <v-overlay
                    v-if="hover"
                    absolute
                    color="#036358"
                  ></v-overlay>
                </v-fade-transition>
              </v-card>
            </template>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="close">Close</v-btn>
      <v-btn color="primary">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import NoLocationImage from '@components/images/map/NoLocationImage.vue';

export default {
  name: 'NoLocationImageList',
  props: ['pid'],
  components: {
    NoLocationImage,
  },
  methods: {
    close() {
      this.$emit('closeDialog');
    },
  },
  computed: {
    ...mapGetters(['allProcessedImages']),
    images() {
      return this.allProcessedImages.filter((image) => Object.keys(image.location).length === 0);
    },
  },
};
</script>
