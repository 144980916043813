<template>
  <div class="dashboard-filters">
    <FilterByFaultTypes />
      <div id="priority-filters" v-if="companyHas('poleByFolders')">
        <FilterByPriority />
      </div>
      <div id="severity-filters" v-else>
        <FilterBySeverity />
      </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FilterByFaultTypes from '@components/dashboard/filters/FilterByFaultTypes.vue';
import FilterBySeverity from '@components/dashboard/filters/FilterBySeverity.vue';
import FilterByPriority from '@components/dashboard/filters/FilterByPriority.vue';

export default {
  name: 'DashboardFilters',
  components: {
    FilterByFaultTypes,
    FilterBySeverity,
    FilterByPriority,
  },
  computed: {
    ...mapGetters(['companyHas']),
  },
};
</script>
