<template>
  <v-container fluid>
    <NfAnalytics v-if="isNewfoundland"/>
    <Analytics v-else />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import NfAnalytics from '@views/dashboard/newfoundland/NfAnalytics.vue';
import Analytics from '@views/dashboard/Analytics.vue';

export default {
  name: 'Dashboard',
  components: {
    NfAnalytics,
    Analytics,
  },
  computed: {
    ...mapGetters(['currentCompany']),
    isNewfoundland() {
      return this.currentCompany.companyName.includes('Newfoundland Power');
    },
  },
};
</script>
