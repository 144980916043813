<template>
  <SkeletonLoader type='insights' v-if="loadingImages || loadingCurrentImage"/>
  <v-container fluid v-else>
    <div id="insights-content">
      <v-row>
        <!-- TOOLBAR FOR MOBILE / TABLET SIZES -->
        <v-col cols="12">
          <InsightsToolbar :pid="pid" :images="folderImages.all" :index="index" />
        </v-col>
        <!-- IMAGES SECTION, MAP, FAULT SUMMARY CARDS -->
        <v-col xs="12" sm="12" :md="(isMobile) ? '12' : '7'" lg="8" xl="9">
          <v-card>
            <InsightsImageSection
             :processed="processedImages"
             :topImages="topImages"
             :currentTopImage="currentTopImage ? currentTopImage : processedImagesToShow[0]" />
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn fab small left color='primary' @click='setIndex(-1)'>
                <v-icon dark>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn fab small color='primary' @click='setIndex(1)'>
                <v-icon dark>mdi-chevron-right</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <!-- H U M A N  I N  T H E  L O O P -->
              <v-dialog
                v-model="annotateDialog"
                @click:outside="closeAnnotation"
                transition="dialog-bottom-transition"
                persistent
                fullscreen
              >
                <template v-slot:activator="{ on: dialog, attrs }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn
                        small
                        fab
                        right
                        color='primary'
                        id="human-in-loop-btn-analytics"
                        v-bind="attrs"
                        v-on="{...tooltip, ...dialog}"
                        ref="annotateBtn"
                        @click="annotateDialog = true"
                      >
                        <v-icon>brush</v-icon>
                      </v-btn>
                    </template>
                    Annotate
                  </v-tooltip>
                </template>
                <HumanInTheLoop
                  v-on:closeHIL="annotateDialog = false"
                  :editMode="true"
                ></HumanInTheLoop>
                <v-card>
                  <router-view v-on:closeDialog="closeAnnotation"></router-view>
                </v-card>
              </v-dialog>
              <!-- E N D  O F  H U M A N  I N  T H E  L O O P -->
            </v-card-actions>
          </v-card>
          <EpriMap
            v-if="companyHas('poleByFolders')"
            :images="pImages.withLocation"
            :folders="folders.withLocation"
            :key="epriMapKey"
          />
          <Map v-else :images="processedImagesToShow" />
          <FaultSummaryCards v-if="!companyHas('poleByFolders')" :images='processedImagesToShow' />
        </v-col>
        <!-- REPORTS DETAILS, ABBREVIATIONS REFERENCE, FILTERS ON LAPTOP SCREEN -->
        <v-col
          id="filters-column"
          xs="12" sm="12" :md="(isMobile) ? '12' : '5'" lg="4" xl="3"
          v-show="!isMobile"
        >
          <ReportsDetailDesktop :pid="pid" class="mb-5" />
          <CollapseAbbreviations class="mb-2" />
          <SimpleFileManager
            v-if="companyHas('poleByFolders')"
            type="ai"
            :images="folderImages.all"
           />
          <div id="insights-filters">
              <v-card>
                <VirtualScroller
                  :items="[1]"
                  :mw="400"
                  :height="450"
                  :itemHeight="650"
                  dynamic
                  v-bind:show="panelsOpened"
                >
                  <InsightsFilters
                   :images="processedImagesToShow"
                   :index="index"
                   :pImages="pImages" />
                </VirtualScroller>
              </v-card>
          </div>
        </v-col>
      </v-row>
      <!-- FAULT SUMMARY SECTION -->
      <FaultSummaryCharts :images='processedImages' />
      <!-- AI DETECTIONS OVER TIME -->
      <h2 class="headline">Time Frame</h2>
      <DetectedFaultsTime :processed="processedImagesToShow"/>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SkeletonLoader from '@components/common/loaders/SkeletonLoader.vue';
import VirtualScroller from '@components/common/VirtualScroller.vue';
import Map from '@components/images/map/Map.vue';
import InsightsToolbar from '@components/analytics/insights/InsightsToolbar.vue';
import InsightsImageSection from '@components/analytics/insights/InsightsImageSection.vue';
import CollapseAbbreviations from '@components/analytics/insights/CollapseAbbreviations.vue';
import ReportsDetailDesktop from '@components/analytics/insights/ReportsDetailDesktop.vue';
import InsightsFilters from '@components/analytics/insights/filters/InsightsFilters.vue';
import FaultSummaryCards from '@components/analytics/insights/fault_summary/FaultSummaryCards.vue';
import FaultSummaryCharts from '@components/analytics/insights/fault_summary/FaultSummaryCharts.vue';
import DetectedFaultsTime from '@components/analytics/insights/DetectedFaultsTime.vue';
import HumanInTheLoop from '@views/annotations/HumanInTheLoop.vue';
import EpriMap from '@components/images/map/EpriMap.vue';
import SimpleFileManager from '@components/folders/SimpleFileManager.vue';
import hasLocation from '@methods/locations';

export default {
  name: 'InsightsContent',
  props: ['pid'],
  data: () => ({
    index: 0,
    annotateDialog: false,
    components: [1],
    processedImages: [],
    processedImagesToShow: [],
    topImages: [],
    currentTopImage: null,
  }),
  components: {
    SkeletonLoader,
    InsightsToolbar,
    InsightsImageSection,
    CollapseAbbreviations,
    VirtualScroller,
    Map,
    ReportsDetailDesktop,
    InsightsFilters,
    FaultSummaryCards,
    FaultSummaryCharts,
    DetectedFaultsTime,
    HumanInTheLoop,
    EpriMap,
    SimpleFileManager,
  },
  methods: {
    ...mapActions(['getImagesByProjectIds', 'getProcessedImagesByProject', 'setLoadingImage', 'getImagesByProject', 'getProcessedFoldersByProject', 'setCurrentImage', 'currentImageFilter', 'setFolders', 'getImagesByProjectAndFolder', 'setLoadingFolders', 'getFoldersByCompany', 'filterPImages', 'setInsightsFolders', 'setFilteredImagesByFault', 'fetchLabelsForProject']),
    imageChanged(image) {
      this.currentTopImage = image;
    },
    setIndex(n) {
      // If the new index goes out of bounds from the processedImages array,
      // then set the index to 0 if n is +1
      // or set index to length - 1 if n is -1
      if (this.companyHas('poleByFolders')) {
        if (this.index + n > this.topImages.length - 1) this.index = 0;
        else if (this.index + n < 0) this.index = this.topImages.length - 1;
        else this.index += n;
        this.currentTopImage = this.topImages[this.index];
        this.setCurrentImage(this.processedImages[this.index]);
      } else {
        if (this.index + n > this.processedImages.length - 1) this.index = 0;
        else if (this.index + n < 0) this.index = this.processedImages.length - 1;
        else this.index += n;
        this.setCurrentImage(this.processedImages[this.index]);
      }
    },
    filterProcessedImages(pImages) {
      const filtered = pImages.filter((processed) => {
        // Get classes of the most recent processed image
        const { length } = processed.process_tracking;
        // List of labels associated with the current processed image
        const classes = processed.process_tracking[length - 1].labels;
        // Handles edge case of if the label has been modified and has a colon
        classes.forEach((label, index) => {
          if (typeof label === 'string') {
            if (label.includes(':')) [, classes[index]] = label.split(':');
          } else {
            classes[index] = label.label;
          }
        });
        // Filters the list of classes in the processed image and sees if it includes the selected
        // label filter (faults)
        const hasFault = classes.filter((label) => this.insightsFaults.includes(label)).length > 0;
        let h = [];
        // Compute whether or not the current image includes the listed faults or severities
        if (this.currentFilterStatus.severity.opened && this.insightsSeverities.length !== 0) {
          h = this.insightsSeverities.includes(processed.process_tracking[length - 1].severity);
        }
        // If either conditions are met, include the image into the processedImages list
        return h || hasFault;
      });
      return filtered;
    },
    closeAnnotation() {
      this.annotateDialog = false;
      if (this.fromEnlargeDialog) {
        this.$refs.imgViewBtn.$el.click();
      }
    },
    setProcessedImages() {
      if (!(this.companyHas('poleByFolders') || this.companyHas('general_folders'))) {
        // Get list of processed images
        const pImages = this.allImages.filter((image) => image.processedImageUrl);
        // Return new processed image list based on user selected filters
        const result = this.filterProcessedImages(pImages).map((image) => {
          const img = { ...image };
          if (image.location === null || image.location === undefined) img.location = [null, null];
          return img;
        });

        this.processedImages = result;
        this.processedImagesToShow = result;
        return;
      }
      // return this.processedImages
      this.processedImages = this.filterProcessedImages(this.processedImagesR).map((image) => {
        const img = { ...image };
        if (image.location === null || image.location === undefined) img.location = [null, null];
        return img;
      });
      this.processedImagesToShow = this.processedImages;
      this.topImages = this.processedImages;
    },
    getProcessedImages(images) {
      return images
        .filter((image) => image.processedImageUrl)
        .map((image) => {
          const currImage = { ...image };

          const { length } = currImage.process_tracking;
          const processedImage = {
            filename: currImage.filename,
            location: currImage.location,
            confidence: currImage.process_tracking[length - 1].confidence,
            caption: currImage.process_tracking[length - 1].caption,
            severity: currImage.process_tracking[length - 1].severity,
            classes: currImage.process_tracking[length - 1].labels,
            regions: currImage.process_tracking[length - 1].regions,
            polygons: currImage.process_tracking[length - 1].polygons,
            notes: (Object.keys(currImage.process_tracking[length - 1]).includes('notes')) ? currImage.process_tracking[length - 1].notes : [],
            url: currImage.processedImageUrl,
            date: currImage.date,
            companyId: currImage.companyId,
            project_id: currImage.project_id,
            process_tracking: currImage.process_tracking,
            user_id: currImage.user_id,
            processedImageUrl: currImage.processedImageUrl,
            originalImageUrl: currImage.originalImageUrl,
            folder: currImage.folder,
            time_logs: currImage.time_logs,
          };
          if (Object.keys(currImage).includes('gimbalPitchDegree')) processedImage.gimbalPitchDegree = currImage.gimbalPitchDegree;

          return processedImage;
        });
    },
  },
  computed: {
    ...mapGetters(['allProjects', 'allImages', 'currentCompany', 'loadingImages', 'processedImagesR', 'loadingCurrentImage', 'currentImage', 'insightsSeverities', 'insightsFaults', 'small', 'extraSmall', 'isLaptop', 'currentPanelStates', 'companyHas', 'currentFolder', 'zeroProcessedImages', 'allFolders', 'insightFilters', 'insightProcessedImages', 'insightsFolders', 'insightsPriorities', 'currentProject', 'currentFilterStatus', 'epriMapKey']),
    pImages() {
      const { getProcessedImages, processedImagesR } = this;
      const allProcessedImages = getProcessedImages(processedImagesR);
      // const filtered = allProcessedImages.filter((image) => {
      //   return image.folder
      // })
      const filteredFolderNames = [];
      this.insightsFolders.forEach((folder) => {
        filteredFolderNames.push(folder.path);
      });

      // eslint-disable-next-line arrow-body-style
      const filtered = allProcessedImages.filter((image) => {
        return filteredFolderNames.includes(image.folder);
      });
      const noLocation = filtered.filter((image) => {
        if (!image.location) return true;
        return !Object.keys(image).includes('location') || image.location.length < 2;
      });
      const withLocation = filtered.filter((image) => {
        if (!image.location) return false;
        return Object.keys(image).includes('location') || image.location.length === 2;
      });

      return { all: filtered, noLocation, withLocation };
    },
    isMobile() {
      return (this.small || this.extraSmall) || !this.isLaptop;
    },
    filtersColumnHeight() {
      const column = document.querySelector('#filters-column');
      return column;
    },
    panelsOpened() {
      const panelStates = Object.values(this.currentPanelStates);
      const hasPanelOpened = panelStates.filter((panel) => panel);

      // If there are any opened panels this should be true
      return hasPanelOpened.length > 0;
    },
    folderImages() {
      if (this.currentFolder.path === '__all__') return this.pImages;
      const filterPath = (imgs) => imgs.filter((e) => e.folder === this.currentFolder.path);
      const all = filterPath(this.pImages.all);
      const noLocation = filterPath(this.pImages.noLocation);
      const withLocation = filterPath(this.pImages.withLocation);

      return {
        all,
        noLocation,
        withLocation,
      };
    },
    folders() {
      if (!this.companyHas('poleByFolders')) return [];
      return {
        all: this.allFolders,
        withLocation: this.allFolders.filter((folder) => hasLocation(folder)),
        noLocation: this.allFolders.filter((folder) => !hasLocation(folder)),
      };
    },
  },
  async created() {
    this.$eventBus.$on('folder:imageChanged', this.imageChanged);
    // Set loading state of the images
    let images;
    this.setLoadingImage(true);
    this.setLoadingFolders(true);

    // If pid is 'all', then get a list of all pids,
    // fetch every image pertaining to the list of pids
    // then set the first processed image as the current image
    // Otherwise fetch the images of specified project
    if (!(this.companyHas('poleByFolders') || this.companyHas('general_folders'))) {
      if (this.pid === 'all') {
        const pids = this.allProjects.map((project) => project.pid);
        images = await this.getImagesByProjectIds({ cid: this.currentCompany.cid, projects: pids });
      } else {
        const response = await this.getImagesByProject({
          pid: this.pid,
          cid: this.currentCompany.cid,
        });
        images = response.data;
      }

      // If images isn't an empty array, set the first processed image as current image
      if (images.length > 0) {
        const [image] = images.filter((img) => img.processedImageUrl);
        this.setCurrentImage(image);
      }
      await this.setProcessedImages();
    } else {
      const pids = (this.pid === 'all')
        ? this.allProjects.map((project) => project.pid)
        : this.allProjects.filter((project) => project.pid === this.pid)
          .map((project) => project.pid);
      // Get the selected pid (the selected project) and use in the next line
      await this.getProcessedImagesByProject({
        projects: pids,
        cid: this.currentCompany.cid,
      }).then((result) => {
        if (!this.zeroProcessedImages) {
          this.setCurrentImage(result.imagesReturned[0]);
          // eslint-disable-next-line prefer-destructuring
          // eslint-disable-next-line prefer-destructuring
          this.currentTopImage = result.imagesReturned[0];
          this.setFolders(result.companyFolders);
          this.setInsightsFolders(result.companyFolders);
        }
      });

      this.setProcessedImages();
      const categorize = this.companyHas('categorize_labels') ? 'yes' : 'no';
      const custom = this.companyHas('custom_labels') ? 'yes' : 'no';
      await this.fetchLabelsForProject({
        pid: this.pid, cid: this.currentCompany.cid, categorize, custom,
      });
    }

    // Regardless of if there are images fetched, set the loading state back to false
    this.setLoadingImage(false);
    this.setLoadingFolders(false);
  },
  watch: {
    async currentFolder(newFolder) {
      if (this.companyHas('poleByFolders') || this.companyHas('general_folders')) {
        if (newFolder.path !== '__all__') {
          await this.getImagesByProjectAndFolder({
            pid: newFolder.project,
            cid: newFolder.company,
            folderPath: newFolder.path,
          })
            .then((res) => {
              if (res.status === 200) {
                this.processedImagesToShow = res.data;
                this.topImages = res.data;
                // eslint-disable-next-line prefer-destructuring
                this.currentTopImage = res.data[0];
              }
            });
        } else {
          this.topImages = this.processedImagesToShow;
          if (this.processedImagesToShow.length > 0) {
            // eslint-disable-next-line prefer-destructuring
            this.currentTopImage = this.processedImagesToShow[0];
          }
        }
      }
    },
    insightFilters() {
      this.setProcessedImages();
    },
  },
};
</script>

<style scoped>
.no-display {
  display: none;
}
</style>
