<template>
  <v-card>
    <v-card-title class="headline">Create a new Customer Instance</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col xs="12" sm="12" md="7" lg="7" xl="7">
            <v-text-field
              v-model="company.companyName"
              outlined
              label="Name of Company/Customer"
              :error-messages="errors.name"
            ></v-text-field>
          </v-col>
          <v-col xs="12" sm="12" md="5" lg="5" xl="5">
            <v-text-field
              v-model="company.billingEmail"
              outlined
              label="Primary Billing Email"
            ></v-text-field>
          </v-col>
          <v-col xs="12" sm="12" md="5" lg="5" xl="5">
            <v-select
              outlined
              label="Storage Service"
              :items="['GCP Storage', 'AWS S3']"
              value="GCP Storage"
              @change="handleStorageChange"
            ></v-select>
          </v-col>
          <v-col xs="12" sm="12" md="7" lg="7" xl="7">
            <v-text-field
              v-model="company.bucket"
              outlined
              label="Image Bucket Name"
              :prefix="prefix"
              @input="reformat"
              :error-messages="errors.bucket"
            ></v-text-field>
          </v-col>
          <v-col xs="12" sm="12" md="12" lg="12" xl="12">
            <v-text-field
              v-model="company.ml_model"
              outlined
              label="Model"
            ></v-text-field>
          </v-col>
          <v-col xs="12" sm="12" md="12" lg="12" xl="12">
            <v-select
              v-model="company.companyMode"
              label="Company Mode"
              outlined
              :items="['Default', 'Trial']"
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="close">Close</v-btn>
      <v-btn text color="primary" @click="save" :loading="creating">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { cloneDeep, trim } from 'lodash';
import dateFormat from '@utils/time-formats';
// import services from '@services';

export default {
  name: 'CreateInstanceForm',
  data: () => ({
    gcp: true,
    creating: false,
    prefix: 'buzz-powerai-dbucket-',
    company: {
      companyName: '',
      billingEmail: '',
      bucket: ' ',
      ml_model: '',
      companyMode: 'Default',
    },
    errors: {
      name: '',
      bucket: '',
    },
    service: [],
    services: ['Bronze Plan - $0.95 USD / image', 'Silver Plan - $0.90 USD / image', 'Gold Plan - $0.80 USD / image', 'Dashboard - $150 USD / month'],
  }),
  methods: {
    ...mapActions(['createCompany', 'createStorageBucket', 'createCustomer', 'setNotification']),
    handleStorageChange(storage) {
      this.gcp = storage === 'GCP Storage';
      this.prefix = (this.gcp)
        ? 'buzz-powerai-dbucket-'
        : 'buzz-powerai-s3-dbucket-';
    },
    async save() {
      // Checks if required fields are filled out. Will not make requests if empty.
      if (this.company.companyName === '') {
        this.errors.name = 'A company name is required';
        return;
      }

      if (this.company.bucket === '') {
        this.errors.bucket = 'A bucket name is required';
        return;
      }
      this.creating = true;
      // Make a deep copy of the company property within `data`
      const newCompany = cloneDeep(this.company);
      newCompany.enrollment_start_date = dateFormat(Date.now(), 'DATETIME_LOCAL_SECONDS');

      // Set the copied company with the naming convention of our GCP Storage bucketes
      newCompany.bucket = `${this.prefix}${newCompany.bucket}`;

      // Initialize the new company with an empty list of employees
      newCompany.employees = [];

      // If ml_model is an empty string, set it as null
      newCompany.ml_model = (this.company.ml_model.length > 0) ? this.company.ml_model : null;

      switch (this.company.companyMode) {
        case 'Trial':
          newCompany.companyMode = 1;
          break;
        default:
          newCompany.companyMode = 0;
      }

      try {
        // // Create the GCP Storage bucket
        const storage = (this.gcp) ? 'gcp' : 'aws';
        if (storage === 'aws') {
          newCompany.storage_platform = 2;
          // await services.storage.s3.create({ bucket: newCompany.bucket });
        } else {
          newCompany.storage_platform = 1;
          // await services.storage.storage_create(newCompany.bucket);
        }
        // await this.createStorageBucket(newCompany.bucket);
        // If it is either the dev or stage environment,
        // just use the customer id in the env.local file
        if (!process.env.VUE_APP_ENVIRONMENT) {
          // Edge case for local env since VUE_APP_ENVIRONMENT is undefined
          newCompany.stripe_id = process.env.VUE_APP_DEV_CUSTOMER;
        }
        if (process.env.VUE_APP_ENVIRONMENT === 'dev' || process.env.VUE_APP_ENVIRONMENT === 'mig') newCompany.stripe_id = process.env.VUE_APP_DEV_CUSTOMER;
        if (process.env.VUE_APP_ENVIRONMENT === 'mig-stage') newCompany.stripe_id = process.env.VUE_APP_STAGE_CUSTOMER;

        const companyResponse = await this.createCompany(newCompany);
        // If it is the production environment, create a new customer
        if (process.env.VUE_APP_ENVIRONMENT === 'main' || process.env.VUE_APP_ENVIRONMENT === 'mig-prod') {
          await this.createCustomer(companyResponse.data.cid);
        }

        this.$nextTick(() => {
          // Resets the form v-models
          Object.keys(this.company).forEach((key) => { this.company[key] = ''; });
          this.setNotification({
            success: true,
            message: `Successfully created a new client for ${companyResponse.data.companyName}`,
            color: '',
          });
          this.creating = false;
          // Close the dialog
          this.close();
        });
      } catch (err) {
        this.setNotification({
          message: 'There was an error creating a new client',
          success: true,
          color: 'error',
        });
      }
    },
    close() {
      this.$emit('close');
    },
    reformat() {
      const bucket = trim(this.company.bucket);
      this.company.bucket = bucket.split(' ').join('-');
    },
  },
  computed: {
    ...mapGetters(['creatingCustomer']),
  },
};
</script>
