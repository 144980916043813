<template>
  <v-card>
    <v-card-title class="headline">
      Edit {{ company.companyName }}
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-container fluid>
        <v-row>
          <v-col xs="12" sm="12" md="6" lg="6" xl="6">
            <v-text-field
              outlined
              label="Name"
              :value="init.companyName"
              @input="nameHandler"
            ></v-text-field>
          </v-col>
          <v-col xs="12" sm="12" md="6" lg="6" xl="6">
            <v-text-field
              outlined
              label="Model"
              @input="modelHandler"
              :value="init.ml_model"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="$emit('close')">Cancel</v-btn>
      <v-btn text color="primary" @click="save">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'EditCompanyForm',
  props: ['company'],
  data: () => ({
    init: {
      companyName: '',
      ml_model: '',
    },
    updates: {},
  }),
  methods: {
    ...mapActions(['updateClient', 'setNotification']),
    nameHandler(name) {
      this.updates.companyName = name;
    },
    emailHandler(email) {
      this.updates.billingEmail = email;
    },
    modelHandler(model) {
      if (model === '') this.updates.ml_model = null;
      else this.updates.ml_model = model;
    },
    save() {
      const payload = {
        updates: this.updates,
        id: this.company.cid,
      };
      this.updateClient(payload)
        .then((res) => {
          this.setNotification({
            success: true,
            message: `Successfully updated ${res.data.companyName}`,
            color: '',
          });
          this.$emit('close');
        })
        .catch(() => {
          this.setNotification({
            success: true,
            message: `There was an error updating ${this.company.companyName}`,
            color: 'error',
          });
        });
    },
  },
  created() {
    this.init.companyName = (this.company.companyName) ? this.company.companyName : '';
    this.init.ml_model = (this.company.ml_model === 'Default')
      ? ''
      : this.company.ml_model;
  },
};
</script>
