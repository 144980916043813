<template>
  <v-card>
    <StructureSelect :images="images" :index="index"/>
    <FaultSummaryFilters />
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="close">Close</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import StructureSelect from '@components/analytics/insights/map_section/StructureSelect.vue';
import FaultSummaryFilters from '@components/analytics/insights/fault_summary/FaultSummaryFilters.vue';

export default {
  name: 'DashboardFiltersDialog',
  props: ['images', 'index'],
  components: {
    StructureSelect,
    FaultSummaryFilters,
  },
  methods: {
    close() {
      this.$emit('closeFilter');
    },
  },
};
</script>
