<template>
  <v-container fluid>
    <div id="second-reviewer-display" v-if="!editingFolder">
      <div id="second-reviewer-name" class="d-inline-block mr-2 text-caption">
        {{ secondReviewerName }}
      </div>
      <div id="reviewed" class="d-inline-block ml-2" v-if="hasSecondReviewer()">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              :color="(folder.second_reviewer.reviewed) ? 'primary' : ''"
              v-on="on"
              v-bind="attrs"
            >verified</v-icon>
          </template>
          {{ (folder.second_reviewer.reviewed) ? 'Review Complete' : 'Pending Review' }}
        </v-tooltip>
      </div>
      <div id="reason" v-if="hasSecondReviewer()">
        <span class="font-weight-black">Reason:</span>
         {{ folder.second_reviewer.reason }}
      </div>
    </div>
    <div id="second-reviewer-edit" v-else>
      <SecondReviewerForm :secondReviewer="folder.second_reviewer" />
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import SecondReviewerForm from '@components/images/image_details/reviewers/SecondReviewerForm.vue';

export default {
  name: 'SecondReviewer',
  props: ['folder'],
  components: {
    SecondReviewerForm,
  },
  methods: {
    hasSecondReviewer() {
      const secondReviewer = this.folder.second_reviewer;
      const exists = secondReviewer && Object.keys(secondReviewer).includes('uid');
      return exists;
    },
  },
  computed: {
    ...mapGetters(['editingFolder']),
    secondReviewerName() {
      const reviewer = this.hasSecondReviewer() ? this.folder.second_reviewer.name : 'There is no second reviewer assigned';
      return reviewer;
    },
  },
};
</script>
