<template>
  <v-col>
    <v-row>
      <v-spacer></v-spacer>
      <v-btn id="navigate-left" fab small left color="primary" @click="navigateLeft">
        <v-icon dark>mdi-chevron-left</v-icon>
      </v-btn>
      <div class="pagination-count">{{ currentIndex }} of {{ lastIndex }}</div>
      <v-btn id="navigate-right" fab small right color="primary" @click="navigateRight">
        <v-icon dark>mdi-chevron-right</v-icon>
      </v-btn>
      <div class="select-image">
        <v-select
          v-model="currentIndex"
          label="Go to image"
          :items="Array.from({ length: lastIndex }, (_, i) => i + 1)"
          dense
          outlined
          hide-details
          @change="navigateToImage"
        ></v-select>
      </div>
      <v-spacer></v-spacer>
    </v-row>
  </v-col>
</template>

<style scoped>
.select-image {
  margin-left: 30px;
}
</style>

<script>
export default {
  name: 'Paginator',
  props: ['firstIndex', 'lastIndex'],
  created() {
    window.addEventListener('keydown', this.keyboardHandler);
  },
  destroyed() {
    window.removeEventListener('keydown', this.keyboardHandler);
  },
  data() {
    return {
      currentIndex: this.firstIndex,
    };
  },
  methods: {
    keyboardHandler(event) {
      if (event.key === 'ArrowLeft') {
        this.navigateLeft();
      }
      if (event.key === 'ArrowRight') {
        this.navigateRight();
      }
    },
    navigateRight() {
      if (this.currentIndex <= this.lastIndex - 1) {
        this.currentIndex += 1;
      } else {
        this.currentIndex = 1;
      }
      this.$emit('indexChanged', this.currentIndex);
    },
    navigateLeft() {
      if (this.currentIndex === 1) {
        this.currentIndex = this.lastIndex;
      } else {
        this.currentIndex -= 1;
      }
      this.$emit('indexChanged', this.currentIndex);
    },
    navigateToImage() {
      this.$emit('indexChanged', this.currentIndex);
    },
  },
};
</script>

<style scoped>
.pagination-count {
  padding: 10px;
}
</style>
