<template>
  <v-container fluid>
    <Loading v-if="loadingPaymentMethods"/>
    <PaymentMethodList v-else :payments="allPaymentMethods"/>
    <SnackBar
      :snackbar="notification.success"
      :text="notification.message"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Loading from '@components/common/Loading.vue';
import PaymentMethodList from '@components/payments/billing-portal/PaymentMethodList.vue';
import SnackBar from '@components/common/SnackBar.vue';

export default {
  name: 'PaymentMethods',
  components: {
    Loading,
    PaymentMethodList,
    SnackBar,
  },
  data: () => ({
    deleteDialog: false,
  }),
  computed: {
    ...mapGetters(['allPaymentMethods', 'loadingPaymentMethods', 'currentCompany', 'customerNotification', 'paymentMethodNotification']),
    notification() {
      const { customerNotification, paymentMethodNotification } = this;
      if (customerNotification.success && customerNotification.message !== '') return customerNotification;
      if (paymentMethodNotification.success && paymentMethodNotification.message !== '') return paymentMethodNotification;

      return {
        success: false,
        message: '',
      };
    },
  },
  methods: {
    ...mapActions(['detachPaymentFromCustomer', 'getPaymentMethodsByCustomerId']),
    confirmDeletePayment(payment) {
      const removeData = {
        paymentId: payment.id,
        customerId: this.currentCompany.customerId,
      };

      this.detachPaymentFromCustomer(removeData);
      this.deleteDialog = false;
    },
  },
  created() {
    const { customerId } = this.currentCompany;
    this.getPaymentMethodsByCustomerId(customerId);
  },
};
</script>
