<template>
  <div class="sr-root">
    <div class="sr-payment-form card" ref="paymentForm">
      <v-container fluid>
        <v-row>
          <v-col xs="12" sm="12" md="6" lg="6" xl="6">
            <v-text-field
              outlined
              id="first-name"
              label="First Name"
              v-model="firstName"
            ></v-text-field>
          </v-col>
          <v-col xs="12" sm="12" md="6" lg="6" xl="6">
            <v-text-field
              id="last-name"
              outlined
              label="Last Name"
              v-model="lastName"
            ></v-text-field>
          </v-col>
          <v-col class="px-2" cols="6">
            <v-text-field
              outlined
              id="email"
              label="Email Address"
              v-model="email"
            ></v-text-field>
          </v-col>
          <v-col class="px-2" cols="6">
            <v-text-field
              outlined
              id="phone"
              label="Phone Number"
              v-model="phone"
              @input="formatPhone"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid>
        <v-row>
          <v-col xs="12" sm="12" md="8" lg="8" xl="8">
            <v-text-field
              outlined
              id="line1"
              label="Street Address"
              v-model="line1"
            ></v-text-field>
          </v-col>
          <v-col xs="12" sm="12" md="4" lg="4" xl="4">
            <v-select
              id="us-states"
              :items="states"
              outlined
              label="State"
              v-model="selectedState"
            ></v-select>
          </v-col>
          <v-col xs="12" sm="12" md="8" lg="8" xl="8">
            <v-text-field
              outlined
              id="city"
              label="City"
              v-model="city"
            ></v-text-field>
          </v-col>
          <v-col xs="12" sm="12" md="4" lg="4" xl="4">
            <v-text-field
              outlined
              id="zip"
              label="Postal Code"
              v-model="postalCode"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid>
        <div class="sr-form-row" style="border:">
          <label class="my-4">
            Payment details
          </label>
          <div
            class="sr-input sr-element sr-card-element"
            id="card-element"
            ref="card"
            style="border: 1px solid #ececec;
              border-radius: 4px;
              border-color: rgba(0, 0, 0, 0.6);"
          ></div>
        </div>
      </v-container>
      <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
        <div class="sr-field-error" id="id-errors" role="alert"></div>
          <v-btn color="primary" class="my-4" id="submit" ref="addPayBtn" @click="addPayment">
            <span id="button-text" style="color:white;">Link your card to your account</span>
          </v-btn>
          <!-- <v-btn color="primary">Close</v-btn> -->
        </v-card-actions>
    </div>
    <div id="success" class="sr-result hidden" ref="addResult">
      <!-- <p>Card setup completed <br /></p> -->
      <StripePaymentSuccess />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import states from '@components/billing/states';
import { getStripe } from '@methods/stripe-helpers';
import { baseURL } from '@utils/endpoint';
import StripeAPI from '../../stripe';

import StripePaymentSuccess from './StripePaymentSuccess.vue';

export default {
  name: 'StripeAddPayment',
  components: {
    StripePaymentSuccess,
  },
  data: () => ({
    states: [],
    stripe: null,
    elements: null,
    card: null,
    email: '',
    phone: '',
    firstName: '',
    lastName: '',
    line1: '',
    selectedState: '',
    city: '',
    postalCode: '',
  }),
  methods: {
    ...mapActions([
      'finishPaymentAdd',
      'createSetupIntent',
      'getSetupIntent',
      'getPaymentMethodsByCustomerId',
    ]),
    formatPhone() {
      const number = this.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.phone = !number[2] ? number[1] : `(${number[1]}) ${number[2]}${number[3] ? `-${number[3]}` : ''}`;
    },
    async addPayment(e) {
      e.preventDefault();
      const setupIntent = await this.createSetupIntent(this.currentCompany.customerId);
      const { confirmCardSetup } = this.stripe;
      confirmCardSetup(setupIntent.client_secret, {
        payment_method: {
          card: this.card,
          billing_details: {
            email: this.email,
            phone: this.phone,
            address: {
              line1: this.line1,
              city: this.city,
              country: 'US',
              state: this.state,
            },
            name: `${this.firstName} ${this.lastName}`,
          },
        },
      })
        .then((result) => {
          if (result.error) {
            const displayError = document.getElementById('card-errors');
            displayError.textContent = result.error.message;
          } else {
            // The PaymentMethod was successfully set up
            this.orderComplete(setupIntent.client_secret);
          }
        });
    },
    orderComplete(clientSecret) {
      const { retrieveSetupIntent } = this.stripe;

      this.getSetupIntent({ retrieveSetupIntent, clientSecret })
        .then(() => {
          const { paymentForm, addResult } = this.$refs;
          paymentForm.classList.add('hidden');
          addResult.classList.remove('hidden');
          // this.finishPaymentAdd(result.payment_method);
          this.getPaymentMethodsByCustomerId(this.currentCompany.customerId);
          setTimeout(() => {
            addResult.classList.add('expand');
          }, 200);
        });
    },
  },
  computed: {
    ...mapGetters(['currentCompany', 'loadingSetupIntents']),
  },
  async mounted() {
    const key = await getStripe(`${baseURL}/api/stripe/config`);

    const { stripePublishableKey } = key;
    this.states = states;

    // eslint-disable-next-line no-undef
    this.stripe = StripeAPI(Stripe, stripePublishableKey);
    this.elements = this.stripe.elements;

    const style = {
      base: {
        fontSize: '16px',
        color: '#32325d',
        fontFamily:
          '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif',
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: 'rgba(0,0,0,0.4)',
        },
      },
    };

    this.card = this.elements.create('card', { style });

    this.card.mount(this.$refs.card);

    this.card.on('focus', () => {
      const el = this.$refs.card;
      el.classList.add('focused');
    });

    this.card.on('blur', () => {
      const el = this.$refs.card;
      el.classList.remove('focused');
    });
  },
};
</script>

<style scoped src="./form.css"></style>
