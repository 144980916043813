<template>
  <v-card max-width="800">
    <Loading v-if="loadingProduct"/>
    <div class="product-content" v-else>
      <v-card-title class="headline">
        <span
          id="product-name"
          class="text-center text-xs-h6 text-sm-h5 text-md-h4 text-lg-h3 text-xl-h2">
          {{ currentProduct.name }}
        </span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <p
          class="font-weight-medium"
          v-for="(feature, index) in listFeatures(features)"
          :key="index"
        >{{ feature }}</p>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Loading from '@components/common/Loading.vue';

export default {
  name: 'ProductInfo',
  components: {
    Loading,
  },
  methods: {
    ...mapActions(['getProduct']),
    listFeatures: (features) => features.split(','),
  },
  computed: {
    ...mapGetters(['currentProduct', 'loadingProduct']),
    features() {
      return this.currentProduct.description;
    },
  },
  created() {
    const { productId } = this.$route.params;
    this.getProduct(productId);
  },
};
</script>
