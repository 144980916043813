<template>
  <v-container fluild>
    <TabViews :vertical="false" :centered="false" :tabs="helpTabs" />
  </v-container>
</template>

<script>
import TabViews from '@components/common/TabViews.vue';

export default {
  name: 'Help',
  components: {
    TabViews,
  },
  data: () => ({
    helpTabs: [
      {
        tabId: 'report',
        title: 'Report Issue',
        icon: 'bug_report',
      },
      {
        tabId: 'faqs',
        title: 'FAQs',
        icon: 'support',
      },
    ],
  }),
  mounted() {
    const { helpTabs } = this;

    helpTabs.forEach((tab, index) => {
      this.helpTabs[index].route = `/help/${tab.tabId}`;
    });
  },
  created() {
    // this.getHelpByDocRef(this.currentUser.help);
  },
};
</script>
