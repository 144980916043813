<template>
  <v-container fluid>
    {{month}}
    <v-card v-for="(invoice, day) in invoices" :key="day" class="mx-auto my-5">
      <v-card-text>
        <p>{{day}} {{month}}</p>
        <p class="red--text" v-if="invoice.amount_remaining > 0"> Balance outstanding of
          <i><b>${{invoice.amount_remaining}}</b></i></p>
        <p class="green--text" v-else><i>Fully settled</i></p>
        <p>Amount Due : <i><b>${{invoice.total_due}}</b></i></p>
        <p>Amount Paid : <i><b>${{invoice.total_paid}}</b></i></p>
      </v-card-text>
      <v-card-actions>
        <v-btn
          text
          color="primary"
          :href="invoice.invoice_pdf"
          target="_blank"
        >
          Download
        </v-btn>

        <v-btn
          text
          color="error"
          :href="invoice.hosted_invoice_url"
          target="_blank"
          v-if="invoice.amount_remaining > 0"
        >
          Settle
        </v-btn>
        <v-btn
          text
          color="success"
          :href="invoice.hosted_invoice_url"
          target="_blank"
          v-else
        >
          Details
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'Invoices',
  props: ['invoices', 'month'],
};
</script>
