<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6" id="original-img">
        <ImageCard
          :url="currentImage.originalImageUrl"
          :compress_url="
              (currentImage.compressedUrl)
                ? currentImage.compressedUrl
                : currentImage.originalImageUrl
            "
        />
      </v-col>
      <v-col cols="6" id="processed-img">
        <AnnotoriousAnalyticsRenderer
         :images="companyHas('poleByFolders') ? processed : topImages"
         :currentTopImage="currentTopImage"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import ImageCard from '@components/common/ImageCard.vue';
import AnnotoriousAnalyticsRenderer from '@components/annotations/AnnotoriousAnalyticsRendere.vue';

export default {
  name: 'InsightsImageSection',
  props: ['processed', 'topImages', 'currentTopImage'],
  components: {
    ImageCard,
    AnnotoriousAnalyticsRenderer,
  },
  computed: {
    ...mapGetters(['extraSmall', 'small', 'currentInsightsIndex', 'allImages', 'currentImage', 'companyHas']),
  },
};
</script>
