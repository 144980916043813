<template>
  <v-dialog v-model="dialog" width="650px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn rounded color="info" elevation="0" x-small v-on="on" v-bind="attrs">
        See Details
      </v-btn>
    </template>
    <v-card>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Date</th>
              <th class="text-left">User</th>
              <th class="text-left">Time Spent</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="timeLog in timeLogs" :key="timeLog.name">
              <td>{{ millisecondsToDate(timeLog.date) }}</td>
              <td>{{ timeLog.userName }}</td>
              <td>{{ secondsToTime(timeLog.timeSpent) }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close" color="primary">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import helpers from '@methods/helpers';
import time from '@utils/time';

export default {
  name: 'AnnotationTimeLogs',
  props: ['timeLogs'],
  data: () => ({
    dialog: false,
  }),
  methods: {
    millisecondsToDate: (seconds) => helpers.convertToDate(new Date(seconds)).format('dddd, MMMM Do YYYY, h:mm:ss a'),
    secondsToTime: (seconds) => time.secondsToTime(seconds),
    close() {
      this.dialog = false;
    },
  },
};
</script>
