<template>
  <v-container fluid>
    <v-card class="map pa-2" ref="map"> </v-card>
    <NoLocationAlert
      :showError="showError"
      :filename="currentImage.filename"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import { EpriContent, nfContent } from '@utils/map/Content';
import { getFolder, setFolder } from '@utils/map/session-storage';
import { conductors, noImages, getMapIcon } from '@utils/map/svg';
import { dateFormats } from '@utils/time-formats';
import severityLevels from '@utils/severity-level';
import NoLocationAlert from '@components/images/map/NoLocationAlert.vue';
import moment from 'moment';
import gmapsInit from '../../../gmaps_api';
//
// const iconPath = 'M37,13L60,13L60,17L37,17,'
//   + 'M27,17L4,17L4,13L27,13,'
//   + 'M37,62L27,62L27,4L28,2L36,2L37,4L37,62z,'
//   + 'M11,13L11,7L15,7L15,13,M19,13L19,7L23,7L23,13,'
//   + 'M41,13L41,7L45,7L45,13,M49,13L49,7L53,7L53,13,'
// eslint-disable-next-line max-len
//   + 'M27,35L17,35L17,51L27,51,M60,12H38V4a1,1,0,0,0-.11-.45l-1-2A1,1,0,0,0,36,1H28a1,1,0,0,0-.89.55l-1,2A1,1,0,0,0,26,4v8H4a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1H8.59L26,35.41V62a1,1,0,0,0,1,1H37a1,1,0,0,0,1-1V35.41L55.41,18H60a1,1,0,0,0,1-1V13A1,1,0,0,0,60,12ZM5,14H26v2H5Zm6.41,4H26V32.59ZM36,61H28V4.24L28.62,3h6.76L36,4.24Zm2-28.41V18H52.59ZM59,16H38V14H59Z,'
//   + 'M37,35L47,35L47,51L37,51';
export default {
  name: 'StructureMap',
  props: ['images', 'folders'],
  components: {
    NoLocationAlert,
  },
  data: () => ({
    // the loaded flag is used to prevent unnecessary store updates
    loaded: false,
    map: null,
    google: null,
    heatmap: null,
    showError: false,
    heatmapOn: false,
    markersOn: false,
    showAll: false,
    allImageMarkers: [],
    severityCode: {
      None: 0,
      Low: 10.0,
      Medium: 50.0,
      High: 100.0,
    },
    renderingMap: false,
    markers: [],
    currentInfo: {},
    options: {
      mapTypeId: 'terrain',
    },
    allMarkers: [],
    transmissionMarkers: [],
    currentTransmissionInfo: {},
    showCount: 0,
  }),
  methods: {
    ...mapActions([
      'setCurrentImage',
      'setSeverityFilter',
      'setSeverityFilterClicked',
      'setCurrentFolder',
      'setShowFolders',
    ]),
    showMarkers() {
      this.setMapOnAll(this.map);
    },
    clearMarkers() {
      this.setMapOnAll(null);
    },
    closeInfoWindow(previousWindow) {
      previousWindow.close();
    },
    openInfoWindow(currentWindow, marker) {
      currentWindow.open(this.map, marker);
    },
    getReviewStatus(folder) {
      const hasSecondReviewer = Object.keys(folder.second_reviewer).length > 0;
      if (hasSecondReviewer) {
        return folder.second_reviewer.reviewed
          ? 'inspection_complete' : 'inspection_pending';
      }

      if (folder.processed_image_count < 2 || folder.processed_image_count > 8) return 'missing_images';

      if (folder.complete_inspection) return 'inspection_complete';

      return '';
    },
    centerControl(controlDiv, label, toggleMethod) {
      const controlUI = document.createElement('div');
      controlUI.style.backgroundColor = '#fff';
      controlUI.style.border = '2px solid #fff';
      controlUI.style.borderRadius = '3px';
      controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
      controlUI.style.cursor = 'pointer';
      controlUI.style.marginBottom = '22px';
      controlUI.style.textAlign = 'center';
      controlUI.title = label;
      controlDiv.appendChild(controlUI);

      const controlText = document.createElement('div');
      controlText.style.color = 'rgba(25, 25, 25)';
      controlText.style.fontFamily = 'Roboto, Arial, sans-serif';
      controlText.style.fontSize = '10px';
      controlText.style.lineHeight = '38px';
      controlText.style.paddingLeft = '5px';
      controlText.style.paddingRight = '5px';
      controlText.innerHTML = label;
      controlUI.appendChild(controlText);

      controlUI.addEventListener('click', () => {
        toggleMethod();
      });
    },
    getHighestSeverity(images) {
      const severities = [...severityLevels];
      const indexes = images.map(
        (image) => (image.severity ? severities.indexOf(image.severity) : -1),
      );
      return severities[Math.max(...indexes)];
    },
    severityMarker(folder) {
      const folderImages = this.imageData.filter((img) => img.folder === folder.path);
      const severity = this.getHighestSeverity(folderImages);

      // const severityColor = {
      //   None: '#66BB6A',
      //   Low: '#00B0FF',
      //   Medium: '#FFEE58',
      //   High: '#F44336',
      // };
      // let svg;
      const hasImages = this.images.find((image) => image.folder === folder.path);
      const icon = {
        scaledSize: new this.google.maps.Size(40, 40), // scaled size
        // origin: new this.google.maps.Point(0, 0), // origin
        anchor: new this.google.maps.Point(15, 30), // anchor
      };
      if (hasImages) {
        icon.url = getMapIcon(severity);
      } else {
        icon.url = noImages;
      }

      return icon;

      // eslint-disable-next-line no-unreachable
      // const iconColor = hasImages ? severityColor[severity] : '#000000';
      // eslint-disable-next-line max-len
      // if (this.currentProject.project_type && this.currentProject.project_type === 'Distribution') {
      //   svg = {
      //     path: iconPath,
      //     fillColor: iconColor,
      //     strokeColor: iconColor,
      //     fillOpacity: 1,
      //     strokeWeight: 1,
      //     rotation: 0,
      //     scale: 0.4,
      //     anchor: new this.google.maps.Point(15, 30),
      //   };
      // } else {
      //   svg = {
      //     path: iconPath,
      //     fillColor: iconColor,
      //     strokeColor: iconColor,
      //     fillOpacity: 1,
      //     strokeWeight: 1,
      //     rotation: 0,
      //     scale: 0.4,
      //     anchor: new this.google.maps.Point(15, 30),
      //   };
      // }
      //
      // return svg;
    },
    transmissionSeverityMarker(image) {
      const { severity } = image;
      const severityColor = {
        '': '#D7CCC8', // brown lighten-4
        None: '#66BB6A', // green lighten-1
        Low: '#00B0FF', // light-blue accent-3
        Medium: '#FFEE58', // yellow lighten-1
        High: '#F44336', // Red
        Emergency: '#F44336',
        Unknown: 'black',
      };
      const svg = {
        path: conductors,
        fillColor: severityColor[severity],
        fillOpacity: 1,
        strokeWeight: 1,
        rotation: 0,
        scale: 1,
        anchor: new this.google.maps.Point(15, 30),
      };

      return svg;
    },
    toggleHeatMap() {
      if (this.heatmapOn) this.heatmap.setMap(this.map);
      else this.heatmap.setMap(null);
      this.heatmapOn = !this.heatmapOn;
    },
    toggleMarkers() {
      if (this.showAll) {
        this.removeTransmissionMarkers();
        this.setTransmissionMarkers();
        this.showAll = false;
      } else {
        this.showAllImageMarkers();
        this.showAll = true;
      }
      // if (this.markersOn) this.clearMarkers();
      // else this.showMarkers();
      // this.markersOn = !this.markersOn;
    },
    setMapOnAll(map) {
      this.markers.forEach((marker) => {
        marker.setMap(map);
      });
    },
    setMarkers() {
      const { maps } = this.google;
      const bounds = new maps.LatLngBounds();

      let longSum = 0;
      let latSum = 0;
      this.folderToUse.forEach((folder, n) => {
        const currentFolder = cloneDeep(folder);
        const { location } = currentFolder;

        const [lat, long] = location;
        latSum += lat;
        longSum += long;

        const latitude = latSum / (n + 1);
        const longitude = longSum / (n + 1);

        const coordinates = new maps.LatLng(latitude, longitude);
        bounds.extend(coordinates);

        const { map } = this;
        const icon = this.severityMarker(currentFolder);
        const position = { lat, lng: long };

        const marker = new maps.Marker({
          position,
          map,
          icon,
          size: 0.3,
          optimized: true,
        });
        this.markers.push(marker);

        // Event listener for a click on marker
        marker.addListener('click', () => {
          // Remove the 'images' property from the modified folder object to match
          // the folder object in Vuex Store
          const folderSetter = cloneDeep(currentFolder);

          if (!getFolder()) setFolder(folderSetter.path);
          delete folderSetter.image;
          this.setCurrentFolder(folderSetter);
          if (!this.isNFInsightsPage) this.setShowFolders(false);

          // Setting up info window for each coordinates clicked
          const infoWindow = new maps.InfoWindow({
            position: {
              lat: latitude,
              lng: longitude,
            },
            maxWidth: 200,
          });
          infoWindow.open(this.map, marker);

          // When the marker is clicked, use GeoCoder API to fetch the address of the point
          const geocoder = new maps.Geocoder();
          geocoder.geocode({ location: coordinates }, (results, status) => {
            // If there is an error just throw Error
            if (status !== 'OK' || !results[0]) throw new Error(status);

            // Otherwise set the content within the Info Window
            const epriContent = EpriContent(
              currentFolder.path,
              currentFolder.image_count,
              results[0].formatted_address,
            );

            infoWindow.setContent(epriContent);

            infoWindow.addListener('closeclick', () => {
              // removeFolder();
              // this.setCurrentFolder(this.rootFolder);
              // this.setShowFolders(true);
            });
          });

          if (Object.keys(this.currentInfo).length > 0) {
            this.closeInfoWindow(this.currentInfo, folderSetter.path);
          }

          this.currentInfo = infoWindow;
        });
        this.map.fitBounds(bounds);
        this.allMarkers.push(marker);
      });
    },
    removeTransmissionMarkers() {
      this.transmissionMarkers.forEach((tm) => {
        tm.setMap(null);
      });
      this.transmissionMarkers = [];
    },
    showAllImageMarkers() {
      this.folderToUse.forEach(({ path }) => {
        this.setTransmissionMarkers(path);
      });
    },
    setTransmissionMarkers(folderPath = null) {
      const { maps } = this.google;
      const bounds = new maps.LatLngBounds();

      let longSum = 0;
      let latSum = 0;
      const severityFilter = this.currentSeverityFilters[0];
      const currentFolderImages = this.imageData.filter(
        (img) => img.folder === (folderPath || this.currentFolder.path)
          && (!severityFilter || img.severity === severityFilter),
      );
      currentFolderImages.forEach((image, n) => {
        const currentImage = cloneDeep(image);

        const { location } = currentImage;

        const [lat, long] = location;
        latSum += lat;
        longSum += long;

        const latitude = latSum / (n + 1);
        const longitude = longSum / (n + 1);

        const coordinates = new maps.LatLng(latitude, longitude);
        bounds.extend(coordinates);

        const { map } = this;
        const icon = this.transmissionSeverityMarker(currentImage);
        const position = { lat, lng: long };
        const marker = new maps.Marker({
          position,
          map,
          icon,
          id: currentImage.id,
          optimized: true,
        });
        this.markers.push(marker);

        // Event listener for a click on marker
        marker.addListener('click', () => {
          const fullImage = this.images.filter((img) => img.id === currentImage.id);
          if (fullImage.length > 0) this.setCurrentImage(fullImage[0]);
          // Setting up info window for each coordinates clicked
          const infoWindow = new maps.InfoWindow({
            position: {
              lat: latitude,
              lng: longitude,
            },
            maxWidth: 300,
          });
          infoWindow.setZIndex(999);
          infoWindow.open(this.map, marker);

          // When the marker is clicked, use GeoCoder API to fetch the address of the point
          const geocoder = new maps.Geocoder();
          geocoder.geocode({ location: coordinates }, (results, status) => {
            // If there is an error just throw Error
            if (status !== 'OK' || !results[0]) throw new Error(status);
            // Otherwise set the content within the Info Window
            const windowContent = nfContent(
              currentImage.caption,
              currentImage.classes,
              currentImage.severity,
              currentImage.location,
              currentImage.dateInspected,
              currentImage.filename,
            );
            infoWindow.setContent(windowContent);
          });

          if (Object.keys(this.currentTransmissionInfo).length > 0) {
            this.closeInfoWindow(this.currentTransmissionInfo);
          }

          this.currentTransmissionInfo = infoWindow;
        });
        this.transmissionMarkers.push(marker);
      });
    },
    async initMap() {
      try {
        // Google maps set up
        this.google = await gmapsInit();
        const { maps } = this.google;
        this.map = new maps.Map(this.$children[0].$el);
        const mapId = this.companyHas('roadmap') ? 'roadmap' : 'satelite';
        this.map.setMapTypeId(mapId);

        // Set up heatmap Data
        // const data = Object.keys(this.folderToUse).map((folder) => {
        //   const currentFolder = this.folderToUse[folder];
        //   const [lat, lng] = currentFolder.location;
        //   const location = new maps.LatLng(lat, lng);
        //
        //   return { location, weight: this.severityCode[currentFolder.severity] };
        // });
        //
        // this.heatmap = new maps.visualization.HeatmapLayer({ data });
        // this.heatmap.set(this.map);
        // this.heatmap.set('radius', 40.0);
        // this.heatmapOn = true;

        const bounds = new maps.LatLngBounds();

        // Rendering heatmap and marker toggle buttons
        if (Object.keys(this.folderToUse).length > 0) {
          // const centerControlDiv = document.createElement('div');
          // this.centerControl(centerControlDiv, 'Toggle Heatmap', this.toggleHeatMap);
          const markerControl = document.createElement('div');
          // this.centerControl(markerControl, 'Toggle Image Markers', this.toggleMarkers);
          // this.map.controls[maps.ControlPosition.BOTTOM_CENTER].push(centerControlDiv);
          this.map.controls[maps.ControlPosition.BOTTOM_CENTER].push(markerControl);
        }

        // Default edge case for no images with locations
        if (this.folderToUse.length === 0) {
          const defaultCluster = [
            [38.64398880555556, -90.53346227777777],
            [38.956436958333335, -89.53787868055555],
            [38.97904263888889, -90.43658578703703],
            [39.37555106944444, -90.31008775000001],
          ];

          defaultCluster.forEach((defaultLoc) => {
            const coordinates = new maps.LatLng(...defaultLoc);
            bounds.extend(coordinates);
            const marker = new maps.Marker({
              position: { lat: defaultLoc[0], lng: defaultLoc[1] },
              map: this.map,
            });
            this.markers.push(marker);
            this.map.fitBounds(bounds);
          });
          this.clearMarkers();
        }
        this.setMarkers();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
      this.markersOn = this.folderToUse.length > 0;
      if (this.google) {
        this.focusFolderOnMap();
      }
    },
    focusFolderOnMap() {
      if (getFolder()) {
        const getMarker = (location) => {
          const [lat, lng] = location;
          const pos = new this.google.maps.LatLng(lat, lng);
          const marker = this.allMarkers.find((m) => {
            const lats = m.getPosition().lat() === pos.lat();
            const lngs = m.getPosition().lng() === pos.lng();

            return lats && lngs;
          });

          return { pos, marker };
        };
        if (this.currentFolder.path !== '__all__') {
          const coordinate = getMarker(this.currentFolder.location);
          if (coordinate.marker) {
            this.map.panTo(coordinate.pos);
            if (coordinate.marker) this.google.maps.event.trigger(coordinate.marker, 'click');
            this.map.setZoom(16);
          }
        }
      }
    },
    focusTransmissionMarkerOnMap() {
      const getMarker = ({ id, location }) => {
        const [lat, lng] = location;
        const pos = new this.google.maps.LatLng(lat, lng);

        const marker = this.transmissionMarkers.find((m) => m.id === id);

        return { pos, marker };
      };

      const coordinate = getMarker(this.currentImage);
      if (coordinate.marker) {
        this.map.panTo(coordinate.pos);
        this.google.maps.event.trigger(coordinate.marker, 'click');
        this.map.setZoom(18);
      }
    },
    hasSomeReview(folder) {
      const folderImages = this.allImages.filter((img) => img.folder === folder.path);
      // eslint-disable-next-line no-restricted-syntax
      for (const img of folderImages) {
        let currentTimeLogs = [];
        let aggregatedTime = 0;
        if (img.time_logs !== null && img.time_logs !== undefined) {
          currentTimeLogs = img.time_logs;
        }
        aggregatedTime += currentTimeLogs.reduce((p, c) => c.timeSpent + p, 0);
        if (aggregatedTime > 0) return true;
      }
      return false;
    },
  },
  computed: {
    ...mapGetters([
      'currentProject',
      'currentSeverityFilters',
      'allImages',
      'allFolders',
      'rootFolder',
      'currentFolder',
      'currentCompany',
      'companyHas',
      'insightsFolders',
      'currentImage',
    ]),
    folderToUse() {
      return this.folders;
    },
    isNFInsightsPage() {
      return this.$route.name === 'Insights' && this.companyHas('poleByFolders');
    },
    showStructureMapOnInsight() {
      return this.$route.name === 'Insights';
    },
    imageData() {
      // Retrieve only necessary data from the image objects
      return this.imagesWithLocation.map((image) => ({
        filename: image.filename,
        classes: image.process_tracking.slice(-1)[0].labels?.map(({ label }) => label),
        createdAt: image.date,
        location: image.location,
        severity: image.process_tracking.slice(-1)[0].severity,
        caption: image.caption,
        folder: image.folder,
        id: image.id,
        gimbalPitchDegree: image.gimbalPitchDegree,
        dateInspected: image.timestamp
          ? moment.utc(image.timestamp, 'YYYYMMDD_HHmmss').format(dateFormats.FULL_DATE)
          : '',
      }));
    },
    imagesWithLocation() {
      const locImages = this.images.filter((img) => {
        try {
          // eslint-disable-next-line no-restricted-globals
          let invalidLocation = isNaN(img.location[0]) || isNaN(img.location[1]);
          invalidLocation = invalidLocation || img.location === undefined;
          invalidLocation = invalidLocation || img.location?.length === 0;
          invalidLocation = invalidLocation || img.location[0] === null;
          invalidLocation = invalidLocation || img.location[0] === null;
          if (invalidLocation) return false;
        } catch (e) {
          return false;
        }
        return true;
      });
      return locImages.filter((image) => image.location[0] !== null && image.location[1] !== null);
    },
    // folders() {
    //   const { insightsFolders, imageData } = this;

    //   const foldersWithImages = insightsFolders.map((folder) => {
    //     const currentFolder = cloneDeep(folder);

    //     // Handles edge cases of ['x', 'y'] and turns it into numeric values of type float
    //     if (typeof currentFolder.location[0] === 'string')
    // currentFolder.location[0] = parseFloat(currentFolder.location[0]);
    //     if (typeof currentFolder.location[1] === 'string')
    // currentFolder.location[1] = parseFloat(currentFolder.location[1]);

    //     const folderImages = imageData.filter((image) => image.folder === currentFolder.path);
    //     currentFolder.images = folderImages;
    //     return currentFolder;
    //   });

    //   const foldersWhichContainImages = foldersWithImages.filter((folder) => {
    //     const currentFolder = cloneDeep(folder);
    //     return currentFolder.images.length > 0;
    //   });

    //   return this.companyHas('poleByFolders') ? foldersWithImages : foldersWhichContainImages;
    // },
  },
  async mounted() {
    await this.initMap();
    this.loaded = true;
    if (this.showStructureMapOnInsight) {
      this.showAllImageMarkers();
      this.focusTransmissionMarkerOnMap();
    } else {
      const imageId = localStorage.getItem(`${this.currentProject.pid}image`);
      const folderPath = localStorage.getItem(`${this.currentProject.pid}folder`);
      if (folderPath && this.currentFolder.path !== folderPath) {
        this.setCurrentFolder(this.allFolders.find((folder) => folder.path === folderPath));
        this.setShowFolders(false);
      }
      this.$nextTick(() => {
        const severityFilter = localStorage.getItem(`${this.currentProject.pid}severity`);
        if (severityFilter) {
          this.setSeverityFilter([severityFilter]);
          this.setSeverityFilterClicked(true);
        }
        if (imageId && this.currentImage.id !== imageId) {
          this.setCurrentImage(this.allImages.find((image) => image.id === Number(imageId)));
        }
      });
    }
  },
  watch: {
    currentSeverityFilters(next, prev) {
      this.$nextTick(() => {
        const isSame = next.length === prev.length
          && next.every((element, index) => element === prev[index]);
        if (!isSame && this.google) {
          this.removeTransmissionMarkers();
          this.setTransmissionMarkers();
          if (this.transmissionMarkers.length > 0) {
            this.google.maps.event.trigger(this.transmissionMarkers[0], 'click');
          }
          localStorage.setItem(`${this.currentProject.pid}severity`, next);
        }
      });
    },
    allFolders() {
      // Reinitialize Marker
      this.$nextTick(() => {
        if (this.google) {
          this.setMarkers();
          this.focusFolderOnMap();
        }
      });
    },
    insightsFolders() {
      // Reinitialize Marker
      this.$nextTick(() => {
        if (this.google) {
          this.setMarkers();
          this.focusFolderOnMap();
        }
      });
      // this.initMap();
    },
    currentFolder(newF, oldF) {
      if (newF.path === oldF.path) return;
      if (!this.showStructureMapOnInsight && this.loaded) {
        localStorage.setItem(`${this.currentProject.pid}folder`, newF.path);
      }
      if (this.google) {
        this.focusFolderOnMap();
        this.removeTransmissionMarkers();
        this.setTransmissionMarkers();
        this.map.setZoom(18);
      }
    },
    currentImage(newI, oldI) {
      if (
        !newI.location
        || !Array.isArray(newI.location)
        || !newI.location[0]
        || !newI.location[1]
      ) {
        this.showError = true;
        return;
      }
      this.showError = false;
      // this.showCount += 1;
      // if (this.showCount <= 1) return;
      if (newI.filename === oldI.filename) return;
      // if (newI.folder === this.currentFolder.path) return;
      if (!this.showStructureMapOnInsight && this.loaded) {
        localStorage.setItem(`${this.currentProject.pid}image`, newI.id);
      }
      this.focusTransmissionMarkerOnMap();
    },
  },
  destroyed() {
    const currentFolder = sessionStorage.getItem('currentFolder');
    if (currentFolder) sessionStorage.clear();
  },
};
</script>

<style scoped>
.map {
  width: 100%;
  height: 50vh;
}
</style>
