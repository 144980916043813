<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        id="export-xlsx-btn"
        icon
        x-large
        color="primary"
        v-bind="attrs"
        v-on="on"
        @click="exportXlsx"
      >
        <v-icon>fas fa-file-excel</v-icon>
      </v-btn>
    </template>
    <span>Export Excel Spreadsheet</span>
  </v-tooltip>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment/moment';
import platformBackendClient from '@backend-clients/platform-backend';

export default {
  name: 'ExportXlsx',
  data: () => ({
    excel: null,
    DEFECTIVE_LABELS: ['High', 'Medium', 'Low'],
  }),
  methods: {
    ...mapActions(['setRetrieveImage', 'setRetrieveFolder']),
    // eslint-disable-next-line consistent-return
    async exportXlsx() {
      this.setRetrieveImage(true);
      const filename = `${this.currentProject.name}-${moment().format('MMMM-DD-YYYY')}`;
      const { data } = await platformBackendClient.get(`/api/dashboard/company/${this.currentCompany.cid}/project/${this.currentProject.pid}/xlsx`, { responseType: 'blob' });

      const href = window.URL.createObjectURL(new Blob([data]));

      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', `${filename}.xlsx`);
      document.body.appendChild(link);
      link.click();
      this.setRetrieveImage(false);
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    },
  },
  computed: {
    ...mapGetters(['currentCompany', 'companyHas', 'companyConfig', 'currentProject', 'currentUser']),
  },
};
</script>
