<template>
  <v-card>
    <v-card-title class="headline">{{ title }}</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      {{ content }}
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="close">Close</v-btn>
      <v-btn color="primary" @click="confirm">Confirm</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'UnpauseSubscriptionConfirm',
  props: ['title', 'content', 'id'],
  methods: {
    ...mapActions(['updateSubscriptionById', 'setSubscriptionNotification']),
    close() {
      this.$emit('closeUnpauseSub');
    },
    confirm() {
      const payload = {
        data: {
          pause_collection: '',
        },
        id: this.id,
      };

      this.updateSubscriptionById(payload)
        .then(() => {
          this.close();
          setTimeout(() => {
            this.setSubscriptionNotification({ success: true, message: `${this.currentSubscription.plan.nickname} has been unpaused. You may use your services again.` });
          }, 1000);
        });
    },
  },
  computed: {
    ...mapGetters(['currentSubscription']),
  },
};
</script>
