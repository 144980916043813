import Pollymer from 'pollymer';

const LongPoll = () => {
  const lp = new Pollymer.Request();

  LongPoll.maxTries = 2;
  LongPoll.maxDelay = 1000;

  return lp;
};

export default LongPoll;
