<template>
  <div>
    <v-row>
      <v-col cols="12" class="text-center buttons-row">
        <v-btn
          :color="uploadBtnDisplay.color"
          @click="showUploader"
          :disabled="(processingImage || uploadingImages || isTrial)"
          class="mx-2"
          id="upload-btn"
          v-if="hasImage &&  hasPermission('IMAGE_UPLOAD') "
        >
          {{uploadBtnDisplay.text}}
          <v-icon right dark>
            cloud_upload
          </v-icon>
        </v-btn>
        <v-btn
          :color="eNFUploaderBtnDisplay.color"
          @click="showENFUploader"
          :disabled="(processingImage || uploadingImages || isTrial)"
          class="mx-2"
          id="upload-btn"
          v-if="companyHas('epriUploader')"
        >
          {{eNFUploaderBtnDisplay.text}}
          <v-icon right dark>
            cloud_upload
          </v-icon>
        </v-btn>
        <v-btn
          v-if="hasImage"
          :color="filterBtnDisplay.color"
          @click="showFilter"
          :disabled="(processingImage || uploadingImages || isTrial)"
          id="filter-btn"
          class="mx-2"
        >
          {{filterBtnDisplay.text}}
          <v-icon right dark>
            filter_alt
          </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" v-show="uploader || !hasImage">
        <div class="headline text-center" color="primary" v-if="!hasImage">
          There are no images within this project. Upload your first images to get started.
        </div>
        <V2Uploader
          :cid="currentUser.cid"
          :pid="currentProject.pid"
          v-if="companyHas('v2_uploader')"
        />
        <DirectImageUploader
          :cid="currentUser.cid"
          :pid="currentProject.pid"
          v-else-if="storageService === 'gcp'"
        />
        <S3ImageUploader
          :cid="currentUser.cid"
          :pid="currentProject.pid"
          v-else
        />
      </v-col>
      <v-col cols="12" v-if="eNFUploader">
        <EPRIInputFolderUploader :cid="currentUser.cid" :pid="currentProject.pid" />
      </v-col>
      <v-col cols="12" v-if="filter">
        <ImageFilters />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DirectImageUploader from '@components/images/utilities/DirectImageUploader.vue';
import S3ImageUploader from '@components/images/utilities/S3ImageUploader.vue';
import EPRIInputFolderUploader from '@components/images/utilities/EPRIInputFolderUploader.vue';
import ImageFilters from '@components/images/utilities/ImageFilters.vue';
import V2Uploader from '@components/images/utilities/V2Uploader.vue';

export default {
  name: 'ImageToolBar',
  components: {
    V2Uploader,
    DirectImageUploader,
    S3ImageUploader,
    EPRIInputFolderUploader,
    ImageFilters,
  },
  props: ['hasImage'],
  data: () => ({
    uploader: false,
    filter: false,
    metaUploader: false,
    eNFUploader: false,
  }),
  methods: {
    showUploader() {
      this.filter = false;
      this.uploader = !this.uploader;
      this.eNFUploader = false;
    },
    showFilter() {
      this.filter = !this.filter;
      this.uploader = false;
      this.metaUploader = false;
      this.eNFUploader = false;
    },
    showMetaUploader() {
      this.filter = false;
      this.uploader = false;
      this.eNFUploader = false;
    },
    showENFUploader() {
      this.filter = false;
      this.uploader = false;
      this.eNFUploader = !this.eNFUploader;
    },
  },
  computed: {
    ...mapGetters(['isTrial', 'hasPermission', 'currentUser', 'processingImage', 'currentProject', 'uploadingImages', 'companyHas', 'currentCompany']),
    uploadBtnDisplay() {
      return {
        color: this.uploader ? 'error' : 'primary',
        text: this.uploader ? 'Hide Uploaders' : 'Show Uploaders',
      };
    },
    eNFUploaderBtnDisplay() {
      return {
        color: this.eNFUploader ? 'error' : 'primary',
        text: this.eNFUploader ? 'Hide EPRI NF Uploader' : 'Show EPRI NF Uploader',
      };
    },
    metaUploadBtnDisplay() {
      return {
        color: this.metaUploader ? 'error' : 'primary',
        text: this.metaUploader ? 'Hide Meta Data Uploader' : 'Show Meta Data Uploader',
      };
    },
    filterBtnDisplay() {
      return {
        color: this.filter ? 'error' : 'primary',
        text: this.filter ? 'Hide Filters' : 'Filter Images',
      };
    },
    storageService() {
      const isS3 = this.currentCompany.storage_platform === 2;
      return (isS3) ? 's3' : 'gcp';
    },
  },
  created() {
    this.uploader = !this.hasImage;
  },
};
</script>

<style scoped>
.buttons-row {
  margin-top: -10px;
}
.buttons-row .v-btn {
  margin-top: 10px;
}
</style>
