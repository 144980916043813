<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="headline">Dashboard Subscription Paused</v-card-title>
      <v-card-text>
        Your access to the Dashboard is restricted since the subscription has been paused.
        To enable this feature again, please go back to your settings under Analytics and
        unpause this subscription.
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" to="/analytics/settings/access">Go to Analytics Settings</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'DashboardAccessPaused',
};
</script>
