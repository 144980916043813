<template>
  <v-card>
    <v-card-title class="headline" id="complete-user-form-title">
      Complete User Credentials
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text id="user-form-title">
      Please fill out the following personal information to
      complete the authentication process.
    </v-card-text>
    <v-container fluid>
      <v-row>
        <v-col cols="6">
          <v-text-field
            outlined
            id="first-name-field"
            v-model="firstName"
            label="First Name"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            outlined
            id="last-name-field"
            v-model="lastName"
            label="Last Name"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            outlined
            v-model="email"
            id="email-field"
            label="Email"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            outlined
            id="phone-number-field"
            v-model="phone"
            @input="formatPhone"
            label="(Optional) Phone Number"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-select
            :items="availableCountries"
            id="country-code-field"
            v-model="country"
            outlined
            item-text="name"
            item-value="code"
            label="Phone Number Country Code"
          ></v-select>
        </v-col>
      </v-row>
    </v-container>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        text
        color="primary"
        @click="addUser"
        id="add-user-btn"
      >Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import countries from '@utils/map/country-codes';

export default {
  name: 'CompleteUser',
  props: ['emailAddress', 'uid'],
  data: () => ({
    firstName: '',
    lastName: '',
    phone: '',
    country: '',
    email: '',
    availableCountries: [],
    errors: null,
  }),
  methods: {
    ...mapActions(['createUserSSO']),
    formatPhone() {
      const number = this.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.phone = !number[2] ? number[1] : `(${number[1]}) ${number[2]}${number[3] ? `-${number[3]}` : ''}`;
    },
    addUser() {
      const newUser = {
        firstName: this.firstName,
        lastName: this.lastName,
        name: `${this.firstName} ${this.lastName}`,
        phone: this.phone,
        country: this.country,
        uid: this.uid,
        email: this.email,
        status: 'active',
        role: 'user',
        sid: '',
        teams: [],
      };

      this.createUserSSO(newUser)
        .then((res) => this.$emit('createUser', res.data))
        .catch((err) => {
          this.errors = err.response.data;
        });
    },
  },
  mounted() {
    this.email = this.emailAddress;
    this.availableCountries = [...countries];
  },
};
</script>
