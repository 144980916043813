<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="success"
        fab
        @click="refresh"
        class="ml-1"
        :loading="refreshing"
        v-bind="attrs"
        v-on="on"
        medium
        fixed
        bottom
        right
      >
        <v-icon>refresh</v-icon>
      </v-btn>
    </template>
    <span>Refresh</span>
  </v-tooltip>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ImageRefreshButton',
  data: () => ({
    refreshing: false,
  }),
  methods: {
    ...mapActions(['setCurrentImage']),
    async refresh() {
      this.refreshing = true;
      this.setCurrentImage(this.currentImage);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      this.refreshing = false;
    },
  },
  computed: {
    ...mapGetters(['currentImage']),
  },
};
</script>
