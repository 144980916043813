<template>
  <v-dialog v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        v-if="isIcon"
        id="icon-btn"
        :color="color"
        v-on="on"
        v-bind="attrs"
      >{{ icon }}</v-icon>
      <v-btn
        v-else-if="isCombination"
        id="combo-btn"
        :color="color"
        v-on="on"
        v-bind="attrs"
        x-small
      >
        <span id="open-btn-text">{{ buttonText }}</span>
        <v-icon x-small id="open-btn-icon">{{ icon }}</v-icon>
      </v-btn>
      <v-btn
        v-else
        id="normal-btn"
        :color="color"
        v-on="on"
        v-bind="attrs"
      >{{ buttonText }}</v-btn>
    </template>
    <v-card class="confirmation-card">
      <v-card-title class="headline">{{ title }}</v-card-title>
      <v-card-text id="confirm-content">{{ content }}</v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          id="close-btn"
          text
          color="primary"
          @click="close"
        >Close</v-btn>
        <v-btn
          id="confirm-btn"
          text
          :loading="isConfirming"
          color="primary"
          @click="confirm"
        >Confirm</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ActionConfirmation',
  props: ['content', 'buttonType', 'icon', 'buttonText', 'color', 'title', 'isConfirming', 'id'],
  data: () => ({
    dialog: false,
  }),
  methods: {
    close() {
      this.dialog = false;
    },
    confirm() {
      this.$emit('confirm', this.id);
    },
  },
  computed: {
    isIcon() {
      const { buttonType } = this;

      return buttonType === 'icon';
    },
    isBlock() {
      const { buttonType } = this;
      return buttonType === 'block';
    },
    isCombination() {
      const { buttonType } = this;
      return buttonType === 'combination';
    },
  },
};
</script>
