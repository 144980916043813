/*
  |---------|--------------------- | --------------------- |
  | #90A4AE | blue-grey lighten-2  | ''                    |
  | ------- | -------------------- | --------------------- |
  | #66BB6A | green lighten-1      | None                  |
  | ------- |--------------------- | --------------------- |
  | #00B0FF | light-blue accent-3  | Low, TD-5             |
  | ------- |--------------------- | --------------------- |
  | #FFEE58 | yellow lighten-1     | Medium, TD-4, TD-2    |
  | ------- |--------------------- | --------------------- |
  | #F44336 | red                  | High, TD-1, Emergency |
  | ------- |--------------------- | ----------------------|
*/

const td = {
  '': {
    color: '#90A4AE',
    text: 'white',
  }, // blue-grey lighten-2
  None: {
    color: '#66BB6A', // green lighten-1
    text: 'white',
  },
  'TD-5': {
    color: '#00B0FF', // light-blue accent-3}
    text: 'black',
  },
  'TD-4': {
    color: '#FFEE58', // yellow lighten-1
    text: 'black',
  },
  'TD-2': {
    color: '#FFEE58', // yellow lighten-1
    text: 'black',
  },
  'TD-1': {
    color: '#F44336', // Red
    text: 'white',
  },
  Emergency: {
    color: '#F44336',
    text: 'white',
  },
};

// const severityColorPicker = (level, tdScale) => ((!tdScale) ? severityColors[level] : td[level]);
const tdScalingSeverityColorPicker = (level) => td[level];

export default tdScalingSeverityColorPicker;
