<template>
  <v-container fill-height fluid>
    <LoadingOverlay :loading="loadingImages" type="Images"/>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="4">
        <v-card>
          <v-card-title class="headline">
            No Images Match Filters
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <span>We were not able to match any of the images within the </span>
            <span><b>{{currentProject.name}}</b> project. You may either adjust</span>
            <span>the configurations that were set on the images, or clear the filters</span>
            <span>that are current set.</span>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" :to="`/project/${currentProject.pid}`">
              View {{ currentProject.name }}
            </v-btn>
            <v-btn text color="primary" @click="clearCurrentFilters">Clear Filters</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import LoadingOverlay from '@components/common/loaders/LoadingOverlay.vue';

export default {
  name: 'NoImagesMatchFilter',
  components: {
    LoadingOverlay,
  },
  computed: {
    ...mapGetters(['currentProject', 'currentCompany', 'dashboardFilters', 'loadingImages']),
  },
  methods: {
    ...mapActions(['clearDashboardFilters', 'getDashboardData', 'setLoadingImage', 'setCurrentImage', 'getDashboardImagesByFilters']),
    clearCurrentFilters() {
      // Clears the dashboard filters
      this.clearDashboardFilters();
    },
  },
};
</script>
