<template>
  <v-card>
    <v-card-title class="headline">Request Summary</v-card-title>
    <v-card-subtitle class="subtitle">
      {{ oldPlan.plan.nickname }} to {{ newPlan.nickname }}
    </v-card-subtitle>
    <v-divider></v-divider>
    <Loading v-if="loadingPrice" />
    <PlanRequest
      v-else
      :oldPlan="oldPlan"
      :newPlan="newPlan"
      :currentServices="currentServices"
      :newServices="newServices"
      v-on:close="close"
    />
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PlanRequest from '@views/billing_views/subscription_plans/PlanRequest.vue';
import Loading from '@components/common/Loading.vue';
import { find } from '@methods/helpers';

export default {
  name: 'PlanRequestDialog',
  components: {
    Loading,
    PlanRequest,
  },
  props: ['oldPlan', 'newPlan', 'currentServices', 'newServices'],
  methods: {
    ...mapActions(['getPriceById', 'getPrices', 'changePlan', 'getNewPriceById', 'setLoadingPrice', 'finishLoadingPrice']),
    close() {
      this.$emit('close');
    },
    requestPlanChange() {
      const newPriceId = this.newPlan.id;

      const { customerId } = this.currentCompany;
      const payload = {
        customerId,
        data: {
          company: this.currentCompany.companyName,
          email: this.currentUser.email,
          newPlanId: newPriceId,
        },
      };
      this.changePlan(payload)
        .then(() => this.close());
    },
  },
  computed: {
    ...mapGetters(['allPrices', 'currentSubscription', 'currentCompany', 'currentUser', 'currentPrice', 'currentNewPrice', 'loadingPrice']),
    currentPriceId() {
      const { id } = this.currentSubscription.plan;
      return find(this.allPrices, (price) => price.id === id).id;
    },
  },
  async created() {
    const newPlanId = this.newPlan.id;
    this.setLoadingPrice();
    await this.getNewPriceById(newPlanId);
    const res = await this.getPriceById(this.currentPriceId);

    if (res.status === 200) this.finishLoadingPrice();
  },
};
</script>
