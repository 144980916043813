import firebase from '@database';
import { decrypt } from '@utils/crypto';
import { readCookie } from '@authentication/cookie';

export default () => ({
  firstFactor: firebase.auth().currentUser !== null,
  secondFactor: decrypt(
    readCookie('2fa'),
  ) === process.env.VUE_APP_2FA_VALIDATION,
});
