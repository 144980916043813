<template>
  <v-container fluid>
    <v-card flat>
      <v-card-title class="headline">Success!</v-card-title>
      <v-card-text>You payment method has been successfully added</v-card-text>
    </v-card>
  </v-container>
</template>

<script>

export default {
  name: 'StripePaymentSuccess',
};
</script>
