<template>
  <div class="background" id="login-background">
    <div class="dark-overlay" id="login-page-overlay">
      <v-card class="modal">
        <v-dialog v-model="dialog" persistent
                  max-width="750px" id="complete-user-form">
          <CompleteUserForm
            :emailAddress="email"
            :uid="uid"
            @createUser="createUserHandler"
          />
        </v-dialog>
        <!-- Buzz Solutions Logo -->
        <v-card-title class="headline" id="login-headline">
            <img
              src="../../assets/buzz-icon-yellow.svg"
              alt="Buzz Solutions"
              class="buzz-logo"
              id="login-buzz-logo-img"
            >
            Buzz Solutions
        </v-card-title>
        <v-card-title style="color: grey" id="login-title">Login</v-card-title>
        <v-card-subtitle v-if="message" class="red--text" id="error-message">
          {{ message }}
        </v-card-subtitle>

        <!-- Email and Password Login Form -->
        <v-container fluid id="login-form">
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Email"
                single-line
                outlined
                v-model="email"
                id="email-credential-login"
                class="mx-auto"
                :error-messages="errors.email"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Password"
                single-line
                outlined
                v-model="password"
                id="password-credential-login"
                class="mx-auto"
                type="password"
                :error-messages="errors.password"
                @keydown.enter="emailAndPasswordAuthentication"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-btn color="primary"
                @click="emailAndPasswordAuthentication"
                id="login-btn"
                block
                :loading="loading">
                Login
              </v-btn>
            </v-col>

            <!-- Login via MSFT Azure Active Directory -->
             <!-- <v-col cols="12" v-if="access.azure">
              <v-btn
                outlined
                @click="authenticateAzure"
                :loading="authenticatingMsft"
                id="azure-authenticate-btn"
                block
                color="primary"
              >
                <v-icon>mdi-microsoft</v-icon>
                Sign in with Microsoft
              </v-btn>
            </v-col> -->

            <!-- Login via JumpCloud SAML -->
            <v-col cols="12" v-if="access.sso">
              <v-btn outlined
                @click="authenticateSaml"
                id="saml-authenticate-btn"
                block
                color="primary"
                :loading="authenticatingSaml"
              >
                <v-icon>mdi-login</v-icon>
                SSO Login
              </v-btn>
            </v-col>

            <!-- Forgot Password Link -->
            <v-col cols="12">
              <router-link
                to="/forgot-password/"
                id="forgot-password-btn"
              >Forgot Password?</router-link>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import authenticate from '@database/authentication';
import services from '@services';
import CompleteUserForm from '@components/authentication/CompleteUserForm.vue';

export default {
  name: 'Login',
  components: {
    CompleteUserForm,
  },
  data: () => ({
    dialog: false,
    loading: false,
    message: null,
    email: '',
    uid: '',
    password: '',
    errors: {
      email: '',
      password: '',
    },
    authenticatingMsft: false,
    authenticatingSaml: false,
    access: {
      sso: false,
      azure: false,
    },
  }),
  methods: {
    ...mapActions(['login', 'logout', 'setNotification']),
    /*
      |---------------------------------------------------|
      |   @method       handleExistingUser                |
      |   @params       email <String>                    |
      |   @description  When a user tries to authenticate |
      |                 to the platform using an email    |
      |                 that already exists in Firebase   |
      |                 Auth, it will throw an error.     |
      |                 It will then authenticate         |
      |                 using a customToken               |
      |---------------------------------------------------|
    */
    handleExistingUser: (email) => {
      // This will catch and handle the error in which case, the user's
      // email already exists in Firebase Auth.
      const query = `email=${email}`;
      authenticate.authenticateExistingUser(query)
        .then((user) => {
          // Once the user has been authenticated via custom token,
          // it will set up the jwt auth as per usual
          this.$router.push({ name: 'TwoFactor', params: { uid: user.firestore_uid } });
        });
    },
    /*
      |---------------------------------------------------|
      |   @method       authenticateAzure                 |
      |   @description  Authenticates user with MSFT Azure|
      |                 active directory                  |
      |---------------------------------------------------|
    */
    authenticateAzure() {
      // Sets authenticating state for the MSFT active directory method
      this.authenticatingMsft = true;

      authenticate.microsoft()
        .then((result) => {
          // Once the user has sucessfully authenticated via pop up
          // it will automatically set up the jwt token auth rather than
          // having the user go through 2FA
          authenticate.setJwtAuthentication(result.user.uid)
            .then(() => {
              // If successful, it'll push to the projects page
              this.$router.push('/projects/view/grid');
            })
            .catch((err) => {
              // Catching an error will open the 'Complete User' form
              // in a dialog, which `this.email` and `this.uid` will be passed
              // into the form component as props
              this.openCompleteAuthForm(
                err.response.data.body.details.email,
                err.response.data.body.details.uid,
              );
            });
        })
        .catch(((err) => {
          // This will catch and handle the error in which case, the user's
          // email already exists in Firebase Auth.
          const query = `email=${err.email}`;
          authenticate.authenticateExistingUser(query);
        }));
    },

    /*
      |---------------------------------------------------|
      |   @method       authenticateSaml                  |
      |   @description  Authenticates user with JumpCloud |
      |                 SAML                              |
      |---------------------------------------------------|
    */
    async authenticateSaml() {
      // Sets authenticatingSaml state to a loading state
      this.authenticatingSaml = true;

      // Begins creating a SAML Provider and invokes a sign in popup
      // for the user
      const result = await authenticate.saml().catch(() => {
        // This will catch and handle the error in which case, the user's
        // email already exists in Firebase Auth.
        this.authenticatingSaml = false;
        // const query = `email=${err.email}`;
        console.log('There is an error with SAML');
        // authenticate.authenticateExistingUser(query)
        //   .then(async (user) => {
        //     // Once the user has been authenticated via custom token,
        //     // it will set up the jwt auth as per usual
        //     authenticate.setJwtAuthentication(user.uid)
        //       .then(() => this.$router.push('/projects/view/grid'));
        //   });
      });
      if (!result) return;
      const { data: user } = await services.users.firebase_get(result.user.uid)
        .catch(async (err) => {
          const { status } = err.response;
          // handle 404
          if (status === 404) {
            const { data } = await services.companies.allowAutoSignup();
            if (data.allow) {
              this.email = result.user.email;
              this.uid = result.user.uid;
              this.dialog = true;
            } else {
              this.authenticatingSaml = false;
              await this.logout();
              services.auth.deleteFirebaseUser(err.response.data.body.details.uid)
                .catch((error) => {
                  console.error(error);
                });
              this.setNotification({
                success: true,
                message: 'User information has not been registered in PowerAI. Contact support.',
                color: 'error',
              });
            }
          } else {
          // handle other errors
            console.error(err);
            this.logout()
              .then(() => {
                this.setNotification({
                  success: true,
                  message: 'something went wrong. Please try again.',
                  color: 'error',
                });
              });
          }
        });

      if (user.status === 'not_registered') {
        await services.users.update(null, result.user.uid, { status: 'active' });
      }
      this.$router.push({ name: 'TwoFactor', params: { uid: user.firestore_uid } });
    },
    createUserHandler(user) {
      this.$router.push({ name: 'TwoFactor', params: { uid: user.uid } });
    },
    /*
      |---------------------------------------------------|
      |   @method       emailAndPasswordAuthentication    |
      |   @description  Authenticates user with an email  |
      |                 password input field              |
      |---------------------------------------------------|
    */
    emailAndPasswordAuthentication() {
      if (this.email !== '' && this.password !== '') {
        const credentials = {
          email: this.email,
          password: this.password,
        };
        this.loading = true;
        this.login(credentials)
          .then((res) => {
            this.$router.push({ name: 'TwoFactor', params: { uid: res.uid } });
          }).catch((err) => {
            this.message = err.message;
          })
          .then(() => {
            this.loading = false;
          });
      } else {
        if (this.email === '') this.errors.email = 'Please enter in your email';
        if (this.password === '') this.errors.password = 'Please enter in your password';
      }
    },
  },
  mounted() {
    this.access.sso = authenticate.access.sso[window.location.hostname];
    this.access.azure = authenticate.access.azure[window.location.hostname];
  },
};
</script>

<style scoped>
.background {
  text-align: center;
  width: 100%;
  position: relative;
  background: url('../../assets/washed-out-wires.png') no-repeat;
  background-size: cover;
  height: calc(100vh - 60px);
  margin-bottom: -50px;
  -o-backgorund-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
}
.dark-overlay {
  background-color: rgb(0,0,0,0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.modal {
  position: fixed;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  width: 100%;
  max-width: 500px;
}
.headline {
  justify-content: center;
  /* color: grey; */
  font-weight: 500;
}
.buzz-logo {
  width: 75px;
  margin-right: 10px;
}
</style>
