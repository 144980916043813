/*
  |----------------------------------|
  |                                  |
  |       F O L D E R  M A P         |
  |                                  |
  |----------------------------------|
*/
export const setFolder = (folder) => {
  sessionStorage.setItem('currentFolder', folder);
};

export const getFolder = () => sessionStorage.getItem('currentFolder');

export const removeFolder = () => {
  sessionStorage.removeItem('currentFolder');
};

/*
  |----------------------------------|
  |                                  |
  |         I M A G E  M A P         |
  |                                  |
  |----------------------------------|
*/
export const setImage = (filename) => {
  sessionStorage.setItem('currentImage', filename);
};

export const getImage = () => sessionStorage.getItem('currentImage');

export const removeImage = () => {
  sessionStorage.removeItem('currentImage');
};
