<template>
  <v-container>
    <v-card>
      <v-card-text>
        Please review and confirm the information provided below.
        You are also asked to set a new password.
      </v-card-text>
      <v-form ref="registerForm" id="registration-form">
      <v-container>
        <v-row>
          <v-col xs="12" sm="6" md="6" lg="6" xl="6">
            <v-text-field
              outlined
              label="First Name"
              v-model="firstName"
              id="first-name-field"
              :rules="[requiredRule]"
            ></v-text-field>
          </v-col>
          <v-col xs="12" sm="6" md="6" lg="6" xl="6">
            <v-text-field
              outlined
              label="Last Name"
              v-model="lastName"
              id="last-name-field"
              :rules="[requiredRule]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col xs="12" sm="6" md="4" lg="4" xl="4">
            <v-text-field
              outlined
              label="Email Address"
              id="email-field"
              v-model="email"
              disabled
            ></v-text-field>
          </v-col>
          <v-col xs="12" sm="6" md="4" lg="4" xl="4">
            <v-text-field
              outlined
              label="Phone"
              id="phone-number-field"
              v-model="phone"
            ></v-text-field>
          </v-col>
          <v-col xs="12" sm="6" md="4" lg="4" xl="4">
            <v-select
              outlined
              :items="items"
              label="Country"
              id="country-code-field"
              item-text="name"
              item-value="Iso2"
              v-model="country"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              label="Password"
              id="password-field"
              :rules="[requiredRule]"
              :type="showPassword ? 'text' : 'password'"
              v-model="password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              label="Confirm Password"
              id="confirm-password-field"
              :rules="[requiredRule]"
              :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showConfirm = !showConfirm"
              :type="showConfirm ? 'text' : 'password'"
              :error-messages="error"
              v-model="confirm"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      </v-form>
      <v-divider></v-divider>
      <v-card-actions class="my-4">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="registerUser"
          :loading=loading
          id="register-user-btn"
        >Register</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import services from '@services';
import countries from '@utils/country-codes.json';

export default {
  name: 'Register',
  data: () => ({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    country: '',
    password: '',
    confirm: '',
    showPassword: false,
    showConfirm: false,
    error: '',
    uid: '',
    requiredRule: (v) => !!v || 'Field is required',
    loading: false,
    user: {},
    items: [],
  }),
  methods: {
    ...mapActions(['getUser', 'setNotification']),
    errorHandler(message) {
      this.setNotification({
        message,
        success: true,
        color: 'error',
      });
      this.loading = false;
    },
    registerUser() {
      this.loading = true;
      const isFormValid = this.$refs.registerForm.validate();
      if (isFormValid) {
        const { password, confirm } = this;
        if (password !== confirm) {
          this.error = 'Your passwords must match';
          this.loading = false;
          return;
        }

        const payload = {
          uid: this.$route.params.uid,
          data: {
            password: this.password,
            emailVerified: true,
          },
        };

        services.auth.update(payload.uid, payload.data)
          .then(() => {
            this.loading = false;
            this.setNotification({
              success: true,
              message: "Successfully set this account's password",
              color: '',
            });
            this.$router.push('/login');
          })
          .catch(() => this.errorHandler('Unable to update password'));
      }
    },
    formatPhone() {
      const number = this.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.phone = !number[2] ? number[1] : `(${number[1]}) ${number[2]}${number[3] ? `-${number[3]}` : ''}`;
    },
  },
  mounted() {
    this.items = [...countries];
  },
  created() {
    const { uid } = this.$router.history.current.params;
    this.uid = uid;

    services.users.get(uid)
      .then((res) => {
        if (res.data.registered) {
          this.setNotification({
            message: 'This user has already been registered.',
            success: true,
            color: '',
          });
          this.$router.push('/login');
        }
        [this.firstName, this.lastName] = res.data.name.split(' ');
        this.email = res.data.email;
        if (res.data.phone) this.phone = res.data.phone;
        if (res.data.country) this.country = res.data.country;
      })
      .catch(() => this.errorHandler('Unable to find user'));
  },
};
</script>
