<template>
  <SwitchPlansLoader v-if="loadingSubscription"/>
  <v-container fluid v-else>
    <v-row>
      <v-col
        xs="12"
        sm="12"
        md="4"
        lg="4"
        xl="4"
        v-for="(price, i) in allPrices"
        :key="i"
      >
        <PlanCard
          :services="getServices(price.nickname)"
          :currentServices="getServices(currentSubscription.plan.nickname)"
          :subscription="currentSubscription"
          :price="price"
        />
      </v-col>
    </v-row>
    <SnackBar
      :snackbar="subscriptionNotification.success"
      :text="subscriptionNotification.message"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SwitchPlansLoader from '@components/common/loaders/SwitchPlansLoaders.vue';
import SnackBar from '@components/common/SnackBar.vue';
import { toLowerFirst } from '@methods/helpers';
import plans from '@components/billing/subscriptions/plans';
import PlanCard from './PlanCard.vue';

export default {
  name: 'SwitchPlans',
  components: {
    SwitchPlansLoader,
    PlanCard,
    SnackBar,
  },
  data: () => ({
    plans: [],
    otherPlans: [],
    plan: {},
    subId: '',
  }),
  methods: {
    ...mapActions(['getSubscriptionById', 'setCurrentSubscription', 'setLoadingSubscription', 'getSubscriptionByType', 'getPrices', 'updateSubscriptionById', 'getPriceByProductId', 'getImagePlanPrices', 'setLoadingPrices']),
    getServices: (planName) => plans[toLowerFirst(planName)],
    switchPlan(plan) {
      const priceId = plan.id;
      const data = {
        cancel_at_period_end: false,
        items: [
          {
            id: this.currentSubscription.items.data[0].id,
            price: priceId,
          },
        ],
      };
      this.updateSubscriptionById({ id: this.currentSubscription.id, data });
    },
  },
  computed: {
    ...mapGetters(['currentCompany', 'loadingSubscription', 'currentSubscription', 'currentCustomer', 'currentPrice', 'allPrices', 'loadingPrices', 'subscriptionNotification']),
    customerSubId() {
      const { data } = this.currentCustomer.subscriptions;
      const [customerSubscription] = data.filter((subscription) => subscription.plan.nickname !== 'Dashboard');
      return customerSubscription.id;
    },
  },
  async created() {
    this.setLoadingPrices(true);
    this.setLoadingSubscription();

    const res = await this.getImagePlanPrices();

    if (res.status === 200) {
      const subRes = await this.getSubscriptionById(this.customerSubId);
      if (subRes.status === 200) this.setLoadingPrices(false);
    }
  },
};
</script>
