<template>
  <v-container fluid id="twofactor-verification-form">
    <v-row>
      <v-col cols="12">
        <v-text-field
          label="Enter your code"
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          single-line
          outlined
          v-model="code"
          id="code-input"
          class="mx-auto"
          :error-messages="authenticationErrors.code"
          :type="show ? 'text' : 'password'"
          @click:append="show = !show"
          @keydown.enter="authenticate"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-btn
          color="primary"
          block
          @click="authenticate"
          id="two-fact-btn">
          Authenticate
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-btn
          text
          block
          id="resend-btn"
          :loading="loadingSid"
          @click="resend"
        >Resend Code</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TwoFactorForm',
  props: [
    'authType', // So the form knows whether it's sms or email
    'loadingSid', // So the form knows that an SID has been generated
    // used for the "Resend Code". If there's no sid then
    // there's no verification to cancel.
  ],
  data() {
    return {
      code: '',
      show: false,
    };
  },
  methods: {
    ...mapActions(['setAuthenticationError', 'clearAuthenticationErrors']),
    /*
      |-----------------------------------------------|
      |    @method   resend                           |
      |    @params   Void                             |
      |    @desc     Emits a 'resend' event back      |
      |               to the parent component since   |
      |               all api calls are made there    |
      |-----------------------------------------------|
    */
    async resend() {
      this.$emit('resend');
    },

    /*
      |------------------------------------------------|
      |    @method   Authenticate                      |
      |    @params   Void                              |
      |    @desc     Emits an 'authenticate' event back|
      |               to the parent component to let   |
      |               the TwoFactor view to verifiy the|
      |               2FA code through the backend     |
      |------------------------------------------------|
    */
    authenticate() {
      if (this.code === '') {
        this.setAuthenticationError([
          {
            key: 'code',
            message: 'Please provide the authentication code that was sent to your device.',
          },
        ]);
        return;
      }
      this.$emit('authenticate', this.code);
    },
  },
  computed: {
    ...mapGetters(['currentUser', 'authenticationErrors', 'secondFactorAuthenticated']),
  },
  created() {
    // The 'create' event will let the TwoFactor view know
    // to make a request to the backend to have a 2FA code sent to the user
    this.$emit('create');
  },
  destroyed() {
    this.clearAuthenticationErrors();

    this.$nextTick(() => {
      // This component lifecycle gets called everytime the user is navigating AWAY
      // from the 2FA page (i.e. going to the projects page, going back to the 2fa method select)
      // But we only want to clear the component states when the user clicks the back button to
      // change 2FA methods.

      // When the user goes from 2FA -> projects page, the component data clears out automatically
      if (this.$route.path === '/projects/view/grid') return;
      this.$emit('clear');
    });
  },
};
</script>
