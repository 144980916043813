<template>
  <!-- <v-container> -->
    <v-card max-width="400" class="mx-auto d-block">
      <v-card-title class="py-2 my-0" v-if="hasPermission('IMAGE_DETAIL_WRITE')">
        <v-row justify="center">
          <v-col cols="9">
            <v-text-field
              label="Add a note"
              outlined
              v-model="newNote"
              id="note-input"
              :error-messages="imageError.notes"
              @keydown.enter="addNote"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-btn
              color="primary"
              small
              fab
              @click="addNote"
              id="add-note-btn"
            >
              <v-icon>add</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-virtual-scroll
        :items="notes"
        :item-height="50"
        height="250"
      >
        <template v-slot="{ item, index }">
          <v-list-item>
            <v-list-item-content>
              {{item}}
            </v-list-item-content>
            <v-list-item-action>
              <v-btn v-if="hasPermission('IMAGE_DETAIL_WRITE')" icon @click="deleteNote(index)">
                <v-icon>delete</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
        </template>
      </v-virtual-scroll>
    </v-card>
  <!-- </v-container> -->
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import services from '@services';

export default {
  name: 'ImageEditForm',
  props: ['image', 'type'],
  data: () => ({
    newNote: '',
    notes: [],
    model: [],
    error: {
      message: '',
    },
  }),
  methods: {
    ...mapActions(['updateImage', 'setCurrentImage', 'setNotification', 'setImageError']),
    updateImageNote({
      cid,
      pid,
      iid,
      type,
      update,
    }) {
      return services.images.updateImageNote(cid, pid, iid, type, update);
    },
    async addNote() {
      const error = { ...this.imageError };
      if (this.newNote.length !== 0) {
        const currentImage = { ...this.image };
        const { length } = currentImage.process_tracking;
        this.notes.push(this.newNote);
        const updateData = {
          cid: currentImage.companyId,
          pid: currentImage.project_id,
          iid: currentImage.id,
          update: this.notes,
          type: this.type,
        };
        const response = await this.updateImageNote(updateData);
        if (response.status === 200) {
          // const { filename } = response.data;
          if (this.type === 'original') currentImage.notes = this.notes;
          else {
            currentImage.process_tracking[length - 1].notes = this.notes;
          }

          this.setCurrentImage(currentImage);

          const imageType = (this.type === 'original') ? 'image' : 'processed image';
          this.setNotification({ success: true, message: `You have successfully added a note to the ${imageType} with file name: ${currentImage.filename}` });
          this.newNote = '';
          if (this.imageError.notes) {
            error.notes = '';
            this.setImageError(error);
          }
        }
      } else {
        error.notes = 'You may not submit an empty text as a note.';
        this.setImageError(error);
      }
    },
    async deleteNote(index) {
      const currentImage = { ...this.image };
      const { length } = currentImage.process_tracking;
      const removedNote = this.notes.splice(index, 1);
      const updateData = {
        cid: this.currentCompany.cid,
        pid: this.currentProject.pid,
        iid: currentImage.id,
        update: this.notes,
        type: this.type,
      };
      const response = await this.updateImageNote(updateData);
      if (response.status === 200) {
        if (this.type === 'original') currentImage.notes = this.notes;
        else {
          currentImage.process_tracking[length - 1].notes = this.notes;
        }
        const { filename } = currentImage;
        const imageType = (this.type === 'original') ? 'image' : 'processed image';

        this.setNotification({
          success: true,
          message: `You have successfully removed the note "${removedNote}" from ${imageType} with file name ${filename}`,
        });
      }
    },
  },
  computed: {
    ...mapGetters(['imageError', 'hasPermission', 'currentCompany', 'currentProject']),
  },
  mounted() {
    const img = { ...this.image };
    if (this.type === 'ai') img.process_tracking[0].notes = Object.keys(img.process_tracking[0]).includes('notes') ? img.process_tracking[0].notes : [];
    if (this.type === 'original') {
      this.notes = img.notes ? img.notes : [];
    } else {
      this.notes = img.process_tracking[0].notes;
    }
  },
  watch: {
    image(newValue) {
      const img = { ...newValue };
      if (this.type === 'ai') img.process_tracking[0].notes = Object.keys(img.process_tracking[0]).includes('notes') ? img.process_tracking[0].notes : [];
      if (this.type === 'original') {
        this.notes = img.notes ? img.notes : [];
      } else {
        this.notes = img.process_tracking[0].notes;
      }
    },
  },
};
</script>
