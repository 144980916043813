<template>
  <v-container fluid>
    <v-expansion-panels popout id="faq-panels">
      <v-expansion-panel>
        <v-expansion-panel-header>
          What payment options are available?
          <template v-slot:actions>
            <v-icon color="primary">expand_more</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          To ensure timely payment for the monthly subscriptions, we're only accepting payment
          via credit cards for now. The primary card on file can be updated
          in the <b>Payment Methods</b> tab under <b>Billing</b> at anytime.
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          How do I change my subscription for Image Processing?
          <template v-slot:actions>
            <v-icon color="primary">expand_more</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          We have three different plans:
          <p>Gold Plan</p>
          <p>Silver Plan</p>
          <p>Bronze Plan</p>
          <p>Each of these have their own usage rates, and if the current one that is activated
            isn't working, no problem. Head on over to the <b>Billing</b> tab and navigate to the
            <b>Subscriptions</b>, and then <b>Switch Plans</b> sub tabs. There should be information
            regarding each of these plans to see which one best fits your use cases! </p>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          How do I activate my own Analytics Dashboard?
          <template v-slot:actions>
            <v-icon color="primary">expand_more</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          Activating the Analytics Dashboard is very simple. Click into the <b>Analytics</b> tab,
          and either <b>Insights</b> or <b>Settings</b> tab will navigate you straight to the
          purchasing page to start your services.
        </v-expansion-panel-content>
      </v-expansion-panel>
       <v-expansion-panel>
        <v-expansion-panel-header>
          I have more questions about PowerAI
          <template v-slot:actions>
            <v-icon color="primary">expand_more</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          We'd love to hear it! Please go to the <b>Report Issue</b> tab, prior to the
          <b>FAQS</b> tab and reach out. Our support team will be in touch as soon as possible.
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
export default { name: 'Faqs' };
</script>
