<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search Folder"
          single-line
          hide-details
          outlined
        ></v-text-field>
      </v-card-title>
      <v-data-table
        id="folders-list-table"
        :headers="headers"
        :items="setFolders(allFolders)"
        :loading="fetchingImages"
        :loadingText="loadingMessage"
        v-scroll:#folders-selector="onScroll"
        :search="search"
      >
        <template v-slot:item="{ item, index }">
          <tr
            :id="`folder-${index}`"
            @click="setFolder(item)"
            :class="(isSelected(item)) ? 'selected-folder' : 'not-selected-folder'"
          >
            <td>
              <v-btn icon text>
                <v-icon dark>mdi-folder</v-icon>
              </v-btn>
            </td>
            <td id="folder-path">
              <span>{{ item.path }}</span>
            </td>
            <td id="folder-date">
              <span>{{ item.date }}</span>
            </td>
            <td id="folder-process-image">
              <span>{{ item.processed_image_count }}</span>
            </td>
            <td id="folder-reviewed">
              <v-icon :color="(item.reviewed) ? 'primary' : ''">verified</v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import dashboard from '@components/dashboard/methods/';

export default {
  name: 'FolderSelector',
  data: () => ({
    headers: [
      { text: '' },
      {
        text: 'Folder',
        align: 'start',
        filterable: true,
        value: 'path',
      },
      { text: 'Created', value: 'date' },
      { text: 'Amount of Processed Images', value: 'processed_image_count' },
      { text: 'Reviewed', value: 'reviewed' },
    ],
    search: '',
    fetchingImages: false,
    errors: null,
    loadingMessage: '',
  }),
  methods: {
    ...mapActions(['setCurrentFolder', 'getImagesByFolder', 'setShowFolders', 'getImagesByProject', 'setCurrentImage', 'setDashboardFilters']),
    async setFolder(folder) {
      try {
        // If the value of newFolder has the path of __all__,
        // then just set it as the rootFolder for consistent formatting
        const newFolder = (folder.path === '__all__') ? this.rootFolder : folder;
        this.setCurrentFolder(newFolder);

        // If the folder path is __all__, then we look for
        // a response from the 'getImagesByProject' action
        // Otherwise we'll be looking to fetch images by project
        // let res;
        this.fetchingImages = true;
        this.loadingMessage = `Fetching images for the ${newFolder.path} structure...`;

        this.setDashboardFilters({
          type: 'folders',
          value: [newFolder.path],
        });
        this.setShowFolders(false);
        this.fetchingImages = false;
      } catch (err) {
        // An error occurs, so we are no longer loading anymore images
        this.fetchingImages = false;
        this.errors = err;
      }
    },

    // Formats the folder data so that it's consistent throughout the platform
    setFolders(folders) {
      const folderList = folders;

      // Checks if the folder list contains the root __all__
      // folder. If it does not then we place it at
      // the top of the folder list
      const hasAllFolder = folders.filter((folder) => folder.path === '__all__').length > 0;
      if (!hasAllFolder) folderList.unshift(this.rootFolder);

      // Format and return the newly formatted
      // folder list
      return folderList
        .map((folder) => dashboard.formatFolder(folder, this.allImages, this.currentProject));
    },
    isSelected(folder) {
      // If the currentFolder.path and the clicked folder path
      // match up, then we flag it as `true` to highlight
      // the clicked row as blue
      return this.currentFolder.path === folder.path;
    },
    onScroll(e) {
      // debounce if scrolling fast
      // eslint-disable-next-line no-unused-expressions
      this.timeout && clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        const { scrollTop } = e.target;
        const rows = Math.ceil(scrollTop / this.rowHeight);

        this.start = rows + this.perPage > this.allFolders.length
          ? this.allImages.length - this.perPage : rows;

        this.$nextTick(() => {
          e.target.scrollTop = scrollTop;
        });
      }, 20);
    },
  },
  computed: {
    ...mapGetters(['allFolders', 'rootFolder', 'allImages', 'currentProject', 'currentFolder']),
  },
};
</script>

<style scoped>
#folders-list-table{
  max-height: 250px;
  overflow: auto;
}
.selected-folder {
  cursor: pointer;
  background-color: #E3F2FD;
}
.not-selected-folder {
  cursor: pointer;
}
</style>
