<template>
    <img
      :src="currentImage.compressedUrl"
      ref="marker"
      width="100%"
    />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ReadOnlyAnnotator } from '@components/annotations/annotorious';

export default {
  name: 'FilterableAnnotoriousRenderer',
  data: () => ({
    anno: null,
  }),
  computed: {
    ...mapGetters(['currentLabelFilter', 'currentImage', 'currentProcessedImage', 'currentAnnotations', 'small', 'extraSmall', 'flatProjectLabels']),
    filteredAnnotations() {
      const filteredAnnos = this.currentAnnotations.filter((anno) => {
        // const severityBody = anno.body.find((b) => b.purpose === 'severity');
        const labelBody = anno.body.find((b) => b.purpose === 'labeling');
        // let hasSeverity;
        let hasLabel;

        // if (!severityBody) hasSeverity = false;
        // else hasSeverity = true;

        if (!labelBody) hasLabel = false;
        else hasLabel = this.currentLabelFilter.includes(labelBody.value);
        if (this.currentLabelFilter.length === 0) hasLabel = true;

        return hasLabel;
      });
      return filteredAnnos;
    },
    isMobile() {
      return this.small || this.extraSmall;
    },
  },
  methods: {
    initAnnotations() {
      const { marker } = this.$refs;
      this.anno = ReadOnlyAnnotator(marker, this.flatProjectLabels);
      this.anno.setAnnotations(this.filteredAnnotations);
    },
    async refreshAnnotator() {
      this.anno.destroy();
      this.initAnnotations();
      await new Promise((r) => setTimeout(r, 1));
      this.anno.destroy();
      this.initAnnotations();
    },
    ...mapActions(['emptyLabelFilter']),
  },
  mounted() {
    this.initAnnotations();
  },
  watch: {
    async currentAnnotations() {
      await this.refreshAnnotator();
      this.emptyLabelFilter();
    },
    async currentLabelFilter() {
      await this.refreshAnnotator();
    },
  },
};
</script>

<style scoped>
.center-image {
  text-align: center;
}
</style>>
