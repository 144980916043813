<template>
  <v-dialog v-model="dialog">
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn icon color="primary" v-on="{...tooltip, ...dialog}" v-bind="attrs">
            <v-icon>{{ icon }}</v-icon>
          </v-btn>
        </template>
        {{ buttonText }}
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="headline">{{ title }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <slot></slot>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="dialog=false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogTest',
  props: {
    title: {
      type: String,
      default: 'Untitled Dialog',
    },
    buttonText: {
      type: String,
      default: 'N/A',
    },
    icon: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    dialog: false,
  }),
};
</script>
