/* eslint-disable consistent-return */

const menuWidget = (menuLabels) => (args) => {
  // 1. Find the current label setting in the annotation, if any
  const currentLabelBody = args.annotation
    ? args.annotation.bodies.find((b) => b.purpose === 'labeling') : null;

  // 4. Keep value in a variable
  const currentLabelValue = currentLabelBody ? currentLabelBody.value : null;

  // 5. Triggers callbacks on user actions
  const addTag = (newLabel) => {
    const labelBody = { type: 'TextualBody', purpose: 'labeling', value: newLabel };

    if (currentLabelBody) {
      args.onUpdateBody(currentLabelBody, labelBody);
    } else {
      args.onAppendBody(labelBody);
    }
  };

  // 4. Renders the UI elements
  // The container that holds the widget components
  const container = document.createElement('ul');
  container.id = 'anno-menu-widget';

  // currently selected
  const currentLabel = document.createElement('li');
  currentLabel.className = 'menu-widget-grand-parent';
  const currentLabelLink = document.createElement('a');
  currentLabelLink.className = 'menu-widget-grand-parent-link';
  currentLabelLink.innerText = currentLabelValue === null ? 'Click to select a label' : currentLabelValue;
  currentLabel.appendChild(currentLabelLink);
  // anytime an li is clicked
  currentLabel.addEventListener('click', (ev) => {
    const toggleMenu = () => {
      const catElements = document.getElementsByClassName('menu-widget-cat');
      Array.from(catElements).forEach((ele) => {
        const e = ele;
        if (e.style.display === '' && e.style.position === '') {
          e.style.display = 'block';
          e.style.position = 'absolute';
        } else {
          e.style.display = '';
          e.style.position = '';
        }
      });
    };
    if (['menu-widget-grand-parent', 'menu-widget-grand-parent-link'].includes(ev.path[0].className)) {
      // when the main menu is clicked
      toggleMenu();
    } else if (['menu-widget-single', 'menu-widget-child-li', 'menu-widget-single-link', 'menu-widget-child-link'].includes(ev.path[0].className)) {
      // when an actual label is clicked.
      const clickedLabel = ev.path[0].getAttribute('label');
      const currentLabelNodes = document.getElementsByClassName('menu-widget-grand-parent-link');
      currentLabelNodes[0].innerText = clickedLabel;
      addTag(clickedLabel);
      toggleMenu();
    }
  });

  // categories children container
  const labelCategories = document.createElement('ul');
  labelCategories.className = 'menu-widget-child menu-widget-cat';

  menuLabels.forEach((menu) => {
    const singleCategory = document.createElement('li');
    if (menu.labels.length > 0) {
      // single category text container
      singleCategory.className = 'menu-widget-parent';
      const singleCategoryLink = document.createElement('a');
      singleCategoryLink.className = 'menu-widget-parent-link';
      singleCategoryLink.innerText = menu.category.faultType;
      const singleCategoryLinkExpand = document.createElement('span');
      singleCategoryLinkExpand.className = 'menu-widget-expand';
      singleCategoryLinkExpand.innerHTML = '&#8250;';
      singleCategoryLink.appendChild(singleCategoryLinkExpand);
      singleCategory.appendChild(singleCategoryLink);

      // singel category children container
      const singleCategoryChildren = document.createElement('ul');
      singleCategoryChildren.className = 'menu-widget-child';

      // attach labels in single category
      menu.labels.forEach((label) => {
        const categoryLabel = document.createElement('li');
        categoryLabel.className = 'menu-widget-child-li';
        const categoryLabelLink = document.createElement('a');
        categoryLabelLink.className = 'menu-widget-child-link';
        // put the label in attribute of link and li
        categoryLabel.setAttribute('label', label.faultType);
        categoryLabelLink.setAttribute('label', label.faultType);
        categoryLabelLink.innerText = label.faultType;
        categoryLabel.appendChild(categoryLabelLink);
        singleCategoryChildren.appendChild(categoryLabel);
      });
      singleCategory.appendChild(singleCategoryChildren);
    } else {
      // single category text container
      singleCategory.className = 'menu-widget-single';
      const singleCategoryLink = document.createElement('a');
      singleCategoryLink.className = 'menu-widget-single-link';
      // put the label in attribute of link and li
      singleCategory.setAttribute('label', menu.category.faultType);
      singleCategoryLink.setAttribute('label', menu.category.faultType);
      singleCategoryLink.innerText = menu.category.faultType;
      singleCategory.appendChild(singleCategoryLink);
    }
    labelCategories.appendChild(singleCategory);
  });

  currentLabel.appendChild(labelCategories);

  container.appendChild(currentLabel);

  return container;
};

export default {
  menuWidget,
};
