<template>
    <v-btn
      v-if="isVisible"
      id="back-to-top-btn"
      color="primary"
      fab
      fixed
      bottom
      right
      @click="$vuetify.goTo(target, options)"
    ><v-icon>mdi-chevron-up</v-icon></v-btn>
</template>

<script>

export default {
  name: 'ScrollToTop',
  props: ['target', 'options'],
  data: () => ({
    isVisible: false,
  }),
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      const h = window.outerHeight;
      const offsetTop = window.pageYOffset || document.documentElement.scrollTop;
      this.isVisible = offsetTop > h;
    },
  },
};
</script>
