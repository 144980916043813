export default [
  {
    text: 'Team',
    align: 'start',
    sortable: true,
    value: 'name',
  },
  {
    text: 'Members',
    sortable: true,
    value: 'members',
  },
  {
    text: 'Projects',
    sortable: true,
    value: 'projects',
  },
  {
    text: 'Actions',
    value: 'actions',
    sortable: false,
  },
];
