import platformBackendClient from '@backend-clients/platform-backend';

export default {
  /*
    |---------------------------------------------|
    |   @params   storage <String>, payload       |
    |             <Object>                        |
    |   @desc     Passes in the specified storage |
    |             in as a request parameter and   |
    |             the bucket name as a request    |
    |             body to create a new bucket     |
    |---------------------------------------------|
  */
  create: (storage, payload) => platformBackendClient.post(`/api/upload/${storage}`, payload),
  /*
    |---------------------------------------------|
    |   @params   storage <String>, bucket        |
    |             <String>, payload <Object>      |
    |   @desc     Creates new signed urls for file|
    |             uploads                         |
    |---------------------------------------------|
  */
  signed_url: (bucket, payload) => platformBackendClient.post(`/api/upload/signed_url/bucket/${bucket}`, payload),
  storage_create: (bucketName) => platformBackendClient.post(`/api/upload/create_bucket/${bucketName}`),

  s3: {
    signed_url: (bucket, payload) => platformBackendClient.post(`/api/upload/s3/signed-url/${bucket}`, payload),
    create: (payload) => platformBackendClient.post('/api/upload/s3', payload),
    bucket_location: (bucket) => platformBackendClient.get(`/api/upload/s3/location/${bucket}`),
  },
};
