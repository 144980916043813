<template>
  <v-dialog v-model="isShown" width="90vw">
    <v-card>
      <v-card-title>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                id="metadata-list-search"
                outlined
                v-model="search"
                append-icon="mdi-magnify"
                label="Search MetaData"
                single-line
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-data-table
        id="metadata-list-table"
        :loading="loadingMetas"
        :items="metaDatas"
        :headers="headers"
        class="elevation-1"
        :search="search"
        :sort-by="['date']"
        :sort-desc="[true]"
        v-scroll:#images-list-table="onScroll"
        :footer-props="footer"
      >
      <template v-slot:item="{  item }">
        <tr>
          <td>
            <v-btn icon text><v-icon dark>mdi-file</v-icon></v-btn>
          </td>
          <td>{{item.nice_date}}</td>
          <td>{{item.filename}}</td>
          <td><v-btn text color="primary" @click="emitMetaUrl(item)"> USE </v-btn></td>
        </tr>
      </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="emitCancel">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import helpers from '@methods/helpers';

export default {
  name: 'MetaDataFromURL',
  props: {
    isShown: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: '',
      headers: [
        {},
        {
          text: 'File Name',
          align: 'start',
          sortable: false,
          value: 'filename',
        },
        {
          text: 'Date',
          value: 'nice_date',
        },
      ],
      footer: {
        showFirstLastPage: true,
        firstIcon: 'mdi-page-first',
        lastIcon: 'mdi-page-last',
        prevIcon: 'mdi-chevron-left',
        nextIcon: 'mdi-chevron-right',
        'items-per-page-options': [10, 50, 100, 200, 500],
      },
    };
  },
  methods: {
    ...mapActions(['fetchMeta']),
    emitMetaUrl(meta) {
      this.$emit('setMetaUrl', meta.url);
    },
    emitCancel() {
      this.$emit('cancel');
    },
    onScroll(e) {
      // debounce if scrolling fast
      // eslint-disable-next-line no-unused-expressions
      this.timeout && clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        const { scrollTop } = e.target;
        const rows = Math.ceil(scrollTop / this.rowHeight);

        this.start = rows + this.perPage > this.metaDataFiles.length
          ? this.metaDataFiles.length - this.perPage : rows;

        this.$nextTick(() => {
          e.target.scrollTop = scrollTop;
        });
      }, 20);
    },
  },
  computed: {
    ...mapGetters(['loadingMetas', 'metaDataFiles', 'currentProject', 'currentCompany']),
    metaDatas() {
      return this.metaDataFiles.map((meta) => ({
        ...meta,
        nice_date: helpers.convertToDate(new Date(meta.date)).format('dddd, MMMM Do YYYY, h:mm:ss a'),
        filename: meta.url.split('/').slice(-1)[0],
      }));
    },
  },
  watch: {
    isShown() {
      if (this.metaDataFiles.length === 0 && this.isShown) {
        this.fetchMeta({
          cid: this.currentCompany.cid,
          pid: this.currentProject.pid,
        });
      }
    },
  },
};
</script>
