<template>
  <div id="collapsible-components">
    <ExpansionPanel header="Reports Detail">
      <ReportsDetails />
    </ExpansionPanel>
    <ExpansionPanel header="Label References">
      <LabelReferenceTable />
    </ExpansionPanel>
    <ExpansionPanel header="Select Images By Structures">
      <FileManagement type="ai"/>
    </ExpansionPanel>
  </div>
</template>

<script>
import ExpansionPanel from '@components/common/ExpansionPanel.vue';
import ReportsDetails from '@components/dashboard/collapsible-content/ReportsDetails.vue';
import LabelReferenceTable from '@components/dashboard/collapsible-content/LabelReferenceTable.vue';
import FileManagement from '@components/dashboard/file-management/FileManagement.vue';

export default {
  name: 'Collapsibles',
  props: ['images', 'pid'],
  components: {
    ExpansionPanel,
    ReportsDetails,
    LabelReferenceTable,
    FileManagement,
  },
};
</script>
