const state = {
  time: 0,
  timer: null,
};

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const getters = {
  timeSpent: (state) => state.time,
};

const actions = {
  startTimer({ commit, state }) {
    if (state.timer === null || state.timer === undefined) {
      const timer = setInterval(() => commit('add_time'), 1000);
      commit('set_timer', timer);
    }
  },
  stopTimer({ commit, state }) {
    if (state.timer !== null || state.timer !== undefined) clearInterval(state.timer);
    commit('set_timer', null);
  },
  resetTimer({ commit, state }) {
    if (state.timer !== null || state.timer !== undefined) clearInterval(state.timer);
    commit('reset_time');
  },
};

const mutations = {
  reset_time: (state) => {
    state.time = 0;
    state.timer = null;
  },
  add_time: (state) => {
    state.time += 1;
  },
  set_timer: (state, timer) => {
    state.timer = timer;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
