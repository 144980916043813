<template>
  <v-container fluid>
    You do not have admin rights
  </v-container>
</template>

<script>
export default {
  name: 'NoAdminAccess',
};
</script>
