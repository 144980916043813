<template>
  <v-card
    max-width="400"
    class="mx-auto d-block"
  >
    <v-card-title class="headline">
      {{ price.nickname }}
    </v-card-title>
    <v-card-subtitle
      class="subtitle"
      v-if="subscription.plan.nickname === price.nickname"
    >
      Current Plan
    </v-card-subtitle>
    <v-divider></v-divider>
    <v-list-item
      v-for="(service, index) in services"
      :key="index"
    >
      <v-list-item-title class="service-of-plan">{{ service }}</v-list-item-title>
    </v-list-item>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-dialog v-model="requestDialog" width="700">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            class="request-btn"
            text
            :disabled="subscription.plan.nickname === price.nickname"
            v-on="on"
            v-bind="attrs"
          >
            Request Plan Change
          </v-btn>
        </template>
       <PlanRequestDialog
        :newPlan="price"
        :oldPlan="subscription"
        :newServices="services"
        :currentServices="currentServices"
        v-on:close="closeRequestDialog"
      />
      </v-dialog>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import PlanRequestDialog from '@views/billing_views/subscription_plans/PlanRequestDialog.vue';
import { toLowerFirst } from '@methods/helpers';
import plans from '../../../components/billing/subscriptions/plans';

export default {
  name: 'PlanCard',
  components: {
    PlanRequestDialog,
  },
  props: ['services', 'subscription', 'price', 'currentServices'],
  data: () => ({
    requestDialog: false,
  }),
  methods: {
    closeRequestDialog() { this.requestDialog = false; },
    getServices: (name) => plans[toLowerFirst(name)],
  },
  computed: {
    ...mapGetters(['currentSubscription']),
  },
};
</script>
