<template>
<v-container fluid>
  <v-card>
    <v-card-title class="headline" id="cost-trend-title">Cost Trend</v-card-title>
    <v-card-subtitle class="subtitle"></v-card-subtitle>
    <v-card-text>
      <p class="font-weight-medium">Average monthly total cost</p>
      <p class="font-weight-bold" id="cost-trend-average">${{average(values)}}</p>
    </v-card-text>
    <BarChart
      :title="(false) ? 'No billing data' : 'Monthly Costs'"
      :labels="labels"
      :values="values"
    />
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn small text color="primary" to="/billing/reports">View Report</v-btn>
    </v-card-actions>
  </v-card>
</v-container>
</template>

<script>
import BarChart from '@components/charts/BarChart.vue';

export default {
  name: 'CostTrend',
  props: ['labels', 'values'],
  components: {
    BarChart,
  },
  methods: {
    average(values) {
      const sum = values.reduce((a, b) => a + b, 0);
      return new Intl.NumberFormat().format(sum / values.length);
    },
  },
};
</script>
