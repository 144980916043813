<template>
  <v-card tile>
    <v-navigation-drawer
      v-model="drawer"
      clipped
      dark
      width="224"
      app
      id="sidebar"
      :permanent="(isMobile) ? false : drawerShown"
      :bottom="isMobile"
      @input="drawerChange"
    >
      <v-list dense nav class="py-0">
        <v-list-item two-line class="px-0">
          <v-list-item-avatar color="primary" id="avatar">
            <span class="white--text headline" id="initials">{{initials}}</span>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title id="full-name-display">
              {{`${this.currentUser.firstName} ${this.currentUser.lastName}`}}
            </v-list-item-title>
            <v-list-item-subtitle id="company-name-displace">
              {{currentCompany.companyName}}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <div v-for="item in sidebarLinks" :key="item.title">
          <v-list-item
            :id="item.id"
            v-if="!Object.keys(item).includes('children')"
            :to="item.path"
            link
            class="sidebar-tabs"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="side-title">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <ListGroupItem
            v-else
            :icon="item.icon"
            :title="item.title"
            :children="item.children"
            :id="item.id"
          />
        </div>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import links from '@components/layouts/links';
import ListGroupItem from '@components/common/ListGroupItem.vue';

export default {
  name: 'NewSideBar',
  props: ['drawerShown'],
  components: {
    ListGroupItem,
  },
  data() {
    return {
      drawer: false,
      tabs: links.side,
    };
  },
  computed: {
    ...mapGetters(['isDrift', 'isAdmin', 'isTeamLead', 'currentUser', 'currentCompany', 'small', 'extraSmall', 'isMobile', 'companyConfig']),
    initials() {
      const { name } = this.currentUser;
      const nameSplit = name.split(' ');

      return `${nameSplit[0][0]}${nameSplit[1][0]}`;
    },
    isMobile() {
      return this.extraSmall || this.small;
    },
    sidebarLinks() {
      let { side } = links;
      // Find indices of the company and admin tab
      // DO NOT USE THESE INDEXES BECAUSE IT WILL FAIL TO RENDER SIDEBAR WHEN RESIZING THE SCREEN
      // const companyIndex = side.findIndex((tab) => tab.title === 'Company');
      // const adminIndex = side.findIndex((tab) => tab.title === 'Admin');

      // If the current user is the instance admin, then pass in
      // a dynamically created router path
      // Otherwise if companyIndex isn't -1 (not found), then remove it from array
      if (this.isAdmin || this.isTeamLead) {
        side[2].path = `/company/${this.currentUser.cid}/employees`;
        if (this.isTeamLead) {
          side = side.filter(({ title }) => title !== 'Billing');
        }
        if (this.isDrift && this.isAdmin) {
          side[2].title = 'Admin';
        }
      } else {
        const filters = ['Company'];
        if (this.companyConfig('disable_user_billing_access')) {
          filters.push('Billing');
        }
        side = side.filter(({ title }) => !filters.includes(title));
      }

      // Checks if the user is a buzzAdmin
      const isBuzzAdmin = Object.keys(this.currentUser).includes('buzzAdmin') && this.currentUser.buzzAdmin;
      // If user isn't a buzzAdmin, remove the buzz admin tab from the array
      if (!isBuzzAdmin) {
        // side.splice(5, 1);
        side = side.filter(({ title }) => title !== 'Buzz Admin');
      }
      // If the browser is on mobile device, concatenate the auth links into the side bar for it
      // to appear as a bottom bar
      const tabs = (this.isMobile) ? side.concat(links.auth) : side;

      if (this.isTrial()) {
        return tabs.filter((tab) => tab.id !== 'billing-tab');
      }
      return tabs;
    },
  },
  watch: {
    drawerShown(isDrawer) { this.drawer = isDrawer; },
  },
  methods: {
    drawerChange(tog) {
      this.$emit('drawerChange', tog);
    },
    isTrial() {
      return this.currentCompany.companyMode === 1;
    },
  },
};
</script>
