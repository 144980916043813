const colorCoding = {
  'n/a': '',
  '#4CAF50': 'None',
  '#2196F3': 'Low',
  '#ffeb3b': 'Medium',
  '#F44336': 'High',
  black: 'Critical',
};
//                  0     1       2       3       4
const severities = ['', 'None', 'Low', 'Medium', 'High', 'Critical'];

export default (markers) => {
  // Gets the number of markers within the cluster
  const count = markers.length;

  // Within the cluster of markers, map the color coding of the marker to an array
  // of severity levels from 0 to 4 ('' - 'High')
  const markerSeverities = markers.map((marker) => {
    const { fillColor } = marker.getIcon();
    const severity = (fillColor) ? colorCoding[fillColor] : colorCoding['n/a'];
    return severities.findIndex((s) => s === severity);
  });

  // Computes the highest severity level
  const highest = Math.max(...markerSeverities);

  return {
    text: `${count}`,
    // Add one to it because it doesn't seem like gmaps marker clusterer
    // starts at the 0th index
    index: highest + 1,
  };
};
