<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="headline">
        Edit Project
        <v-spacer></v-spacer>
        <v-checkbox
          v-if="showPrototyping"
          :value="prototype"
          @change="prototypeHandler"
          id="set-prototyping-project"
          label="Prototyping Project?"
        ></v-checkbox>
      </v-card-title>
      <v-container fluid>
      <v-row justify="center">
        <v-col cols="6">
          <v-text-field
            label="Project Name"
            outlined
            :value="name"
            @input="projectNameHandler"
            dense
            id="project-name-edit"
            class="mx-auto"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Location"
            prepend-icon="mdi-map-marker"
            @input="projectLocationHandler"
            outlined
            dense
            :value="location"
            id="project-location-edit"
            class="mx-auto"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-textarea
            :value="description"
            outlined
            dense
            @input="projectDescriptionHandler"
            label="Description"
            id="project-description-edit"
            class="mx-auto"
          ></v-textarea>
        </v-col>
        <v-col cols="6">
          <DatePicker
            class="project-date project-input"
            label="Inspection Date"
            id="inspection-date"
            v-on:dateInput="getDate"
            :iDate="date"
            :projectsLoading="loadingProject"
          />
          <v-select
            outlined
            dense
            label="Teams"
            clearable
            chips
            clear-icon="clear"
            @change="teamsChangeHandler"
            :value="teams"
            :items="items"
            :loading="loading"
            id="select-teams"
            prepend-icon="mdi-account-multiple"
            item-text="name"
            return-object
            multiple
            detectable-chips
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            outlined
            dense
            label="Reviewer"
            clearable
            clear-icon="clear"
            @change="reviewerChangeHandler"
            :value="first_reviewer"
            :items="reviewers"
            :loading="loadingUsers"
            id="select-first-reviewer"
            item-text="name"
            return-object
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            outlined
            dense
            :value="project_type"
            @change="projectTypeHandler"
            label="Project Type"
            :items="['Transmission', 'Distribution', 'Other']"
            id="project-type-selector"
          ></v-select>
        </v-col>
      </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :to="`/project/${project.pid}`"
          id="project-back-btn"
        >Cancel</v-btn>
        <v-btn
          color="primary"
          @click="onClickUpdateProject"
          id="update-project-btn"
        >Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DatePicker from '@components/common/DatePicker.vue';
import helpers from '@methods/helpers';
import services from '@services';
import dateFormat from '@utils/time-formats';
import moment from 'moment/moment';

export default {
  name: 'EditProjectForm',
  props: ['project'],
  components: {
    DatePicker,
  },
  data: () => ({
    name: '',
    prototype: false,
    teams: [],
    location: '',
    description: '',
    date: '',
    items: [],
    first_reviewer: {},
    project_type: '',
    reviewers: [],
    updates: {},
    loading: true,
    loadingUsers: false,
  }),
  methods: {
    ...mapActions(['updateProject', 'setNotification']),
    getDate(date) {
      this.updates.date = date;
    },
    projectNameHandler(name) {
      this.updates.name = name;
    },
    projectLocationHandler(location) {
      this.updates.location = location;
    },
    projectDescriptionHandler(description) {
      this.updates.description = description;
    },
    teamsChangeHandler(teams) {
      this.updates.teams = teams;
    },
    reviewerChangeHandler(reviewer) {
      if (reviewer) {
        this.updates.first_reviewer = {
          uid: reviewer.uid,
          firestore_uid: reviewer.firestore_uid,
        };
      } else this.updates.first_reviewer = null;
    },
    projectTypeHandler(type) {
      this.updates.project_type = type;
    },
    prototypeHandler(isPrototype) {
      this.updates.prototyping = (isPrototype) || false;
    },
    onClickUpdateProject() {
      const updatedProject = {
        cid: this.project.cid,
        pid: this.project.pid,
        name: this.name,
        description: this.description,
        location: this.location,
        date: this.date,
        teams: this.teams.map((team) => helpers.getDocReference('teams', team.tid)),
        project_type: this.project_type,
        prototyping: this.prototype,
        uid: this.currentUser.uid,
      };

      if (Object.keys(this.first_reviewer).length > 0) {
        updatedProject.first_reviewer = this.first_reviewer;
      }
      const payload = {
        id: this.project.pid,
        pid: this.project.firestore_pid,
        company_id: this.currentCompany.cid,
        updates: this.updates,
      };
      if (this.updates.date) {
        this.updates.date = moment(this.updates.date, 'YYYY-MM-DD');
      }
      this.updateProject(payload)
        .then((res) => {
          this.setNotification({
            success: true,
            message: `Successfully updated the ${res.data.name} project`,
            color: '',
          });
        });
    },
  },
  computed: {
    ...mapGetters(['loadingProject', 'currentUser', 'companyHas', 'currentProject', 'currentCompany']),
    showPrototyping() {
      return this.currentUser.buzzAdmin && this.currentUser.buzzAdmin !== null;
    },
  },
  mounted() {
    this.name = this.currentProject.name;
    this.description = this.currentProject.description;
    this.location = this.currentProject.location;
    this.date = dateFormat(this.currentProject.date, 'YEAR_MONTH_DATE');
    this.teams = this.currentProject.teams;
    this.first_reviewer = (this.currentProject.first_reviewer)
      ? {
        name: this.currentProject.first_reviewer.name,
        uid: this.currentProject.first_reviewer.uid,
        email: this.currentProject.first_reviewer.email,
      }
      : {};
    this.project_type = (this.currentProject.project_type) ? this.currentProject.project_type : '';
    this.prototype = (this.currentProject.prototyping) ? this.currentProject.prototyping : false;
  },
  async created() {
    this.loading = true;
    this.loadingUsers = true;

    services.teams.all(`company_id=${this.currentUser.cid}`)
      .then((res) => {
        this.items = res.data.map((team) => ({
          name: team.name,
          tid: team.tid,
          firestore_tid: team.firestore_tid,
        }));
        this.loading = false;
      });

    services.users.all(`company_id=${this.currentUser.cid}`)
      .then((res) => {
        this.reviewers = [...res.data].map((user) => ({
          email: user.email,
          name: `${user.firstName} ${user.lastName}`,
          uid: user.uid,
          firestore_uid: user.firestore_uid,
        }));
        this.loadingUsers = false;
      });
  },
};
</script>
