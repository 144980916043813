<template>
  <v-card>
    <v-card-title class="headline">
      <v-icon color="warning" class="mr-2">warning</v-icon>
      Duplicate Image Processing Detected
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text style="padding-top: 20px">
      <span class="mb-2">
        One or more images has already been processed:
      </span>
      <br />
      <div v-if="duplicates.length" style="margin: 10px 0px">
        <div class="image-list-item" v-for="(image, i) in duplicates" :key="i">
          {{ image.filename }}
        </div>
        <div v-if="duplicates.length > 10">...</div>
      </div>
      <span>
        Please confirm below that you'd like to proceed with the image processing.
        The image will still be charged as usual.
      </span>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        olor="primary"
        text
        @click="close"
      >Close</v-btn>
      <v-btn
        color="primary"
        text
        @click="confirm"
      >Confirm</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'DuplicateImageProcessWarning',
  methods: {
    close() {
      this.$emit('closeWarning');
    },
    confirm() {
      this.$emit('confirm');
    },
  },
  computed: {
    duplicates: (vm) => vm.$store.state.images.selected
      .filter((i) => i.processedImageUrl)
      .slice(0, 10),
  },
};
</script>
