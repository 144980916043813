<template>
  <v-card flat class="mb-12" height="200px">
    <v-card-title class="headline">Your password has successfully been resetted</v-card-title>
    <v-card-text>Please navigate back to login page</v-card-text>
    <v-card-actions>
      <v-btn text color="primary" to="/login">Go to Login</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'PasswordResetComplete',
};
</script>
