export const all = ['Cross Arm Damaged', 'Brace bent, missing or hanging', 'Cross Arm Good', 'Ground Bonds Broken', 'Sag', 'Quick Sleeves', 'Missing Line Guards', 'Tie Wires or Clamps Broken', 'Conductor Good', 'Conductor Damaged', 'Floating', 'Warning Markers Missing', 'Broken Strands', 'Warning Markers Broken', 'Conductor Corroded', 'Rodent Guard', 'Leaking/Weeping', 'PCB Label Missing', 'Blown Fuse', 'Cracked/Broken Bushing', 'Mounting Bracket Split', 'Thin Wall Transformer Bracket', 'Rusting', 'Tank Ungrounded', 'Stirrups Missing', 'Connectors Damaged', 'Connectors Corroded', 'Joint Rusting', '8080 Insulator Good', 'Porcelain Insulators Broken ', 'Porcelain Insulator Flashed', 'Porcelain Insulators Good', '8080 Insulator Damaged', 'Stand-Off Bracket', 'Porcelain Insulators Contaminated', 'Missing', 'Failed', 'Ungrounded', 'Glass Insulators Broken', 'Glass Insulators Contaminated', 'Glass Insulators Good', 'Deck Damaged', 'Brace Damaged', 'Pole Rot', 'Pole Crib', 'Damaged', 'Wood Pole Cavities', 'Pole Ground', 'Pole', 'Wood Pole Cap Problems', 'Lean', 'Double Pole', 'Unauthorized Attachments', 'Backfilling', 'Rust', 'EPAC Insulator Damaged', 'Polymer Insulators Contaminated', 'Polymer Insulator Broken', 'EPAC Insulator Good', 'Polymer Insulator Good', 'Polymer Insulator Flashed', 'Switch Damaged', 'Switch Ungrounded', 'Label Missing', 'Brush Clearing', 'Tree Trimming', 'Cotter Pin Missing_Loose', 'Ball Link Eye Bolt', 'Misaligned Hardware', 'Cotter Pin Good', 'Misaligned Amor Grips', 'Hot Clamp', 'Dampers Damaged', 'Loose Guard', 'Preform Rusting', 'Broken Guard', 'Anchor Buried', 'Guard Missing', 'Preform Unraveling ', 'Broken Rod or Fitting', 'Ungrounded/Uninsulated'];

export const structure = [
  'Wood Pole Cap Problems',
  'Wood Pole Cavities',
];

export const cross = [
  'Cross Arm Damaged',
];

export const equipment = [
  'Conductor Damaged',
  'Connectors Corroded',
  'Cotter Pin Missing Loose',
  'Dampers Damaged',
  'Glass Insulators Broken',
  'Glass Insulators Contaminated',
  'Ground Bonds Broken',
  'Misaligned Armor Grips',
  'Misaligned Hardware',
  'Misaligned Insulators',
  'Nests',
  'Polymer Insulators Contaminated',
  'Polymer Insulators Flashed',
  'Porcelain Insulators Broken',
  'Porcelain Insulators Contaminated',
  'Porcelain Insulators Flashed',
  'Rust',
];
export const custom = [];
