<template>
  <v-container fluid>
    <v-skeleton-loader
      v-bind="attrs"
      type="table-heading"
    ></v-skeleton-loader>
    <StructureImageLoader :attrs="attrs"/>
    <MapSectionLoader :attrs="attrs" />
  </v-container>
</template>

<script>
import StructureImageLoader from '@components/common/loaders/insights/StructureImageLoader.vue';
import MapSectionLoader from '@components/common/loaders/insights/MapSectionLoader.vue';

export default {
  name: 'InsightsLoader',
  components: {
    StructureImageLoader,
    MapSectionLoader,
  },
  data: () => ({
    attrs: {
      class: 'mb-6',
      boilerplate: false,
      elevation: 2,
    },
  }),
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
};
</script>
