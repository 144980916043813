import services from '@services';

const state = {
  teams: [],
  team: {},
  loading: false,
  loadingTeam: false,
};

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const getters = {
  allTeams: (state) => state.teams,
  currentTeam: (state) => state.team,
  loadingTeam: (state) => state.loadingTeam,
  loadingTeams: (state) => state.loading,
};

const actions = {
  clearTeams({ commit }) {
    commit('clear_teams');
  },
  getTeam({ commit }, tid) {
    return new Promise((resolve, reject) => {
      services.teams.get(tid)
        .then((res) => {
          commit('fetch_team', res.data);
          commit('loading_team', false);
          resolve(res);
        })
        .catch((err) => {
          commit('loading_team', false);
          reject(err);
        });
    });
  },
  getTeams({ commit }, query) {
    commit('set_loading_teams', true);
    return new Promise((resolve, reject) => {
      services.teams.all(query)
        .then((res) => {
          commit('fetch_teams', res.data);
          commit('set_loading_teams', false);
          resolve(res);
        })
        .catch((err) => {
          commit('set_loading_teams', false);
          reject(err);
        });
    });
  },
  createTeam({ commit }, payload) {
    return new Promise((resolve, reject) => {
      services.teams.create(payload.id, payload.cid, payload.data)
        .then((res) => {
          commit('create_team', res.data);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateTeam({ commit }, payload) {
    return new Promise((resolve, reject) => {
      services.teams.update(payload.cid, payload.id, payload.tid, payload.data)
        .then((res) => {
          commit('update_team', res.data);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteTeam({ commit }, payload) {
    return new Promise((resolve, reject) => {
      services.teams.delete(payload.cid, payload.id, payload.tid)
        .then((res) => {
          commit('remove_team', res.data.tid);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  clear_teams: (state) => {
    state.teams = [];
  },
  set_loading_teams: (state, loading) => {
    state.loading = loading;
  },
  finish_loading_teams: (state) => {
    state.loading = false;
  },
  fetch_teams: (state, teams) => {
    state.teams = teams;
  },
  create_team: (state, newTeam) => {
    state.teams.unshift(newTeam);
  },
  fetch_team: (state, team) => {
    state.team = team;
  },
  remove_team: (state, tid) => {
    state.teams = state.teams.filter((team) => team.tid !== tid);
  },
  update_team: (state, updatedTeam) => {
    const index = state.teams.findIndex((team) => team.tid === updatedTeam.tid);
    if (index !== -1) state.teams.splice(index, 1, updatedTeam);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
