const state = {
  progress: false,
  title: '',
  total: 0,
  completed: 0,
};

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const getters = {
  progressOn: (state) => state.progress,
  progressTitle: (state) => state.title,
  progressPercentge: (state) => Math.floor((state.completed / state.total) * 100),
  progressCompleted: (state) => state.completed,
  progressTotal: (state) => state.total,
};

const actions = {
  // set progress to true or false
  setProgressStatus({ commit }, status) {
    commit('set_progress', status);
  },
  // title of progress component
  setTitle({ commit }, title) {
    commit('set_title', title);
  },
  // total of expected
  setTotal({ commit }, total) {
    commit('set_total', total);
  },
  // how much progress has been completed
  addProgressCompleted({ commit }, completed) {
    commit('add_completed', completed);
  },
  // how much progress has been completed
  setProgressCompleted({ commit }, completed) {
    commit('set_completed', completed);
  },
  // initialize progress component
  initProgress({ dispatch }, progressData) {
    const { title, total } = progressData;
    dispatch('setTitle', title);
    dispatch('setTotal', total);
    dispatch('setProgressStatus', true);
  },
  // end progress component
  endProgress({ dispatch }) {
    dispatch('setTitle', '');
    dispatch('setTotal', 0);
    dispatch('setProgressCompleted', 0);
    dispatch('setProgressStatus', false);
  },
};

const mutations = {
  set_progress: (state, status) => {
    state.progress = status;
  },
  set_title: (state, title) => {
    state.title = title;
  },
  set_total: (state, total) => {
    state.total = total;
  },
  set_completed: (state, completed) => {
    state.completed = completed;
  },
  add_completed: (state, completed) => {
    state.completed += completed;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
