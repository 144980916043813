<template>
  <v-app>
    <Loading v-if="loading"/>
    <div v-else>
      <NewSideBar
        :drawerShown="drawer"
        v-if="shouldShowSidebar"
        v-on:drawerChange="drawerChange"
      />
      <Navbar
        v-on:toggleSidebar="toggleSidebarSize"
        :isMobile="resizeScreen(window.width)"
        :drawerStatus="drawer"
      />
      <v-main class="scroll-y">
        <v-container fluid class="pa-0 d-print-block">
          <router-view v-show="!loadingUserContent"></router-view>
        </v-container>
      </v-main>
      <SnackBar
        :snackbar="currentNotification.success"
        :text="currentNotification.message"
      />
    </div>
    <!-- P R O G R E S S  B A R -->
    <div id="progress-bar" width="100%" ref="progress" v-show="showProgressBar"></div>
    <v-bottom-sheet
      flat
      v-model="sheet"
      :retain-focus="false"
      dark
      persistent
      no-click-animation
      hide-overlay
      max-width="400px"
      attach="#progress-bar"
      eager
    >
      <v-divider></v-divider>
      <v-expansion-panels v-model="panel">
        <v-expansion-panel>
          <v-expansion-panel-header>
            Progress
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ProgressBar v-on:close="closeProgressBar" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-bottom-sheet>
    <GeneralProgressBar />
  </v-app>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';

import Navbar from '@components/layouts/Navbar.vue';
import NewSideBar from '@components/layouts/NewSideBar.vue';
import SnackBar from '@components/common/SnackBar.vue';
import Loading from '@components/common/Loading.vue';
import ProgressBar from '@components/common/ProgressBar.vue';
import GeneralProgressBar from '@components/common/GeneralProgressBar.vue';
import firebase from '@database';
import { baseProcessUrl } from '@utils/endpoint';
import services from '@services';
import userInactive from '@utils/userActivity';

export default {
  name: 'App',
  components: {
    Navbar,
    NewSideBar,
    Loading,
    SnackBar,
    ProgressBar,
    GeneralProgressBar,
  },
  data: () => ({
    sideBarMini: false,
    drawer: true,
    window: { width: 0, height: 0 },
    sheet: true,
    panel: 0,
    errors: null,
    loading: false,
  }),
  methods: {
    ...mapActions([
      'getCompany',
      'logout',
      'setCurrentUser',
      'setLoadingCompany',
      'handleScreenSize',
      'getCustomer',
      'setLoadingCustomer',
      'updateTaskProgress',
      'getTasks',
      'longPollProgress',
      'setProcessingImages',
      'setNewImageAmount',
      'setUploadPercent',
    ]),
    drawerChange(drawerStatus) { this.drawer = drawerStatus; },
    toggleSidebarSize(drawer) { this.drawer = drawer; },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      this.handleScreenSize(this.window);
    },
    resizeScreen(size) { return size <= 992; },

    // Positions the progress bar on `mounted` to the right corner
    positionProgressBar() {
      const progressBar = this.$refs.progress;
      progressBar.children[0].style = 'justify-content: flex-end;';
    },

    // Clears the progress bar
    closeProgressBar() {
      this.updateTaskProgress({});
    },
    async setup() {
      this.loading = true;

      const { uid } = firebase.auth().currentUser;

      try {
        const uRes = await this.setCurrentUser(uid);
        const userData = uRes.data;

        const res = await this.getCompany(userData.cid);
        const company = res.data;

        if (Object.keys(company).includes('customerId')) {
          await this.getCustomer(company.customerId);
        }

        if (uRes && res) {
          this.loading = false;
        }
        this.setProcessTasks();

        return {};
      } catch (err) {
        return err;
      }
    },
    async setProcessTasks() {
      const tasks = await this.getTasks(firebase.auth().currentUser.uid);
      const res = await services.auth.getJwtToken(firebase.auth().currentUser.uid);
      const { token } = res.data;

      const taskIds = Object.keys(tasks);

      if (taskIds.length > 0) {
        const requestPayload = {
          type: 'GET',
          headers: { Authorization: token },
        };

        taskIds.forEach((taskId) => {
          requestPayload.url = `${baseProcessUrl}/batch_process/result/${taskId}`;
          requestPayload.taskId = taskId;
          this.longPollProgress(requestPayload);
        });
      }
    },
  },
  computed: {
    ...mapGetters([
      'loggedIn',
      'loadingUser',
      'loadingCompany',
      'loadingCustomer',
      'processingImage',
      'currentProgress',
      'currentNotification',
      'processingImage',
      'allInProgressTasks',
      'allImages',
      'currentProject',
      'uploadingImages',
      'totalUploadPercent',
      'currentCompany',
      'currentUser',
      'firstFactorAuthenticated',
      'secondFactorAuthenticated',
    ]),
    shouldShowSidebar() {
      return this.loggedIn && this.$route.name !== 'App';
    },
    loadingUserContent() {
      const {
        loadingUser,
        loadingCompany,
        loadingCustomer,
      } = this;

      let loading;

      if (this.loggedIn) {
        loading = loadingCompany || loadingCustomer || loadingUser;
      } else loading = loadingCompany || loadingCustomer;
      return loading;
    },
    showProgressBar() {
      const show = (this.processingImage || Object.keys(this.currentProgress).length > 0)
      || this.currentProgress.status === 'Success'
      || this.allInProgressTasks.length > 0;

      return show;
    },
  },
  mounted() {
    const { name } = this.$route;
    if (name === 'ProcessedImages' || name === 'OriginalImages') this.drawer = false;

    // Immediately postion the progress bar to the right
    // since Vuetify doesn't offer any properties to do so
    this.positionProgressBar();
  },
  async created() {
    this.loading = true;
    window.addEventListener('resize', this.handleResize);
    userInactive(() => {
      // eslint-disable-next-line no-alert
      alert('session expired.');
      window.location.reload();
    });
    this.handleResize();
    try {
      if (this.firstFactorAuthenticated && this.secondFactorAuthenticated) {
        await this.setup();
      }
      this.loading = false;
    } catch (err) {
      this.loading = false;
    }
  },
  watch: {
    uploadingImages(uploading) {
      if (uploading && this.$route.name !== 'Report Issue') {
        window.onbeforeunload = () => {
          const confirmationMessage = '\\o/';
          // Safari, Chrome, and other WebKit-derived browsers
          return confirmationMessage;
        };
      } else {
        window.onbeforeunload = null;
      }
    },
    $route(newRoute) {
      const { name } = newRoute;
      const hasImages = (name === 'ProcessImages')
        || (name === 'OriginalImages')
        || (name === 'Analytics')
        || (name === 'Insights');
      if (hasImages) this.drawer = false;
    },
  },
};
</script>

<style>
  html { overflow-y: auto; }
  @media print {
    body,
    html { overflow: auto; height: auto; }
    .no-print { display: none; }
  }
</style>
