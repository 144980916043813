<template>
  <v-overlay :value="loading">
    <div id="text" class="d-inline">
      <p class="text-h5 text-center" v-if="!override">Retrieving {{ type }} Data...</p>
      <p class="text-h5 text-center" v-else> {{ type }} ...</p>
    </div>
    <div id="progress-circular" class="d-inline">
      <v-progress-circular id="progress" indeterminate size="45" class="mx-auto d-block">
      </v-progress-circular>
    </div>
  </v-overlay>
</template>

<script>
export default {
  name: 'LoadingOverlay',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    override: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'Unknown_Data',
    },
  },
};
</script>
