import { render, staticRenderFns } from "./FilterableAnnotoriousRenderer.vue?vue&type=template&id=89223514&scoped=true"
import script from "./FilterableAnnotoriousRenderer.vue?vue&type=script&lang=js"
export * from "./FilterableAnnotoriousRenderer.vue?vue&type=script&lang=js"
import style0 from "./FilterableAnnotoriousRenderer.vue?vue&type=style&index=0&id=89223514&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89223514",
  null
  
)

export default component.exports