<template>
  <v-card>
    <v-tabs
      v-model="tab"
      class="elevation-2"
      dark
      :centered="isCentered"
      :vertical="isVertical"
      mobile-breakpoint="414"
      :fixed-tabs="true"
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab v-for="tab in tabs" :key="tab.title"
             :disabled="tab.tabId === 'ai' && disabledAiTab"
             @click="goToTab(tab.title)" link>
        <v-icon>{{tab.icon}}</v-icon>
        {{tab.title}}
      </v-tab>
      <v-tabs-items>
        <router-view></router-view>
      </v-tabs-items>
    </v-tabs>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TabViews',
  props: ['vertical', 'centered', 'tabs', 'current'],
  data: () => ({
    tab: null,
    tabsMounted: [],
    isVertical: false,
    isCentered: false,
  }),
  methods: {
    ...mapActions(['setSelectedFolderPath', 'setCurrentPage', 'setCurrentFolder', 'setIsFormOriginalTab', 'setSeverityFilter']),
    goToTab(title) {
      const tab = this.tabs.find((t) => t.title === title);
      this.setIsFormOriginalTab(tab.tabId === 'ai');
      // reset filters when changing tab
      this.setSeverityFilter([]);
      // reset folder panel page number
      this.setCurrentPage(1);
      // reset selected folder path
      this.setSelectedFolderPath(null);
      this.$router.push(tab.route);
      // reset to root folder when changing tab;
      this.setCurrentFolder(this.rootFolder);
    },
    updateTab() {
      const tabIndex = this.tabs.findIndex((t) => t.route === this.$route.path);
      if (tabIndex !== -1) {
        this.tab = tabIndex;
      }
      // console.log(tabIndex);
      // if (this.allImages.length === 0) {
      //   console.log(this.tab);
      // }
    },
  },
  computed: {
    ...mapGetters(['uploadingImages', 'rootFolder', 'allImages']),
    disabledAiTab() {
      return this.uploadingImages || this.allImages.length === 0;
    },
  },
  mounted() {
    this.isVertical = this.vertical;
    this.isCentered = this.centered;
    this.tabsMounted = this.tabs;
    this.tab = this.current;
    this.updateTab();
  },
  watch: {
    $route: 'updateTab',
  },
};
</script>
