<template>
  <v-card flat class="mb-12" v-show="!loadingUser">
    <v-card-title class="headline">Resetting Password</v-card-title>
    <v-card-text>
      <v-text-field
        outlined
        id="password-1"
        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
        label="New Password"
        v-model="password"
        :type="show1 ? 'text':'password'"
        :rules="[rules.required, rules.min]"
        @click:append="show1 = !show1"
      ></v-text-field>
      <v-text-field
        outlined
        id="password-2"
        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
        label="Re-enter Password"
        :rules="[rules.required, rules.min]"
        v-model="password2"
        :error-messages="errors.matching"
        :type="show2 ? 'text':'password'"
        @click:append="show2 = !show2"
      ></v-text-field>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" id="reset-password" text @click="onResetPassword">Reset</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ResetPasswordForm',
  props: ['uid'],
  data: () => ({
    password: '',
    password2: '',
    show1: false,
    show2: false,
    errors: { matching: '' },
    rules: {
      required: (value) => !!value || 'Required.',
      min: (v) => v.length >= 8 || 'Min 8 characters',
      emailMatch: () => ('The email and password you entered don\'t match'),
    },
  }),
  computed: {
    ...mapGetters(['currentUser', 'loadingUser']),
  },
  methods: {
    ...mapActions(['resetPassword', 'getUserDocumentById']),
    passwordInput() {
      this.$emit('passwordInput', this.password);
    },
    passwordRetypeInput() {
      this.$emit('passwordRetype', this.password2);
    },
    matchingFields: (password1, password2) => password1 === password2,
    async onResetPassword() {
      if (!this.matchingFields(this.password, this.password2)) {
        this.errors.matching = 'Your passwords must match one another';
      } else {
        const payload = { email: this.currentUser.email, password: this.password };

        try {
          const res = await this.resetPassword(payload);
          if (res.status === 200) this.$router.push('/forgot-password/complete');
        } catch (err) {
          console.error('err: ', err);
        }
      }
    },
  },
  created() {
    if (!this.currentUser) this.getUserDocumentById(this.uid);
  },
};
</script>
