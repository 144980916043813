<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          id="download-pdf-btn"
          icon
          x-large
          color="primary"
          v-bind="attrs"
          v-on="on"
          @click="downloadPdf"
        >
          <v-icon>fas fa-file-pdf</v-icon>
        </v-btn>
      </template>
      Export to PDF
    </v-tooltip>
    <v-dialog
      v-model="dialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Reports
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :hide-default-footer="true"
            :items="files"
            :items-per-page="30"
            class="elevation-1"
          >
            <template v-slot:[`item.name`]="{ item }">
              {{ item.name }}
            </template>
            <template v-slot:[`item.url`]="{ item }">

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    target="_blank"
                    :href="item.url"
                  >
                    <v-icon>fas fa-file-pdf</v-icon>
                  </v-btn>
                </template>
                View
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="downloadFile(item)"
                  >
                    <v-icon>fas fa-download</v-icon>
                  </v-btn>
                </template>
                Download
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <DialogMessage
      :show="this.showMessage"
      @updateShow="this.updateShow"
      :title="'Download All Files'"
      :description="'Your browser may prevent multiple file downloading. \n ' +
       'If download doesn\'t start automatically, '+
        'please click allow when popup box is shown. \n' +
        'Download will start in '+ counter +'s.'" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DialogMessage from '@components/common/DialogMessage.vue';
import platformBackendClient from '@backend-clients/platform-backend';

export default {
  name: 'ExportPdf',
  props: ['pid', 'getMapRef'],
  components: {
    DialogMessage,
  },
  data: () => ({
    error: true,
    dialog: false,
    showMessage: false,
    counterInterval: null,
    counter: 5,
    headers: [
      {
        text: 'File Name',
        align: 'start',
        sortable: false,
        value: 'filename',
      },
      {
        text: 'Actions', sortable: false, value: 'url', align: 'end', width: '50%',
      },
    ],
    files: [
    ],
  }),
  methods: {
    ...mapActions(['setRetrievePdf']),
    updateShow(value) {
      this.showMessage = value;
    },
    async downloadPdf() {
      this.setRetrievePdf(true);
      const { data } = await platformBackendClient.get(`/api/dashboard/company/${this.currentCompany.cid}/project/${this.pid}/pdf`);
      this.files = data;
      this.dialog = true;
      this.setRetrievePdf(false);
    },
    async downloadFile(item) {
      const { downloadUrl, filename } = item;
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    },
  },
  computed: {
    ...mapGetters(['currentProject', 'currentCompany', 'projectLabels']),
  },
  watch: {
    showMessage(newShow) {
      if (!newShow) {
        this.counter = 5;
        if (this.counterInterval) {
          clearInterval(this.counterInterval);
          this.counterInterval = null;
        }
      }
    },
  },
};
</script>
