<template>
  <v-container fluid>
    <router-view></router-view>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import subscriptionAccess from '../../router/subscription-access';

export default {
  name: 'Analytics',
  props: ['pid'],
  methods: {
    ...mapActions(['getDashboardImages', 'getProjects']),
    async getFirstProject() {
      let res;
      try {
        res = await this.getProjects(`company_id=${this.currentCompany.cid}`);

        const projects = res.data;
        const [project] = projects;

        return project;
      } catch (err) {
        return err;
      }
    },
    async fetchProjects() {
      let res;
      try {
        res = await this.getProjects(`company_id=${this.currentCompany.cid}`);
        const projects = res.data;
        return projects;
      } catch (err) {
        return new Error(err);
      }
    },
  },
  computed: {
    ...mapGetters(['currentCompany', 'currentProject', 'currentUser', 'allProjects']),
  },
  async created() {
    // Check dashboard access
    // const canAccess = await subscriptionAccess({
    //   customerId: this.currentCompany.customerId,
    //   product: 'dashboard',
    // });

    await this.fetchProjects();
    this.$nextTick(() => {
      const [firstProject] = this.allProjects;
      if (!firstProject) {
        return this.$router.push('/analytics/no-project');
      }
      const { pid } = this.$route.params;
      const currentPid = (!pid) ? firstProject.pid : pid;
      return this.$router.push(`/analytics/insights/${currentPid}`);
    });

    // Can't access
    // if (!canAccess) this.$router.push('/analytics/no-access');
    // else this.$router.push(`/analytics/insights/${currentPid}`);
  },
};
</script>
