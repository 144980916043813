import mlBackendClient from '@backend-clients/ml-backend';

export default {
  process: (processData, options) => new Promise((resolve, reject) => {
    mlBackendClient.post('/process', processData, options)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  }),

  batch_process: (processData, options) => new Promise((resolve, reject) => {
    mlBackendClient.post('/batch_process/predict', processData, options)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  }),
};
