import severities from '@utils/severity-level';
// eslint-disable-next-line import/no-cycle
import store from '@store';
/* eslint-disable consistent-return */

const decimalToPercentage = (decimal) => {
  if (!decimal) return '100%';
  const percentage = Math.round(decimal * 100);
  return `${percentage}%`;
};

const getConfidenceElement = (args) => {
  const shouldShow = store.getters.companyHas('show_confidence_level');
  const confidenceLevel = document.createElement('div');
  if (shouldShow) {
    const confidence = args.annotation ? args.annotation.underlying?.confidence : '';
    confidenceLevel.innerHTML = `Confidence Level: ${decimalToPercentage(confidence)}`;
  }
  return confidenceLevel;
};

const createFillerOption = (text) => {
  const fillerOption = document.createElement('option');
  fillerOption.value = text;
  fillerOption.innerText = text;
  fillerOption.dataset.tag = text;

  return fillerOption;
};

const LabelSelectorWidget = (abbreviation) => (args) => {
  const labels = abbreviation.map((abbr) => abbr.faultType);
  // 1. Find the current label setting in the annotation, if any
  const currentLabelBody = args.annotation
    ? args.annotation.bodies.find((b) => b.purpose === 'labeling')
    : null;

  // 4. Keep value in a variable
  const currentLabelValue = currentLabelBody ? currentLabelBody.value : null;

  // 5. Triggers callbacks on user actions
  const addTag = (evt) => {
    const labelBody = { type: 'TextualBody', purpose: 'labeling', value: evt.target.value };

    if (currentLabelBody) {
      args.onUpdateBody(currentLabelBody, labelBody);
    } else {
      args.onAppendBody(labelBody);
    }
  };

  // 4. Renders the UI elements
  // The container that holds the widget components
  const container = document.createElement('div');
  container.className = 'labelselector-widget';
  container.appendChild(getConfidenceElement(args));
  // select input creation
  const selectInput = document.createElement('select');
  selectInput.className = 'form-select';
  selectInput.setAttribute('aria-label', 'Select a label');

  selectInput.appendChild(createFillerOption('Please select a label'));

  // Setting each select options to be a label
  const options = labels.sort().map((label) => {
    const optionTag = document.createElement('option');
    if (label === currentLabelValue) optionTag.selected = true;
    optionTag.value = label;
    optionTag.innerText = label;
    optionTag.dataset.tag = label;
    return optionTag;
  });

  // Appending each option tags to selectInput
  options.forEach((option) => {
    selectInput.appendChild(option);
  });

  // Adding a change event listener to the select field
  selectInput.addEventListener('change', addTag);

  // Appending the select field to the container
  container.appendChild(selectInput);

  return container;
};

const CustomLabelTextWidget = (args) => {
  // 1. Find the current label setting in the annotation, if any
  const currentLabelBody = args.annotation
    ? args.annotation.bodies.find((b) => b.purpose === 'labeling')
    : null;
  // 4. Keep value in a variable
  const currentLabelValue = currentLabelBody ? currentLabelBody.value : null;
  // 5. Triggers callbacks on user actions
  const addTag = (evt) => {
    const labelBody = { type: 'TextualBody', purpose: 'labeling', value: evt.target.value };

    if (currentLabelBody) {
      args.onUpdateBody(currentLabelBody, labelBody);
    } else {
      args.onAppendBody(labelBody);
    }
  };

  // 4. Renders the UI elements
  // The container that holds the widget components
  const container = document.createElement('div');
  container.className = 'labeltext-widget';

  // select input creation
  const textInput = document.createElement('textarea');
  textInput.className = 'annote-form-textbox';
  textInput.setAttribute(
    'aria-label',
    'Additional Note',
  );
  textInput.setAttribute(
    'placeholder',
    'Additional Note',
  );
  textInput.value = currentLabelValue;

  // Adding a change event listener to the select field
  textInput.addEventListener('change', addTag);
  textInput.addEventListener('keydown', (evt) => {
    evt.stopPropagation();
  });

  // Appending the select field to the container
  container.appendChild(textInput);

  return container;
};

const LabelDisplayWidget = (args) => {
  // 1. Find the current label setting in the annotation, if any
  const currentLabelBody = args.annotation
    ? args.annotation.bodies.find((b) => b.purpose === 'labeling')
    : null;
  // 4. Keep value in a variable
  const currentLabelValue = currentLabelBody ? currentLabelBody.value : null;

  // 4. Renders the UI elements
  // The container that holds the widget components
  const container = document.createElement('div');
  container.className = 'labeldisplay-widget';
  container.appendChild(getConfidenceElement(args));
  // select input creation
  const textInput = document.createElement('textarea');
  textInput.readOnly = true;
  textInput.className = 'label-display-textbox';
  textInput.value = currentLabelValue;

  // Appending the select field to the container
  container.appendChild(textInput);

  return container;
};

const SeveritySelectorWidget = (args) => {
  // 2. Find the current severity setting in the annotation, if any
  const currentSeverityBody = args.annotation
    ? args.annotation.bodies.find((b) => b.purpose === 'severity')
    : null;
  // 4. Keep value in a variable
  const currentSeverityValue = currentSeverityBody ? currentSeverityBody.value : null;
  // 5. Triggers callbacks on user actions
  const addTag = (evt) => {
    const severityBody = { type: 'TextualBody', purpose: 'severity', value: evt.target.value };

    if (currentSeverityBody) {
      args.onUpdateBody(currentSeverityBody, severityBody);
    } else {
      args.onAppendBody(severityBody);
    }
  };

  // 4. Renders the UI elements
  // The container that holds the widget components
  const container = document.createElement('div');
  container.className = 'severityselector-widget';

  // select input creation
  const selectInput = document.createElement('select');
  selectInput.className = 'form-select';
  selectInput.setAttribute('aria-label', 'Please Select a severity level');

  selectInput.appendChild(createFillerOption('Please Select a severity level'));

  // Setting each select options to be a label
  const options = severities.map((severity) => {
    const optionTag = document.createElement('option');
    if (severity === currentSeverityValue) optionTag.selected = true;
    optionTag.value = severity;
    optionTag.innerText = severity;
    optionTag.dataset.tag = severity;
    return optionTag;
  });

  // Appending each option tags to selectInput
  options.forEach((option) => {
    selectInput.appendChild(option);
  });

  // Adding a change event listener to the select field
  selectInput.addEventListener('change', addTag);

  // Appending the select field to the container
  container.appendChild(selectInput);

  return container;
};

const LabelFormatter = (annotation) => {
  const labelBody = annotation.bodies.find((b) => b.purpose === 'label');

  if (labelBody) return labelBody.value;
};

export default {
  labelDisplay: LabelDisplayWidget,
  labeling: LabelSelectorWidget,
  customLabeling: CustomLabelTextWidget,
  severity: SeveritySelectorWidget,
  formatter: LabelFormatter,
};
