<template>
  <v-container fluid>
    <Loading v-if="loadingProject" />
    <EditProjectForm v-else :project="formatProject(currentProject)" :allTeams="allTeams"/>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Loading from '@components/common/Loading.vue';
import EditProjectForm from '@components/projects/EditProjectForm.vue';

export default {
  name: 'EditProject',
  props: ['pid'],
  components: {
    Loading,
    EditProjectForm,
  },
  methods: {
    ...mapActions(['getProject']),
    formatProject(project) {
      const editingProject = { ...project };
      return editingProject;
    },
  },
  computed: {
    ...mapGetters([
      'currentProject',
      'currentCompany',
      'loadingProject',
      'allTeams',
    ]),
  },
  created() {
    const { pid } = this;
    this.getProject({ cid: this.currentCompany.cid, pid });
  },
};
</script>
