<template>
  <v-container fluid ref="top">
    <LoadingOverlay :loading="retrievingData" :type="retrievingDataType"/>
    <div id="loader" v-if="loadingCurrentImage">
      <SkeletonLoader type='insights' />
      <span class="text-center">{{ loadingMessage }}</span>
    </div>
    <InsightsContent :pid="pid" v-else />
    <ScrollToTop :target="target" :options="options"/>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import LoadingOverlay from '@components/common/loaders/LoadingOverlay.vue';
import SkeletonLoader from '@components/common/loaders/SkeletonLoader.vue';
import InsightsContent from '@components/dashboard/InsightsContent.vue';
import ScrollToTop from '@components/common/ScrollToTop.vue';

export default {
  name: 'Insights',
  props: ['pid'],
  data: () => ({
    loadingMessage: '',
    target: {},
    options: {
      offset: 0,
      easing: 'easeInOutCubic',
      duration: 300,
    },
  }),
  components: {
    SkeletonLoader,
    LoadingOverlay,
    InsightsContent,
    ScrollToTop,
  },
  methods: {
    ...mapActions(['getDashboardData', 'setCurrentImage', 'getImagesByProjectIds', 'setProjectsLoading', 'finishProjectLoad', 'setCurrentDashboardImage', 'getFoldersByCompany', 'setLoadingImage', 'getProjects', 'getImagesByProject', 'getFoldersByProject', 'fetchLabelsForProject', 'clearDashboardFilters']),
    async fetchProjects() {
      let res;
      try {
        res = await this.getProjects(`company_id=${this.currentCompany.cid}`);

        const projects = res.data;
        return projects;
      } catch (err) {
        return new Error(err);
      }
    },
    async fetchDashboardData(pid) {
      this.setLoadingImage(true);
      const categorize = this.companyHas('categorize_labels') ? 'yes' : 'no';
      const custom = this.companyHas('custom_labels') ? 'yes' : 'no';

      try {
        await this.fetchLabelsForProject({
          pid,
          cid: this.currentCompany.cid,
          categorize,
          custom,
        });
        const res = await this.getDashboardData({
          pid,
          cid: this.currentCompany.cid,
        });

        if (res.status === 200) {
          const { images } = res.data;

          if (images.length < 1) {
            this.setLoadingImage(false);
          } else {
            const [image] = res.data.images.filter((img) => img.processedImageUrl);
            if (image) this.setCurrentImage(image);
            this.setLoadingImage(false);
          }
        }
        return res;
      } catch (err) {
        return new Error(err);
      }
    },
  },
  computed: {
    ...mapGetters(['retrievingPdfData', 'currentCompany', 'loadingCurrentImage', 'currentUser', 'allImages', 'currentImage', 'loadingFolders', 'companyHas', 'currentProject', 'retrievingImageData', 'retrievingFolderData', 'retrievingCsvData']),
    retrievingData() {
      return this.retrievingImageData
            || this.retrievingFolderData
            || this.retrievingCsvData
            || this.retrievingPdfData;
    },
    retrievingDataType() {
      if (this.retrievingFolderData) return 'Folder';
      if (this.retrievingImageData) return 'Image';
      if (this.retrievingCsvData) return 'CSV';
      if (this.retrievingPdfData) return 'PDF';
      return '';
    },
  },
  watch: {
    pid(newPid) {
      this.fetchDashboardData(newPid);
    },
    loadingFolders(newLoading) {
      if (newLoading) this.loadingMessage = `Loading folders within project: ${this.currentProject.name}`;
      else this.loadingMessage = '';
    },
  },
  mounted() {
    this.target = this.$refs.top;
  },
  async created() {
    await this.fetchDashboardData(this.pid);
  },
  destroyed() {
    this.clearDashboardFilters();
  },
};
</script>
