<template>
  <v-container fluid>
    <Loading v-if="loadBillingSummary"/>
    <v-row v-else>
      <v-col cols="12">
        <h2 id="billing-report-header">Billing Reports</h2>
      </v-col>
      <v-col cols="12" xs="12" sm="7" md="6" lg="4">
        <DatePicker v-on:reportRange="setPeriod"/>
      </v-col>
      <ReportOverview class="ma-4"
      :cost="periodCost()"
      :dateRange="dateRange"/>
      <Loading v-if="reload"/>
      <ReportChart class="ma-4"
      :labels="monthlyBillingSummary.months"
      :values="monthlyBillingSummary.total_due"
      :dateRange="dateRange"/>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ReportOverview from '@components/billing/report/ReportOverview.vue';
import ReportChart from '@components/billing/report/ReportChart.vue';
import DatePicker from '@components/billing/report/DatePicker.vue';
import Loading from '@components/common/Loading.vue';

export default {
  name: 'BillingReport',
  data: () => ({
    reload: false,
    startDate: new Date((new Date()).setDate(1)),
    endDate: new Date(),
  }),
  components: {
    ReportOverview,
    ReportChart,
    DatePicker,
    Loading,
  },
  computed: {
    ...mapGetters([
      'billingSummary',
      'loadBillingSummary',
      'currentCompany',
      'monthlyBillingSummary',
      'dailyBillingSummary',
    ]),
    dateRange() {
      const options = { month: 'long' };
      const startMonth = new Intl.DateTimeFormat('en-US', options).format(this.startDate);
      const endMonth = new Intl.DateTimeFormat('en-US', options).format(this.endDate);
      const startYear = this.startDate.getFullYear();
      const endYear = this.endDate.getFullYear();
      const startDay = this.startDate.getDate();
      const endDay = this.endDate.getDate();
      let timeRange;
      if (startYear === endYear) {
        timeRange = `${startMonth} ${startDay} - ${endMonth} ${endDay}, ${endYear}`;
      } else {
        timeRange = `${startMonth} ${startDay}, ${startYear} - ${endMonth} ${endDay}, ${endYear}`;
      }
      return timeRange;
    },
  },
  methods: {
    ...mapActions(['fetchBillingSummary']),
    async setPeriod(timeRange) {
      this.startDate = new Date(timeRange.start * 1000);
      this.endDate = new Date(timeRange.end * 1000);
      this.reload = true;
      await this.fetchBillingSummary({
        customerId: this.currentCompany.customerId,
        ...timeRange,
        load: false,
      });
      this.reload = false;
    },
    periodCost() {
      const sum = this.monthlyBillingSummary.total_due.reduce((a, b) => a + b, 0);
      return sum;
    },
  },
  async created() {
    if (Object.keys(this.billingSummary).length > 0) {
      this.startDate = new Date(this.billingSummary.startDate);
      this.endDate = new Date(this.billingSummary.endDate);
      return;
    }
    const today = Math.ceil(Date.now() / 1000);
    await this.fetchBillingSummary({
      customerId: this.currentCompany.customerId,
      start: 0,
      end: today,
    });
    this.startDate = new Date(this.billingSummary.startDate);
    this.endDate = new Date(this.billingSummary.endDate);
  },
};
</script>
