import firebase from '@database';
import service from '@services';
import store from '@store';
import uniq from 'lodash/uniq';

const unsubscribeFirebase = () => {
  if (global.updateImageInterval) {
    clearInterval(global.updateImageInterval);
  }
  if (global.uploadListener && typeof global.uploadListener === 'function') {
    global.uploadListener();
  }
};
export const firebaseListener = (projectId, callback = () => {}) => {
  const { bucket } = store.getters.currentCompany;
  const { firestore_uid: uid } = store.getters.currentUser;
  unsubscribeFirebase();
  const ids = [];
  const updateImages = async (imageIds) => {
    if (!imageIds || imageIds.length === 0) {
      return;
    }
    const { currentCompany, currentProject } = store.getters;
    // eslint-disable-next-line max-len
    const { data } = await service.images.get_batch(currentCompany.cid, currentProject.pid, { images: uniq(imageIds) });

    store.commit('create_images', data);
    store.commit('set_current_image', data[0]);
    store.commit('set_amount');
  };
  global.updateImageInterval = setInterval(() => {
    const imageIds = [...ids];
    ids.splice(0, imageIds.length);
    updateImages(imageIds);
  }, 5000);
  global.uploadListener = firebase.database.collection('upload')
    .where('bucket', '==', bucket)
    .where('projectId', '==', projectId)
    .where('uid', '==', uid)
    .onSnapshot((querySnapshot) => {
      querySnapshot.docChanges()
        .forEach((change) => {
          if (change.type === 'modified') {
            const doc = change.doc.data();
            // eslint-disable-next-line max-len
            if (doc.created && !doc.error && doc.imageId) {
              ids.push(doc.imageId);
              change.doc.ref.delete();
            }
            if (doc.error) {
              // eslint-disable-next-line no-console
              console.error(doc.error);
              change.doc.ref.delete();
            }
          }
          callback(change.type, change.doc, () => {
            updateImages(ids);
            ids.splice(0, ids.length);
          });
        });
    });
};

export const clearFirebaseRecords = (projectId) => {
  const { bucket } = store.getters.currentCompany;
  const { firestore_uid: uid } = store.getters.currentUser;
  return new Promise((resolve, reject) => {
    firebase.database.collection('upload')
      .where('bucket', '==', bucket)
      .where('projectId', '==', projectId)
      .where('uid', '==', uid)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
        });
        resolve();
      })
      .catch(reject);
  });
};

export const getUploadStatus = async (projectId) => {
  const { bucket } = store.getters.currentCompany;
  const { firestore_uid: uid } = store.getters.currentUser;
  return new Promise((resolve, reject) => {
    firebase.database.collection('upload')
      .where('bucket', '==', bucket)
      .where('projectId', '==', projectId)
      .where('uid', '==', uid)
      .get()
      .then((querySnapshot) => {
        let total = 0;
        querySnapshot.forEach((doc) => {
          const docData = doc.data();
          if ((docData.imageId && docData.created) || docData.error) {
            doc.ref.delete();
          } else {
            total += 1;
          }
        });
        resolve({ total });
      })
      .catch(reject);
  });
};
