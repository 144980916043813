<template>
  <CardSkeletonLoader v-if="loadingSubscription" width="300" />
  <v-container fluid v-else>
    <v-card>
      <v-card-title class="headline">
        Your subscription is paused for the {{ currentSubscription.plan.nickname }} Plan
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        To use the services of {{ currentSubscription.plan.nickname }}
        please navigate to your settings and resume
        the subscription plan. Your billing will immediately resume as normal.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" to="/billing/subscription-plans/access">
          Go To {{ currentSubscription.plan.nickname }} Plan Access Settings
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CardSkeletonLoader from '@components/common/loaders/CardSkeletonLoader.vue';

export default {
  name: 'ImageProcessPlanPaused',
  props: ['subId'],
  components: {
    CardSkeletonLoader,
  },
  methods: {
    ...mapActions(['getSubscriptionById']),
  },
  computed: {
    ...mapGetters(['currentSubscription', 'loadingSubscription']),
  },
  created() {
    this.getSubscriptionById(this.subId);
  },
};
</script>
