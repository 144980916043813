import moment from 'moment';

export default {
  secondsToTime(secs) {
    if (secs <= 0) return 0;
    const divmod = (x, y) => [Math.floor(x / y), x % y];
    const pluralize = (w, p, n) => (n > 0 ? `${w}${p}` : w);
    const [h, hr] = divmod(secs, 3600);
    const [m, s] = divmod(hr, 60);

    let timeStr = '';
    if (h > 0) timeStr += `${h} ${pluralize('hour', 's', h)}, `;
    if (m > 0) timeStr += `${m} ${pluralize('minute', 's', m)} `;
    if (m > 0 && s > 0) timeStr += 'and ';
    if (s > 0) timeStr += `${s} ${pluralize('second', 's', s)}`;

    return timeStr;
  },
  sortByDescendingDates(list) {
    return list.sort((a, b) => {
      const dateA = a.createdAt?.toDate?.() || 0;
      const dateB = moment(b.createdAt?.toDate?.() || 0);

      return moment(dateA).isBefore(dateB);
    });
  },
};
