<template>
  <v-card>
    <v-card-title class="headline">{{ title }}</v-card-title>
    <v-divider></v-divider>
    <v-card-text id="description">
      {{ content }}
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn id="close-btn" color="primary" @click="close">Close</v-btn>
      <v-btn id="confirm-btn" color="primary" @click="cancel">Confirm</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'CancelSubscriptionConfirm',
  props: ['id', 'title', 'content'],
  methods: {
    ...mapActions(['updateSubscriptionById', 'setSubscriptionNotification']),
    close() {
      this.$emit('closeCancelSub');
    },
    cancel() {
      const payload = {
        id: this.id,
        data: {
          cancel_at_period_end: true,
        },
      };
      this.updateSubscriptionById(payload)
        .then(() => {
          const notification = {
            success: true,
            message: `${this.title} has been cancelled. You have until end of billing period to use the services.`,
          };
          this.setSubscriptionNotification(notification);
          this.close();
        });
    },
  },
  computed: {

  },
};
</script>
