<template>
  <v-container fluid>
    <span class="font-weight-black float-left">Payment Methods</span>
    <v-container fluid class="my-8">
      <v-list
        subheader
        two-line
      >
        <v-list-item-group
          v-model="selectedCard"
        >
          <v-list-item v-for="(payment, index) in payments" :key="index">
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox
                  :input-value="active"
                  color="primary"
                  @click="paymentMethodSelected(index, payment)"
                ></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{cardBrandFormat(payment.card.brand)}} ****{{payment.card.last4}}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Expiration: {{payment.card.exp_month}}/{{payment.card.exp_year}}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-list-item>
          <v-card-actions>
            <v-dialog v-model="addPaymentDialog" max-width="700">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  text
                  v-on="on"
                  v-bind="attrs"
                >Add Payment Method</v-btn>
              </template>
              <AddPayment v-on:closeDialog="close"/>
            </v-dialog>
          </v-card-actions>
        </v-list-item-group>
      </v-list>
    </v-container>
  </v-container>
</template>

<script>
import AddPayment from '@components/payments/AddPayment.vue';
import { toUpperFirst } from '@methods/helpers';

export default {
  name: 'CheckoutCardData',
  props: ['payments'],
  components: {
    AddPayment,
  },
  methods: {
    cardBrandFormat: (brand) => toUpperFirst(brand),
    close() {
      this.addPaymentDialog = false;
    },
    paymentMethodSelected(currentCardIndex, payment) {
      const previousSelectedCardIndex = this.selectedCard;

      if (currentCardIndex === previousSelectedCardIndex) this.$emit('selectedPayment', {});
      else this.$emit('selectedPayment', payment);
    },
  },
  data: () => ({
    selectedCard: undefined,
    addPaymentDialog: false,
  }),
};
</script>
