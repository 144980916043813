/* eslint-disable no-shadow */
import services from '@services';

const state = {
  loading: false,
  mission: {},
};

const getters = {
  loadingMission: (state) => state.loading,
  currentMission: (state) => state.mission,
};

const actions = {
  getMission({ commit }, projectId) {
    commit('loading_mission', true);
    return new Promise((resolve, reject) => {
      services.missions.get(projectId)
        .then((res) => {
          const { items } = res.data;
          const [mission] = items;
          commit('fetch_mission', mission);
          commit('loading_mission', false);
          resolve(res);
        })
        .catch((err) => {
          commit('loading_mission', false);
          reject(err);
        });
    });
  },
};

const mutations = {
  loading_mission(state, loading) {
    state.loading = loading;
  },
  fetch_mission(state, mission) {
    state.mission = mission;
  },
};

export default {
  state,
  actions,
  getters,
  mutations,
};
