<template>
  <v-container>
    <div class="filters">
      <v-row class="light--text">
        <v-col cols="3">
          <v-checkbox
            v-model="control.oneDay"
            label="One Day"
            class="checkbox"
            id="one-day"
            @click="select('oneDay')"
          ></v-checkbox>
        </v-col>
        <v-col cols="3">
          <v-checkbox
            v-model="control.oneWeek"
            label="One Week"
            class="checkbox"
            id="one-week"
            @click="select('oneWeek')"
          ></v-checkbox>
        </v-col>
        <v-col cols="3">
          <v-checkbox
            v-model="control.oneMonth"
            label="One Month"
            class="checkbox"
            id="one-month"
            @click="select('oneMonth')"
          ></v-checkbox>
        </v-col>
        <v-col cols="3">
          <v-checkbox
            v-model="control.betweenDates"
            label="Between Dates"
            class="checkbox"
            id="between-dates"
            @click="select('betweenDates')"></v-checkbox>
        </v-col>
      </v-row>
      <v-row v-if="control.betweenDates">
        <v-col cols="6">
          <DatePicker
            class="image-from"
            label="From"
            v-on:dateInput="getStartDate"
            :iDate="from"
            error=""
          />
        </v-col>
        <v-col cols="6">
          <DatePicker
            class="image-to"
            label="To"
            v-on:dateInput="getEndDate"
            :iDate="to"
            error=""
          />
        </v-col>
        <v-btn
          color="primary"
          class="text-center"
          @click="onFilterBetweenDates"
          id="filter-btn"
        >
          Filter Images
        </v-btn>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DatePicker from '@components/common/DatePicker.vue';

export default {
  name: 'ImageFilters',
  components: {
    DatePicker,
  },
  data: () => ({
    control: {
      oneDay: false,
      oneWeek: false,
      oneMonth: false,
      betweenDates: false,
    },
    current: '',
    from: '',
    to: '',
  }),
  methods: {
    ...mapActions(['setImageDateFilter']),
    select(value) {
      if (this.current === value) {
        this.current = '';
        this.setImageDateFilter({ type: '' });
      } else {
        this.current = value;
        const filterTypes = Object.keys(this.control);
        filterTypes.forEach((filter) => {
          if (filter !== this.current) this.control[filter] = false;
          else {
            this.control[filter] = true;
            const filteringData = {
              type: filter,
              start: '',
              end: '',
            };

            if (filter !== 'betweenDates') this.setImageDateFilter(filteringData);
          }
        });
      }
    },
    getStartDate(date) {
      this.from = date;
    },
    getEndDate(date) {
      this.to = date;
    },
    onFilterBetweenDates() {
      if (this.from !== '' && this.to !== '') {
        const filterData = {
          type: 'betweenDates',
          start: this.from,
          end: this.to,
        };

        this.setImageDateFilter(filterData);
      }
    },
  },
  computed: {
    ...mapGetters(['allImages']),
  },
};
</script>
