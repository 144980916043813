<template>
  <v-card flat>
    <v-card-title>
      <v-text-field
        id="search-bar"
        v-model="search"
        outlined
        append-icon="mdi-magnify"
        label="Search Label"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      id="label-table"
      :headers="headers"
      :items="projectLabels"
      :loading="loadingLabels"
      :height="auto"
      hide-default-footer
      class="elevation-1"
      :items-per-page="projectLabels.length"
      :search="search"
      v-scroll:#label-table="onScroll"
    ></v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'LabelReferenceTable',
  props: ['h', 'labels'],
  data: () => ({
    search: '',
    headers: [
      {
        text: 'Fault Type',
        align: 'start',
        sortable: false,
        value: 'faultType',
      },
      { text: 'Abbreviation', value: 'abbreviation' },
    ],
  }),
  methods: {
    onScroll(e) {
      // debounce if scrolling fast
      // eslint-disable-next-line no-unused-expressions
      this.timeout && clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        const { scrollTop } = e.target;
        const rows = Math.ceil(scrollTop / this.rowHeight);

        this.start = rows + this.perPage > this.projectLabels.length
          ? this.projectLabels.length - this.perPage : rows;

        this.$nextTick(() => {
          e.target.scrollTop = scrollTop;
        });
      }, 20);
    },
  },
  computed: {
    ...mapGetters(['projectLabels', 'loadingLabels']),
  },
};
</script>

<style scoped>
#label-table{
  max-height: 250px;
  overflow: auto;
}
</style>
