<template>
  <v-container fluid>
    <v-sheet class="mx-auto" elevation="8">
      <v-slide-group
        v-model="model"
        class="pa-4"
        active-class="success"
        show-arrows
      >
        <v-slide-item
          v-for="(list, classType) in labelDictionary"
          :key="classType"
        >
          <FaultSummaryCard :images="list" :label="classType"/>
        </v-slide-item>
      </v-slide-group>
    </v-sheet>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import dashboard from '@components/dashboard/methods/';
import FaultSummaryCard from '@components/dashboard/fault-summary/FaultSummaryCard.vue';

export default {
  name: 'FaultSummaryCards',
  props: ['images'],
  components: {
    FaultSummaryCard,
  },
  data: () => ({
    model: false,
    labelDictionary: {},
  }),
  methods: {
    getLabelLists: (images) => dashboard.getClassList(images),
  },
  computed: {
    ...mapGetters(['loadingImages']),
  },
  created() {
    const imagesWithLabels = this.images
      .filter((image) => image.process_tracking.slice(-1)[0].labels);
    this.labelDictionary = dashboard.getClassList(imagesWithLabels);
  },
};
</script>
