export default (classes) => {
  const classObject = {};

  classes.forEach((labelClass) => {
    const key = labelClass.toLowerCase().split(' ').join('_');

    classObject[key] = {
      color: '',
      active: false,
      textColor: '',
    };
  });

  return classObject;
};
