<template>
  <v-chip
    :color="color"
    :x-small="xs"
    :small="sm"
    :large="lg"
    :x-large="xl"
    :text-color="(textColor) ? textColor : undefined"
    @click="emitClicked"
    filter
  >{{text}}</v-chip>
</template>

<script>
export default {
  name: 'Chip',
  props: ['text', 'color', 'size', 'textColor'],
  data: () => ({
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false,
    active: false,
  }),
  methods: {
    emitClicked() {
      this.active = !this.active;
      this.$emit('clicked', this.active);
    },
  },
  mounted() {
    switch (this.size) {
      case 'x-small':
        this.xs = true;
        break;
      case 'small':
        this.sm = true;
        break;
      case 'medium':
        this.md = true;
        break;
      case 'large':
        this.lg = true;
        break;
      case 'extra-large':
        this.xl = true;
        break;
      default:
        break;
    }
  },
};
</script>
