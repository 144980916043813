<template>
  <v-container fluid>
    <v-card flat class="mx-auto d-block" max-width="2140">
      <v-card-title class="headline">
        No Image Processing Plan
      </v-card-title>
      <v-card-text>
        <span>You currently do not have access to process images.</span>
        <br />
        <span>Please visit our
          <!-- <v-btn text to="/billing/subscription-plans">subscriptions</v-btn> -->
          <router-link to="/billing/subscription-plans/access">subscriptions</router-link>
          page under the billing tab.
        </span>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'NoProcessingImageAccess',
};
</script>
