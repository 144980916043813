import platformBackendClient from '@backend-clients/platform-backend';

export default {
  /*
    |-----------------------------------------------|
    |   @method       all                           |
    |   @parameter    query <String>                |
    |   @description  Fetches a list of companies   |
    |-----------------------------------------------|
  */
  all: () => platformBackendClient.get('/api/companies'),

  /*
    |-----------------------------------------------|
    |   @method       get                           |
    |   @parameter    cid <String>                  |
    |   @description  Fetches a company by company  |
    |                 id                            |
    |-----------------------------------------------|
  */
  get: (cid) => platformBackendClient.get(`/api/companies/${cid}`),

  /*
    |-----------------------------------------------|
    |   @method       create                        |
    |   @parameter    company <Object>              |
    |   @description  Creates a new company         |
    |-----------------------------------------------|
  */
  create: (company) => platformBackendClient.post('/api/companies', company),

  /*
    |-----------------------------------------------|
    |   @method       update                        |
    |   @parameter    id <String>, updates <Object> |
    |   @description  Updates a company by id       |
    |-----------------------------------------------|
  */
  update: (id, updates) => platformBackendClient.put(`/api/companies/${id}`, updates),

  /*
    |-----------------------------------------------|
    |   @method       delete                        |
    |   @parameter    id <String>                   |
    |   @description  Deletes a company by id       |
    |-----------------------------------------------|
  */
  delete: (id) => platformBackendClient.delete(`/api/companies/${id}`),

  allowAutoSignup: () => platformBackendClient.get('/api/companies/allow-auto-signup'),
};
