// eslint-disable-next-line import/prefer-default-export
export const retry = async (fn, retries = 3, delay = 0) => {
  try {
    return await fn();
  } catch (err) {
    if (retries > 0) {
      await new Promise((resolve) => setTimeout(resolve, delay));
      return retry(fn, retries - 1, delay);
    }
    throw err;
  }
};
