<template>
  <v-container fluid id="clustering-uncertainty">
    <div id="clustering-uncertainty-display" v-if="!editingFolder">
      <span class="text-caption">{{ clusteringUncertaintyValue }}</span>
    </div>
    <div id="clustering-uncertainty-editor" v-else>
      <v-select
        :items="['Yes', 'No']"
        dense
        outlined
        v-model="clusteringUncertaintyValue"
        label="Clustering uncertainty"
        @change="changeClusteringUncertainty"
      >
      </v-select>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ClusteringUncertainty',
  props: ['folder'],
  data: () => ({
    edit: false,
    clusteringUncertaintyValue: '',
  }),
  methods: {
    ...mapActions(['updateFolder', 'setNotification', 'setClusteringUncertainty']),
    changeClusteringUncertainty(val) {
      this.setClusteringUncertainty(val === 'Yes');
    },
  },
  computed: {
    ...mapGetters(['currentProject', 'editingFolder', 'clusteringUncertainty', 'currentFolder', 'allFolders']),
  },
  watch: {
    currentFolder(newFolder) {
      // eslint-disable-next-line no-nested-ternary
      this.clusteringUncertaintyValue = newFolder.clustering_uncertainty ? 'Yes' : 'No';
    },
    clusteringUncertainty(newVal) {
      this.clusteringUncertaintyValue = newVal ? 'Yes' : 'No';
    },
    allFolders() {
      this.clusteringUncertaintyValue = this.clusteringUncertainty ? 'Yes' : 'No';
    },
  },
  mounted() {
    // eslint-disable-next-line no-nested-ternary
    this.clusteringUncertaintyValue = this.currentFolder.clustering_uncertainty ? 'Yes' : 'No';
  },
};
</script>
