<template>
  <v-card>
    <v-card-title class="headline">Unpausing the {{ subscriptionName }}</v-card-title>
    <v-card-text id="pausing-description">
      Unpausing the {{ subscriptionName }} will enable your access to the plan services immediately.
    </v-card-text>
     <v-card-actions>
      <v-dialog v-model="unpauseSub" width="450">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            id="pause-sub-btn"
            v-on="on"
            v-bind="attrs"
            text
            color="primary"
          >{{ `Unpause ${currentSubscription.plan.nickname} Plan` }}</v-btn>
        </template>
        <UnpauseSubscriptionConfirm
          :title="`Unpause ${currentSubscription.plan.nickname} Plan`"
          :id="currentSubscription.id"
          :content="`
          Unpausing the ${currentSubscription.plan.nickname} Plan
          will immediately enable your services for this plan.
          You will be billed for this plan again under your default payment method.
          `"
          v-on:closeUnpauseSub="closeUnpauseDialog"
        />
      </v-dialog>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import UnpauseSubscriptionConfirm from '@components/dashboard-settings/access/confirms/UnpauseSubscriptionConfirm.vue';

export default {
  name: 'UnpausePlanSection',
  components: {
    UnpauseSubscriptionConfirm,
  },
  props: ['subscriptionName', 'buttonText', 'content'],
  data: () => ({
    unpauseSub: false,
  }),
  computed: {
    ...mapGetters(['currentSubscription']),
  },
  methods: {
    closeUnpauseDialog() {
      this.unpauseSub = false;
    },
  },
};
</script>
