<template>
  <v-card tile elevation="4" width="250" height="150" class="ma-4">
    <v-card-title class="headline">
      {{this.images.length}}
    </v-card-title>
    <v-card-subtitle class="subtitle">
      {{type}}
    </v-card-subtitle>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-dialog v-model="infoModel">
        <template v-slot:activator="{ on, attrs }">
          <v-btn text color="primary" small v-on="on" v-bind="attrs">
            <span>More Info</span>
            <v-icon small right>info</v-icon>
          </v-btn>
        </template>
        <FaultSummary
          :type="type"
          :images="images"
          v-on:closeDialog="shouldClose"
        />
      </v-dialog>
    </v-card-actions>
  </v-card>
</template>

<script>
import FaultSummary from '@components/analytics/insights/fault_summary/FaultSummary.vue';

export default {
  name: 'FaultSummaryCard',
  props: ['type', 'images'],
  components: {
    FaultSummary,
  },
  data: () => ({
    infoModel: false,
  }),
  methods: {
    shouldClose() {
      this.infoModel = false;
    },
  },
};
</script>
