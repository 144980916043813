export default (fileList) => {
  const imageFiles = Array.from(fileList)
    .filter((file) => file.type.indexOf('image/') === 0);
  const jpegFiles = Array.from(fileList)
    .filter((file) => file.type === 'image/jpeg');

  const removedFileAmount = Math.abs(imageFiles.length - jpegFiles.length);
  const message = (removedFileAmount > 0) ? `${removedFileAmount} file(s) were not jpeg files and were removed...` : '';
  console.log(message);

  return {
    imageFiles: jpegFiles,
    message,
  };
};
