import firebase from '@database';

const delay = (ms) => new Promise((res) => setTimeout(res, ms));
class Uploader {
  updateStatus = () => {}

  onError = () => {}

  constructor(params) {
    this.params = params;
    this.worker = null;
    this.fetchWorkerJs();
  }

  fetchWorkerJs = async () => {
    try {
      const res = await fetch(
        `${process.env.VUE_APP_V2_BACKEND_URL}/api/v1/static//workers/uploader`,
        {
          method: 'GET',
          headers: {
            authorization: `Bearer ${await Uploader.getIdToken()}`,
          },
        },
      );
      const js = await res.text();
      const blob = new Blob([js], { type: 'application/javascript' });
      this.worker = new Worker(URL.createObjectURL(blob));
    } catch (err) {
      console.error(err);
    }
  }

  async startUpload() {
    let WaitCounter = 0;
    while (!this.worker) {
      WaitCounter += 1;
      // eslint-disable-next-line no-await-in-loop
      await delay(500);
      if (WaitCounter > 10) {
        throw new Error('Failed to load worker');
      }
    }
    const workerData = {
      ...this.params,
      backendUrl: process.env.VUE_APP_V2_BACKEND_URL,
      token: await Uploader.getIdToken(),
    };
    this.worker.postMessage({ type: 'START_UPLOAD', payload: workerData });
    this.worker.onmessage = async (msg) => {
      switch (msg.data.type) {
        case 'UPDATE_TOKEN':
          this.worker.postMessage({
            type: 'SET_TOKEN',
            payload: { token: await Uploader.getIdToken() },
          });
          break;
        case 'UPDATE_STATUS':
          this.updateStatus(msg.data.payload);
          break;
        case 'ON_ERROR':
          this.onError(msg.data.payload);
          break;
        default:
          break;
      }
    };
  }

  static getIdToken() {
    return firebase.auth()
      .currentUser
      .getIdToken();
  }
}
export default Uploader;
