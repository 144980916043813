<template>
  <v-row>
    <v-progress-circular
      color="light-blue-darken-2"
      id="loading-circular"
      :indeterminate="true"
      class="text-center mx-auto"
    ></v-progress-circular>
  </v-row>
</template>

<script>
export default {
  name: 'Loading',
};
</script>
