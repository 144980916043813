<template>
  <v-snackbar
  v-model="progressOn"
  timeout="-1"
  top right
  >
    <b>{{ progressTitle }} [{{progressCompleted}} / {{progressTotal}}]</b>
    <br><br>
    <v-progress-linear
      buffer-value="0"
      :value="progressPercentge"
      height="10"
      rounded
      stream
    ></v-progress-linear>
  </v-snackbar>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'GeneralProgressBar',
  data: () => ({
    progress: 0,
    status: '',
  }),
  computed: {
    ...mapGetters(['progressOn', 'progressTitle', 'progressPercentge', 'progressCompleted', 'progressTotal']),
  },
};
</script>
