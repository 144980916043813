<template>
  <v-card>
    <v-card-title class="headline">
      Are you sure you want to delete this card?
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="pa-4" id="delete-content">
      <p class="font-weight-light text">
        You are about to remove the payment method ending in {{last4}}.
      </p>
      <p class="font-weight-light text">
        Please confirm that you want to remove this credit card.
      </p>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        text
        id="close-delete-btn"
        color="primary"
        @click="closeDelete"
      >Close</v-btn>
      <v-btn
        text
        id="confirm-delete-btn"
        color="primary"
        @click="confirmDelete"
      >Confirm</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'DeletePaymentConfirmation',
  props: ['payment'],
  data: () => ({
    last4: '',
  }),
  methods: {
    ...mapActions(['detachPaymentMethodFromCustomer']),
    closeDelete() {
      this.$emit('closeDeleteDialog');
    },
    confirmDelete() {
      this.detachPaymentMethodFromCustomer(this.payment.id)
        .then(() => {
          this.$emit('closeDeleteDialog');
        });
    },
  },
  mounted() {
    this.last4 = this.payment.card.last4;
  },
};
</script>
