<template>
  <v-btn
    color="primary"
    small
    id="retrain-model-btn"
    :loading="handlingRequest"
    @click="requestModelRetraining"
  >
    Retrain Model
  </v-btn>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import services from '@services';

export default {
  name: 'RetrainModelButton',
  data() {
    return {
      handlingRequest: false,
    };
  },
  methods: {
    ...mapActions(['setNotification']),
    async requestModelRetraining() {
      const { pid } = this.$route.params;
      this.handlingRequest = true;

      try {
        const payload = {
          company: this.currentCompany.companyName,
          company_id: this.currentCompany.cid,
          project: this.currentProject.name,
          project_id: pid,
          user: this.currentUser.name,
          subject: `Request for Model Retraining for ${this.currentCompany.companyName} (${process.env.VUE_APP_ENVIRONMENT})`,
        };

        const res = await services.email.send('model_retrain', payload);

        this.setNotification({
          success: true,
          message: res.data.message,
          color: '',
        });
        this.handlingRequest = false;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Error: ', err);
        this.setNotification({
          success: true,
          message: err.response.data.body.message,
          color: 'error',
        });
        this.handlingRequest = false;
      }
    },
  },
  computed: {
    ...mapGetters(['currentUser', 'currentProject', 'currentCompany']),
  },
};
</script>
