<template>
  <v-container style="margin-top: 40px; margin-bottom: 40px">
    <h2 class="headline text-center">
      There are no processed images. Go back to the original image tab to process
    </h2>
    <div class="has-images">
      <h2 class="headline text-center">
        You have not processed any images for this project yet.
      </h2>
      <p class="text-center">
        Please go back to the <b>Original Images</b> tab to process your images
      </p>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'NoProcessedImage',
  props: ['pid', 'cid', 'tab'],
  data: () => ({
    class: [
      'h2',
      'There are no images within this project. Please upload your first images',
      '3.75rem',
      '300',
      '-0.03125rem',
      0,
    ],
  }),
  computed: {
    ...mapGetters(['allImages', 'currentProject', 'uploadingImages']),
  },
  watch: {
    allImages: {
      deep: true,
      handler(newImageList) {
        const processedImages = newImageList.filter((image) => image.processedImageUrl);
        // If processedImages array has images in it, redirect to the AI tab
        if (processedImages.length > 0) {
          const [image] = processedImages;
          this.$router.push(`/project/${image.project_id}/images/ai/${image.filename}/processed`);
        }
      },
    },
  },
  created() {
    // If there are processed image, just reroute to { name: 'ProcessedImages' }
    const processedImages = this.allImages.filter((image) => image.processedImageUrl);
    if (processedImages.length > 0) {
      const [image] = processedImages;
      this.$router.push(`/project/${image.project_id}/images/ai/${image.filename}/processed`);
    }
  },
};
</script>
