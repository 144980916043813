<template>
  <div id="insights-filters">
    <StructureSelect :images="reformattedImages" :index="index" :pImages="pImages" />
    <FaultSummaryFilters />
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import StructureSelect from '@components/analytics/insights/map_section/StructureSelect.vue';
import FaultSummaryFilters from '@components/analytics/insights/fault_summary/FaultSummaryFilters.vue';

export default {
  name: 'InsightsFilters',
  props: ['images', 'index', 'pImages'],
  components: {
    StructureSelect,
    FaultSummaryFilters,
  },
  computed: {
    reformattedImages() {
      const imageList = cloneDeep(this.images);
      return imageList.map((image) => {
        const current = cloneDeep(image);
        const originalFilename = image.filename;

        // If the original filename includes a '+', then take everything after the '+' sign
        // Otherwise 'niceFilename' takes the value of 'originalFilename'
        if (originalFilename.includes('+')) [, current.niceFilename] = image.filename.split('+');
        else current.niceFilename = originalFilename;

        // If the filename at this point includes a '!' sign,
        // then take truncate it to the filename after the !
        if (current.niceFilename.includes('!')) [, current.niceFilename] = current.niceFilename.split('!');

        return current;
      });
    },
  },
};
</script>
