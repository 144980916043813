<template>
  <v-skeleton-loader
    v-bind="attrs"
    :type="type"
  ></v-skeleton-loader>
</template>

<script>
export default {
  name: 'SkeletonLoader',
  props: {
    type: {
      type: String,
      default: 'card',
    },
  },
  data: () => ({
    attrs: {
      class: 'mb-6',
      boilerplate: false,
      elevation: 2,
    },
  }),
  inject: {
    theme: {
      default: {
        isDark: false,
      },
    },
  },
};
</script>
