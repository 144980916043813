import axios from 'axios';
import firebase from '@database';
import { baseURL } from '@utils/endpoint';
import { eraseCookie } from '@authentication/cookie';

const platformBackendClient = axios.create({
  baseURL,
  maxContentLength: Infinity,
  maxBodyLength: Infinity,
});

const unauthorizedRedirect = () => {
  // Reload the browser because it clears the
  // component states while checking for authentication tokens
  // and app.vue will handle the redirect
  window.location.reload();
};

platformBackendClient.interceptors.request.use(async (config) => {
  const c = { ...config };

  if (firebase.auth().currentUser) {
    const idToken = await firebase.auth().currentUser.getIdToken();
    c.headers.common.Authorization = `Bearer ${idToken}`;
  }

  return c;
});

platformBackendClient.interceptors.response.use((response) => response, (err) => {
  // eslint-disable-next-line no-console
  console.error('err: ', err.response);
  // If status code is 401, then it is an unauthorized token. Move user back
  // to login page
  if (err.response.status === 401) {
    const user = firebase.auth().currentUser;
    eraseCookie('2fa');
    if (user) {
      firebase.auth().signOut()
        .then(() => unauthorizedRedirect());
    } else {
      unauthorizedRedirect();
    }
  }

  return Promise.reject(err);
});

export const setAuthToken = (token) => {
  if (token) {
    platformBackendClient.defaults.headers.common.Authorization = token; // Apply to every request
  } else delete platformBackendClient.defaults.headers.common.Authorization; // Deletes auth header
};
export default platformBackendClient;
