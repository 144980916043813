import { uniq, compact } from 'lodash';
import abbreviations from '@components/images/abbreviations';
import moment from 'moment';

// List of severity levels that are considered 'defective'
const DEFECTIVE_LABELS = ['High', 'Medium', 'Low'];
const DATE_FORMAT = 'YYYY-MM-DD HH:mm';

export default {
  getDateInspected: (image) => {
    if (!image.timestamp) return 'N/A';
    return moment(image.timestamp, 'YYYYMMDD_HHmmss').format(DATE_FORMAT);
  },

  getDateProcessed: (image) => {
    if (!image.date) return 'N/A';

    return moment(image.date).format(DATE_FORMAT);
  },

  getBucket: (image) => image.bucket,

  getSeverity: (image) => {
    if (!image.processedImageUrl) return '';

    const { severity } = image.process_tracking.slice(-1)[0];
    return severity;
  },

  getTitle: (image) => {
    if (!image.processedImageUrl) return '';

    const { labels } = image.process_tracking.slice(-1)[0];
    const uniqueLabels = compact(uniq(labels));
    const labelList = uniqueLabels.map((label) => {
      if (typeof label === 'object') {
        return label.label;
      }
      if (label.includes(':')) {
        return label.split(':')[1];
      }
      return label;
    });
    // eslint-disable-next-line arrow-body-style
    const filtered = abbreviations.filter((label) => {
      return DEFECTIVE_LABELS.includes(label.severity) && labelList.includes(label.faultType);
    });

    return filtered.map((label) => label.faultType).join(', ');
  },

  getDefectiveLabels: (labels) => labels.filter((label) => {
    if (typeof label === 'object') {
      return DEFECTIVE_LABELS.includes(label.severity);
    }
    if (label.includes(':')) {
      const [severity] = label.split(':');
      return DEFECTIVE_LABELS.includes(severity);
    }

    const [labelData] = abbreviations.filter((abbr) => abbr.faultType === label);

    return DEFECTIVE_LABELS.includes(labelData.severity);
  }),

  getDescription(image) {
    if (!image.processedImageUrl) return '';

    const labelList = compact(uniq(image.process_tracking.slice(-1)[0].labels));

    const defectiveLabels = this.getDefectiveLabels(labelList);

    const normalizedLabels = defectiveLabels.map((label) => {
      if (typeof label === 'object') {
        return `${label.severity}:${label.label}`;
      }

      if (label.includes(':')) {
        return label;
      }

      const [labelData] = abbreviations.filter((abbr) => abbr.faultType === label);
      return `${labelData.severity}:${label}`;
    });
    return uniq(normalizedLabels).join(', ');
  },

  getAttachment(folder, image, project) {
    return encodeURI(`https://console.cloud.google.com/storage/browser/${this.getBucket(image)}/avantis_wms/${project}/${folder.path}/`);
  },

  getStartLongitude: (folder) => {
    if (folder.location) {
      return folder.location[1];
    }

    return '';
  },

  getStartLatitude: (folder) => {
    if (folder.location) {
      return folder.location[0];
    }

    return '';
  },
};
