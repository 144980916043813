export default {
  Projects: (params) => `/project/${params.pid}`,
  ProjectsLayout: (params) => `/project/${params.pid}`,
  Project: (params) => `/project/${params.pid}`,
  NoImages: (params) => `/project/${params.pid}/images/original/no-images`,
  Images: (params) => `/project/${params.pid}/images/${params.tab}/${params.filename}`,
  OriginalImages: (params) => `/project/${params.pid}/images/original/${params.filename}`,
  HumanInTheLoop: (params) => `/project/${params.pid}/images/${params.tab}/${params.filename}/annotate`,
  NoProcessedImages: (params) => `/project/${params.pid}/ai/no-processed-images/processed`,
  ProcessedImages: (params) => `/project/${params.pid}/images/ai/${params.filename}/processed`,
  Insights: (params) => `/analytics/insights/${params.pid}`,
  HumanInTheLoopAnalytics: (params) => `/analytics/insights/${params.pid}/annotate/${params.filename}`,

  // Gets the first image within the folder
  getFirstImageInFolder: (folders, images) => {
    if (folders.length < 1) return images[0];

    // Find the first folder that contains images within it
    const foldersWithImages = folders.filter((folder) => {
      const currentFolder = folder;

      const imagesInCurrentFolder = images.filter((image) => image.folder === currentFolder.path);
      return imagesInCurrentFolder.length > 0;
    });

    const [firstFolder] = foldersWithImages;

    // Returns first image within that list
    return images
      .filter((image) => image.folder)
      .filter((image) => image.folder === firstFolder.path)[0];
  },
};
