<template>
  <v-card>
    <v-card-title class="headline">Edit Card Ending in {{payment.card.last4}}</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-container fluid>
        <!-- Basic Info / Contact -->
        <span class="text-h4">Contact Information</span>
        <v-row>
          <v-col xs="12" sm="12" md="6" lg="6" xl="6">
            <v-text-field
              id="card-name"
              outlined
              label="Full Name"
              v-model="paymentDetails.billing_details.name"
            ></v-text-field>
          </v-col>
          <v-col xs="12" sm="12" md="6" lg="6" xl="6">
            <v-text-field
              id="card-email"
              outlined
              label="Email Address"
              v-model="paymentDetails.billing_details.email"
            ></v-text-field>
          </v-col>
          <v-col xs="12" sm="12" md="6" lg="6" xl="6">
            <v-text-field
              id="card-phone"
              outlined
              label="Phone Number"
              @input="formatPhone"
              v-model="paymentDetails.billing_details.phone"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- Address -->
        <span class="text-h4">Billing Address</span>
        <v-row>
          <v-col xs="12" sm="12" md="6" lg="6" xl="6">
            <v-text-field
              id="card-street-address"
              outlined
              label="Street Address"
              v-model="paymentDetails.billing_details.address.line1"
            ></v-text-field>
          </v-col>
          <v-col xs="12" sm="12" md="6" lg="6" xl="6">
            <v-text-field
              id="card-city-address"
              outlined
              label="City"
              v-model="paymentDetails.billing_details.address.city"
            ></v-text-field>
          </v-col>
          <v-col xs="12" sm="12" md="6" lg="6" xl="6">
            <v-text-field
              id="card-state-address"
              outlined
              label="State"
              v-model="paymentDetails.billing_details.address.state"
            ></v-text-field>
          </v-col>
          <v-col xs="12" sm="12" md="6" lg="6" xl="6">
            <v-text-field
              id="card-zip-address"
              outlined
              label="Zip Code"
              v-model="paymentDetails.billing_details.address.postal_code"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- Expiration Date -->
        <v-row>
         <v-col xs="12" sm="12" md="6" lg="6" xl="6">
           <span class="text-h4">Credit Card Expiration Date</span>
            <v-row>
              <v-col cols="6">
                <v-select
                  id="card-exp-month"
                  v-model="paymentDetails.card.exp_month"
                  outlined
                  label="Expiration Month"
                  :items="months"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  id="card-exp-year"
                  v-model="paymentDetails.card.exp_year"
                  outlined
                  label="Expiration Year"
                  :items="years"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        id="close-payment-form"
        color="primary"
        @click="close"
      >Close</v-btn>
      <v-btn
        id="save-card"
        color="primary"
        @click="savePayment"
      >Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'EditPaymentForm',
  props: ['payment', 'index'],
  data: () => ({
    paymentDetails: {
      billing_details: {
        address: {
          city: '',
          country: 'US',
          line1: '',
          line2: null,
          postal_code: '',
          state: '',
        },
        email: '',
        name: '',
        phone: '',
      },
      card: {
        exp_month: '',
        exp_year: '',
      },
    },
    months: [],
    years: [],
  }),
  methods: {
    ...mapActions(['updatePaymentMethod']),
    close() {
      this.$emit('closeEditForm', this.index);
    },
    savePayment() {
      const { paymentDetails } = this;

      const payload = {
        id: this.payment.id,
        data: paymentDetails,
      };

      this.updatePaymentMethod(payload)
        .then(() => this.close());
    },
    formatPhone() {
      const number = this.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.phone = !number[2] ? number[1] : `(${number[1]}) ${number[2]}${number[3] ? `-${number[3]}` : ''}`;
    },
  },
  mounted() {
    const currentYear = new Date().getFullYear();
    for (let i = 0; i <= 20; i += 1) {
      const newYear = currentYear + i;
      this.years.push(`${newYear}`);
    }

    for (let i = 1; i < 13; i += 1) {
      const currentMonth = (i < 10) ? `0${i}` : `${i}`;
      this.months.push(currentMonth);
    }
    const { name } = this.payment.billing_details;

    // Setting up contact info
    this.paymentDetails.billing_details.name = (name === '') ? '' : name;
    this.paymentDetails.billing_details.email = this.payment.billing_details.email;
    this.paymentDetails.billing_details.phone = this.payment.billing_details.phone;

    // Setting up card expiration date
    this.paymentDetails.card.exp_month = (this.payment.card.exp_month < 10) ? `0${this.payment.card.exp_month}` : `${this.payment.card.exp_month}`;
    this.paymentDetails.card.exp_year = `${this.payment.card.exp_year}`;

    // Setting up address
    this.paymentDetails.billing_details.address.line1 = this.payment.billing_details.address.line1;
    this.paymentDetails.billing_details.address.city = this.payment.billing_details.address.city;
    this.paymentDetails.billing_details.address.state = this.payment.billing_details.address.state;
    const zip = this.payment.billing_details.address.postal_code;
    this.paymentDetails.billing_details.address.postal_code = zip;
  },
};
</script>
