/* eslint-disable no-shadow */
const state = {
  width: 0,
  height: 0,
};

const getters = {
  screenSize: (state) => ({ width: state.width, height: state.height }),
  extraSmall: (state) => state.width < 600,
  small: (state) => state.width >= 600 && state.width < 960,
  medium: (state) => state.width >= 960 && state.width < 1264,
  large: (state) => state.width >= 1264 && state.width < 1904,
  extraLarge: (state) => state.width > 1904,
  isLaptop: (state) => state.width >= 1224,
  // screenWidth: (state) => state.width,
  // screenHeight: (state) => state.height,
};

const actions = {
  handleScreenSize({ commit }, dimensions) {
    commit('adjust_screen', dimensions);
  },
};

const mutations = {
  adjust_screen: (state, dimensions) => {
    const { width, height } = dimensions;
    state.width = width;
    state.height = height;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
