<template>
  <v-container fluid>
    <v-select
      outlined
      label="Filter by Severity"
      :items="items"
      multiple
      chips
      small-chips
      item-color="primary"
      clearable
      v-model="value"
      @change="onFilterChange"
    ></v-select>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import severities from '@utils/severity-level';

export default {
  name: 'SeverityFilter',
  props: ['all', 'custom'],
  data: () => ({
    items: [],
    value: [],
  }),
  computed: {
    ...mapGetters(['currentFilterStatus']),
    allOrCustom() {
      const filter = this.all ? 'all' : 'custom';
      return filter;
    },
  },
  watch: {
    allOrCustom(newStatus) {
      if (newStatus === 'all') this.value = [...severities];
      else this.value = [];

      const filter = cloneDeep(this.currentFilterStatus);
      filter.severity.value = this.value;
      this.setFilterStatus(filter);
      this.setInsightsSeverities(this.value);
    },
    // Keeps this.value v-model consistent when the UI switches between
    // scrollable and un-scrollable
    // eslint-disable-next-line func-names
    'currentFilterStatus.severity': function (newSeverity) {
      this.value = newSeverity.value;
    },
  },
  methods: {
    ...mapActions(['setInsightsSeverities', 'setFilterStatus']),
    onFilterChange() {
      if (this.value === null || this.value === undefined) this.value = [];
      const filterStatus = cloneDeep(this.currentFilterStatus);
      filterStatus.severity.value = this.value;

      this.setInsightsSeverities(this.value);
      this.setFilterStatus(filterStatus);
    },
  },
  mounted() {
    this.setInsightsSeverities(this.value);
    this.items = [...severities];
    this.value = [...severities];
  },
};
</script>
