<template>
  <v-container fluid>
    <span class="font-weight-black float-left">Billing Address</span>
    <v-container fluid class="my-8" v-if="Object.keys(payment).length > 0">
      <v-card flat v-if="!NoDefaultAddress">
        <v-card-title>{{ address.line1 }}</v-card-title>
        <v-card-subtitle class="subtitle">
          <p>
            {{ address.city }}, {{ address.state }}, {{ address.postal_code }}
          </p>
          <p>408 881 4814</p>
        </v-card-subtitle>
        <v-card-actions>
          <v-dialog v-model="addressDialog" width="600">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                text
                v-on="on"
                v-bind="attrs"
              >Edit Billing Address</v-btn>
            </template>
            <AddressForm
              :payment="payment"
              v-on:closeAddress="close"
            />
          </v-dialog>
        </v-card-actions>
      </v-card>
      <NoAddress v-else />
    </v-container>
  </v-container>
</template>

<script>
import AddressForm from '@components/checkout/AddressForm.vue';
import NoAddress from '@components/checkout/NoAddress.vue';

export default {
  name: 'CheckoutAddress',
  props: ['address', 'id', 'payment'],
  data: () => ({
    addressDialog: false,
  }),
  components: {
    AddressForm,
    NoAddress,
  },
  methods: {
    close() {
      this.addressDialog = false;
    },
  },
  computed: {
    NoDefaultAddress() {
      const { address } = this;
      const addressData = Object.keys(address);
      return addressData.filter((data) => address[data] !== null && data !== 'postal_code') < 1;
    },
    paymentAddress() {
      const { payment } = this;
      const { address } = payment.billing_details;
      return address;
    },
  },
  created() {
    // if (this.id) this.getPaymentMethodById(this.id);
  },
};
</script>
