import Vue from 'vue';
import vue2Dropzone from 'vue2-dropzone';
import ZoomOnHover from 'vue-zoom-on-hover';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import Hotjar from 'vue-hotjar';

import { decrypt } from '@utils/crypto';
import { readCookie } from '@authentication/cookie';

import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import firebase from './firebase/index';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css';
import '@recogito/annotorious/dist/annotorious.min.css';
import './assets/css/annotorious.customizations.css';

Vue.config.productionTip = false;
window.eventBus = new Vue();

const version = {
  major: 1,
  minor: 0,
  patch: 0,
  date: '2021-10-08_12-16-45',
};
// eslint-disable-next-line no-console
console.log(`Running on version: ${version.major}.${version.minor}.${version.patch}`);

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: 'https://31eeeda03a0d46e68c81e23d972aefbe@o553041.ingest.sentry.io/5679720',
    logErrors: true,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [
          'localhost',
          'powerai-platform-backend-dot-buzz-ai-stage.uc.r.appspot.com',
          'firestore.googleapis.com',
          'buzzappengine.poweraiapi.com',
          '34.117.182.46',
        ],
      }),
    ],
    tracingOptions: {
      trackComponents: true,
    },
    environment: process.env.NODE_ENV,
    sampleRate: 0.6,
    tracesSampleRate: 0.5,
  });
} else {
  window.store = store; // expose global store for tests and development
  window.firebase = firebase;
  // HACK - cypress serialized objects are not accepted by firebase
  // this hack method allows e2e tests to call store methods which use firebase
  window.store.cy_dispatch = (name, data) => store
    .dispatch(name, data && JSON.parse(JSON.stringify(data)));
}

if (localStorage.getItem('jwtToken')) {
  // eslint-disable-next-line no-console
  console.log('Detected old jwtToken within localStorage. Removing...');
  localStorage.removeItem('jwtToken');
}

Vue.use(vue2Dropzone);
Vue.use(ZoomOnHover);
// add Hotjar plugin
Vue.use(Hotjar, {
  id: process.env.VUE_APP_HOTJAR_SITE_ID,
  isProduction: true,
});

let app;
firebase.auth().onAuthStateChanged((user) => {
  const authenticated = user !== null;
  const twoFactorAuthenticated = decrypt(readCookie('2fa')) === process.env.VUE_APP_2FA_VALIDATION;

  store.commit('set_first_factor', authenticated);
  store.commit('set_two_factor', twoFactorAuthenticated);

  if (!app) {
    // Create an global event bus
    Vue.prototype.$eventBus = new Vue();
    app = new Vue({
      router,
      store,
      vuetify,
      vueDropzone: vue2Dropzone,
      render: (h) => h(App),
    }).$mount('#app');
  }
});
