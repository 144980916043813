import platformBackendClient from '@backend-clients/platform-backend';
import { handlePaymentThatRequiresCustomerAction, handleRequiresPaymentMethod } from '../../../methods/helpers';

const state = {
  subscriptions: [],
  subscription: {},
  requesting: false,
  usage: {},
  loading: false,
  load: false, // for singular subscription
  loadRecord: false,
  paying: false,
  pause: false,
  recording: false,
  notification: {
    success: false,
    message: '',
  },
};

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const getters = {
  allSubscriptions: (state) => state.subscriptions,
  currentSubscription: (state) => state.subscription,
  subscriptionUsage: (state) => state.usage,
  loadingSubscriptions: (state) => state.loading,
  loadingSubscription: (state) => state.load,
  loadingUsageRecord: (state) => state.loadRecord,
  processingPayment: (state) => state.paying,
  pausingSubscription: (state) => state.pause,
  recordingUsage: (state) => state.recording,
  requestingPlanChange: (state) => state.requesting,
  subscriptionNotification: (state) => state.notification,
};

const actions = {
  setSubscriptionNotification({ commit }, notification) {
    commit('notification_subscriptions', notification);
    setTimeout(() => {
      commit('notification_subscriptions', { success: false, message: '' });
    }, 3000);
  },
  setLoadingSubscription({ commit }) { commit('loading_subscription'); },
  setCurrentSubscription({ commit }, subscription) { commit('fetch_subscription', subscription); },
  createSubscription({ commit }, ids) {
    commit('process_payment');
    const { customerId, paymentMethodId, priceId } = ids;

    return new Promise((resolve, reject) => {
      platformBackendClient.post(`/api/subscription/${customerId}`, { paymentMethodId, priceId })
        .then((res) => {
          if (res.error) {
            // The card had an error when trying to attach it to the customer
            throw res;
          }
          return res;
        })
        .then((result) => ({
          // Use the stripe 'object' property returned
          // by Stripe. Add the additional details we need
          subscription: result.data,
          paymentMethodId,
          priceId,
        }))
        .then(handlePaymentThatRequiresCustomerAction)
        .then(handleRequiresPaymentMethod)
        .then((result) => {
          commit('create_subscription', result.data);
          resolve(result.data);
        })
        .catch((err) => reject(err));
    });
  },
  async getSubscriptionById({ commit }, subscriptionId) {
    commit('loading_subscription');

    return new Promise((resolve, reject) => {
      platformBackendClient.get(`/api/subscription/${subscriptionId}`)
        .then((res) => {
          if (res.status === 200) commit('fetch_subscription', res.data);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },
  async getSubscriptionByType({ commit }, type) {
    commit('loading_subscription');
    return new Promise((resolve, reject) => {
      platformBackendClient.get(`/api/subscription/type/${type}`)
        .then((res) => {
          const [subscription] = res.data;
          commit('fetch_subscription', subscription);
          resolve(subscription);
        })
        .catch((err) => reject(err));
    });
  },
  async updateSubscriptionById({ commit }, payload) {
    const { id, data } = payload;

    return new Promise((resolve, reject) => {
      platformBackendClient.put(`/api/subscription/${id}`, data)
        .then((res) => {
          commit('update_subscription', res.data);
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  },
  cancelSubscription({ commit }, subscriptionId) {
    return new Promise((resolve, reject) => {
      platformBackendClient.post(`/api/subscription/deactivate/${subscriptionId}`)
        .then((res) => {
          commit('deactivate_subscription', res.data);
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  },
  recordSubscriptionUsage({ commit }, payload) {
    commit('record_subscription_usage');
    const { id, quantity } = payload;

    return new Promise((resolve, reject) => {
      platformBackendClient.post(`/api/subscription-items/${id}`, { quantity })
        .then((res) => {
          commit('record_usage', res.data);
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  },
  getCurrentUsageRecord({ commit }, subscriptionItemId) {
    commit('loading_usage_record');

    return new Promise((resolve, reject) => {
      platformBackendClient.get(`/api/subscription-items/usage_record/${subscriptionItemId}`)
        .then((res) => {
          commit('fetch_record_usage', res.data);
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  },
  changePlan({ commit }, payload) {
    commit('request_change');
    const { customerId, data } = payload;

    return new Promise((resolve, reject) => {
      platformBackendClient.post(`/api/subscription/request-change/${customerId}`, data)
        .then((res) => {
          commit('notification_subscriptions', {
            success: true,
            message: 'Your request to change plans are submitted. A representative will be in touch with you shortly',
          });
          setTimeout(() => {
            commit('notification_subscriptions', { success: false, message: '' });
          });
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  },
};

const mutations = {
  notification_subscriptions: (state, notification) => { state.notification = notification; },
  request_change: (state) => { state.requesting = true; },
  record_subscription_usage: (state) => { state.recording = true; },
  loading_usage_record: (state) => { state.loadRecord = true; },
  loading_subscription: (state) => { state.load = true; },
  loading_subscriptions: (state, status = true) => { state.loading = status; },
  process_payment: (state) => { state.paying = true; },
  create_subscription: (state, subscription) => {
    state.subscriptions.unshift(subscription);
    state.paying = false;
  },
  fetch_subscriptions: (state, subscriptions) => {
    state.subscriptions = subscriptions;
    state.loading = false;
  },
  fetch_paid_products: (state, paidProducts) => { state.paidProducts = paidProducts; },
  fetch_subscription: (state, subscription) => {
    state.subscription = subscription;
    state.load = false;
  },
  fetch_record_usage: (state, recordUsage) => {
    state.usage = recordUsage;
    state.loadRecord = false;
  },
  update_subscription: (state, subscription) => { state.subscription = subscription; },
  record_usage: (state, usage) => { state.usage = usage; },
  pause_subscription: (state) => { state.pause = true; },
  deactivate_subscription: (state) => { state.loading = true; },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
