<template>
  <v-container fluid>
    <v-row>
      <v-col cols="4" class="mt-2">
        <v-container fill-height fluid>
          <v-row align="center" justify="center">
            <v-col>
              <DetectionSelector />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols="8">
        <div id="histogram-loader" class="pa-2" v-if="loadingImages">
          <SkeletonLoader type="image"/><SkeletonLoader type="image"/>
        </div>
        <HistogramV2 :images="allImages" title="Detected Faults Over Time" v-else />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import DetectionSelector from '@components/dashboard/fault-detections/DetectionSelector.vue';
import HistogramV2 from '@components/charts/HistogramV2.vue';
import SkeletonLoader from '@components/common/loaders/insights/SkeletonLoader.vue';
import helpers from '@methods/helpers';

export default {
  name: 'FaultDetections',
  props: {
    images: {
      type: Array,
      default: Array,
    },
  },
  components: {
    DetectionSelector,
    HistogramV2,
    SkeletonLoader,
  },
  data: () => ({
    attrs: {
      class: 'mb-5',
      boilerplate: false,
      elevation: 2,
    },
  }),
  inject: {
    theme: {
      default: {
        isDark: false,
      },
    },
  },
  computed: {
    ...mapGetters(['allImages', 'loadingImages', 'dashboardFilters']),
    imagesByDates() {
      const dateDictionary = {};

      this.allImages.forEach((image) => {
        const time = helpers.convertToDate(image.date).format('YYYY-MM-DD');
        if (!(time in dateDictionary)) {
          dateDictionary[time] = [];
        }
        dateDictionary[time].push(image);
      });

      return dateDictionary;
    },
  },
};
</script>

<style scoped>
  .detection-selector {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
</style>
