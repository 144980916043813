<template>
<v-container fluid>
  <v-card>
    <v-card-title class="headline" id="current-month-title">
      Current Month</v-card-title>
    <v-card-subtitle class="subtitle">{{dateRange()}}</v-card-subtitle>
    <v-card-text>
      <p class="font-weight-medium">
        Month-to-date total cost</p>
      <p class="font-weight-bold" id="current-month-cost">
        ${{new Intl.NumberFormat().format(cost)}}</p>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn small text color="primary" to="/billing/reports">View Report</v-btn>
    </v-card-actions>
  </v-card>
</v-container>
</template>

<script>
export default {
  name: 'CurrentMonthOverview',
  props: ['cost'],
  methods: {
    dateRange() {
      const today = new Date();
      const options = { month: 'long' };
      const month = new Intl.DateTimeFormat('en-US', options).format(today);
      return `${month} 1 - ${today.getDate()}, ${today.getFullYear()}`;
    },
  },
};
</script>
