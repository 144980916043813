<template>
  <v-card flat>
    <v-card-title class="headline">Select a verification method:</v-card-title>
    <v-card-text>
      <v-checkbox label="Email"
                  id="email-verification"
                  v-model="verification.email" @click="select('email')"></v-checkbox>
      <v-checkbox label="Phone"
                  id="phone-verification"
                  v-model="verification.phone" @click="select('phone')"></v-checkbox>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" text @click="navigateBack">Back</v-btn>
      <v-btn color="primary" id="verification-continue" text @click="continueStep">Continue</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'VerficationMethods',
  props: ['uid'],
  data: () => ({
    verification: {
      email: false,
      phone: false,
    },
    selected: '',
  }),
  methods: {
    ...mapActions(['getUserDocumentById']),
    select(type) {
      this.selected = type;
      const verificationTypes = Object.keys(this.verification);
      verificationTypes.forEach((verification) => {
        if (verification !== this.selected) this.verification[verification] = false;
        else this.verification[verification] = true;
      });
    },
    navigateBack() {
      this.$router.push('/forgot-password');
    },
    continueStep() {
      const type = (this.verification.email) ? 'email' : 'phone';

      this.$router.push(`/forgot-password/verify-user/${this.uid}/${type}`);
    },
  },
};
</script>
