/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { baseURL } from '@utils/endpoint';
import platformBackendClient from '@backend-clients/platform-backend';

const state = {
  metadatas: [],
  loading: false,
};

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const getters = {
  metaDataFiles: (state) => state.metadatas,
  loadingMetas: (state) => state.loading,
};

const actions = {
  // Saves metadata to Datastore
  async createMeta({ commit }, metadatas) {
    const res = await platformBackendClient.post(`${baseURL}/api/metadata/save_metadatas`, metadatas);
    if (res.status === 201) {
      commit('create_metadatas', res.data);
      return res;
    }
  },
  // sdfa
  async fetchMeta({ commit }, payload) {
    commit('set_loading', true);
    const { pid, cid } = payload;
    const res = await platformBackendClient.get(`${baseURL}/api/metadata/project/${pid}/company/${cid}`);
    if (res.status === 200) {
      commit('create_metadatas', res.data);
      commit('set_loading', false);
    }
  },
};

const mutations = {
  create_metadatas: (state, metadatas) => {
    state.metadatas = [...state.metadatas, ...metadatas];
  },
  set_loading: (state, status) => {
    state.loading = status;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
