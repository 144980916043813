<template>
  <CardSkeletonLoader v-if="loadingSubscription || loadingPaymentMethod" width="300" />
  <v-container fluid v-else>
    <v-card flat>
      <v-card-title class="headline card-headline">
        Your current subscription
      </v-card-title>
      <v-card-subtitle class="subtitle">
        {{ currentSubscription.plan.nickname }} Plan
      </v-card-subtitle>
      <v-divider></v-divider>
      <v-card-title class="headline sub-details">Subscription Details</v-card-title>
      <v-card-text>
        <span id="pay-start">
          Current period start: {{ convertTimestamp(currentSubscription.current_period_start) }}
        </span>
        <br />
        <span id="pay-end">
          Current period end: {{ convertTimestamp(currentSubscription.current_period_end )}}
        </span>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-title class="headline" id="default-pay-method">
        Default Payment Method
      </v-card-title>
        <v-list dense>
          <v-list-item>
            <v-list-item-content>
              <span class="font-weight-light" id="pay-description">
                This is your set customer default payment method, and will be used throughout
                all subscriptions that you are getting invoices for. Editing it will change the
                default payment method for your other plans as well.
              </span>
              <br />
              <br />
              <span class="font-weight-light" id="card-brand">
                {{getPayment(currentPaymentMethod.card.brand,currentPaymentMethod.card.last4)}}
              </span>
              <br/>
              <span class="font-weight-light" id="card-exp">
                {{
                  getExpire(currentPaymentMethod.card.exp_month, currentPaymentMethod.card.exp_year)
                }}
              </span>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      <v-card-actions>
        <v-btn
          text
          id="edit-default-payment-btn"
          color="primary"
          to="/billing/payment_methods"
        >Edit Default Payment Method</v-btn>
      </v-card-actions>
      <v-divider></v-divider>
      <PausePlanSection
        v-if="!paused"
        :subscriptionName="subscriptionName"
        :buttonText="buttonText"
        :content="pauseSubscriptionContent"
      />
      <UnpausePlanSection
        v-else
        :subscriptionName="subscriptionName"
        :buttonText="buttonText"
        :content="pauseSubscriptionContent"
      />
      <v-divider></v-divider>
      <v-card-title class="headline" id="cancel-title">
        Cancelling the {{ subscriptionName }}
      </v-card-title>
      <v-card-text id="cancel-description">
        When you cancel the {{ subscriptionName }},
        you will lose all the data that creates your Dashboard.
        You will have until the end of the billing period to use your services.
      </v-card-text>
      <v-card-actions>
        <v-dialog v-model="cancelSub" width="450">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              id="cancel-sub-btn"
              text
              color="error"
              v-on="on"
              v-bind="attrs"
            >Cancel Plan</v-btn>
          </template>
          <CancelSubscriptionConfirm
            v-on:closeCancelSub="closeCancelDialog"
            :title="`Cancel ${subscriptionName}`"
            :content="`Are you sure you want to cancel the ${subscriptionName}?
            When ${subscriptionName} is cancelled, you will still have access to its
            features until the end of the billing cycle.
            You may still activate it again before the end of the billing cycle.
            `"
            :name="currentSubscription.plan.nickname"
            :id="currentSubscription.id"
          />
        </v-dialog>
      </v-card-actions>
    </v-card>
    <SnackBar
      :snackbar="subscriptionNotification.success"
      :text="subscriptionNotification.message"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CardSkeletonLoader from '@components/common/loaders/CardSkeletonLoader.vue';
import PausePlanSection from '@components/billing/subscriptions/PausePlanSection.vue';
import UnpausePlanSection from '@components/billing/subscriptions/UnpausePlanSection.vue';
import CancelSubscriptionConfirm from '@components/dashboard-settings/access/confirms/CancelSubscriptionConfirm.vue';
import SnackBar from '@components/common/SnackBar.vue';

import { toUpperFirst } from '@methods/helpers';
import { secondsToISO } from '@methods/stripe-helpers';

export default {
  name: 'PlanAccess',
  data: () => ({
    accessSections: [],
    cancelSub: false,
  }),
  components: {
    CardSkeletonLoader,
    PausePlanSection,
    UnpausePlanSection,
    CancelSubscriptionConfirm,
    SnackBar,
  },
  methods: {
    ...mapActions(['getSubscriptionById', 'getPaymentMethodById', 'setLoadingSubscription']),
    convertTimestamp: (timestamp) => secondsToISO(timestamp),
    closeCancelDialog() {
      this.cancelSub = false;
    },
    getPayment(brand, last4) {
      const formattedBrand = toUpperFirst(brand);
      const formattedLast4 = `****${last4}`;
      return `${formattedBrand} ending in ${formattedLast4}`;
    },
    getExpire: (month, year) => `Expires: ${month}/${year}`,
  },
  computed: {
    ...mapGetters(['currentCompany', 'currentCustomer', 'currentSubscription', 'loadingSubscription', 'loadingPaymentMethod', 'currentPaymentMethod', 'subscriptionNotification']),
    paused() {
      const pauseCollection = this.currentSubscription.pause_collection;
      return !(pauseCollection === null);
    },
    buttonText() {
      const pauseCollection = this.currentSubscription.pause_collection;
      const { nickname } = this.currentSubscription.plan;

      return (!pauseCollection) ? `Pause ${nickname} plan` : `Unpause ${nickname} plan`;
    },
    subscriptionName() {
      let name;
      const { nickname } = this.currentSubscription.plan;

      switch (nickname) {
        case 'Dashboard':
          name = 'Dashboard Subscription';
          break;
        case 'Bronze':
          name = 'Bronze Plan';
          break;
        case 'Silver':
          name = 'Silver Plan';
          break;
        case 'Gold':
          name = 'Gold Plan';
          break;
        default:
          name = '';
      }
      return name;
    },
    pauseSubscriptionContent() {
      if (this.currentSubscription.pause_collection === null) {
        return `Are you sure you want to pause the ${this.subscriptionName}?
              All features pertaining to this subscription will be inactive immediately
              until it is subscribed to again.`;
      }
      return 'unpause subscription';
    },
  },
  created() {
    this.setLoadingSubscription();
    const { subscriptions } = this.currentCustomer;

    const [imageProcessSub] = subscriptions.data.filter((subscription) => subscription.plan.nickname === 'Bronze'
      || subscription.plan.nickname === 'Silver'
      || subscription.plan.nickname === 'Gold');
    const { id } = imageProcessSub;
    this.getSubscriptionById(id);

    const defaultPayment = (this.currentCustomer.default_source)
      ? this.currentCustomer.default_source
      : this.currentCustomer.invoice_settings.default_payment_method;
    this.getPaymentMethodById(defaultPayment);
  },
};
</script>
