<template>
  <div class="redirect">
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Redirect',
  // pid props is the pid of the next project
  props: ['pid', 'name', 'filename'],
  methods: {
    ...mapActions(['getImagesByProject']),
  },
  async created() {
    const params = { pid: this.pid };
    let { name } = this;

    if (name === 'NoImages') {
      name = 'OriginalImages';
    }
    if (name === 'NoProcessedImages') {
      name = 'ProcessedImages';
    }

    if (name === 'OriginalImages' || name === 'ProcessedImages') {
      // redirect to project/:pid/original|processed/:firstImageId
      const { cid } = this.currentCompany;
      const response = await this.getImagesByProject({ cid, pid: this.pid });
      const images = response.data;
      const firstImage = this.name === 'OriginalImages'
        ? images[0]
        : images.find((image) => !!image.processedImageUrl);
      params.filename = firstImage?.filename;
      params.tab = name === 'OriginalImages' ? 'original' : 'ai';
      if (!params.filename) {
        name = name === 'OriginalImages'
          ? 'NoImages'
          : 'NoProcessedImages';
      }
    } else {
      // by default redirect to /project/:pid page
      name = 'Project';
    }

    this.$router.replace({ name, params });
  },
  computed: {
    ...mapGetters(['currentCompany']),
  },
};
</script>
