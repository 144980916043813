<template>
  <v-container fluid>
    <v-data-iterator
      :items="items"
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      :search="search"
      :sort-by="sortBy.toLowerCase()"
      :sort-desc="sortDesc"
      hide-default-footer
      :loading="loadingProjects"
    >
      <template v-slot:header>
        <v-toolbar dark color="blue darken-3" class="mb-1">
          <template v-if="$vuetify.breakpoint.mdAndUp">
            <v-btn-toggle v-model="sortDesc" mandatory>
              <v-btn large depressed color="blue" :value="false" id="ascending-sort-btn">
                <v-icon>mdi-arrow-up</v-icon>
              </v-btn>
              <v-btn
                large
                depressed
                color="blue"
                :value="true"
                id="descending-sort-btn"
              >
                <v-icon>mdi-arrow-down</v-icon>
              </v-btn>
            </v-btn-toggle>
            <v-spacer></v-spacer>
            <v-select
              v-model="sortBy"
              flat
              solo-inverted
              hide-details
              :items="keys"
              prepend-inner-icon="mdi-magnify"
              label="Sort by"
              id="project-sort-select"
              clearable
            ></v-select>
            <v-spacer></v-spacer>
            <v-dialog v-model="addProject" max-width="700px">
              <template v-slot:activator="{ on: dialog, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      id="add-project-btn"
                      large
                      depressed
                      color="blue"
                      v-on="{ ...dialog, ...tooltip}"
                      v-bind="attrs"
                      :disabled="isTrial"
                      v-if="hasPermission('PROJECT_WRITE')"
                    ><v-icon>add</v-icon></v-btn>
                  </template>
                  Add a Project
                </v-tooltip>
              </template>
              <CreateProjectForm
                :cid="currentCompany.cid"
                :teams="allTeams"
                v-on:closeDialog="close"
              />
            </v-dialog>
          </template>
        </v-toolbar>
      </template>

      <template v-slot:default="props">
        <v-row>
          <v-col v-for="item in props.items" :key="item.pid" cols="12" sm="6" md="4" lg="4">
            <v-card>
              <v-card-title class="subheading font-weight-bold" :id="`project-${item.pid}-name`">
                {{ item.name }}
              </v-card-title>
              <v-card-subtitle :id="`project-${item.pid}-reviewer`">
                Reviewer:
                {{
                  (hasReviewer(item.first_reviewer))
                  ? item.first_reviewer.name
                  : 'No reviewer assigned for this project'
                }}
              </v-card-subtitle>
              <v-divider></v-divider>
              <v-list dense style="overflow-y: auto; height: 225px">
                <v-list-item
                  v-for="(key, index) in filteredKeys"
                  :key="index"
                  class="body-1"
                  :id="`project-${key.toLowerCase()}`"
                >
                <!-- Project field labels -->
                  <v-list-item-content
                    :class="{ 'blue--text' : sortBy === key }"
                    :id="`project-${key.toLowerCase()}-label`"
                  >
                    <v-icon>{{ }}</v-icon>{{ key }}:
                  </v-list-item-content>

                  <!-- Description or Location Content -->
                  <v-list-item-content
                    v-if="key === 'Location' || key === 'Description'"
                    class="align-end"
                    :class="{ 'blue--text' : sortBy === key }"
                    :id="`project-${key.toLowerCase()}-content`"
                  >
                    {{
                      (item[key.toLowerCase()])
                        ? item[key.toLowerCase()]
                        : `No ${key.toLowerCase()} was given to this project`
                    }}
                  </v-list-item-content>

                  <!-- Date Content -->
                  <v-list-item-content
                    v-if="key === 'Date'"
                    class="align-end"
                    :class="{ 'blue--text' : sortBy === key }"
                    :id="`project-${key.toLowerCase()}-content`"
                  >
                    {{
                      (item[key.toLowerCase()])
                        ? formatDate(item[key.toLowerCase()], 'YEAR_MONTH_DATE')
                        : 'No date was assigned to this project'
                    }}
                  </v-list-item-content>
                  <!-- Project teams content -->
                  <v-list-item-content
                    v-if="key === 'Teams'"
                    class="align-end"
                    :class="{ 'blue--text' : sortBy === key }"
                    :id="`project-${key.toLowerCase()}-content`"
                  >
                    {{ getTeams(item) }}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider light style="margin-top: 15px"></v-divider>
              <v-divider light></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  x-small color="primary"
                  :to="`/project/${item.pid}`"
                  :id="`view-${item.name}`"
                >
                <span>View Project</span>
                  <v-icon x-small>visibility</v-icon>
                </v-btn>
                <ActionConfirmation
                  v-if="hasPermission('PROJECT_WRITE')"
                  color="error"
                  :title="`Deleting ${item.name}`"
                  :content="`Are you sure you want to delete the project: ${item.name}?`"
                  buttonText="Delete"
                  :id="item.pid"
                  icon="delete"
                  buttonType="combination"
                  v-on:confirm="confirmDeleteProject({
                    pid: item.pid,
                    firestore_pid: item.firestore_pid,
                    name: item.name
                  })"
                  :isConfirming="isDeleting"
                  ref="confirmation"
                />
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </template>

      <template v-slot:footer>
        <v-row class="mt-2" align="center" justify="center">
          <span class="grey--text">Items per page</span>
          <v-menu offset-y id="number-of-pages">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                text
                color="primary"
                class="ml-2"
                v-bind="attrs"
                v-on="on"
                id="projects-per-page-btn"
              >
                {{ itemsPerPage }}
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(number, index) in itemsPerPageArray"
                :key="index"
                @click="updateItemsPerPage(number)"
                :id="`select-${number}-projects`"
              >
                <v-list-item-title>{{ number }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-spacer></v-spacer>
          <span
            class="mr-4 grey--text"
            id="current-page"
          >
            Page {{ page }} of {{ numberOfPages }}
          </span>
          <v-btn
            fab
            dark
            color="blue darken-3"
            class="mr-1"
            @click="formerPage"
            id="previous-page-btn"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn fab dark color="blue darken-3" class="ml-1" @click="nextPage" id="next-page-btn">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-iterator>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import CreateProjectForm from '@components/projects/CreateProjectForm.vue';
import ActionConfirmation from '@components/common/ActionConfirmation.vue';
import formatDate from '@utils/time-formats';

export default {
  name: 'ProjectsDataIterators',
  components: { ActionConfirmation, CreateProjectForm },
  data: () => ({
    itemsPerPageArray: [6, 12, 18],
    addProject: false,
    search: '',
    filter: {},
    sortDesc: true,
    isDeleting: false,
    page: 1,
    itemsPerPage: 6,
    sortBy: 'date',
    keys: ['Name', 'Location', 'Description', 'Date', 'Teams'],
    items: [],
  }),
  computed: {
    ...mapGetters(['isTrial', 'hasPermission', 'allProjects', 'allTeams', 'loadingProjects', 'currentProjectSearch', 'currentCompany', 'companyHas']),
    numberOfPages() { return Math.ceil(this.items.length / this.itemsPerPage); },
    filteredKeys() { return this.keys.filter((key) => key !== 'Name'); },
  },
  watch: {
    currentProjectSearch(newSearch) { this.search = newSearch; },
    allProjects(newProjectList) {
      this.items = newProjectList;
    },
  },
  methods: {
    formatDate,
    ...mapActions(['deleteProject', 'setNotification']),
    nextPage() { if (this.page + 1 <= this.numberOfPages) this.page += 1; },
    formerPage() { if (this.page - 1 >= 1) this.page -= 1; },
    close() { this.addProject = false; },
    hasReviewer: (reviewer) => {
      if (!reviewer) return false;
      return Object.keys(reviewer).length > 0;
    },
    updateItemsPerPage(number) { this.itemsPerPage = number; },
    getTeams(item) {
      if (item.teams.length === 0) return 'This project has not been assigned a team';
      return item.teams.map((team) => team.name).join(', ');
    },
    confirmDeleteProject(project) {
      this.isDeleting = true;
      this.deleteProject({
        cid: this.currentCompany.cid,
        pid: project.pid,
        firestore_pid: project.firestore_pid,
      })
        .then(() => {
          this.setNotification({
            success: true,
            message: `Project ${project.name} has been deleted`,
            color: '',
          });
          this.isDeleting = false;
        })
        .catch(() => {
          this.setNotification({
            success: true,
            message: `Failed to delete ${project.name}`,
            color: 'error',
          });
        });
    },
  },
  mounted() {
    this.items = cloneDeep(this.allProjects);
  },
};
</script>
