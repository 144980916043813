<template>
    <v-select
      v-model="selectedPriorities"
      :items="priorities"
      label="Filter Structures By Priority"
      chips
      :loading="loadingImages"
      clearable
      multiple
      single-line
      outlined
      @input="filterByPriority"
    >
      <template v-slot:prepend-item>
        <v-list-item ripple @mousedown.prevent @click="toggle">
          <v-list-item-action>
            <v-icon :color="selectedPriorities.length > 0 ? 'indigo darken-4' : ''">
              {{ icon }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Select All
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
      </template>
      <template v-slot:append-item>
        <v-divider class="mb-2"></v-divider>
        <v-list-item disabled>
          <v-list-item-avatar color="grey lighten-3">
            <v-icon>
              checklist
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              Priority Count
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ selectedPriorities.length }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-select>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'FilterByPriority',
  data: () => ({
    priorities: ['TD-1', 'TD-2', 'TD-4', 'TD-5', 'None', 'Emergency'],
    selectedPriorities: [],
  }),
  methods: {
    ...mapActions(['setDashboardFilters']),
    toggle() {
      this.$nextTick(() => {
        if (this.picksAllPriority) {
          this.selectedPriorities = [];
        } else {
          this.selectedPriorities = this.priorities.slice();
        }
      });
    },
    filterByPriority() {
      this.setDashboardFilters({
        type: 'priorities',
        value: this.selectedPriorities,
      });
    },
  },
  computed: {
    ...mapGetters(['dashboardFilters', 'loadingImages']),
    picksAllPriority() {
      return this.selectedPriorities.length === this.priorities.length;
    },
    picksSomePriorities() {
      return this.selectedPriorities.length > 0 && !this.picksAllPriority;
    },
    icon() {
      if (this.picksAllPriority) return 'mdi-close-box';
      if (this.picksSomePriorities) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
  },
  mounted() {
    this.selectedPriorities = [...this.dashboardFilters.priorities];
  },
};
</script>
