import store from '@store';

export const getFolderIdByPath = (path) => {
  const folders = store.getters.allFolders;
  const found = folders.find((folder) => folder.path === path);
  return found ? found.id : null;
};

// eslint-disable-next-line max-len
export const getFolderIdsByPathArray = (path) => path.filter((p) => p !== '__all__').map((p) => getFolderIdByPath(p)).filter((id) => id !== null);
