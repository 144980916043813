<template>
  <v-sheet
    :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
    class="pa-3"
  >
    <v-skeleton-loader
      class="mx-auto"
      type="table"
    ></v-skeleton-loader>
  </v-sheet>
</template>

<script>
export default {
  name: 'TableSkeletonLoader',
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
};
</script>
