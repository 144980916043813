<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        id="download-json-btn"
        icon
        x-large
        color="primary"
        v-bind="attrs"
        v-on="on"
        @click="downloadJson"
      >
        <v-icon>fab fa-js</v-icon>
      </v-btn>
    </template>
    Export to JSON
  </v-tooltip>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import services from '@services';
import { saveAs } from 'file-saver';
import moment from 'moment/moment';

export default {
  name: 'ExportJson',
  props: {
    pid: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    error: true,
  }),
  methods: {
    ...mapActions(['setRetrieveImage', 'setRetrieveFolder', 'setRetrieveProject']),
    async downloadJson() {
      if (this.pid === '') this.error = true;
      try {
        const { data } = await services.dashboard.getJsonData(this.currentCompany.cid, this.pid);
        const filename = `${this.currentProject.name}-${moment().format('MMMM-DD-YYYY')}.json`;
        const fileToSave = new Blob([...JSON.stringify(data, null, 4)], {
          type: 'application/json',
          name: filename,
        });

        saveAs(fileToSave, filename);
      } catch (err) {
        console.error(err);
      }
    },
  },
  computed: {
    ...mapGetters(['currentProject', 'currentCompany']),
  },
};
</script>
