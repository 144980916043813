<template>
  <v-alert :value="showError" color="red" type="error">
    {{ `${filename} does not contain any location data` }}
  </v-alert>
</template>

<script>
export default {
  name: 'NoLocationAlert',
  props: {
    showError: {
      type: Boolean,
      default: false,
    },
    filename: {
      type: String,
      default: '',
    },
  },
};
</script>
