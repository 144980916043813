<template>
    <img
      :src="currentImage.originalImageUrl"
      ref="marker"
      width="100%"
    />
</template>

<script>
import { mapGetters } from 'vuex';
import { ReadOnlyAnnotator } from '@components/annotations/annotorious';

export default {
  name: 'AnnotoriousRenderer',
  data: () => ({
    anno: null,
  }),
  computed: {
    ...mapGetters(['currentImage', 'currentProcessedImage', 'currentAnnotations', 'flatProjectLabels']),
  },
  methods: {
    initAnnotations() {
      const { marker } = this.$refs;
      this.anno = ReadOnlyAnnotator(marker, this.flatProjectLabels);
      this.anno.setAnnotations(this.currentAnnotations);
    },
  },
  mounted() {
    this.initAnnotations();
  },
  watch: {
    async currentAnnotations() {
      this.anno.destroy();
      this.initAnnotations();
      await new Promise((r) => setTimeout(r, 1));
      this.anno.destroy();
      this.initAnnotations();
    },
  },
};
</script>

<style scoped>
.center-image {
  text-align: center;
}
</style>>
