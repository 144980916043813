<template>
  <v-card flat>
    <v-progress-linear v-model="progress" color="blue-grey" height="25" query>
      <template v-slot:default="{ value }">
        <strong>{{ value }}%</strong>
      </template>
    </v-progress-linear>
    <v-divider class="my-4"></v-divider>
    <v-card-actions>
      Status: {{ status }}
      <v-spacer></v-spacer>
      <v-btn outlined x-small v-show="status === 'Success'" @click="close">
        Close
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ProgressBar',
  data: () => ({
    progress: 0,
    status: '',
  }),
  computed: {
    ...mapGetters(['processTasks', 'currentProgress']),
  },
  watch: {
    // eslint-disable-next-line func-names
    'currentProgress.percentage': function (newPercentage) {
      this.progress = newPercentage;
    },
    // eslint-disable-next-line func-names
    'currentProgress.status': function (newStatus) {
      this.status = newStatus;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>
