<template>
  <v-container fluid>
    <div id="analytics-content">
      <div id="has-dashboard-service">
        <router-view></router-view>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import subscriptionAccess from '../../../router/subscription-access';

export default {
  name: 'NfAnalytics',
  props: ['pid'],
  data: () => ({
    items: [],
    selectedProject: {
      name: 'All Projects',
    },
    show: false,
    options: {
      offset: 0,
      easing: 'easeInOutCubic',
      duration: 300,
    },
    target: {},
    currentTab: '',
  }),
  methods: {
    ...mapActions([
      'setProcessedImageLoading',
      'getProcessedImagesByProjectId',
      'finishImageLoading',
      'finishProcessedImageLoading',
      'setProjectsLoading',
      'finishProjectLoad',
      'clearProjects',
      'getProjects',
      'clearTeams',
    ]),
    async fetchDocumentReferenceArray(docRefs) {
      const documentSnapshots = await Promise.all(docRefs.map(async (docRef) => docRef.get()));
      return documentSnapshots.map((snapshot) => snapshot.data());
    },
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'allTeams',
      'allProjects',
      'loadingProjects',
      'loadingImages',
      'loadingTeams',
      'currentCompany',
      'allSubscriptions',
      'loadingSubscriptions',
      'currentCustomer',
    ]),
    displayAnalysis() {
      if (this.selectedProject.name === 'All Projects') {
        const allProjectSelected = {
          name: this.selectedProject.name,
          processed: this.items,
        };
        return allProjectSelected;
      }
      return this.selectedProject;
    },
    hasService() {
      return this.allSubscriptions.length > 0;
    },
  },
  async created() {
    let firstProject;
    // Check Dashboard access
    // const canAccess = await subscriptionAccess({
    //   customerId: this.currentCompany.customerId,
    //   product: 'dashboard',
    // });

    // Can't access gets re-routed
    // if (!canAccess) {
    //   this.$router.push('/analytics/no-access');
    //   return;
    // }
    if (this.allProjects.length < 1) {
      const res2 = (this.currentUser.adminStatus)
        ? await this.getProjects(`company_id=${this.currentCompany.cid}`)
        : await this.getProjects(`user_id=${this.currentUsers.uid}`);
      [firstProject] = res2.data;
    } else {
      [firstProject] = this.allProjects;
    }

    this.$router.push(`/analytics/nf/insights/${firstProject.pid}`);
  },
};
</script>
