<template>
  <v-select
    outlined
    v-model="selectedLabels"
    :items="labels"
    chips
    :loading="loadingImages"
    label="Filter by Labels"
    multiple
    id="filter-by-labels"
    single-line
    clearable
    @input="filterByFaultTypes"
  >
  </v-select>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import lodash from 'lodash';

export default {
  name: 'FilterByFaultTypes',
  data: () => ({
    labels: [],
    selectedLabels: [],
  }),
  methods: {
    ...mapActions(['setDashboardFilters']),
    filterByFaultTypes() {
      this.setDashboardFilters({
        type: 'labels',
        value: this.selectedLabels,
      });
    },
  },
  computed: {
    ...mapGetters(['flatProjectLabels', 'projectLabels', 'dashboardFilters', 'companyHas', 'loadingImages']),
  },
  mounted() {
    let list;
    if (this.companyHas('custom_labels') && this.companyHas('categorize_labels')) {
      const labelList = lodash.flatten(this.projectLabels.map((category) => category.labels));
      list = [...labelList.map((label) => label.faultType)];
    } else {
      list = this.projectLabels.map((label) => label.faultType);
    }

    this.labels = list.sort();
    this.selectedLabels = [...this.dashboardFilters.labels];
  },
};
</script>
