<template>
  <v-container fluid>
    <!--- add loader -->
    <Loading v-if="loadingUsers" />
    <v-row v-else>
      <v-col xs="12" sm="12" md="6" lg="6" xl="6">
        <ProductInfo />
      </v-col>
      <v-col xs="12" sm="12" md="6" lg="6" xl="6">
        <v-card width="800" class="mx-auto d-block">
          <CheckoutForm
            :product="currentPrice.nickname"
            :pricePerUnit="false"
            :cost="(currentPrice.unit_amount/100).toFixed(2)"
            v-on:selectedPaymentId="getPaymentId"
          />
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              id="complete-purchase-btn"
              :loading="processingPayment"
              color="primary"
              @click="submitPayment"
            >Complete Purchase</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Loading from '@components/common/Loading.vue';
import CheckoutForm from '@components/checkout/CheckoutForm.vue';
import ProductInfo from '@components/checkout/ProductInfo.vue';
// import helpers from '../methods/helpers';

export default {
  name: 'Checkout',
  props: ['productId'],
  data: () => ({
    stripe: null,
    paymentMethodId: '',
    error: {
      minUsers: '',
      noPaymentSelected: '',
    },
  }),
  components: {
    CheckoutForm,
    ProductInfo,
    Loading,
  },
  methods: {
    ...mapActions(['createSubscription', 'getEmployeesByCompanyId', 'updateCompany', 'getPriceByProductId']),
    getPaymentId(paymentMethodId) {
      this.paymentMethodId = paymentMethodId;
    },
    submitPayment() {
      if (this.paymentMethodId === '') {
        this.error.noPaymentSelected = 'Please select a payment method or add a new one';
        return;
      }
      const data = {
        paymentMethodId: this.paymentMethodId,
        customerId: this.currentCompany.customerId,
        priceId: this.currentPrice.id,
      };

      this.createSubscription(data)
        .then(async (result) => {
          const { status } = result.subscription;

          if (status === 'active') {
            const { currentCompany } = this;
            currentCompany.services.dashboard = result.subscription.id;
            await this.updateCompany(currentCompany);

            this.$router.push({ name: 'AnalyticsOverview' });
          }
        });
    },
  },
  computed: {
    ...mapGetters([
      'processingPayment',
      'currentCompany',
      'currentProduct',
      'loadingProduct',
      'loadingCustomer',
      'currentCustomer',
      'loadingPaymentMethods',
      'allPaymentMethods',
      'loadingUsers',
      'allEmployees',
      'loadingPrices',
      'currentPrice',
    ]),
  },
  async created() {
    const { cid } = this.currentCompany;
    this.employees = await this.getEmployeesByCompanyId(cid);
    this.getPriceByProductId({ planName: 'Dashboard' });
  },
};
</script>
