/* eslint-disable consistent-return */
/// /////////////////////////////////////////////////////////
//                                                         //
// >   I M P O R T S     I M P O R T S   I M P O R T S     //
//                                                         //
/// /////////////////////////////////////////////////////////
import moment from 'moment';
import EXIF from 'exif-js';
import * as lodash from 'lodash';
import generator from 'generate-password';
import firebase from '@database';
import stripe from '../stripe';

/// ///////////////////////////////////////////////////////
//
// >              C O M M O N  H E L P E R S
//
/// ///////////////////////////////////////////////////////
export const generatePassword = (options) => generator.generate(options);

export const severityColorPicker = (severity) => {
  const key = lodash.lowerCase(severity);
  const colorPicker = {
    none: {
      color: '#4CAF50',
      text: 'black',
    },
    low: {
      color: '#2196F3',
      text: 'black',
    },
    medium: {
      color: '#ffeb3b',
      text: 'black',
    },
    high: {
      color: '#F44336',
      text: 'black',
    },
    critical: {
      color: 'black',
      text: 'white',
    },
  };

  return colorPicker[key];
};

export const deepCompare = (obj1, obj2) => lodash.isEqual(obj2, obj1);
export const getAmountChangedBy = (list1, list2, comparator) => {
  // list1 is the original array, list2 is the comparing list
  let numberOfChanges = 0;

  // kept is an array of similar items between 2 arrays
  const kept = lodash.intersectionBy(list1, list2, comparator);
  // removed is an array of items removed from list1. (in list1, but not list2)
  const removed = lodash.differenceBy(list1, list2, comparator);
  // added is an array of added items (in list2, but not list1)
  const added = lodash.differenceBy(list2, list1, comparator);

  // add amount of removed or added items to numberOfChanges
  if (removed.length > 0) numberOfChanges += removed.length;
  if (added.length > 0) numberOfChanges += added.length;
  // If there are any MODIFIED items, and is still in both lists, compare item to item
  if (kept.length > 0) {
    kept.forEach((item) => {
      const comparingItem = lodash.find(list2, (i) => i.id === item.id);
      if (comparingItem !== undefined) {
        const isSame = lodash.isEqual(comparingItem, item);
        if (!isSame) numberOfChanges += 1;
      }
    });
  }
  return numberOfChanges;
};
export const flattenArray = (arr) => lodash.flatten(arr);
export const uniqueArray = (arr) => lodash.uniq(arr);
export const uniqueArrayBy = (arr, compare) => lodash.uniqBy(arr, compare);
export const sortBy = (arr, compare, callback) => lodash.sortedIndexBy(arr, compare, callback);

export const toLowerFirst = (str) => lodash.lowerFirst(str);
export const toUpperFirst = (str) => lodash.upperFirst(str);
export const camelCase = (str) => lodash.camelCase(str);
export const deepDifference = (obj, base) => {
  function customizer(baseValue, value) {
    if (Array.isArray(baseValue) && Array.isArray(value)) {
      return lodash.isEqual(baseValue.sort(), value.sort());
    }
  }
  // o for 'object', b for 'base'
  function changes(o, b) {
    const trans = lodash.transform(o, (result, value, key) => {
      const res = { ...result };
      if (!lodash.isEqualWith(value, b[key], customizer)) {
        res[key] = (lodash.isObject(value) && lodash.isObject(b[key]))
          ? changes(value, b[key]) : value;
      }
      return res;
    });
    return trans;
  }

  return changes(obj, base);
};

export const turnArrayToKeys = (arr, initialValue) => {
  const obj = {};

  arr.forEach((element) => {
    obj[element] = initialValue;
  });

  return obj;
};

export const objectHasKey = (obj, key) => Object.keys(obj).includes(key);

export const find = (arr, cb) => lodash.find(arr, cb);

export const reverse = (arr) => lodash.reverse(arr);

export const findElement = (component, queryElement) => {
  const dfs = (root, element) => {
    if (Object.keys(root.$refs).includes(element)) return root;
    const { $children } = root;
    if ($children.length < 1) return;
    for (let i = 0; i < $children.length; i += 1) {
      const elementFound = dfs($children[i], element);
      if (elementFound !== undefined) return (elementFound) || {};
    }
  };

  return dfs(component, queryElement);
};

/// //////////////////////////////////////////////////////////
//
// >                P A Y M E N T S  H E L P E R S
//
/// //////////////////////////////////////////////////////////
export const getCreditCardIcon = (brand) => {
  let icon;

  switch (brand) {
    case 'visa':
      icon = 'fab fa-cc-visa';
      break;
    case 'mastercard':
      icon = 'fab fa-cc-mastercard';
      break;
    case 'amex':
      icon = 'fab fa-cc-amex';
      break;
    case 'discover':
      icon = 'fab fa-cc-discover';
      break;
    default:
      icon = '';
      break;
  }

  return icon;
};

export const handlePaymentThatRequiresCustomerAction = ({
  subscription,
  invoice,
  priceId,
  paymentMethodId,
  isRetry,
}) => {
  if (subscription && subscription.status === 'active') {
    // Subscription is active, no customer actions required.
    return { subscription, priceId, paymentMethodId };
  }
  // If it's the first payment attempt, the pyament intent is on the subscription latest invoice.
  // If it's a retry, the payment intent will be on the invoice itself
  const paymentIntent = invoice
    ? invoice.payment_intent : subscription.latest_invoice.payment_intent;

  if (paymentIntent.status === 'requires_action'
    || (isRetry === true && paymentIntent.status === 'requires_payment_method')
  ) {
    return stripe.confirmCardPayment(paymentIntent.client_secret, {
      payment_method: paymentMethodId,
    })
      .then((result) => {
        if (result.error) {
          // There's a risk of the customer closing the window before callback
          // execution. To handle this case, set up a webhook endpoint and
          // listen to invoice.paid. This webhook endpoint returns an Invoice.
          throw result;
        } else if (result.paymentIntent.status === 'succeeded') {
          return {
            priceId,
            subscription,
            invoice,
            paymentMethodId,
          };
        }
      });
  }
  // No customer actions needed
  return { subscription, priceId, paymentMethodId };
};

export const handleRequiresPaymentMethod = ({
  subscription,
  paymentMethodId,
  priceId,
}) => {
  if (subscription.status === 'active') {
    // subscription is active, no customer actions required.
    return { subscription, priceId, paymentMethodId };
  } if (
    subscription.latest_invoice.payment_intent.status
    === 'requires_payment_method'
  ) {
    // Using localStorage to store the state of the retry here
    // (feel free to replace with what you prefer)
    // Store the latest invoice ID and status
    localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
    localStorage.setItem(
      'latestInvoicePaymentIntentStatus',
      subscription.latest_invoice.payment_intent.status,
    );
    // eslint-disable-next-line no-throw-literal
    throw { error: { message: 'Your card was declined.' } };
  } else {
    return { subscription, priceId, paymentMethodId };
  }
};

/// //////////////////////////////////////////////////////////
//
// >                L O C A T I O N S  H E L P E R S
//
/// //////////////////////////////////////////////////////////
/*
  |--------------------------------------------------------|
  |                      toDMS                             |
  |--------------------------------------------------------|
  | @description: Converts DD into DMS                     |
  | @parameters: coordinate <Float>, unit <String>         |
  | @return: <String>                                      |
  |--------------------------------------------------------|
*/
export const toDMS = (coordinate, unit) => {
  // If it's a null value, return 'N/A' immediately
  if (coordinate === null) return 'N/A';

  // if it's latitude and the coordinate < 0, return appropriate coordinate/degree/direction
  if (unit === 'latitude') return (coordinate < 0) ? `${coordinate * -1}° S` : `${coordinate}° N`;

  return (coordinate < 0) ? `${coordinate * -1}° W` : `${coordinate}° E`;
};

/*
  |--------------------------------------------------------|
  |                      DMStoDD                           |
  |--------------------------------------------------------|
  | @description: Converts degrees, minutes, seconds to    |
  |               decimal degrees                          |
  | @parameters: DMS <String>, unit <String>               |
  | @return: <String>                                      |
  |--------------------------------------------------------|
*/
export const DMStoDD = (DMS, unit) => {
  // Split by degree symbol. First index is coordinate, second is direction
  // Lodash's trim function eliminates any excess white spaces
  const [coordinate, dir] = DMS.split('°');
  const direction = lodash.trim(dir);

  // If the unit passed in is latitude, then check N/S directions
  if (unit === 'latitude') {
    return (direction === 'N') ? parseFloat(coordinate) : -1 * parseFloat(coordinate);
  }

  // Otherwise check E/W for coordinate value
  return (direction === 'E') ? parseFloat(coordinate) : -1 * parseFloat(coordinate);
};

/*
  |--------------------------------------------------------|
  |                  coordinateInRange                     |
  |--------------------------------------------------------|
  | @description: Detects if the coordinates are within    |
  |               appropriate range                        |
  | @parameters: coordinate <Float>, unit <String>         |
  | @return: isInRange <Boolean>                           |
  |--------------------------------------------------------|
*/
export const coordinateInRange = (coordinate, unit) => {
  let isInRange;

  if (unit === 'latitude') isInRange = coordinate >= -90 && coordinate <= 90;
  else isInRange = coordinate >= -180 && coordinate <= 180;

  return isInRange;
};

export const latitudeDMS = (latitude) => ((latitude < 0) ? `${latitude * -1}° S` : `${latitude}° N`);
export const longitudeDMS = (longitude) => ((longitude < 0) ? `${longitude * -1}° W` : `${longitude}° E`);

export const getCenterPoint = (coordinates) => {
  if (coordinates.length === 0) return coordinates[0];

  let x = 0.0;
  let y = 0.0;
  let z = 0.0;

  coordinates.forEach((coordinate) => {
    const { latitude, longitude } = coordinate;

    const lat = (latitude * Math.PI) / 180;
    const lng = (longitude * Math.PI) / 180;

    x += Math.cos(lat) * Math.cos(lng);
    y += Math.cos(lat) * Math.sin(lng);
    z += Math.sin(lat);
  });

  const total = coordinates.length;

  x /= total;
  y /= total;
  z /= total;

  const centralLongitude = Math.atan2(y, x);
  const centralSquareRoot = Math.sqrt(x * x + y * y);
  const centralLatitude = Math.atan2(z, centralSquareRoot);

  return {
    lat: (centralLatitude * 180) / Math.PI,
    lon: (centralLongitude * 180) / Math.PI,
  };
};

/// /////////////////////////////////////////////////////////////////
//
// >  D A T E  H E L P E R S      D A T E  H E L P E R S
//
/// /////////////////////////////////////////////////////////////////
export const getStartOfMonth = () => moment().startOf('month').format('MMMM DD YYYY');

export const getEndOfMonth = () => moment().endOf('month').format('MMMM DD YYYY');

export const getCurrentYear = () => moment().year();

/// /////////////////////////////////////////////////////////////////
//
// >  C S V  H E L P E R S      C S V  H E L P E R S
//
/// /////////////////////////////////////////////////////////////////
/*
  |--------------------------------------------------------|
  |                      getCSVHeaders                     |
  |--------------------------------------------------------|
  | @description: Grabs headers of CSV                     |
  | @parameters: csvJson <Object>                          |
  | @return: headers <Object>                              |
  |--------------------------------------------------------|
*/
export const getCSVHeaders = (csvJson) => {
  const firstItems = Object.keys(csvJson[0]);
  const headers = {};
  firstItems.forEach((item) => {
    const value = lodash.upperFirst(item);
    headers[item] = value;
  });
  return headers;
};
/*
  |--------------------------------------------------------|
  |                    getCSVJson                          |
  |--------------------------------------------------------|
  | @description: Maps data from each image and processed  |
  |               image data                               |
  | @parameters: originalImages [<Object>], processedImages|
  |                [<Object>]                              |
  | @return: csvData [<Object>]                            |
  |--------------------------------------------------------|
*/
export const getCSVJson = (originalImages) => {
  const csvData = originalImages.map((image) => {
    const {
      filename, location,
    } = image;
    const isProcessed = image.process_tracking.length > 0;
    const data = {};
    const { length } = image.process_tracking;
    let fname = (filename.includes('+')) ? filename.split('+')[1] : filename;
    [fname] = fname.split('!').slice(-1);
    data.file_name = fname;
    data.labels = (isProcessed) ? image.process_tracking[length - 1].labels.join(',') : 'n/a';
    data.severity = (isProcessed) ? image.process_tracking[length - 1].severity : 'n/a';
    data.processed_url = image.processedImageUrl;
    data.date_processed = 'n/a';
    data.metadataID = fname;
    // eslint-disable-next-line prefer-destructuring
    data.latitude = location[0];
    // eslint-disable-next-line prefer-destructuring
    data.longitude = location[1];
    data.original_url = image.originalImageUrl;
    // eslint-disable-next-line no-use-before-define
    data.date_uploaded = helpers.convertToDate(image.date).format('YYYY-MM-DD');
    data.project = image.project_id;

    return data;
  });
  return csvData;
};
/*
  |---------------------------------------------------------|
  |                       formatCSVJson                     |
  |---------------------------------------------------------|
  | @description: Formats each property/value of csv JSON to|
  |                prevent errors                           |
  | @parameters: csvJson [<Object>], headers <Object>       |
  |                [<Object>]                               |
  | @return: csvData [<Object>]                             |
  |---------------------------------------------------------|
*/
export const formatCSVJson = (csvJson, headers) => {
  const formattedData = [];
  const formattedHeaders = headers;
  csvJson.forEach((csv) => {
    const {
      labels, severity, latitude, longitude, metadataID, project, projectId,
    } = csv;

    formattedData.push({
      file_name: csv.file_name,
      labels: labels.replace(/,/g, ':'),
      severity: severity.replace(/,/g, ''),
      processed_url: csv.processed_url,
      date_processed: csv.date_processed,
      metadataID,
      latitude,
      longitude,
      original_url: csv.original_url,
      date_uploaded: csv.date_uploaded,
      project,
      projectId,
    });
  });
  const headerKeys = Object.keys(headers);
  headerKeys.forEach((key) => {
    formattedHeaders[key] = formattedHeaders[key].includes('_') ? formattedHeaders[key].replace(/_/g, ' ') : formattedHeaders[key];
  });
  formattedData.unshift(formattedHeaders);
  return formattedData;
};
/*
  |---------------------------------------------------------|
  |                     getCSVData                          |
  |---------------------------------------------------------|
  | @description: Iterates through each CSV Json to parse   |
  |               file data                                 |
  | @parameters: csvJson [<Object>]                         |
  | @return: str <String>                                   |
  |---------------------------------------------------------|
*/
export const getCSVData = (csvJson) => {
  const array = typeof csvJson !== 'object' ? JSON.parse(csvJson) : csvJson;
  let str = '';

  array.forEach((item) => {
    let line = '';
    const keys = Object.keys(item);
    keys.forEach((key) => {
      if (line !== '') line += ',';
      line += item[key];
    });
    str += `${line}\r\n`;
  });
  return str;
};
/*
  |---------------------------------------------------------|
  |                     generateCSVBlob                     |
  |---------------------------------------------------------|
  | @description: Generates blob data URL for csv file      |
  | @parameters: csv <String>                               |
  | @return: blob <Blob>                                    |
  |---------------------------------------------------------|
*/
export const generateCSVBlob = (csv) => {
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  return blob;
};

const helpers = {
  /*
    |--------------------------------------------------|
    |                   Common Functions               |
    |--------------------------------------------------|
  */
  findDataBeingUpdated: (newData, dataList, id) => lodash
    .find(dataList, (data) => newData[id] === data[id]),
  /*
    |------------------------------------------------------------|
    |   @Parameters: (dataList: [<Object>], action: <Function>)  |
    |------------------------------------------------------------|
  */
  handleSequentialPromises: (dataList, action) => dataList.reduce(async (acc, currentData) => {
    const accumulator = await acc;
    const nextData = await action(currentData);
    accumulator.push(nextData);
    return Promise.resolve(accumulator);
  }, Promise.resolve([])),
  /*
    |--------------------------------------------------|
    |                 Lodash Functions                 |
    |--------------------------------------------------|
  */
  unionizeDocumentsById: (newDocRefList, oldDocRefList) => lodash.unionBy(newDocRefList, oldDocRefList, 'id'),
  intersectDocumentsById: (newDocRefList, oldDocRefList) => lodash.intersectionBy(newDocRefList, oldDocRefList, 'id'),
  differentiateDocumentsById: (newDocRefList, oldDocRefList) => lodash.differenceBy(newDocRefList, oldDocRefList, 'id'),
  findRemovingDocuments: (startingList, endingList) => {
    const intersections = helpers.intersectDocumentsById(endingList, startingList);
    // const intersectionIds = intersections.map((intersection) => intersection.id);
    return helpers.differentiateDocumentsById(startingList, intersections);
  },
  findAddingDocuments: (startingList, endingList) => {
    const intersections = helpers.intersectDocumentsById(endingList, startingList);
    return helpers.differentiateDocumentsById(endingList, intersections);
  },
  findDifference: (startingList, endingList, comparator) => lodash.differenceBy(
    startingList,
    endingList,
    comparator,
  ),
  findRemovedItem: (startingList, endingList, comparator) => {
    const intersections = lodash.intersectionBy(endingList, startingList, comparator);
    return helpers.findDifference(startingList, intersections, comparator);
  },
  findAddedItems: (startingList, endingList, comparator) => {
    const intersections = lodash.intersectionBy(endingList, startingList, comparator);
    return helpers.findDifference(endingList, intersections, comparator);
  },
  compareLists: (startingList, endingList, comparator) => ({
    removed: helpers.findRemovedItem(startingList, endingList, comparator),
    added: helpers.findAddedItems(startingList, endingList, comparator),
  }),
  /*
    |--------------------------------------------------|
    |                  Firebase Functions              |
    |--------------------------------------------------|
  */
  getDocumentPath: (data, collectionName, idType) => `${collectionName}/${data[idType]}`,
  getDocReference(collectionName, id) {
    return firebase.database.doc(`${collectionName}/${id}`);
  },
  getDocReferenceList(dataList, collectionName, idType) {
    return dataList.map((data) => firebase
      .database
      .doc(this.getDocumentPath(data, collectionName, idType)));
  },
  mapDocReferenceToData(docRefList, dataList, idType) {
    return docRefList.map((docRef) => {
      const query = {};
      query[idType] = docRef.id;
      return lodash.find(dataList, query);
    });
  },
  getDataFromDocRef: (docRef) => new Promise((resolve, reject) => docRef.get()
    .then((docSnapshot) => {
      resolve(docSnapshot.data());
    })
    .catch((err) => reject(err))),
  /*
    |--------------------------------------------------|
    |                   Moment Functions               |
    |--------------------------------------------------|
  */
  sortDates: (dateList) => dateList.sort(),
  formatStartDate(date) {
    return moment(date).subtract(1, 'days').endOf('day');
  },
  formatEndDate: (date) => moment(date).add(1, 'days').startOf('day'),
  getStringDate: () => moment().format('YYYY-MM-DD'),
  getStringDateTime: () => moment().format('YYYYMMDD_HHmmss'),
  convertToDate: (date) => moment.parseZone(date, 'YYYYMMDD_HHmmss'),
  today: () => moment(),
  oneDayAgo: () => moment().subtract(1, 'days').startOf('day'),
  oneWeekAgo: () => moment().subtract(7, 'days').startOf('day'),
  oneMonthAgo: () => moment().subtract(1, 'months').startOf('day'),
  withinTimeFrame: (time, start, end) => moment(time).isBetween(start, end),
  greaterThanOrEqualDate: (data, start) => moment(data.createedAt).isSameOrAfter(start),
  lessThanOrEqualDate: (data, end) => moment(data.createdAt).isSameOrBefore(end),
  equalDate: (data, date) => moment(data.createdAt).isSame(date),
  moment: (date) => moment(date),
  /*
    |--------------------------------------------------|
    |                 EXIF Coordinate                  |
    |--------------------------------------------------|
  */
  readFile: (file) => new Promise((resolve) => {
    if (file !== undefined) {
      EXIF.getData(file, () => {
        const fileReader = new FileReader();
        fileReader.onload = () => {
          const lat = EXIF.getTag(file, 'GPSLatitude');
          const latRef = EXIF.getTag(file, 'GPSLatitudeRef');
          const long = EXIF.getTag(file, 'GPSLongitude');
          const longRef = EXIF.getTag(file, 'GPSLongitudeRef');
          resolve({
            lat,
            latRef,
            long,
            longRef,
          });
        };
        fileReader.readAsDataURL(file);
      });
    }
  }),
  convertDMSToDD: (degrees, minutes, seconds, direction) => {
    let dd = degrees + (minutes / 60) + (seconds / 3600);

    if (direction === 'S' || direction === 'W') {
      dd *= -1;
    }

    return dd;
  },
  getGeoCoordinates: (coordinates) => new firebase
    .firestore
    .GeoPoint(coordinates.lat, coordinates.long),
  /*
    |--------------------------------------------------|
    | @parameters: coordinates (<Object>: latitude:    |
    |             <Float>, longitude: <Float>)         |
    | @description: Calc coordinates within data set   |
    | @return: (<Object>: lat <Float>, lon <Float>)    |
    |--------------------------------------------------|
  */
  getCenterPoint: (coordinates) => {
    if (coordinates.length === 0) return coordinates[0];

    let x = 0.0;
    let y = 0.0;
    let z = 0.0;

    coordinates.forEach((coordinate) => {
      const { latitude, longitude } = coordinate;

      const lat = (latitude * Math.PI) / 180;
      const lng = (longitude * Math.PI) / 180;

      x += Math.cos(lat) * Math.cos(lng);
      y += Math.cos(lat) * Math.sin(lng);
      z += Math.sin(lat);
    });

    const total = coordinates.length;

    x /= total;
    y /= total;
    z /= total;

    const centralLongitude = Math.atan2(y, x);
    const centralSquareRoot = Math.sqrt(x * x + y * y);
    const centralLatitude = Math.atan2(z, centralSquareRoot);

    return {
      lat: (centralLatitude * 180) / Math.PI,
      lon: (centralLongitude * 180) / Math.PI,
    };
  },
  /*
    summary: given @baseCoord determine if @otherCoord ~= @baseCord within @error in (0, 1)
  */
  isWithinEuclidError(baseCoord, otherCoord, error) {
    const a = baseCoord[1] - otherCoord[1];
    const b = baseCoord[0] - otherCoord[0];
    const d = Math.sqrt(a * a + b * b);
    return d < error;
  },
  isWithinAbsError(baseCoord, otherCoord, error) {
    const a = Math.abs(baseCoord[1] - otherCoord[1]);
    const b = Math.abs(baseCoord[0] - otherCoord[0]);

    return (a < error) && (b < error);
  },
  euclidError(baseCoord, otherCoord) {
    const a = baseCoord[1] - otherCoord[1];
    const b = baseCoord[0] - otherCoord[0];
    const d = Math.sqrt(a * a + b * b);
    return d;
  },
  isZipFile(mime) {
    const acceptedMime = [
      'application/zip',
      'application/x-zip',
      'application/octet-stream',
      'application/x-zip-compressed',
    ];
    return acceptedMime.includes(mime);
  },
  fixDecimalPlaces: (val, n) => {
    try {
      const floatVal = parseFloat(val);
      return floatVal.toFixed(n);
    } catch {
      return val;
    }
  },
  isImage(mime) {
    const acceptedMime = [
      'image/jpeg',
      'image/png',
      'image/gif',
    ];
    return acceptedMime.includes(mime);
  },
  objectIncludes: (obj, field) => Object.keys(obj).includes(field),
  getImagesByFolder: (images, folder) => images.filter((image) => image.folder === folder.path),
  // eslint-disable-next-line max-len
  getProcessedImagesByFolder: (images, folder) => images.filter((image) => image.folder === folder.path && image.processedImageUrl),
};

export default helpers;
