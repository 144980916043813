<template>
  <v-card>
    <v-card-title class="headline">Unsaved Annotations</v-card-title>
    <v-card-text>
      There are {{ unsavedAmount }} unsaved annotation(s).
      Are you sure you want to close out your annotating session?
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn text small color="error" @click="discardAnnotations">Discard Changes</v-btn>
      <v-spacer></v-spacer>
      <v-btn text small color="primary" @click="continueAnnotations">Continue Annotating</v-btn>
      <v-btn text small color="primary" @click="saveAnnotations">Save Changes</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'UnsavedAnnotationsConfirm',
  props: ['unsavedAmount'],
  methods: {
    continueAnnotations() { this.$emit('continueAnnotations'); },
    saveAnnotations() { this.$emit('saveAnnotations'); },
    discardAnnotations() { this.$emit('discardAnnotations'); },
  },
};
</script>
