<template>
  <v-card>
    <LabelAbbreviations />
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="close">Close</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import LabelAbbreviations from '@components/images/LabelAbbreviations.vue';

export default {
  name: 'AbbreviationsDialog',
  components: {
    LabelAbbreviations,
  },
  methods: {
    close() {
      this.$emit('closeAbbrebiations');
    },
  },
};
</script>
