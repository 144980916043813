const userInactive = (cb) => {
  let inactivityTimeout;
  function inactivityHandler() {
    cb();
  }
  function startInactivityTimer() {
    // Clear any existing timeout to avoid multiple timers running simultaneously
    clearTimeout(inactivityTimeout);

    // Set a new timeout for an hour (in milliseconds)
    inactivityTimeout = setTimeout(inactivityHandler, 60 * 60 * 1000); // 1 hour
  }

  // Call this function whenever there's user activity
  function handleUserActivity() {
    startInactivityTimer();
  }

  // Start the timer initially when the page loads
  startInactivityTimer();

  // Add event listeners to detect user activity (e.g., mouse movement or keyboard input)
  document.addEventListener('mousemove', handleUserActivity);
  document.addEventListener('keydown', handleUserActivity);
};
module.exports = userInactive;
