import store from '@store';

export default {
  getImageDataByProject: async (cid, pid) => {
    const data = {};
    store.dispatch('setLoadingFolders', true);

    try {
      const folderResponse = await store.dispatch('getFoldersByProject', { cid, pid });

      if (folderResponse.status === 200) {
        store.dispatch('setLoadingFolders', false);
        data.folders = folderResponse.data;

        store.dispatch('setImagesLoading', true);
        const imageResponse = await store.dispatch('getImagesByProject', { cid, pid });

        if (imageResponse.status === 200) {
          store.dispatch('setImagesLoading', false);
          data.images = imageResponse.data;
          data.processedImages = imageResponse.data.filter((image) => image.processedImageUrl);

          const categorize = store.getters.companyHas('categorize_labels') ? 'yes' : 'no';
          const custom = store.getters.companyHas('custom_labels') ? 'yes' : 'no';

          if (categorize || custom) {
            store.dispatch('setLabelsLoading', true);
            const labelResponse = await store.dispatch('fetchLabelsForProject', {
              pid,
              cid,
              categorize,
              custom,
            });

            if (labelResponse.status === 200) {
              data.labels = labelResponse.data;
              store.dispatch('setLabelsLoading', false);
            }
          }
        }
      }
      return data;
    } catch (err) {
      return err;
    }
  },
};
