<template>
  <v-card tile flat>
    <v-toolbar tile height="75" dense>
      <ProjectRouteSelector />
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <ExportXlsx v-if="allowReportAccess" :images="images" />
      <ExportJson v-if="allowReportAccess" :pid="pid" />
      <ExportCsv  v-if="allowReportAccess" :pid="pid" />
      <ExportPdf  v-if="allowReportAccess" :getMapRef="this.getMapRef" :pid="pid"/>
      <Dialog
        title="Reports Details"
        buttonText="View Report"
        icon="details" v-if="isMobile"
      >
        <ReportsDetails/>
      </Dialog>
      <Dialog
        title="View Label Reference"
        buttonText="View Label Reference"
        icon="photo_size_select_small"
        v-if="isMobile"
      >
        <LabelReferenceTable />
      </Dialog>
      <Dialog
        title="Images by Structures"
        buttonText="Select Images by Structures"
        icon="folder"
        v-if="isMobile"
      >
        <FileManagement type="ai" />
      </Dialog>
      <Dialog
        title="Filter Structure and Image Data"
        buttonText="Filter Data"
        icon="filter_alt"
        v-if="isMobile"
      >
        <DashboardFilters />
      </Dialog>
    </v-toolbar>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import ExportXlsx from '@components/dashboard/exports/ExportXlsx.vue';
import ExportCsv from '@components/dashboard/exports/ExportCsv.vue';
import ExportJson from '@components/dashboard/exports/ExportJson.vue';
import ProjectRouteSelector from '@components/common/ProjectRouteSelector.vue';
import Dialog from '@components/common/Dialog.vue';
import ReportsDetails from '@components/dashboard/collapsible-content/ReportsDetails.vue';
import LabelReferenceTable from '@components/dashboard/collapsible-content/LabelReferenceTable.vue';
import FileManagement from '@components/dashboard/file-management/FileManagement.vue';
import DashboardFilters from '@components/dashboard/filters/DashboardFilters.vue';
import ExportPdf from '@components/dashboard/exports/ExportPdf.vue';

export default {
  name: 'DashboardToolbar',
  props: ['pid', 'images', 'index', 'getMapRef'],
  data: () => ({
    reports: false,
    abbreviations: false,
    filters: false,
  }),
  components: {
    ExportPdf,
    ExportXlsx,
    ExportCsv,
    ExportJson,
    ProjectRouteSelector,
    ReportsDetails,
    LabelReferenceTable,
    FileManagement,
    DashboardFilters,
    Dialog,
  },
  methods: {
    closeReportsDialog() { this.reports = false; },
    closeAbbrDialog() { this.abbreviations = false; },
    closeFilterDialog() { this.filters = false; },
  },
  computed: {
    ...mapGetters(['small', 'hasPermission', 'extraSmall', 'isLaptop', 'currentUser', 'companyConfig']),
    isMobile() {
      return (this.small || this.extraSmall) || !this.isLaptop;
    },
    allowReportAccess() {
      return this.hasPermission('REPORT_ACCESS');
    },
  },
};
</script>
