import platformBackendClient from '@backend-clients/platform-backend';

const showNotification = (commit, notification) => {
  commit('notification_payment_methods', notification);

  setTimeout(() => {
    commit('notification_payment_methods', { message: '', success: false });
  }, 3000);
};

const state = {
  paymentMethods: [],
  paymentMethod: {},
  submit: false,
  attach: false,
  load: false,
  loading: false,
  notifications: {
    success: false,
    message: '',
  },
};

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const getters = {
  allPaymentMethods: (state) => state.paymentMethods,
  currentPaymentMethod: (state) => state.paymentMethod,
  attachingPayment: (state) => state.attach,
  loadingPaymentMethod: (state) => state.load,
  loadingPaymentMethods: (state) => state.loading,
  submittingPayment: (state) => state.submit,
  paymentMethodNotification: (state) => state.notifications,
};

const actions = {
  setDefaultPaymentMethod({ commit }, paymentMethod) {
    commit('fetch_payment', paymentMethod);
  },
  async updatePaymentMethod({ commit }, payload) {
    const { id, data } = payload;
    return new Promise((resolve, reject) => {
      platformBackendClient.put(`/api/payments/${id}`, data)
        .then((res) => {
          commit('update_payment', res.data);
          showNotification(commit, { success: true, message: `Successfully updated payment method ending in ${res.data.card.last4}` });
        })
        .catch((err) => reject(err));
    });
  },
  async getPaymentMethodById({ commit }, paymentMethodId) {
    commit('loading_payment');

    return new Promise((resolve, reject) => {
      platformBackendClient.get(`/api/payments/${paymentMethodId}`)
        .then((res) => {
          commit('fetch_payment', res.data);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },
  async getPaymentMethodsByCustomerId({ commit }, customerId) {
    commit('loading_payments');

    return new Promise((resolve, reject) => {
      platformBackendClient.get(`/api/payments/list/${customerId}`)
        .then((res) => {
          commit('fetch_payments', res.data);
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  },
  async attachPaymentMethodToCustomer({ commit }, data) {
    commit('attach_payment');
    const { paymentMethodId, customerId } = data;
    return new Promise((resolve, reject) => {
      platformBackendClient.post(`/api/payments/attach:${paymentMethodId}`, { customerId })
        .then((res) => {
          commit('create_payment', res.data);
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  },
  async detachPaymentMethodFromCustomer({ commit }, paymentMethodId) {
    return new Promise((resolve, reject) => {
      platformBackendClient.delete(`/api/payments/detach/${paymentMethodId}`)
        .then((res) => {
          commit('remove_payment', paymentMethodId);
          showNotification(commit, { success: true, message: 'Your credit card has been removed' });
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  },
  async submitPaymentMethod({ commit }, data) {
    const { paymentMethodId, customerId } = data;
    commit('submit_payment');
    return new Promise((resolve, reject) => {
      platformBackendClient.post(`/api/subscriptions/${customerId}`, paymentMethodId)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  },
  finishPaymentAdd({ commit }, paymentMethodId) {
    commit('loading_payment');
    return new Promise((resolve, reject) => {
      platformBackendClient.get(`/api/payments/${paymentMethodId}`)
        .then((res) => { commit('create_payment', res.data); })
        .catch((err) => reject(err));
    });
  },
};

const mutations = {
  submit_payment: (state) => {
    state.submit = true;
  },
  finish_payment: (state) => {
    state.submit = false;
  },
  loading_payment: (state) => {
    state.load = true;
  },
  loading_payments: (state) => {
    state.loading = true;
  },
  fetch_payment: (state, payment) => {
    state.paymentMethod = payment;
    state.load = false;
  },
  fetch_payments: (state, payments) => {
    state.paymentMethods = payments;
    state.loading = false;
  },
  create_payment: (state, payment) => {
    state.paymentMethods.push(payment);
  },
  remove_payment: (state, paymentId) => {
    state.paymentMethods = state.paymentMethods.filter((payment) => payment.id !== paymentId);
  },
  update_payment: (state, newPaymentMethod) => {
    const index = state.paymentMethods
      .findIndex((paymentMethod) => paymentMethod.id === newPaymentMethod.id);
    if (index !== -1) {
      state.paymentMethods.splice(index, 1, newPaymentMethod);
    }
    state.paymentMethod = newPaymentMethod;
  },
  notification_payment_methods: (state, notification) => {
    state.notifications = notification;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
