<template>
  <v-container fluid>
    <v-form ref="reportForm">
      <p class="text-subtitle-1 text-center" id="instruction-text">
        Please fill the form and submit your inquiry.
      Support will get back to you. You can also check FAQ section first.</p>

      <v-text-field
        v-model="report.subject"
        label="Subject"
        outlined
        :error-messages="errors.subject"
        data-cy="subject"
      >
      </v-text-field>

      <v-textarea
        v-model="report.description"
        :error-messages="errors.description"
        label="Description"
        counter="500"
        outlined
        data-cy="description"
      >
      </v-textarea>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="report.name"
            label="Name"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="report.email"
            outlined
            label="Email"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="report.company"
            label="Company"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="report.phone"
            outlined
            label="Phone"
          ></v-text-field>
        </v-col>
      </v-row>
        <SupportImageUploader @upload="setUploadStatus" :reset="reset" />
      <br>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          id="submit-report-button"
          @click="submitReport"
          :loading="loading"
          :disabled="uploadingImages"
          outlined
          color="primary"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-container>
</template>

<script>
import SupportImageUploader from '@components/images/utilities/SupportImageUploader.vue';
import { mapActions, mapGetters } from 'vuex';
import services from '@services';

export default {
  name: 'ReportForm',
  components: {
    SupportImageUploader,
  },
  data: () => ({
    report: {
      subject: '',
      description: '',
      files: [],
    },
    loading: false,
    errors: {
      subject: '',
      description: '',
    },
    uploadingImages: false,
    reset: false,
  }),
  methods: {
    ...mapActions(['setNotification']),
    resetForm() {
      this.report.subject = '';
      this.report.description = '';
      this.report.files = [];
      this.reset = true;

      // Toggles the 'reset' value
      this.$nextTick(() => {
        this.reset = false;
      });
    },
    submitReport() {
      this.loading = true;
      const isFormValid = this.$refs.reportForm.validate();

      if (isFormValid) {
        services.support.create(this.currentUser.cid, this.currentCompany.bucket, this.report)
          .then((res) => {
            console.log('res: ', res);
            this.setNotification({
              message: res.data.message,
              color: '',
              success: true,
            });
            this.loading = false;
            this.resetForm();
          })
          .catch(this.errorHandler);
      } else this.loading = false;
    },
    errorHandler(err) {
      if (err.response.data.subject) this.errors.subject = err.response.data.subject;
      if (err.response.data.description) {
        this.errors.description = err.response.data.description;
      }
      // eslint-disable-next-line no-console
      if (err.response.data.message) console.error(err.response.data.message);
      this.loading = false;
    },
    setUploadStatus(uploadStatus) {
      if (!uploadStatus.uploading) {
        this.report.files = [...uploadStatus.images];
      }
      this.uploadingImages = uploadStatus.uploading;
    },
  },
  computed: {
    ...mapGetters(['currentUser', 'currentCompany']),
  },
  created() {
    const userInfo = {
      name: `${this.currentUser.firstName} ${this.currentUser.lastName}`,
      email: this.currentUser.email,
      company: this.currentCompany.companyName,
      phone: this.currentUser.phone,
    };
    this.report = { ...this.report, ...userInfo };
  },
};
</script>

<style>
</style>
