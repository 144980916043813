<template>
  <div id="bar-chart" ref="bar"></div>
</template>

<script>
import Plotly from '@components/charts/custom-plotly';

export default {
  name: 'BarChart',
  props: ['title', 'labels', 'values'],
  methods: {
    drawPlot() {
      const { bar } = this.$refs;

      const data = [
        {
          x: this.labels,
          y: this.values,
          type: 'bar',
        },
      ];

      const layout = {
        title: {
          text: this.title,
          font: {
            family: 'Courier New, monospace',
            size: 24,
          },
        },
        legend: { orientation: 'h' },
      };

      const config = {
        displaylogo: false,
        responsive: true,
        displayModeBar: false,
      };

      Plotly.newPlot(
        bar,
        data,
        layout,
        config,
      );
    },
    react(labels, values) {
      const { bar } = this.$refs;

      const data = [
        {
          x: labels,
          y: values,
          type: 'bar',
        },
      ];
      const layout = {
        title: {
          text: this.title,
          font: {
            family: 'Courier New, monospace',
            size: 24,
          },
        },
        legend: { orientation: 'h' },
      };

      const config = {
        displaylogo: false,
        responsive: true,
        displayModeBar: false,
      };

      Plotly.react(
        bar,
        data,
        layout,
        config,
      );
    },
  },
  mounted() {
    this.drawPlot();
  },
};
</script>
