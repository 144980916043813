<template>
  <v-container fluid>
    <v-select
      outlined
      label="Filter by Priority"
      :items="items"
      multiple
      chips
      small-chips
      item-color="primary"
      clearable
      v-model="value"
      @change="onFilterChange"
    ></v-select>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';

export default {
  name: 'PriorityFilter',
  props: ['all', 'custom'],
  data: () => ({
    items: ['TD-1', 'TD-2', 'TD-4', 'TD-5', 'None', 'Emergency'],
    value: ['TD-1', 'TD-2', 'TD-4', 'TD-5', 'None', 'Emergency'],
  }),
  computed: {
    ...mapGetters(['currentFilterStatus', 'allFolders', 'insightsPriorities', 'currentCompany', 'insightsFolders']),
    allOrCustom() {
      const filter = (this.all) ? 'all' : 'custom';
      return filter;
    },
  },
  watch: {
    allOrCustom(newStatus) {
      if (newStatus === 'all') {
        this.value = ['TD-1', 'TD-2', 'TD-4', 'TD-5', 'None', 'Emergency'];
        this.setInsightsFolders(this.allFolders);
      } else {
        this.value = [];
      }

      const filter = cloneDeep(this.currentFilterStatus);
      filter.priority.value = this.value;
      this.setFilterStatus(filter);
      this.setInsightsPriorities(this.value);
    },
    // Keeps this.value v-model consistent when the UI switches between
    // scrollable and un-scrollable
    // eslint-disable-next-line func-names
    'currentFilterStatus.priority': function (newPriority) {
      this.value = newPriority.value;
    },
    insightsPriorities(newValue) {
      if (newValue.length === 0) {
        this.setInsightsFolders(this.allFolders);
      }
    },
  },
  methods: {
    ...mapActions(['setInsightsPriorities', 'setFilterStatus', 'setFolders', 'setInsightsFolders', 'setShowFolders']),
    onFilterChange() {
      this.setShowFolders(true);
      if (this.value === null || this.value === undefined) this.value = [];
      const filterStatus = cloneDeep(this.currentFilterStatus);
      filterStatus.priority.value = this.value;

      this.setInsightsPriorities(this.value);
      this.setFilterStatus(filterStatus);

      if (this.insightsPriorities.length > 0) {
        // eslint-disable-next-line arrow-body-style
        const filteredFolders = this.allFolders.filter((folder) => {
          return this.insightsPriorities.includes(folder.overall_severity);
        });
        this.setInsightsFolders(filteredFolders);
      } else {
        this.setInsightsFolders(this.allFolders);
      }
    },
  },
  mounted() { this.setInsightsPriorities(this.value); },
};
</script>
