<template>
  <v-row>
    <v-col
      xs="12"
      sm="12"
      md="4"
      lg="4"
      xl="4"
    >
      <v-skeleton-loader
        v-bind="attrs"
        type="list-item-three-line, list-item-two-line, list-item-two-line"
      ></v-skeleton-loader>
    </v-col>
    <v-col
      xs="12"
      sm="12"
      md="8"
      lg="8"
      xl="8"
    >
      <v-skeleton-loader
        v-bind="attrs"
        type="image"
      ></v-skeleton-loader>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'MapSectionLoader',
  props: ['attrs'],
};
</script>
