import platformBackendClient from '@backend-clients/platform-backend';

export default {
  /*
    |-----------------------------------------------|
    |   @method       get                           |
    |   @parameter    <void>                        |
    |   @description  Fetches the platform's        |
    |                 release information           |
    |-----------------------------------------------|
  */
  get: () => platformBackendClient.get('/api/release'),
};
