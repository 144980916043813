<template>
  <v-select
    id="timeframe-filter"
    :items="timeframes"
    v-model="selectedTimeFrame"
    clearable
    :loading="loadingImages"
    outlined
    label="View Fault Detections Within Timeframe"
    single-line
    @input="filterByTimeframe"
  ></v-select>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'DetectionSelector',
  data: () => ({
    timeframes: ['Month', 'Week', 'Day'],
    selectedTimeFrame: undefined,
  }),
  methods: {
    ...mapActions(['setDashboardFilters']),
    filterByTimeframe() {
      // If timeframe is 'undefined', it means that the user has cleared dropdown
      // and so we can set it for 'All' to let the backend know to fetch all images
      const timeframe = (this.selectedTimeFrame) || 'All';
      this.setDashboardFilters({
        type: 'timeframes',
        value: [timeframe],
      });
    },
  },
  computed: {
    ...mapGetters(['dashboardFilters', 'loadingImages']),
  },
  mounted() {
    [this.selectedTimeFrame] = [...this.dashboardFilters.timeframes];
  },
};
</script>
