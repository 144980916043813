<template>
  <v-container>
    <v-card>
      <!-- <v-img :src="url" :lazy-src="url" height="100%">
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
          </v-row>
        </template>
      </v-img> -->
      <SkeletonLoader type="image" v-show="!loaded" />
      <img :src="compress_url" width="100%" @load="handleLoading" v-show="loaded"/>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              icon
              :href="url"
              target="_blank"
              v-on="on"
              v-bind="attrs"
              :small="isMobile"
            >
              <v-icon>mdi-fullscreen</v-icon>
            </v-btn>
          </template>
          <span>Full Sized Image</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import SkeletonLoader from '@components/common/loaders/insights/SkeletonLoader.vue';

export default {
  name: 'ImageCard',
  props: ['url', 'compress_url'],
  components: {
    SkeletonLoader,
  },
  data: () => ({
    loaded: false,
  }),
  computed: {
    ...mapGetters(['small', 'extraSmall']),
    isMobile() {
      return this.small || this.extraSmall;
    },
  },
  methods: {
    handleLoading() {
      this.loaded = true;
    },
  },
};
</script>
