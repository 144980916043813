<template>
  <v-container fluid>
    <TabViews
      :vertical="false"
      :centered="true"
      :tabs="tabs"
    />
  </v-container>
</template>

<script>
import TabViews from '@components/common/TabViews.vue';

export default {
  name: 'ImageProcessingSettings',
  components: {
    TabViews,
  },
  data: () => ({
    tabs: [
      {
        title: 'Access',
        route: '/billing/subscription-plans/access',
      },
      {
        title: 'Switch Plans',
        route: '/billing/subscription-plans/modify',
      },
    ],
  }),
};
</script>
