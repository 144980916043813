<template>
  <v-list :dense="dense">
    <v-list-item
      v-for="(item, i) in items"
      :key="i"
    >
      <v-list-item-content>
        <span :class="font">{{ item }}</span>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: 'List',
  props: ['dense', 'items', 'font'],
};
</script>
