<template>
    <v-row>
      <v-col xs="12" sm="12" md="4" lg="4" xl="4">
        <DetectionFaultsFilter
          v-on:filterDate="getTimeframe"
        />
      </v-col>
      <v-col xs="12" sm="12" md="8" lg="8" xl="8">
        <Histogram
          ref="histo"
          chartId="time-histogram"
          :images="pairedImages"
          :showLegend="false"
          orientation="h"
          :title="title"
        />
      </v-col>
    </v-row>
</template>

<script>
import helpers, { toUpperFirst, toLowerFirst } from '@methods/helpers';
import DetectionFaultsFilter from '@components/analytics/insights/detect_faults/DetectionFaultFilter.vue';
import Histogram from '@components/charts/Histogram.vue';

export default {
  name: 'DetectedFaultsTime',
  props: ['processed'],
  components: {
    DetectionFaultsFilter,
    Histogram,
  },
  data: () => ({
    filterTimeframe: '',
  }),
  methods: {
    getTimeframe(time) {
      if (time === undefined) this.filterTimeframe = '';
      else this.filterTimeframe = toLowerFirst(time);
    },
    getDetectionsLastDay(images) {
      const yesterday = helpers.oneDayAgo();
      const today = helpers.today();
      const imagesWithinADay = images
        .filter((image) => {
          const time = helpers.convertToDate(image.date).format('YYYY-MM-DD');
          return helpers.withinTimeFrame(time, yesterday, today);
        });
      return imagesWithinADay;
    },
    getDetectionsLastWeek(images) {
      const oneWeekAgo = helpers.oneWeekAgo();
      const today = helpers.today();
      const imagesWithinWeek = images
        .filter((image) => {
          const time = helpers.convertToDate(image.date).format('YYYY-MM-DD');
          return helpers.withinTimeFrame(time, oneWeekAgo, today);
        });
      return imagesWithinWeek;
    },
    getDetectionsLastMonth(images) {
      const oneMonthAgo = helpers.oneMonthAgo();
      const today = helpers.today();

      const imagesWithinMonth = images
        .filter((image) => {
          const time = helpers.convertToDate(image.date).format('YYYY-MM-DD');
          return helpers.withinTimeFrame(time, oneMonthAgo, today);
        });
      return imagesWithinMonth;
    },
  },
  computed: {
    title() {
      return (this.filterTimeframe === '') ? 'AI Detections Over Time' : `AI Detections Within ${toUpperFirst(this.filterTimeframe)}`;
    },
    pairedImages() {
      let filteredProcessed;
      const { processed } = this;
      if (this.filterTimeframe === '') {
        filteredProcessed = processed;
      } else if (this.filterTimeframe === 'day') {
        filteredProcessed = this.getDetectionsLastDay(processed);
      } else if (this.filterTimeframe === 'week') {
        filteredProcessed = this.getDetectionsLastWeek(processed);
      } else if (this.filterTimeframe === 'month') {
        filteredProcessed = this.getDetectionsLastMonth(processed);
      } else {
        filteredProcessed = processed;
      }
      return filteredProcessed;
    },
  },
  watch: {
    pairedImages(imgs) {
      this.$refs.histo.react(imgs, this.title);
    },
  },
};
</script>
