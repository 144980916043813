<template>
  <v-card>
    <div class="card" @click="navigate">
      <img :src="icon" alt="Avatar" style="width:60%">
      <v-card-text>
        <p class="text-h4 text--primary" style="text-align: center;font-weight: bolder">
          <slot>
          {{name}}
          </slot>
        </p>
        <div class="text--primary">
          {{description}}</div>
  </v-card-text>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'AppCard',
  props: ['name', 'description', 'icon'],
  methods: {
    navigate() {
      this.$emit('click');
    },
  },
};

</script>

<style scoped>
.card {
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  width: 300px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

</style>
