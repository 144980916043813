<template>
  <v-card tile elevation="4" width="250" height="150" class="ma-4">
    <v-card-title class="headline" id="amount-of-images">
      {{ images.length }}
    </v-card-title>
    <v-card-subtitle class="subtitle" id="current-label">
      {{ label }}
    </v-card-subtitle>
    <v-dialog v-model="model">
      <template v-slot:activator="{ on, attrs }">
        <v-btn text color="primary" small v-on="on" v-bind="attrs">
          <span>More Info</span>
          <v-icon small right>info</v-icon>
        </v-btn>
      </template>
      <FaultSummaryTable :images="images" :label="label" v-on:closeDialog="model=false"/>
    </v-dialog>
    <v-card-actions>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import FaultSummaryTable from '@components/dashboard/fault-summary/FaultSummaryTable.vue';

export default {
  name: 'FaultSummaryCard',
  props: ['images', 'label'],
  components: {
    FaultSummaryTable,
  },
  data: () => ({
    model: false,
  }),
};
</script>
