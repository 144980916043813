<template>
  <div>
    <!-- <p class="title text-center">{{ title }}</p>
    <v-divider></v-divider> -->
    <v-skeleton-loader
      v-if="loading"
      v-bind="attrs"
      type="table"
    ></v-skeleton-loader>
    <v-data-table
      v-else
      :show-expand="expand"
      :headers="h"
      item-key="key"
      :expanded.sync="expanded"
      :items="items"
      hide-default-footer
      hide-default-header
    >
      <template v-slot:top>
        <v-toolbar dark flat>
          <v-toolbar-title :style="{ display: 'block', margin: 'auto' }">
            {{ toolbar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <div v-if="toolbar.buttons">
            <v-tooltip
              bottom
              v-for="(button, index) in toolbar.buttons"
              :key="index"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    fab
                    dark
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="button.clickFn"
                  >
                    <v-icon>{{ button.icon }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ button.text }}</span>
            </v-tooltip>
          </div>
        </v-toolbar>
        </template>
      <template v-if="expand" v-slot:[`item.data-table-expand`]="{ item, expand, isExpanded }">
        <v-icon
          v-if="item.expandable"
          @click="expand(!isExpanded)"
        >
          {{ expandIcon(isExpanded)}}
        </v-icon>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <span class="font-weight-bold">
          {{ item.name }}
        </span>
      </template>
      <template v-slot:[`item.value`]="{ item }">
        <span :class="(item.value) ? '' : 'thin-weight-text grey--text lighten-1'">
          {{ getCellContent(item) }}
        </span>
      </template>
      <template v-if="expand" v-slot:[`expanded-item`]="{ headers, item }">
        <td :colspan="headers.length">
          <tr
            v-for="(expandedItem, index) in item.expandedItems"
            :key="index"
          >
            <span
              class="text-decoration-underline font-weight-bold"
            >{{ expandedItem.name }}</span>
            <v-spacer></v-spacer>
            <span>{{  expandedItem.value  }}</span>
          </tr>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'DataTable',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    expand: {
      type: Boolean,
      default: false,
    },
    entries: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    toolbar: {
      type: Object,
      default: () => ({
        title: '',
        buttons: [],
      }),
    },
  },
  data() {
    return {
      expanded: [],
      attrs: {
        class: 'mb-6',
        elevation: 2,
      },
      items: [],
      h: this.headers,
    };
  },
  methods: {
    expandIcon: (isExpanded) => ((isExpanded) ? 'expand_less' : 'expand_more'),
    getCellContent: (item) => ((item.value) ? item.value : `${item.name} has not been assigned`),
    cellValueTypography: (item) => ((item.value) ? 'body-1' : 'thin-weight-text text--grey'),
  },
  watch: {
    loading: {
      handler(currentLoadingState) {
        if (!currentLoadingState) {
          this.items = this.entries;
        }
      },
      immediate: true,
    },
  },
};
</script>
