<template>
  <v-expansion-panels>
    <v-expansion-panel @change="updatePanelState">
      <v-expansion-panel-header class="text-xs-h6 text-sm-h6">
        Reports Detail
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <p class="text--primary">
          <span class="font-weight-bold text-sm-caption">Structure Defects: </span>
          <span class="font-weight-regular green--text">{{defections.structure}}</span>
        </p>
        <p class="text--primary">
          <span class="font-weight-bold text-sm-caption">Cross Defects: </span>
          <span class="font-weight-regular red--text text-sm-caption">{{defections.cross}}</span>
        </p>
        <p class="text--primary">
          <span class="font-weight-bold text-sm-caption">Equipment Defect: </span>
          <span class="font-weight-regular yellow--text text-sm-caption">
            {{defections.equipment}}
          </span>
        </p>
        <p class="text--primary">
          <span class="font-weight-bold text-sm-caption">Other Defects: </span>
          <span class="font-weight-regular blue--text text-sm-caption">{{defections.other}}</span>
        </p>
        <p class="text--primary">
          <span class="font-weight-bold text-sm-caption">Total Processed: </span>
          <span class="font-weight-regular blue--text text-sm-caption">
            {{ totalImages.processed }}
          </span>
        </p>
        <p class="text--primary">
          <span class="font-weight-bold text-sm-caption">Total Images: </span>
          <span class="font-weight-regular blue--text text-sm-caption">
            {{ totalImages.original }}
          </span>
        </p>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import { structure, cross, equipment } from '@components/analytics/insights/filters/faults';

export default {
  name: 'ReportsDetail',
  props: ['images', 'pid'],
  data: () => ({ show: false }),
  methods: {
    ...mapActions(['setPanelStates']),
    updatePanelState() {
      this.show = !this.show;
      const panels = cloneDeep(this.currentPanelStates);
      panels.reports = this.show;
      this.setPanelStates(panels);
    },
  },
  computed: {
    ...mapGetters(['currentProject', 'currentInsightsIndex', 'currentImage', 'allImages', 'currentPanelStates', 'companyHas', 'processedImagesR']),
    defections() {
      const { length } = this.currentImage.process_tracking;
      const { labels } = this.currentImage.process_tracking[length - 1];

      const defects = {
        structure: 0,
        cross: 0,
        other: 0,
        equipment: 0,
      };

      labels.forEach((classType) => {
        if (structure.includes(classType)) defects.structure += 1;
        else if (cross.includes(classType)) defects.cross += 1;
        else if (equipment.includes(classType)) defects.equipment += 1;
        else defects.other += 1;
      });

      return defects;
    },
    totalImages() {
      const count = {
        original: this.allImages.length,
        processed: this.allImages.filter((image) => image.processedImageUrl).length,
      };
      return count;
    },
    projectName() {
      const { pid } = this;
      const projectName = this.currentProject ? 'all' : this.currentProject.name;
      if (pid === 'all') return 'Reports Detail: ';
      return `Reports Detail: ${projectName}`;
    },
  },
};
</script>
