const state = {
  success: false,
  message: '',
};

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const getters = {
  currentNotification: (state) => ({
    success: state.success,
    message: state.message,
  }),
};

const actions = {
  setNotification({ commit }, notification) {
    commit('set_notification', notification);

    setTimeout(() => {
      commit('set_notification', { success: false, message: '' });
    }, 3000);
  },
};

const mutations = {
  set_notification: (state, notification) => {
    state.success = notification.success;
    state.message = notification.message;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
