export default {
  bronze: [
    'Annual Image Cap: 0 - 100,000',
    'Asset Fault Detection',
    'Asset Management',
  ],
  silver: [
    'Annual Image Cap: 100,000 - 500,000',
    'Asset Fault Detection',
    'Asset Management',
    'Vegetation Encroachment Detection',
    'Additional Sensor Data Processing',
    'API Access',
  ],
  gold: [
    'Annual Image Cap: 100,000 - 500,000',
    'Asset Fault Detection',
    'Asset Management',
    'Vegetation Encroachment Detection',
    'Additional Sensor Data Processing',
    'API Access',
    'Predictive Asset Management System',
  ],
  dashboard: [
    'Analytics Dashboard',
    'Data Visualization Insights',
    'Assets and Fault Mapping',
    'Comparative Analytics',
    'Report Generation',
    '3 User Minimum',
  ],
};
