<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" v-for="i in 3" :key="i">
        <v-skeleton-loader
          v-bind="attrs"
          type="list-item-three-line"
        >
        </v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'DashboardAccessLoader',
  data: () => ({
    attrs: {
      class: 'mb-6',
      boilerplate: false,
      elevation: 2,
    },
    inject: {
      theme: {
        default: { isDark: false },
      },
    },
  }),
};
</script>
