import pluralize from 'pluralize';
import hasLocation from '@utils/location';
import excelHelpers from '@utils/excel/helpers';

const getBucket = (images) => {
  if (images.length > 0) {
    return images[0].bucket;
  }

  return '';
};

const getProjectType = (project) => pluralize(project.project_type);

const getImageDetails = (folder, images, project, prefix, subprefix) => {
  const imageNames = images.map((image) => image.filename);
  if (prefix && subprefix) {
    return `${prefix}\\${getProjectType(project)}\\${subprefix}\\${project.name}\\${imageNames.join('; ')}`;
  }
  return `https://console.cloud.google.com/storage/browser/${getBucket(images)}/avantis_wms/${project.name}/${folder.id}/${imageNames.join(' ; ')}`;
};

const nfFormat = (folder, images, project, prefix, subprefix) => ({
  'Pole # (a33527)': (folder.path) ? folder.path : 'N/A',
  'WR Site ID': '',
  'Work Request': '',
  Entity: '',
  Title: '',
  'Date Inspected': excelHelpers.getTimeStamp(images),
  Description: excelHelpers.getDescription(images),
  Notes: folder.note ? folder.note : '', // notes section
  'Requested On': '',
  'Requested By': 'PowerAI',
  'Work Type': '',
  Priority: (folder.overall_severity) ? folder.overall_severity : 'N/A',
  'Start No Earlier Than': excelHelpers.getDate(images),
  'Finish No Later Than': '',
  'Estimated cost_amt': '',
  'Estimated duration_amt': '',
  'Estimated duration_uom': '',
  Planner: '',
  Supervisor: '',
  'Approval Comment Text': '',
  'Approval Status': '',
  'Approved Date': '',
  'Approved Employee SL - Work Classification (Cat 1)': '',
  'DIST Deficiencies (Cat 2)': '',
  'DL - Work Classification (Cat 3)': '',
  Keywords: '',
  'Created on date': '',
  Attachment: excelHelpers.getAttachment(folder, images, project, prefix, subprefix),
  'Attachment Title': '',
  'Structure Type (a33515)': '',
  'Conductor Type (a33516)': '',
  'Truck Accessable (a33517)': '',
  'Flagman Required (a33518)': '',
  'Outage Type (a33519)': '',
  'Risk (a33520)': '',
  'GPS Latitude (a33521)': '',
  'GPS Longitude (a33522)': '',
  'WR Source (a33523)': '',
  'Capital Budget Year (a33524)': '',
  'Location (a33528)': '',
  'Start Address (LOCNstaddr)': '',
  'End Address (LOCNendaddr)': '',
  'GIS Object ID (LOCNgis_oi)': '',
  'Start Longitude (LOCNstlng)': (hasLocation(folder)) ? excelHelpers.getStartLatitude(folder) : 'N/A',
  'Start Latitude (LOCNstlat)': (hasLocation(folder)) ? excelHelpers.getStartLongitude(folder) : 'N/A',
  'End Longitude (LOCNendlong)': '',
  'End Latitude (LOCNendlat)': '',
  'City (LOCNcity)': '',
  'State (LOCNstate)': '',
  'Zip Code (LOCNzpcode)': '',
  'Contact Person (LOCNcontpsn)': '',
  'Contact Email (LOCNconteml)': '',
  'Contact Phone (LOCNcontphn)': '',
  'Start Elevation (LOCNSrt_Elv)': '',
  'End Elevation (LOCNEnd_Elv)': '',
  '##DP Processed Flag##': '',
  '##DP Processed Status##': '',
  '##DP Error Text##': '',
});

const generalFormat = (folder, images, project, prefix, subprefix) => ({
  'Site Name': folder.path,
  'Project Name': project.name,
  'Structure Number': folder.id,
  'Fault Type': excelHelpers.getTitle(images),
  Description: excelHelpers.getDescription(images),
  Priority: folder.overall_severity,
  Severity: excelHelpers.getBucketgetSeverity(images),
  'Work Order': (folder.work_order) ? 'Yes' : 'No',
  'Work Order Number': '',
  'Date Inspected': excelHelpers.getTimeStamp(images),
  'Date Processed': excelHelpers.getDate(images),
  Attachment: excelHelpers.getAttachment(folder, images, project, prefix, subprefix),
  Images: getImageDetails(folder, images, project, prefix, subprefix),
  Longitude: excelHelpers.getStartLongitude(folder),
  Latitude: excelHelpers.getStartLatitude(folder),
});

export default (folder, images, project, prefix, subprefix, client) => {
  const isNf = client.includes('Newfoundland');
  return (isNf)
    ? nfFormat(folder, images, project, prefix, subprefix)
    : generalFormat(folder, images, project, prefix, subprefix);
};
