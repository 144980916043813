<template>
  <v-container fluid>
    <PurchaseDashboard />
  </v-container>
</template>

<script>
import PurchaseDashboard from '@views/analytics-views/PurchaseDashboard.vue';

export default {
  name: 'NoDashboardAccess',
  components: {
    PurchaseDashboard,
  },
};
</script>
