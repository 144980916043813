export default [
  {
    width: 40,
    height: 40,
    className: 'custom-clustericon-0',
  },
  {
    width: 40,
    height: 40,
    className: 'custom-clustericon-1',
  },
  {
    width: 40,
    height: 40,
    className: 'custom-clustericon-2',
  },
  {
    width: 40,
    height: 40,
    className: 'custom-clustericon-3',
  },
  {
    width: 40,
    height: 40,
    className: 'custom-clustericon-4',
  },
];
