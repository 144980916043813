<template>
  <div>
    <FolderList v-if="showingFolders" :type="type" />
    <ImageList
      :images="images"
      v-else
      @goBack="folderList"
      :showSeverityFilters="showSeverityFilters"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FolderList from '@components/folders/FolderList.vue';
import ImageList from '@components/folders/ImageList.vue';

export default {
  name: 'SimpleFileManager',
  // props: ['images', 'type', 'showSeverityFilters'],
  props: {
    images: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: '',
      required: true,
    },
    showSeverityFilters: {
      type: Boolean,
      default: false,
    },
  },
  components: { FolderList, ImageList },
  data: () => ({
    showFolders: true,
  }),
  methods: {
    ...mapActions(['setCurrentFolder', 'setShowFolders']),
    folderList() {
      this.setCurrentFolder(this.rootFolder);
      this.setShowFolders(true);
    },
    imageList() { this.showFolders = false; },
  },
  computed: {
    ...mapGetters(['rootFolder', 'currentFolder', 'showingFolders', 'companyHas', 'currentCompany']),
    hasFolder() {
      return this.companyHas('poleByFolders');
    },
  },
  mounted() {
    this.folderList();
  },
};
</script>
