<template>
  <v-container id="image-editor-container" fluid>
    <center id="noimage" v-if="!lastIndex">No images match the filter.</center>
    <v-row id="image-editor-row" v-if="lastIndex">
      <paginator :firstIndex="firstIndex" :lastIndex="lastIndex" @indexChanged="indexChanged" />
      <v-col id="image-editor-col" cols="12" align="center">
        <div id="scrollable">
          <img
            :src="currentImage.originalImageUrl"
            ref="marker"
            :width="`${size}px`"
            id="image-editor-img"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.pagination-count {
  padding: 10px;
}
#image-editor-row {
  margin-top: 80px;
  margin-bottom: 10px;
}
#scrollable {
  overflow: auto;
  width: 100%;
  height: calc(100vh - 190px);
}
#noimage {
  padding-top: 100px;
  font-size: 2em;
}
</style>

<script>
import { mapGetters, mapActions } from 'vuex';
import { cloneDeep } from 'lodash';
import getLabelFilterStatus from '@utils/getLabelFilterStatus';
import { Annotator } from '@components/annotations/annotorious';
import Paginator from '@components/annotations/Paginator.vue';
import { deepCompare } from '@methods/helpers';

export default {
  components: { Paginator },
  name: 'ImageEditor',
  props: ['visibility', 'size', 'tab'],
  data: () => ({
    anno: null,
    selectedAnnotation: null,
    edits: 0,
    labelStatus: {},
    localRegions: [],
    oldX: 0,
    oldY: 0,
    scrollPace: 10,
  }),
  computed: {
    ...mapGetters([
      'currentImage',
      'currentAnnotations',
      'modifiedAnnotations',
      'imagesBeenProcessed',
      'allImages',
      'currentFolder',
      'currentSeverityFilters',
      'flatProjectLabels',
      'companyHas',
      'projectLabels',
      'currentLabelFilter',
    ]),
    images() {
      return this.tab === 'ai' ? this.imagesBeenProcessed : this.allImages;
    },
    firstIndex() {
      const index = this.folderImages.findIndex((image) => {
        const dateMatch = image.date === this.currentImage.date;
        const filenameMatch = image.filename === this.currentImage.filename;
        return dateMatch && filenameMatch;
      });

      const parsedInt = Number(index);
      return parsedInt > 0 ? parsedInt + 1 : 1;
    },
    lastIndex() {
      return this.folderImages.length;
    },
    imageIndex() {
      const index = this.folderImages.findIndex((img) => {
        const datesMatch = img.date === this.currentImage.date;
        const filenameMatch = img.filename === this.currentImage.filename;

        return datesMatch && filenameMatch;
      });
      return index < 0 ? 1 : index + 1;
    },
    folderImages() {
      const filteredImages = this.images.filter((image) => {
        const { length } = image.process_tracking;
        const matchFolder = this.currentFolder.path === '__all__' || image.folder === this.currentFolder.path;
        if (this.currentSeverityFilters.length === 0 || !image.process_tracking[length - 1]) {
          return matchFolder;
        }
        const { severity } = image.process_tracking[length - 1];
        const matchSeverity = this.currentSeverityFilters.includes(severity);
        return matchFolder && matchSeverity;
      });

      return filteredImages;
    },
    imageDetail() {
      const image = { ...this.currentImage };

      const details = {};

      details.severity = 'None';
      // if process tracking exists
      if (image.process_tracking.length) {
        const processing = image.process_tracking.slice(-1)[0];
        details.caption = processing.caption;
        details.classes = processing.labels;
        details.severity = processing.severity;
        details.priority = processing.priority || 'None';
        details.reviewed = processing.reviewed;
      }

      return details;
    },
    filteredAnnotations() {
      if (!this.visibility) return this.localRegions;
      const filteredAnnos = this.modifiedAnnotations.filter((anno) => {
        const severityBody = anno.body.find((b) => b.purpose === 'severity');
        // const labelBody = anno.body.find((b) => b.purpose === 'labeling');
        let hasSeverity;
        // let hasLabel;

        if (!severityBody) hasSeverity = false;
        else hasSeverity = this.currentLabelFilter.includes(severityBody.value);
        if (this.currentLabelFilter.length === 0) hasSeverity = true;

        return hasSeverity;
      });
      return filteredAnnos;
    },
  },
  watch: {
    // If watch lifecycle detects a change in 'visibility', API is set to the
    // most recent visibility state
    async visibility() {
      await this.resetAnnotation();
    },
    async currentAnnotations() {
      await this.resetAnnotation();
    },
    async currentSeverityFilters() {
      await this.resetAnnotation();
    },
    async currentLabelFilter() {
      await this.resetAnnotation();
    },
  },
  methods: {
    ...mapActions(['setRegions', 'emptySeverityFilter']),
    isModified: (current, modified) => {
      const isNewLabel = !!(current === undefined && modified);
      if (isNewLabel) return true;
      const isChanged = !deepCompare(current, modified);

      const currentSeverity = current.body.find((b) => b.purpose === 'severity').value;
      const modifiedSeverity = modified.body.find((b) => b.purpose === 'severity').value;

      const currentLabel = current.body.find((b) => b.purpose === 'labeling').value;
      const modifiedLabel = modified.body.find((b) => b.purpose === 'labeling').value;

      const labelChanged = isChanged && (currentLabel !== modifiedLabel);
      const severityChanged = isChanged && (currentSeverity !== modifiedSeverity);

      return (severityChanged && labelChanged)
        || (!severityChanged && labelChanged);
    },
    indexChanged(index) {
      this.localRegions = [];
      this.$emit('navigated', this.folderImages[index - 1]);
    },
    // Sets the annotator to either Polygon or Rectangle mode
    setMode(toolname) {
      this.anno.setDrawingTool(toolname);
    },

    /*
      |===================================================|
      | @handler        createAnnotation                  |
      | @description    Sets an updated annotations list  |
      |                 after creating a new annotation   |
      |===================================================|
    */
    createAnnotation(annotation) {
      const newAnnotation = { ...annotation };
      if (!('from_model' in newAnnotation)) newAnnotation.from_model = false;
      if (!('confidence' in newAnnotation)) newAnnotation.confidence = 1;
      // Sets updated as the new list of annotations
      const updated = cloneDeep(this.modifiedAnnotations);
      updated.push(newAnnotation);

      if (!this.visibility) this.localRegions.push(newAnnotation);

      // Sets new annotations to annotationEdits Vuex State
      this.setRegions(updated);
      this.edits += 1;
      this.$emit('editCount', this.edits);
    },

    /*
      |===================================================|
      | @handler        deleteAnnotation                  |
      | @description    Sets an updated annotations list  |
      |                 after removing an annotation      |
      |===================================================|
    */
    deleteAnnotation(annotation) {
      // Sets updated as a filtered list of annotations cloned from modifiedAnnotations
      // Condition: Remove the annotation if the deleting annotation and the iterable
      //            annotation match ids
      const updated = cloneDeep(this.modifiedAnnotations).filter(
        (anno) => anno.id !== annotation.id,
      );

      // Sets new annotations to annotationEdits Vuex State
      this.setRegions(updated);
      this.edits += 1;
      this.$emit('editCount', this.edits);

      if (!this.visibility) {
        const localUpdated = cloneDeep(this.localRegions).filter(
          (anno) => anno.id !== annotation.id,
        );
        this.localRegions = localUpdated;
      }
    },

    /*
      |===================================================|
      | @handler        updatingAnnotation                |
      | @description    Sets an updated annotations list  |
      |                 after editing a current annotation|
      |===================================================|
    */
    updateAnnotation(annotation) {
      const updatedAnnotation = { ...annotation };
      // Find unmodified annotation based on id
      const [current] = this.currentAnnotations.filter((a) => a.id === updatedAnnotation.id);

      // Compare it with the annotation that annotorious spits back out
      const changesMade = this.isModified(current, updatedAnnotation);

      if (changesMade) {
        updatedAnnotation.from_model = false;
        updatedAnnotation.confidence = 1;
      }

      // Clone the current modifiedAnnotations array
      const list = cloneDeep(this.modifiedAnnotations);
      // Search for index of the updating annotation based on id comparisons
      const index = list.findIndex((item) => item.id === updatedAnnotation.id);

      // If an index is found, replace the old annotations at the found index with the
      // new one and set annotation changes
      if (index !== -1) {
        list.splice(index, 1, updatedAnnotation);
        this.setRegions(list);
        this.edits += 1;
        this.$emit('editCount', this.edits);
      }

      if (!this.visibility) {
        const localList = cloneDeep(this.localRegions);
        const ind = list.findIndex((item) => item.id === updatedAnnotation.id);

        // If an index is found, replace the old annotations at the found index with the
        // new one and set annotation changes
        if (ind !== -1) {
          localList.splice(ind, 1, updatedAnnotation);
          this.localRegions = localList;
        }
      }
    },

    /*
      |===================================================|
      | @handler        createSelection                   |
      | @description    Event handler that checks for the |
      |                 annotation editor's 'top' css     |
      |                 and makes it visible if it's      |
      |                 being pushed up too far to the top|
      |===================================================|
    */
    createSelection: () => {
      const interval = setInterval(() => {
        const [editor] = document.getElementsByClassName('r6o-editor');
        const style = window.getComputedStyle(editor);
        const top = style.getPropertyValue('top');
        const topValue = parseInt(top.replace('px', ''), 10);
        if (topValue < 0) editor.style.top = '10px';

        if (topValue !== 0) clearInterval(interval);
      }, 100);
    },
    setAnnotation(annotation) {
      this.selectedAnnotation = annotation;
    },
    removeAnnotation() {
      this.anno.selectAnnotation();
      const canDelete = this.selectedAnnotation !== undefined && this.selectedAnnotation !== null;
      if (canDelete) {
        this.deleteAnnotation(this.selectedAnnotation);
        this.anno.removeAnnotation(this.selectedAnnotation);
        this.selectedAnnotation = null;
      }
    },
    cancelSelected() {
      this.anno.cancelSelected();
    },
    selectPoint(event) {
      this.$emit('zoomSelection', event);
    },
    initAnnotations() {
      // Find document object of the image with reference 'marker'
      const { marker } = this.$refs;

      // Initialize Annotator API as this.anno
      this.anno = Annotator(
        marker,
        this.flatProjectLabels,
        this.projectLabels,
        this.companyHas('categorize_labels'),
      );

      // Set annotations on image
      this.anno.setAnnotations(this.filteredAnnotations);

      // Create, delete, update event handlers
      this.anno.on('createAnnotation', this.createAnnotation);
      this.anno.on('deleteAnnotation', this.deleteAnnotation);
      this.anno.on('updateAnnotation', this.updateAnnotation);
      this.anno.on('selectAnnotation', this.setAnnotation);
      this.anno.on('createSelection', this.createSelection);

      const annotationLayers = document.getElementsByClassName('a9s-annotationlayer');
      const annotations = document.getElementsByClassName('a9s-annotation');

      for (let i = 0; i < annotationLayers.length; i += 1) {
        annotationLayers[i].addEventListener('click', this.selectPoint);
      }

      for (let i = 0; i < annotations.length; i += 1) {
        annotations[i].addEventListener('click', this.selectPoint);
      }
    },
    async resetAnnotation() {
      this.anno.destroy();
      this.initAnnotations();
      await new Promise((r) => setTimeout(r, 1));
      this.anno.destroy();
      this.initAnnotations();
    },
    scrollDir(oldPos, newPos) {
      return Math.sign(oldPos - newPos);
    },
  },
  mounted() {
    this.initAnnotations();
  },
  created() {
    this.labelStatus = getLabelFilterStatus(this.flatProjectLabels);
    // Initializes modifiedAnnotations as a copy of currentAnnotations
    const current = cloneDeep(this.currentAnnotations);
    this.setRegions(current);
  },
};
</script>
