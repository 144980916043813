<template>
  <div>
    <FolderSelector v-if="showingFolders" :type="type" />
    <ImageSelector v-else/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FolderSelector from '@components/dashboard/file-management/FolderSelector.vue';
import ImageSelector from '@components/dashboard/file-management/ImageSelector.vue';

export default {
  name: 'FileManagement',
  props: {
    images: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: '',
      required: true,
    },
    showSeverityFilters: {
      type: Boolean,
      default: false,
    },
  },
  components: { FolderSelector, ImageSelector },
  methods: {
    ...mapActions(['setCurrentFolder', 'setShowFolders']),
    folderList() {
      this.setCurrentFolder(this.rootFolder);
      this.setShowFolders(true);
    },
  },
  computed: {
    ...mapGetters(['rootFolder', 'currentFolder', 'showingFolders', 'companyHas', 'currentCompany', 'allFolders']),
    hasFolder() {
      return this.companyHas('poleByFolders');
    },
  },
  mounted() {
    this.folderList();
  },
};
</script>
