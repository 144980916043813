<template>
  <div class="user-settings">
    <v-container fluid>
      <v-row justify="center">
        <span class="green--text" v-if="isUpdated">{{updateStatus}}</span>
        <v-col cols="11">
          <v-text-field
            label="First Name"
            outlined
            :value="firstName"
            @change="firstNameChange"
            id="first-name-field"
            :loading="loadingUser"
            class="mx-auto"
          ></v-text-field>
        </v-col>
        <v-col cols="11">
          <v-text-field
            label="Last Name"
            outlined
            :value="lastName"
            @change="lastNameChange"
            :loading="loadingUser"
            id="last-name-field"
            class="mx-auto"
          ></v-text-field>
        </v-col>
        <v-col cols="11">
          <v-text-field
            label="Email"
            outlined
            :value="email"
            @change="emailChange"
            :loading="loadingUser"
            id="email-field"
            class="mx-auto"
          ></v-text-field>
        </v-col>
        <v-col cols="11">
          <v-text-field
            label="Phone Number"
            outlined
            :value="phone"
            @change="phoneChange"
            :loading="loadingUser"
            type="phone"
            id="phone-field"
            class="mx-auto"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-btn color="primary" right @click="onSaveUserProfile">Save</v-btn>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'UserSettings',
  props: ['uid'],
  data: () => ({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    updateStatus: '',
    isUpdated: false,
    newUser: {},
  }),
  methods: {
    ...mapActions(['updateUser', 'updateUserAuth', 'setNotification']),
    firstNameChange(firstName) {
      this.newUser.firstName = firstName;
    },
    lastNameChange(lastName) {
      this.newUser.lastName = lastName;
    },
    emailChange(email) {
      this.newUser.email = email;
    },
    phoneChange(phone) {
      if (phone === this.phone) delete this.newUser.phone;
      this.newUser.phone = phone;
    },
    onSaveUserProfile() {
      if (Object.keys(this.newUser).length === 0) {
        return;
      }

      const payload = {
        user_id: this.currentUser.uid,
        uid: this.currentUser.firestore_uid,
        updates: { ...this.newUser },
      };

      this.updateUser(payload)
        .then((res) => {
          this.setNotification({
            message: `Successfully updated ${res.data.firstName} ${res.data.lastName}'s information`,
            success: true,
            color: '',
          });
        })
        .catch(() => {
          this.setNotification({
            message: `There was an error updating ${this.currentUser.firstName} ${this.currentUser.lastName}'s information`,
            success: true,
            color: 'error',
          });
        });
    },
  },
  computed: {
    ...mapGetters(['currentUser', 'loadingUser']),
  },
  mounted() {
    const { currentUser } = this;
    if (currentUser.firstName) this.firstName = currentUser.firstName;
    if (currentUser.lastName) this.lastName = currentUser.lastName;
    if (currentUser.email) this.email = currentUser.email;
    if (currentUser.phone) this.phone = currentUser.phone;
  },
};
</script>
