import platformBackendClient from '@backend-clients/platform-backend';

export default {
  /*
    |---------------------------------------------|
    |   @params   query <String>                  |
    |   @desc     Takes in a query string to      |
    |             fetch for a list of projects.   |
    |             Either all company projects,    |
    |             team projects, or user projects |
    |---------------------------------------------|
  */
  all: (query) => platformBackendClient.get(`/api/projects/?${query}`),

  /*
    |---------------------------------------------|
    |   @params   id <String>                     |
    |   @desc     Passes in the project id as the |
    |             parameter to fetch a specific   |
    |             project                         |
    |---------------------------------------------|
  */
  get: (cid, pid) => platformBackendClient.get(`/api/projects/${pid}/?company_id=${cid}`),

  /*
    |---------------------------------------------|
    |   @params   cid <String>, project <Object   |
    |   @desc     Creates and attaches a new      |
    |             project to a company by company |
    |             id                              |
    |---------------------------------------------|
  */
  create: (companyId, cid, project) => platformBackendClient.post(`/api/projects/?company_id=${companyId}&cid=${cid}`, project),

  /*
    |---------------------------------------------|
    |   @params   id <String>, updates <Object>   |
    |   @desc     Passes in the updates within a  |
    |             for a specific project id       |
    |---------------------------------------------|
  */
  update: (cid, firestorePid, pid, updates) => platformBackendClient.put(
    `/api/projects/${firestorePid}/?company_id=${cid}&project_id=${pid}`,
    updates,
  ),

  /*
    |---------------------------------------------|
    |   @params   cid <String>, pid <String>      |
    |   @desc     Deletes project by project id   |
    |---------------------------------------------|
  */
  delete: (cid, projectId, pid) => platformBackendClient.delete(`/api/projects/${pid}/?company_id=${cid}&project_id=${projectId}`),
};
