<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        id="download-csv-btn"
        icon
        x-large
        :color="(!errors) ? 'primary' : 'red'"
        v-bind="attrs"
        v-on="on"
        @click="downloadCsv"
      >
        <v-icon>fas fa-file-csv</v-icon>
      </v-btn>
    </template>
    Export to CSV
  </v-tooltip>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import dashboardHelpers from '@components/dashboard/methods';

export default {
  name: 'ExportCsv',
  props: {
    pid: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    errors: null,
  }),
  methods: {
    ...mapActions(['setRetrieveCsv']),
    async getCsvBlob() {
      this.setRetrieveCsv(true);
      try {
        const res = await dashboardHelpers.getCsvData(this.currentCompany.cid, this.pid);
        this.setRetrieveCsv(false);
        return res;
      } catch (err) {
        this.setRetrieveCsv(false);
        this.errors = err;
        return err;
      }
    },
    async downloadCsv() {
      // Retrieves csv data
      const csv = await this.getCsvBlob();

      // Download the csv data with a filename passed into it
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(csv.blob, csv.filename);
      } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
          const url = URL.createObjectURL(csv.blob);
          link.setAttribute('href', url);
          link.setAttribute('download', csv.filename);
          link.style.visiblility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
  },
  computed: {
    ...mapGetters(['currentCompany']),
  },
};
</script>
