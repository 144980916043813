<template>
  <v-container fluid>
    <v-list-item-title>
      <div id="default-payment-label"  v-if="isDefaultPayment(id)">
        <code>Default</code>
        <br />
      </div>
      {{brand}} ending in {{last4}}
      <v-icon
        color="primary"
        class="float-right card-icon"
      >{{icon}}</v-icon>
    </v-list-item-title>
    <v-list-item-subtitle
      class="text--primary expiration"
      v-text="`Expires ${expirationMonth}/${expirationYear}`"
    ></v-list-item-subtitle>
    <v-list-item-subtitle
      class="text--primary card-holder-name"
      v-text="name"
    ></v-list-item-subtitle>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-dialog v-model="editDialog">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            class="edit-pay-btn"
            color="primary"
            v-on="on"
            v-bind="attrs"
          >Edit</v-btn>
        </template>
        <EditPaymentForm
          :payment="payment"
          v-on:closeEditForm="closeEdit"
        />
      </v-dialog>
      <v-dialog v-model="removeDialog">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            class="delete-pay-btn"
            color="primary"
            v-on="on"
            v-bind="attrs"
          >Remove</v-btn>
        </template>
        <DeletePaymentConfirmation :payment="payment" v-on:closeDeleteDialog="closeRemove"/>
      </v-dialog>
      <v-btn
        text
        class="set-default-pay-btn"
        color="primary"
        :disabled="!isChecked"
        @click="setAsDefaultPaymentMethod"
      >Set Default</v-btn>
    </v-card-actions>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import EditPaymentForm from '@components/payments/common/EditPaymentForm.vue';
import DeletePaymentConfirmation from '@components/payments/common/DeletePaymentConfirmation.vue';

export default {
  name: 'PaymentContent',
  components: {
    EditPaymentForm,
    DeletePaymentConfirmation,
  },
  props: ['brand', 'icon', 'name', 'expirationMonth', 'expirationYear', 'last4', 'payment', 'id', 'isChecked'],
  data: () => ({
    editDialog: false,
    removeDialog: false,
  }),
  methods: {
    ...mapActions(['updateCustomer']),
    closeEdit() {
      this.editDialog = false;
    },
    closeRemove() {
      this.removeDialog = false;
    },
    isDefaultPayment(paymentMethodId) {
      if (!this.currentCustomer.invoice_settings.default_payment_method) {
        return this.currentCustomer.default_source === paymentMethodId;
      }

      return this.currentCustomer.invoice_settings.default_payment_method === paymentMethodId;
    },
    setAsDefaultPaymentMethod() {
      const customerId = this.currentCustomer.id;
      const paymentMethodId = this.payment.id;
      const payload = { customerId, data: {} };

      if (!this.currentCustomer.invoice_settings.default_payment_method) {
        payload.data.default_source = paymentMethodId;
      } else payload.data = { invoice_settings: { default_payment_method: paymentMethodId } };

      this.updateCustomer(payload);
    },
  },
  computed: {
    ...mapGetters(['currentCustomer']),
  },
};
</script>
