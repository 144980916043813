<template>
  <div id="dynamic-scroller">
    <div id="non-scrollable" v-show="showing === false">
      <slot></slot>
    </div>
    <v-responsive :max-width="mw" class="mx-auto mb-4" v-show="showing">
      <v-virtual-scroll :items="items" :height="height" :itemHeight="itemHeight">
        <slot></slot>
      </v-virtual-scroll>
    </v-responsive>
  </div>
</template>

<script>
export default {
  name: 'VirtualScroller',
  props: {
    items: { type: Array, default() { return []; } },
    height: { type: Number, default: 450 },
    itemHeight: { Type: Number, default: 600 },
    dynamic: { type: Boolean, default: false },
    mw: { type: Number, default: 400 },
    showing: Boolean,
  },
};
</script>
