import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';
import config from './config';

firebase.initializeApp(config);

export default {
  database: firebase.firestore(),
  storage: firebase.storage(),
  auth: firebase.auth,
  firestore: firebase.firestore,
};
