/*
  |===================================================|
  |   @method: makeRequest                            |
  |   @parameters: url <String>, option <Object>      |
  |   @description: Using fetch API, makes request to |
  |     the specified url with the specified options  |
  |   @return: <pending> Promise                      |
  |===================================================|
*/
const makeRequest = (url, option) => new Promise((resolve, reject) => {
  fetch(url, option)
    .then((response) => {
      if (response.ok) return response.json();
      throw Error(`Error with status ${response.status}`);
    })
    .then((result) => resolve(result))
    .catch((err) => reject(err));
});

export default makeRequest;
