<template>
  <v-container fluid>
    INVOICES
    <Loading v-if="loadingInvoices" />
    <v-card v-for="(invoice, month) in allInvoices" :key="month" class="mx-auto my-5">
      <v-card-text>
        <p>{{month}}</p>
        <p class="red--text" v-if="invoice.amount_remaining > 0"> Balance outstanding of
          <i><b>${{invoice.amount_remaining}}</b></i></p>
        <p class="green--text" v-else><i>Fully settled</i></p>
        <p>Amount Due : <i><b>${{invoice.total_due}}</b></i></p>
        <p>Amount Paid : <i><b>${{invoice.total_paid}}</b></i></p>
      </v-card-text>
      <v-card-actions>
        <v-btn
          text
          color="primary"
          @click="openDetail(month)"
        >
          Details
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-bottom-sheet v-model="showDetail" inset scrollable>
      <v-card height="700">
      <v-card-text>
        <InvoiceDetail :invoices="currentDetail" :month="currentMonth"/>
      </v-card-text>
    </v-card>
    </v-bottom-sheet>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Loading from '@components/common/Loading.vue';
import InvoiceDetail from '@views/billing_views/InvoiceDetail.vue';

export default {
  name: 'Invoices',
  data: () => ({
    page: '',
    allLoaded: false,
    showDetail: false,
    currentMonth: '',
  }),
  components: {
    Loading,
    InvoiceDetail,
  },
  computed: {
    ...mapGetters(['allInvoices', 'loadingInvoices', 'currentCompany']),
    currentDetail() {
      const month = this.allInvoices[this.currentMonth];
      return month === undefined ? {} : month.days;
    },
  },
  methods: {
    ...mapActions(['fetchInvoices']),
    niceTime(timestamp) {
      const date = new Date(timestamp * 1000);
      return date.toLocaleString();
    },
    async loadMore() {
      if (this.allLoaded) {
        return;
      }
      await this.fetchInvoices({
        page: this.page,
        company: this.currentCompany,
      });
    },
    openDetail(month) {
      this.currentMonth = month;
      this.showDetail = true;
    },
  },
  async created() {
    await this.fetchInvoices({
      page: this.page,
      company: this.currentCompany,
    });
  },
};
</script>
