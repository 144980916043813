import makeRequest from './request';

/*
  |===================================================|
  |   @method: getStripe                              |
  |   @parameters: url <String>                       |
  |   @description: calls makeRequest while           |
  |       passing in an option object that            |
  |       describes http request type (GET) and body  |
  |   @return: response from backend                  |
  |===================================================|
*/
export const getStripe = async (url) => makeRequest(url, {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
  },
});

/*
  |====================================================|
  |   @method: postStripe                              |
  |   @parameters: url <String>, data <Object>         |
  |   @description: calls makeRequest while            |
  |       passing in an option object that             |
  |       describes http request type (POST) and body  |
  |   @return: response from backend                   |
  |====================================================|
*/
export const postStripe = (url, data) => makeRequest(url, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(data),
});

/*
  |===================================================|
  |   @method: postStripe                             |
  |   @parameters: url <String>, data <Object>        |
  |   @description: calls makeRequest while           |
  |       passing in an option object that            |
  |       describes http request type (PUT) and body  |
  |   @return: response from backend                  |
  |===================================================|
*/
export const updateStripe = (url, data) => makeRequest(url, {
  method: 'PUT',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(data),
});

/*
  |===================================================|
  |   @method: postStripe                             |
  |   @parameters: url <String>                       |
  |   @description: calls makeRequest while           |
  |       passing in an option object that            |
  |       describes http request type (DELETE)        |
  |       and body                                    |
  |   @return: response from backend                  |
  |===================================================|
*/
export const removeStripe = (url) => makeRequest(url, {
  method: 'DELETE',
  headers: {
    'Content-Type': 'application/json',
  },
});

/*
  |===================================================|
  |   @method: secondsToISO                           |
  |   @parameters: timestamp <Number>                 |
  |   @description: converts the timestamp (seconds)  |
  |       into a ISO date format                      |
  |   @return: <String>                               |
  |===================================================|
*/
export const secondsToISO = (timestamp) => {
  const date = new Date(timestamp * 1000);
  return date.toDateString();
};
