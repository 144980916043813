import MarkerClusterer from '@googlemaps/markerclustererplus';
// import severityColorPicker from '@components/images/severity';
import { severityColorPicker } from '@methods/helpers';
import { nfContent } from '@utils/map/Content';
import { setImage, removeImage, getImage } from '@utils/map/session-storage';
import calculator from '@utils/map/map-clustering/calculator';
import clusterStyles from '@utils/map/map-clustering/cluster-styles';
import moment from 'moment';
import { dateFormats } from '@utils/time-formats';
import store from '../../store';

class Map {
  constructor(google, mapElement, options) {
    // From arguments
    this.google = google; // gmaps api
    this.map = new this.google.maps.Map(mapElement, options); // map

    // Image Details
    this.currentImage = '';

    // Values set by setters
    this.heatmap = null; // heatmap layer
    this.heatmapOn = false;
    this.markers = []; // list of markers
    this.markerClusterer = null; // markerclusterer
    this.currentInfo = null; // the current info that is opened
    this.currentImage = null; // current image filename
    this.spiderfier = null;

    // Listeners
    this.markerListeners = [];
    this.infoWindowListeners = [];

    // Current Positions
    this.position = null;
    this.zoom = options.zoom;

    // Value will stay unchanged
    this.severityCode = {
      None: 0,
      Low: 10.0,
      Medium: 50.0,
      High: 100.0,
    };
  }

  /*
    |------------------------------------------|
    |           I N I T I A L I Z E R S        |
    |------------------------------------------|
  */
  setMapLoadedEvent() {
    this.google.maps.event.addListener(this.map, 'tilesloaded', () => {
      this.markerClusterer.clearMarkers();

      const currentMarkers = this.markers.filter((marker) => {
        const isVisible = marker.getVisible();
        const position = marker.getPosition();
        const boundContainsMarker = this.map.getBounds().contains(position);

        return isVisible && boundContainsMarker;
      });

      this.markerClusterer.addMarkers(currentMarkers);
    });
  }

  /*
    |--------------------------------------------------|
    |   @method       setMapId                         |
    |   @parameters   hasRoadMap <Boolean>             |
    |   @description  Checks if client has roadmap     |
    |                 configured in their database     |
    |                 entry and set the map type id    |
    |                 accordingly                      |
    |--------------------------------------------------|
  */
  setMapId(hasRoadmap) {
    const mapId = (hasRoadmap) ? 'roadmap' : 'satelite';
    this.map.setMapTypeId(mapId);
  }

  /*
    |--------------------------------------------------|
    |   @method       setCenterControl                 |
    |   @parameters   controlDiv <DOM Element>, label  |
    |                 <String>                         |
    |   @description  Programatically creates a button |
    |                 to toggle heatmap or markers     |
    |--------------------------------------------------|
  */
  setCenterControl(controlDiv, label) {
    const controlUI = document.createElement('div');
    controlUI.style.backgroundColor = '#fff';
    controlUI.style.border = '2px solid #fff';
    controlUI.style.borderRadius = '3px';
    controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
    controlUI.style.cursor = 'pointer';
    controlUI.style.marginBottom = '22px';
    controlUI.style.textAlign = 'center';
    controlUI.title = label;
    controlDiv.appendChild(controlUI);

    const controlText = document.createElement('div');
    controlText.style.color = 'rgba(25, 25, 25)';
    controlText.style.fontFamily = 'Roboto, Arial, sans-serif';
    controlText.style.fontSize = '10px';
    controlText.style.lineHeight = '38px';
    controlText.style.paddingLeft = '5px';
    controlText.style.paddingRight = '5px';
    controlText.innerHTML = label;
    controlUI.appendChild(controlText);

    controlUI.addEventListener('click', () => {
      if (label === 'Toggle Heatmap') this.toggleHeatmap();
      else this.toggleMarkers();
    });
  }

  /*
    |-------------------------------------------------------|
    |               H E A T M A P  L A Y E R                |
    |-------------------------------------------------------|
  */
  /*
    |--------------------------------------------------|
    |   @method       toggleHeatMap                    |
    |   @parameters   <Void>                           |
    |   @description  Toggles the heatmap layer        |
    |--------------------------------------------------|
  */
  toggleHeatMap() {
    if (this.heatmapOn) this.heatmap.setMap(this.map);
    else this.heatmap.setMap(null);
    this.heatmapOn = !this.heatmapOn;
  }

  /*
    |-----------------------------------------------------------|
    |   @method       setHeatMap                                |
    |   @parameters   images [<Objects>]                        |
    |   @description  Maps through all the images within a      |
    |                 project to create a heatmap layer         |
    |-----------------------------------------------------------|
  */
  setHeatMap(images) {
    const { maps } = this.google;

    // Filter out the images that aren't processed
    // and map it to a location and severity weight
    const data = images
      .filter((image) => image.processedImageUrl)
      .map((image) => {
        const [lat, lng] = image.location;
        const location = new maps.LatLng(lat, lng);
        const weight = this.severityCode[image.process_tracking.slice(-1)[0].severity];
        return { location, weight };
      });
    this.heatmap = new this.google.maps.visualization.HeatmapLayer({ data });
    this.heatmap.set(this.map);
    this.heatmap.set('radius', 40.0);
    this.heatmapOn = true;
  }

  /*
    |-------------------------------------|
    |           M A R K E R S             |
    |-------------------------------------|
  */

  /*
    |-----------------------------------------------------------|
    |   @method       setMarkers                                |
    |   @parameters   images [<Objects>]                        |
    |   @description  Maps through all the images within a      |
    |                 project to create markers and infoWindows |
    |-----------------------------------------------------------|
  */
  setMarkers(images) {
    this.markers = images.map((image) => {
      const { location } = image;

      // Get new instance of LatLng using image coordinate points
      const position = new this.google.maps.LatLng(location[0], location[1]);

      // Set up new instance of InfoWindow
      const infoWindow = new this.google.maps.InfoWindow({ displayAutoPan: true });

      // Set up new instnace of Marker
      const marker = new this.google.maps.Marker({
        title: `${image.filename}_${image.date}`,
        position,
        id: image.id,
        icon: this.severityMarker(image.process_tracking.slice(-1)[0].severity, false),
        optimized: true,
      });

      // Use spiderfier to track the current marker
      this.spiderfier.trackMarker(marker);

      // Add a new google map event listener 'spider_click'
      // This is necessary because the normal gmaps 'click' event will reset the map everytime
      // a user clicks on a coordinate point
      const markerListener = this.google.maps.event.addListener(marker, 'spider_click', async () => {
        // Save image filename within session storage so users can return back to the point
        // after refreshing the page
        this.currentImage = image.filename;
        // store.dispatch('setCurrentImage', image);
        this.map.panTo(position);
        const zoom = this.map.getZoom();
        this.zoom = zoom;
        this.position = position;

        if (zoom !== 19) this.map.setZoom(19);
        const pointer = store.getters.allImages
          .findIndex((img) => img.filename === image.filename);
        store.dispatch('setLazyLoadPointer', pointer);

        // Retrieve info window content. Set as an asynchronous function because
        // we make an asynchronous call to retrieve the geolocation of the lat/lng
        const windowContent = await this.getInfoWindowContent(image);
        infoWindow.setContent(windowContent);

        // When the info window is closed using the 'x' close button, it removes the item
        // within the session storage
        const infoWindowListener = infoWindow.addListener('closeclick', () => {
          removeImage();
        });

        this.infoWindowListeners.push(infoWindowListener);

        // If there's currently a infoWindow that's opened
        // close the previous one and open the new one
        if (this.currentInfo) {
          this.closeInfoWindow();
        }
        this.openInfoWindow(infoWindow, marker);

        // Set the new current image within the Vuex store
        // store.dispatch('setCurrentImage', image);
        this.currentImage = image.filename;
      });

      this.markerListeners.push(markerListener);

      return marker;
    });

    return this.markers;
  }

  /*
    |-----------------------------------------------------------|
    |   @method       removeMarkerListeners                     |
    |   @parameters   <Void>                                    |
    |   @description  Iterates through an array of marker event |
    |                 listeners, and removes it from instance   |
    |-----------------------------------------------------------|
  */
  changeColor(image) {
    // Find marker using the marker's title as the identifier
    const marker = this.markers.find((m) => {
      const { title } = m;
      return title === `${image.filename}_${image.date}`;
    });

    // Extract the new severity level
    const { severity } = image.process_tracking.slice(-1)[0];

    // Set the new icon
    const icon = this.severityMarker(severity, false);
    marker.setIcon(icon);
  }

  /*
    |-----------------------------------------------------------|
    |   @method       removeMarkerListeners                     |
    |   @parameters   <Void>                                    |
    |   @description  Iterates through an array of marker event |
    |                 listeners, and removes it from instance   |
    |-----------------------------------------------------------|
  */
  removeMarkerListeners() {
    this.markerListeners.forEach((listener) => {
      this.google.maps.event.removeListener(listener);
    });

    this.markerListeners = [];
  }

  /*
    |-----------------------------------------------------------|
    |   @method       clearMarker                               |
    |   @parameters   <Void>                                    |
    |   @description  Iterates through the array of markers and |
    |                 sets each marker options to null          |
    |-----------------------------------------------------------|
  */
  clearMarkers() {
    this.markers.forEach((marker) => {
      marker.setMap(null);
    });
    this.markers = [];
    this.markerListeners = [];
  }

  /*
    |-----------------------------------------------------------|
    |   @method       severityMarker                            |
    |   @parameters   severity <String>, tdScaling <Boolean>    |
    |   @description  Uses tdScaling boolean value to dictate   |
    |                 whether or not we use the 'none', 'low'   |
    |                 'medium', and 'high' scaling for choosing |
    |                 a color for the map point. Otherwise it   |
    |                 uses the TD-1 to TD-5 scaling             |
    |-----------------------------------------------------------|
  */
  severityMarker(severity) {
    return {
      path:
    'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z',
      fillColor: severityColorPicker(severity).color,
      fillOpacity: 1,
      strokeWeight: 1,
      rotation: 0,
      scale: 1.3,
      anchor: new this.google.maps.Point(15, 30),
    };
  }

  /*
    |-------------------------------------------------|
    |        M A R K E R  C L U S T E R E R           |
    |-------------------------------------------------|
  */

  /*
    |-----------------------------------------------------------|
    |   @method       setMarkerClusterer                        |
    |   @parameters   <Void>                                    |
    |   @description  Sets up marker clusterers for images on   |
    |                 map                                       |
    |-----------------------------------------------------------|
  */
  setMarkerClusterer() {
    const options = {
      gridSize: 30,
      maxZoom: 16,
      styles: clusterStyles,
      clusterClass: 'custom-clustericon',
    };
    this.markerClusterer = new MarkerClusterer(this.map, [], options);
    this.markerClusterer.setCalculator(calculator);
    return this.markerClusterer;
  }

  /*
    |------------------------------|
    |     I N F O  W I N D O W     |
    |------------------------------|
  */
  /*
    |---------------------------------------|
    | @method       closeInfoWindow         |
    | @parameters   <Void>                  |
    | @description  Closes the info window  |
    |---------------------------------------|
  */
  closeInfoWindow() {
    this.currentInfo.close();
  }

  /*
    |-----------------------------------------------|
    | @method       openInfoWindow                  |
    | @parameters   currentInfoWindow <InfoWindow>  |
    |               marker <Marker>                 |
    | @description  Opens info window               |
    |-----------------------------------------------|
  */
  openInfoWindow(currentInfoWindow, marker) {
    this.currentInfo = currentInfoWindow;
    currentInfoWindow.open(this.map, marker);
  }

  /*
    |---------------------------------------------------|
    | @method       getInfoWindowContent                |
    | @parameters   image <Object>                      |
    | @description  Extracts image details and displays |
    |               on the info window. It also extracts|
    |               the address of the coordinate point |
    |---------------------------------------------------|
  */
  getInfoWindowContent(image) {
    const geocoder = new this.google.maps.Geocoder();
    const coordinates = new this.google.maps.LatLng(image.location[0], image.location[1]);

    return new Promise((resolve, reject) => {
      geocoder.geocode({ location: coordinates }, (results, status) => {
        if (status !== 'OK' || !results[0]) {
          reject(status);
        }
        const [processedImage] = image.process_tracking.slice(-1);
        const address = results[0].formatted_address;
        const labels = processedImage.labels.map((label) => {
          if (typeof label === 'string') {
            return label;
          }
          return `${label.severity}:${label.label}`;
        });
        const windowContent = nfContent(
          processedImage.caption,
          labels,
          processedImage.severity,
          address,
          image.timestamp
            ? moment.utc(image.timestamp, 'YYYY-MM-DD_HH:mm:ss').format(dateFormats.DAY_DATE_TIME)
            : '',
          image.filename,
        );
        resolve(windowContent);
      });
    });
  }

  updateInfoWindowContent(image) {
    this.getInfoWindowContent(image)
      .then((windowContent) => {
        this.currentInfo.setContent(windowContent);
      })
      .catch((error) => {
        console.error('Error updating info window content: ', error);
      });
  }

  removeInfoWindowListeners() {
    this.infoWindowListeners.forEach((listener) => {
      this.google.maps.event.removeListener(listener);
    });

    this.infoWindowListeners = [];
  }

  /*
    |-----------------------------------------------------------|
    |   @method       setOverlappingSpiderfier                  |
    |   @parameters   <Void>                                    |
    |   @description  Sets up a spiderfier on map when multiple |
    |                 coordinate points are clumped together    |
    |-----------------------------------------------------------|
  */
  setOverlappingSpiderfier() {
    const options = {
      basicFormatEvents: true,
      circleFootSeparation: 40,
      keepSpiderfied: true,
      markersWontHide: true,
      markersWontMove: true,
    };
    // eslint-disable-next-line no-undef
    this.spiderfier = new OverlappingMarkerSpiderfier(this.map, options);
  }

  /*
    |---------------------------------------|
    |     S E S S I O N  S T O R A G E      |
    |---------------------------------------|
  */

  /*
    |---------------------------------------|
    | @method       setImageSession         |
    | @parameters   filename <String>       |
    | @description  Sets this.currentImage  |
    |               and session storage to  |
    |               contain the filename    |
    |---------------------------------------|
  */
  setImageSession(filename) {
    this.currentImage = filename;
    setImage(filename);
  }

  /*
    |----------------------------------------------|
    | @method       removeImageSession             |
    | @parameters   None                           |
    | @description  Sets this.currentImage         |
    |               to null and removes the        |
    |               filename from session          |
    |               storage                        |
    |----------------------------------------------|
  */
  removeImageSession() {
    this.currentImage = null;
    removeImage();
  }

  /*
    |-----------------------------------------------|
    | @method       getImageSession                 |
    | @parameters   None                            |
    | @description  Attempts to get the image       |
    |               filename from session storage.  |
    |               It will return null if none is  |
    |               set.                            |
    |-----------------------------------------------|
  */
  getImageSession() {
    const filename = getImage();
    if (filename) this.currentImage = filename;
    return filename;
  }

  /*
    |---------------------------------------|
    |         C O N V E R T E R S           |
    |---------------------------------------|
  */
  getCoordinatesFromAddress(location) {
    const geocoder = new this.google.maps.Geocoder();

    return new Promise((resolve, reject) => {
      geocoder.geocode({ address: location }, (results, status) => {
        if (status !== 'OK' || !results[0]) {
          reject(status);
        }

        const [locationResults] = results;

        resolve(locationResults);
      });
    });
  }
}

export default Map;
