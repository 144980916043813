import { all } from '@components/analytics/insights/filters/faults';
import severities from '@utils/severity-level';

const state = {
  index: 0,
  project: 'all',
  faults: all,
  severities: [],
  priorities: [],
  panels: { reports: false, abbreviations: false, folders: false },
  filterStatus: {
    severity: {
      opened: false,
      all: true,
      custom: false,
      values: [...severities],
    },
    priority: {
      opened: false,
      all: true,
      custom: false,
      values: ['None', 'TD-1', 'TD-2', 'TD-4', 'TD-5', 'Emergency'],
    },
    fault: {
      opened: false,
      all: true,
      custom: false,
      values: all,
    },
  },
  retrievingImage: false,
  retrievingFolder: false,
  retrievingProject: false,
  retrievingCsv: false,
  retrievingPdfData: false,
  insightFilters: [],
};

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const getters = {
  currentInsightsIndex: (state) => state.index,
  insightsFaults: (state) => state.faults,
  insightsSeverities: (state) => state.severities,
  insightsPriorities: (state) => state.priorities,
  currentPanelStates: (state) => state.panels,
  currentFilterStatus: (state) => state.filterStatus,
  retrievingImageData: (state) => state.retrievingImage,
  retrievingFolderData: (state) => state.retrievingFolder,
  retrievingProjectData: (state) => state.retrievingProject,
  retrievingPdfData: (state) => state.retrievingPdfData,
  retrievingCsvData: (state) => state.retrievingCsv,
  insightFilters: (state) => state.insightFilters,
};

const actions = {
  setInsightsIndex({ commit }, index) {
    commit('set_index', index);
  },
  setInsightsProject({ commit }, project) {
    commit('set_project', project);
  },
  setInsightsFaults({ commit }, faults) {
    commit('set_faults', faults);
    commit('set_index', 0);
  },
  setInsightsSeverities({ commit }, insightsSeverities) {
    commit('set_severities', insightsSeverities);
    commit('set_index', 0);
  },
  setInsightsPriorities({ commit }, priorities) {
    commit('set_priorities', priorities);
    commit('set_index', 0);
  },
  setPanelStates({ commit }, panels) {
    commit('set_panels', panels);
  },
  setFilterStatus({ commit }, status) {
    commit('set_filter_status', status);
  },
  setInsightFilters({ commit }, status) {
    commit('set_insight_filters', status);
  },
  setRetrieveImage({ commit }, status) { commit('retrieving_image_by_project', status); },
  setRetrieveFolder({ commit }, status) { commit('retrieving_folder_by_project', status); },
  setRetrieveProject({ commit }, status) {
    commit('retrieving_project', status);
  },
  setRetrieveCsv({ commit }, status) {
    commit('retrieving_csv', status);
  },
  setRetrievePdf({ commit }, status) {
    commit('retrieving_pdf', status);
  },
};

const mutations = {
  set_index: (state, index) => {
    state.index = index;
  },
  set_project: (state, project) => {
    state.project = project;
  },
  set_faults: (state, faults) => {
    state.faults = faults;
  },
  set_severities: (state, severityLevels) => {
    state.severities = severityLevels;
  },
  set_priorities: (state, priorities) => {
    state.priorities = priorities;
  },
  set_panels: (state, panels) => { state.panels = panels; },
  set_filter_status: (state, status) => { state.filterStatus = status; },
  set_insight_filters: (state, status) => { state.insightFilters = status; },
  retrieving_image_by_project: (state, status) => { state.retrievingImage = status; },
  retrieving_folder_by_project: (state, status) => { state.retrievingFolder = status; },
  retrieving_project: (state, status) => {
    state.retrievingProject = status;
  },
  retrieving_csv: (state, status) => {
    state.retrievingCsv = status;
  },
  retrieving_pdf: (state, status) => {
    state.retrievingPdfData = status;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
