<template>
  <v-row>
    <v-col
      xs="12"
      sm="12"
      md="4"
      lg="4"
      xl="4"
    >
      <v-skeleton-loader
        v-bind="attrs"
        :loading="true"
        type="card"
      ></v-skeleton-loader>
    </v-col>
    <v-col
      xs="12"
      sm="12"
      md="4"
      lg="4"
      xl="4"
    >
      <v-skeleton-loader
        v-bind="attrs"
        type="card"
      ></v-skeleton-loader>
    </v-col>
     <v-col
        xs="12"
        sm="12"
        md="4"
        lg="4"
        xl="4"
      >
        <v-skeleton-loader
          v-bind="attrs"
          type="list-item-three-line, article"
        ></v-skeleton-loader>
      </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'StructureImageLoader',
  props: ['attrs'],
};
</script>
