import low from '../../assets/icons/low.png';
import medium from '../../assets/icons/medium.png';
import high from '../../assets/icons/high.png';
import none from '../../assets/icons/none.png';
import noImagesIcon from '../../assets/icons/no-images.png';
import criticalIcon from '../../assets/icons/critical.png';

const svg = {
  default: 'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z',
  check: 'M12,2C15.86,2 19,5.14 19,9C19,14.25 12,22 12,22C12,22 5,14.25 5,9C5,5.14 8.14,2 12,2M10.47,14L17,7.41L15.6,6L10.47,11.18L8.4,9.09L7,10.5L10.47,14Z',
  pending: 'M12 2C15.9 2 19 5.1 19 9C19 14.2 12 22 12 22S5 14.2 5 9C5 5.1 8.1 2 12 2M11 6V12H13V6H11M11 14V16H13V14H11Z',
  severe: 'M12,2C8.14,2 5,5.14 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9C19,5.14 15.86,2 12,2M9.59,5.17L12,7.58L14.41,5.17L15.83,6.58L13.41,9L15.83,11.41L14.41,12.83L12,10.41L9.59,12.83L8.17,11.41L10.59,9L8.17,6.58',
  question: 'M12,2C8.14,2 5,5.14 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9C19,5.14 15.86,2 12,2M12.88,15.75H11.13V14H12.88M12.88,12.88H11.13C11.13,10.04 13.75,10.26 13.75,8.5A1.75,1.75 0 0,0 12,6.75A1.75,1.75 0 0,0 10.25,8.5H8.5A3.5,3.5 0 0,1 12,5A3.5,3.5 0 0,1 15.5,8.5C15.5,10.69 12.88,10.91 12.88,12.88Z',
  dot: 'M12,8A4,4 0 0,0 8,12A4,4 0 0,0 12,16A4,4 0 0,0 16,12A4,4 0 0,0 12,8Z',
};

// If image count is anywhere between 2-8, it's correct
const checkImageAmount = (folder) => folder.image_count >= 2 && folder.image_count <= 8;
const checkInspection = (folder) => folder.complete_inspection;
const secondReviewerExists = (folder) => Object.keys(folder.second_reviewer).length > 0;
const secondReviewerReviewed = (folder) => folder.second_reviewer.reviewed;

// Seems like distribution is the default logic???
export const distribution = (folder) => {
  if (folder.clustering_uncertainty) {
    return svg.question;
  }
  // If the second Reviewer Exists
  if (!secondReviewerExists(folder)) {
    // Check if the inspection is complete
    const completeInspection = checkInspection(folder);

    // If it isn't complete, then check the image amount
    if (!completeInspection) {
      const correctImageCount = checkImageAmount(folder);

      // Incorrect image amount results in a question mark
      if (!correctImageCount) return svg.question;

      // Otherwise it goes to default
      return svg.default;
    }

    // Show check mark
    return svg.check;
  }

  // If the second reviewer hasn't reviewed the folder yet, set it as
  // the exclamation point
  if (!secondReviewerReviewed(folder)) return svg.pending;

  // Otherwise, display checkmark
  return svg.check;
};

export const transmission = (folder) => {
  // If the second Reviewer Exists
  if (!secondReviewerExists(folder)) {
    // Check if the inspection is complete
    const completeInspection = checkInspection(folder);

    // If it isn't complete, then check the image amount
    if (!completeInspection) {
      // Otherwise it goes to default
      return svg.default;
    }

    // Show check mark
    return svg.check;
  }

  // If the second reviewer hasn't reviewed the folder yet, set it as
  // the exclamation point
  if (!secondReviewerReviewed(folder)) return svg.pending;

  // Otherwise, display checkmark
  return svg.check;
};

export const conductors = svg.dot;
export const noImages = noImagesIcon;
export const getMapIcon = (severity) => {
  switch (severity) {
    case 'Low':
      return low;
    case 'Medium':
      return medium;
    case 'High':
      return high;
    case 'Critical':
      return criticalIcon;
    default:
      return none;
  }
};
