import moment from 'moment';
import platformBackendClient from '@backend-clients/platform-backend';

export default {
  create: (cid, pid, payload) => new Promise((resolve, reject) => {
    platformBackendClient.post(`/api/folder/?company_id=${cid}&project_id=${pid}`, payload)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  }),
  update: (payload) => new Promise((resolve, reject) => {
    platformBackendClient.put(`/api/folder/project/${payload.pid}/company/${payload.cid}/${payload.sid}`, payload)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  }),
  recount: (cid, pid) => platformBackendClient.put(`/api/folder/recount/?company_id=${cid}&project_id=${pid}`),

  getByCompany: (cid) => new Promise((resolve, reject) => {
    platformBackendClient.get(`/api/folder/company/${cid}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  }),
  /*
    |-------------------------------------------------|
    |   @method       getByCompany                    |
    |   @params       cid <String>, pid <String>      |
    |   @return       Promise [<folders>]             |
    |   @description  Takes in the company id and the |
    |                 project id to fetch a list of   |
    |                 folders within a project        |
    |-------------------------------------------------|
  */
  getByProject: (cid, pid) => new Promise((resolve, reject) => {
    platformBackendClient.get(`/api/folder/company/${cid}/project/${pid}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  }),
  saveFolders: (payload) => new Promise((resolve, reject) => {
    platformBackendClient.post('/api/folder/save_folders', payload)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  }),
  payload: (cid, pid, companyId, projectId, path, count) => ({
    company_id: companyId,
    project_id: projectId,
    data: {
      pid,
      cid,
      structure: {
        name: path,
        structuretype_id: 0,
        lat: null,
        long: null,
        severity: null,
        project_id: projectId,
        line_id: null,
        work_order: false,
        reviewed: false,
        second_reviewer: false,
        note: '',
        image_count: count,
        complete_inspection: false,
        cid,
        date: moment(),
        processed_image_count: 0,
        clustering_uncertainty: false,
        field_inspection: false,
      },
    },
  }),
};
