<template>
  <div style="
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background-color: #D3D3D3;
  height: calc(100vh - 60px);"
  >

   <AppCard
            @click="goTo('PowerAI')"
            :icon="'/favicon.png'"
            description="The most accurate AI-based visual data processing software platform
            to optimize visual inspections ">
     <div >Power<span style="color:#FFB600 ">AI</span></div>
   </AppCard>

    <AppCard
             :icon="'/favicon.png'"
             @click="goTo('PowerGUARD')"
             description="The most advanced security and asset
             condition monitoring for manned and unmanned substations.">
     <div >Power<span style="color:#FFB600 ">GUARD</span></div>
    </AppCard>
  </div>
</template>

<script>
import AppCard from '@components/landing/AppCard.vue';
import axios from 'axios';
import firebase from '@database';
import logoIcon from '../../assets/logo.png';

export default {
  name: 'PowerGuard',
  components: { AppCard },
  data: () => ({
    logoIcon,
  }),
  methods: {
    async redirectToPowerGuard() {
      try {
        const token = await firebase.auth().currentUser.getIdToken();
        const response = await axios.get(`${process.env.VUE_APP_V2_BACKEND_URL}/api/v1/auth/url`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            callback: `${process.env.VUE_APP_POWER_GUARD_URL}/#/auth`,
          },
        });
        const url = `${process.env.VUE_APP_V2_BACKEND_URL}/api/v1/auth/exchange?token=${response.data.token}`;
        window.location.href = url;
      } catch (err) {
        console.error(err);
      }
    },
    goTo(name) {
      if (name === 'PowerAI') {
        return this.$router.push({ path: '/projects/view/grid' });
      }
      // call v2 backend to generate an auth url;
      return this.redirectToPowerGuard();
    },
  },
};
</script>

<style scoped>

</style>
