import moment from 'moment';
import firebase from '@database';
import services from '@services';

const db = firebase.database;
const userCollection = db.collection('users');

const state = {
  companies: [],
  company: {},
  employees: [],
  updating: false,
  creatingBucket: false,
  creating: false,
  loadingCompanies: false,
  loading: false,
  loadEmployees: false,
  loadingInvoices: false,
  adding: false,
};

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const getters = {
  allCompanies: (state) => state.companies,
  currentCompany: (state) => state.company,
  updatingCompany: (state) => state.updating,
  loadingCompanies: (state) => state.loadingCompanies,
  isDrift: (state) => state.company.cid === process.env.VUE_APP_DRIFT_ID,
  loadingCompany: (state) => state.loading,
  creatingCompany: (state) => state.creating,
  creatingBucket: (state) => state.creatingBucket,
  loadingEmployees: (state) => state.loadEmployees,
  allEmployees: (state) => state.employees,
  addingUserToInstance: (state) => state.adding,
  isTrial: (state) => {
    const { company } = state;
    return company.companyMode === 1;
  },
  accessExpired: () => {
    const { company } = state;
    return moment().isAfter(moment(company.accessExpiration));
  },
  companyHas: (state) => (feature) => {
    let { config } = state.company;

    config = config === null || config === undefined ? {} : config;
    return config[feature] === true || (feature in config && config[feature] !== false);
  },
  companyConfig: (state) => (feature) => {
    let { config } = state.company;
    config = config === null || config === undefined ? {} : config;
    return config[feature];
  },
};

const actions = {
  setLoadingCompany({ commit }, loading) { commit('loading_company', loading); },
  setEmployeesLoading({ commit }) { commit('loading_employees'); },
  createCompany({ commit }, payload) {
    return new Promise((resolve, reject) => {
      services.companies.create(payload)
        .then((res) => {
          commit('create_company', res.data);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getCompany({ commit }, cid) {
    commit('loading_company', true);
    return new Promise((resolve, reject) => {
      services.companies.get(cid)
        .then((res) => {
          commit('fetch_company', res.data);
          resolve(res);
        })
        .catch((err) => {
          commit('loading_company', false);
          reject(err);
        });
    });
  },

  getCompanies({ commit }) {
    commit('loading_companies', true);

    return new Promise((resolve, reject) => {
      services.companies.all()
        .then((res) => {
          commit('fetch_companies', res.data);
          commit('loading_companies', false);
          resolve(res);
        })
        .catch((err) => {
          commit('loading_companies', false);
          reject(err);
        });
    });
  },
  updateCompany({ commit }, updatedCompany) {
    commit('updating_company', true);
    return new Promise((resolve, reject) => {
      services.companies.update(updatedCompany.cid, updatedCompany)
        .then((res) => {
          commit('update_company', res.data);
          commit('updating_company', false);
          resolve(res);
        })
        .catch((err) => {
          commit('updating_company', false);
          reject(err);
        });
    });
  },

  updateClient({ commit }, payload) {
    return new Promise((resolve, reject) => {
      services.companies.update(payload.id, payload.updates)
        .then((res) => {
          commit('update_company', res.data);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },

  // Employees
  getEmployeesByCompanyId({ commit }, cid) {
    commit('loading_employees');

    return new Promise((resolve, reject) => {
      userCollection
        .where('cid', '==', cid)
        .get()
        .then((querySnapshot) => {
          const { docs } = querySnapshot;
          const employees = docs.map((doc) => doc.data());

          commit('fetch_employees', employees);
          resolve(employees);
        })
        .catch((err) => reject(err));
    });
  },

  updateEmployee({ commit }, newEmployee) {
    const { uid } = newEmployee;

    return new Promise((resolve, reject) => {
      userCollection
        .doc(uid)
        .update(newEmployee)
        .then(() => {
          const status = {
            code: 'SUCCESS',
            message: 'Updated employee data',
          };
          commit('update_employee', newEmployee);
          resolve(status);
        })
        .catch((err) => reject(err));
    });
  },
  deleteEmployee({ commit, state }, uid) {
    const withoutEmployee = state.employees.filter((e) => e.uid !== uid);
    commit('fetch_employees', withoutEmployee);
  },
  addEmployee({ commit, state }, employee) {
    const withEmployee = [...state.employees, employee];
    commit('fetch_employees', withEmployee);
  },
  createStorageBucket({ commit }, bucketName) {
    commit('creating_bucket', true);

    return new Promise((resolve, reject) => {
      services.storage.storage_create(bucketName)
        .then((res) => {
          commit('creating_bucket', false);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  loading_company: (state, loading) => { state.loading = loading; },
  creating_company: (state) => { state.creating = true; },
  loading_companies: (state, loading) => { state.loadingCompanies = loading; },
  loading_employees: (state) => { state.loadEmployees = true; },
  loading_invoices: (state, status) => { state.loadingInvoices = status; },
  updating_company: (state, updating) => { state.updating = updating; },
  create_company: (state, company) => { state.companies.unshift(company); },
  fetch_companies: (state, companies) => { state.companies = companies; },
  fetch_company: (state, company) => {
    state.company = company;
    state.loading = false;
  },
  fetch_employees: (state, employeeList) => {
    state.employees = employeeList;
    state.loadEmployees = false;
  },
  fetch_invoices: (state, invoiceList) => {
    state.invoices = invoiceList;
    state.loadingInvoices = false;
  },
  update_employee: (state, newEmployee) => {
    const index = state.employees.findIndex((employee) => employee.uid === newEmployee.uid);
    if (index !== -1) state.employees.splice(index, 1, newEmployee);
  },
  update_company: (state, newCompany) => {
    const index = state.companies.findIndex((company) => company.cid === newCompany.cid);
    if (index !== -1) {
      state.companies.splice(index, 1, newCompany);
    }
  },
  update_company_list: (state, newCompany) => {
    const index = state.companies.findIndex((company) => company.cid === newCompany.cid);
    if (index !== -1) state.companies.splice(index, 1, newCompany);
  },
  creating_bucket: (state, creating) => { state.creatingBucket = creating; },
  add_user_instance: (state, addingUser) => { state.adding = addingUser; },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
